import React from "react";
import { Box } from "@chakra-ui/layout";
import { Flex, Heading } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { Step, Steps, useSteps } from "chakra-ui-steps";
import Personal from "./personal";
import Pending from "./pending";
import Services from "./services";
import WorkingHours from "./workingHours";
import Qualifications from "./qualifications";

export const Stepper = () => {
  const user = useSelector((state) => state?.user);
  const { nextStep, prevStep, activeStep } = useSteps({
    initialStep: 0,
  });

  const steps = [
    {
      label: "Personal",
      element: (
        <Personal
          nextStep={nextStep}
          prevStep={prevStep}
          activeStep={activeStep}
        />
      ),
    },
    {
      label: "Services",
      element: (
        <Services
          nextStep={nextStep}
          prevStep={prevStep}
          activeStep={activeStep}
        />
      ),
    },
    {
      label: "Qualifications",
      element: (
        <Qualifications
          nextStep={nextStep}
          prevStep={prevStep}
          activeStep={activeStep}
        />
      ),
    },
    {
      label: "Working Hours",
      element: (
        <WorkingHours
          nextStep={nextStep}
          prevStep={prevStep}
          activeStep={activeStep}
        />
      ),
    },
  ];

  // const isLastStep = activeStep === steps.length - 1;
  const hasCompletedAllSteps = activeStep === steps.length;

  return (
    <Flex flexDir="column" width="100%" backgroundColor="white">
      {!hasCompletedAllSteps ? (
        <Steps
          variant="circles-alt"
          responsive
          colorScheme="blue"
          sx={{
            "& .css-eb100c": {
              _completed: {
                color: "white",
              },
              color: "unset",
            },
            "& .css-1emvh1t, .css-vv8cpc": {
              fontSize: "14px",
              fontFamily: "Inter",
              mt: "8px",
              lineHeight: "20px",
            },
            "& .css-yli7i8:not(:last-child):after": {
              top: "calc(60px/2)",
              // borderBottom: 'dashed',
            },
            "& .css-1j537u4[aria-current=step]": {
              backgroundColor: "#4299E1",
              color: "white",
              border: "none",
              textColor: "white",
            },
          }}
          activeStep={activeStep}
        >
          {steps.map(({ label, element }, index) => (
            <Step label={label} key={index}>
              <Box sx={{ p: 8, mt: "40px", rounded: "md" }}>
                <Heading
                  sx={{
                    "& .chakra-form__required-indicator": {
                      color: "black",
                    },
                  }}
                  textAlign="center"
                >
                  {element}
                </Heading>
              </Box>
            </Step>
          ))}
        </Steps>
      ) : (
        !user?.profile?.verified && (
          <Box>
            {/* <Button onClick={prevStep}>Go Back</Button> */}
            <Pending />
          </Box>
        )
      )}
    </Flex>
  );
};

import { Flex, Image, Text } from "@chakra-ui/react";
import React from "react";
import { BACK_BTN } from "../../../constant";

const GroupCard = ({ categories }) => {
  return (
    <Flex mt="16px" gap="24px">
      {categories.map((c, index) => (
        <Flex
          flexDir="column"
          flexWrap="wrap"
          h="210px"
          border="1px solid #d8e5ee"
          borderRadius="10px"
          p="16px"
          justify="space-between"
          w="245px"
          align="center"
          key={index}
        >
          <Image w="64px" h="64px" src={c.image} />
          <Text
            fontSize="14px"
            fontWeight="400"
            lineHeight="20px"
            textAlign="center"
          >
            {c.title}
          </Text>
          <Flex gap="8px" width="100%" justifyContent="center">
            <Text
              fontSize="14px"
              fontWeight="600"
              lineHeight="20px"
              color="#4299e1"
              mt="16px"
              onClick={() => window.open(c.link)}
              cursor="pointer"
            >
              Join Now
            </Text>
            <Image
              transform="rotate(180deg) translateY(-8.5px)"
              src={BACK_BTN}
              onClick={() => window.open(c.link)}
              cursor="pointer"
            />
          </Flex>
        </Flex>
      ))}
    </Flex>
  );
};

export default GroupCard;

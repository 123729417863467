import { useEffect, useState } from "react";
import {
  Box,
  GridItem,
  Image,
  Grid,
  Avatar,
  Button,
  Show,
  Flex,
  Text,
  useDisclosure,
  Spinner,
} from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import AuthenticatedContainer from "../../../container/Authenticated";
import ProgressBar from "../../../components/ProgressBar";
import Loader from "../../../common/components/Loader";
import {
  APPLE,
  ARTICLE1,
  ARTICLE2,
  ARTICLE3,
  ARTICLE4,
  CAKE_ICON,
  FLAG,
  MUSIC,
  QUOTES,
  USER_ICON,
  VIDEO,
  DOTTED_BORDER,
  LOCKED_CHECKPOINT,
  AUTH_BOTTOM_BG_MOBILE,
  ROUTES_PATH,
  ACTIVITIES,
  ARTICLES,
} from "../../../constant";
import {
  getBalanceDetails,
  getChildPathway,
  getLastAttendedSession,
  getParentUpcomingSession,
  getPopularArticles,
  getUserProfile,
  saveOutcomeDetails,
  toggleAPILoader,
  toggleMainLoader,
  toggleFeddbackLoader,
  toggleSessionLoader,
} from "../../../modules/actions";
import { calculateAge, istTime, istTime2 } from "../../../utils/customHooks";
import {
  getDateMonthYear1,
  isBeforeTodaysDate,
} from "../../../utils/customMoment";
import moment from "moment";
import ScheduleSession from "../ScheduleSession";
import "./home.css";
import "react-circular-progressbar/dist/styles.css";
import FeedbackPopup from "../../../components/FeedbackPopup";
import { Toaster } from "../../../common/components/Toaster";
import {
  addFeedbackService,
  startMeetingService,
} from "../../../modules/services";
import ValidityExpiredPopup from "../../../components/ValidityExpiredPopup";
import RescheduledPopup from "../../../components/RescheduledPopup";
import CancellationPopup from "../../../components/CancellationPopup";

const articleImgArray = [ARTICLE1, ARTICLE2, ARTICLE3, ARTICLE4];
const othersArray = [
  { img: APPLE, text: "Articles" },
  { img: MUSIC, text: "Activities" },
  { img: VIDEO, text: "Videos" },
  // { img: ACTIVITIES, text: "Activities" },
  // { img: ARTICLES, text: "Articles" },
];

const ParentHome = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const pathway = useSelector((state) => state?.pathway);
  const user = useSelector((state) => state.user?.profile);
  const userBalance = useSelector((state) => state.userBalance);
  const popularArticles = useSelector((state) => state?.popularArticles);
  const { mainLoader, apiLoader, feedbackLoader, sessionLoader } = useSelector(
    (state) => state?.loader
  );
  const lastAttentedSession = useSelector(
    (state) => state?.lastAttentedSession
  );
  const upcomingSession = useSelector((state) => state?.upcomingSession);

  const [overlayOpen, setOverlayOpen] = useState(false);

  const [feedback, setFeedback] = useState({
    rating: "",
    ratingError: "",
    comment: "",
    improvement_fields: [],
  });
  const [thankYouOpen, setThankYouOpen] = useState(false);
  const {
    isOpen: isFeedbackOpen,
    onOpen: onFeedbackOpen,
    onClose: onFeedbackClose,
  } = useDisclosure();
  const {
    isOpen: isValidityOpen,
    onOpen: onValidityOpen,
    onClose: onValidityClose,
  } = useDisclosure();
  const {
    isOpen: isRescheduleOpen,
    onOpen: onRescheduleOpen,
    onClose: onRescheduleClose,
  } = useDisclosure();
  const {
    isOpen: isCancelOpen,
    onOpen: onCancelOpen,
    onClose: onCancelClose,
  } = useDisclosure();

  useEffect(() => {
    const token = localStorage.getItem("idToken");
    dispatch(toggleSessionLoader(true));
    dispatch(getBalanceDetails());
    dispatch(toggleMainLoader(true));
    dispatch(getUserProfile(token));
    dispatch(toggleMainLoader(true));
    dispatch(
      getParentUpcomingSession({
        filterDate: moment().format("YYYY/MM/DD"),
      })
    );
    dispatch(toggleAPILoader(true));
    dispatch(getLastAttendedSession(user?.id));
    dispatch(toggleMainLoader(true));
    dispatch(getChildPathway(user?.id));
    dispatch(getPopularArticles());
  }, []);

  useEffect(() => {
    if (lastAttentedSession?.feedback?.parent?.feedback === "") {
      onFeedbackOpen();
    }
  }, [lastAttentedSession]);

  useEffect(() => {
    if (location?.state?.isAfterPayment) {
      setOverlayOpen(true);
    }
  }, [location.state]);

  const handleCheckpointView = (outcome) => {
    dispatch(saveOutcomeDetails(outcome));
    navigate(ROUTES_PATH.PARENT_EXERCISE);
  };
  const handleFeedbackDataChange = (name, value, errorName) => {
    setFeedback({ ...feedback, [name]: value, [errorName]: "" });
  };

  const handleSceduleSession = () => {
    (user?.validTill && isBeforeTodaysDate(user?.validTill)) ||
    userBalance?.currentBalance < 1
      ? onValidityOpen()
      : setOverlayOpen(true);
  };
  const handleFeedbackSubmit = () => {
    const { comment, rating, improvement_fields } = feedback;
    const validateRating = !rating ? "Please choose rating smiley" : "";

    setFeedback({
      ...feedback,
      ratingError: validateRating,
    });
    if (!validateRating) {
      const reqData = {
        rating,
        comment,
        improvement_fields,
        sessionId: lastAttentedSession.id,
        role: user.role,
      };
      dispatch(toggleFeddbackLoader(true));
      addFeedbackService(reqData)
        .then((res) => {
          dispatch(toggleFeddbackLoader(false));
          setThankYouOpen(true);
        })
        .catch((error) => {
          console.log(error);
          dispatch(toggleFeddbackLoader(false));
          Toaster(error.response.data.message, "error");
        });
    }
  };
  const joinButtonVisibility = (session) => {
    if (
      !session?.attendedBy.parent &&
      (session["status"] === "Scheduled" ||
        session["status"] === "Expert Joined")
    ) {
      const sessionDate = new Date(
        session.sessionDate._seconds * 1000 +
          session.sessionDate._nanoseconds / 1000000
      );
      let exactSessionDate = istTime2(sessionDate);

      let joinBtnEndTime = new Date(sessionDate);
      let exactJoinEndTime = istTime2(joinBtnEndTime);

      // startTime is HH:MM
      let startTime = session.startTime.split(":");
      const startingHour = parseInt(startTime[0], 10);
      const startingMinute = parseInt(startTime[1], 10);

      //Convert start time to minutes and add to session date to get exact start date time.
      exactSessionDate.setMinutes(
        exactSessionDate.getMinutes() + startingHour * 60 + startingMinute - 5
      );

      exactJoinEndTime.setMinutes(
        exactJoinEndTime.getMinutes() + startingHour * 60 + startingMinute + 15
      );

      //Check if now is greater or equal to session start date time
      // let currentTime = new Date();
      // let now = this.helper.istTime(currentTime);

      // let nowTimeStamp = firebase.firestore.Timestamp.now()
      // let now = new Date(nowTimeStamp.toDate())

      let nowTime = new Date().toLocaleString("en-US", {
        timeZone: "Asia/Kolkata",
      });
      let now = new Date(nowTime);

      if (
        now.getTime() >= exactSessionDate.getTime() &&
        now.getTime() <= exactJoinEndTime.getTime()
      ) {
        //session start time has crossed
        return true;
      }
    }
    return false;
  };
  const rejoinBtnVisibility = (session) => {
    if (
      session.attendedBy.parent &&
      (session["status"] === "Expert Joined" ||
        session["status"] === "Parent Joined" ||
        session["status"] === "Ongoing")
    ) {
      const sessionDate = new Date(
        session.sessionDate._seconds * 1000 +
          session.sessionDate._nanoseconds / 1000000
      );
      let exactSessionDate = istTime2(sessionDate);

      // startTime is HH:MM
      let endTime = session.endTime.split(":");
      const endingHour = parseInt(endTime[0], 10);
      const endingMinute = parseInt(endTime[1], 10);

      //Convert end time to minutes and add to session date to get exact end date time.
      exactSessionDate.setMinutes(
        exactSessionDate.getMinutes() + endingHour * 60 + endingMinute
      );

      let currentTime = new Date();
      let now = istTime(currentTime);

      //Check if now is smaller or equal to session end date time
      if (
        session.sessionType !== "Free Consultation" &&
        session.sessionType !== "Paid Consultation"
      ) {
        if (now.getTime() <= exactSessionDate.getTime() - 10 * 60 * 1000) {
          //session end time has not crossed
          return true;
        }
      } else {
        if (now.getTime() <= exactSessionDate.getTime()) {
          //session end time has not crossed
          return true;
        }
      }
    }
    return false;
  };
  const handleJoinMeeting = async (selectedSession) => {
    const reqData = {
      sessionId: selectedSession.id,
      role: user?.role,
    };
    dispatch(toggleAPILoader(true));
    startMeetingService(reqData)
      .then((res) => {
        const session = res.data.data;
        dispatch(toggleAPILoader(false));
        window.open(session["zoomJoinUrl"]);
      })
      .catch((error) => {
        console.log(error);
        dispatch(toggleAPILoader(false));
        Toaster(error.response.data.message, "error");
      });
  };
  return (
    <AuthenticatedContainer>
      <FeedbackPopup
        isOpen={isFeedbackOpen}
        onClose={onFeedbackClose}
        lastAttentedSession={lastAttentedSession}
        handleFeedbackDataChange={handleFeedbackDataChange}
        feedback={feedback}
        thankYouOpen={thankYouOpen}
        handleFeedbackSubmit={handleFeedbackSubmit}
        apiLoader={feedbackLoader}
      />
      <ValidityExpiredPopup
        isOpen={isValidityOpen}
        onClose={onValidityClose}
        parent={user}
      />

      {overlayOpen && <ScheduleSession setOverlayOpen={setOverlayOpen} />}

      {mainLoader || apiLoader || sessionLoader ? (
        <Loader text="Loading,Please wait..." />
      ) : (
        <>
          {(user?.isRenewalParent || userBalance?.currentBalance <= 3) && (
            <Box className="topUpDiv">
              Your renewal is pending
              <Button
                className="topUpBtn"
                _hover={{
                  bg: "#BA1A1A",
                }}
                onClick={() => {
                  navigate(ROUTES_PATH.PARENT_CHOOSE_PLAN);
                }}
              >
                Purchase here
              </Button>
            </Box>
          )}
          {upcomingSession.length > 0 && (
            <Box className="upcomingDiv">
              <Box>
                <Box>
                  {upcomingSession[0].sessionStudentName} /{" "}
                  {upcomingSession[0].sessionExpertName}
                </Box>
                <Flex className="txnsSecondMapFlexBoxFlexFlex1" color="#1A365D">
                  {getDateMonthYear1(upcomingSession[0]?.sessionDate)}

                  <Text mt="-3px" mb={0}>
                    .
                  </Text>
                  <Text mb={0}>
                    {upcomingSession[0]?.startTime +
                      " - " +
                      upcomingSession[0]?.endTime}
                  </Text>
                </Flex>
              </Box>

              <Box className="sessionBtnsDiv">
                {joinButtonVisibility(upcomingSession[0]) && (
                  <Button
                    className="sesssionJoinBtn"
                    onClick={() => handleJoinMeeting(upcomingSession[0])}
                  >
                    {apiLoader ? <Spinner /> : "Join"}
                  </Button>
                )}
                {rejoinBtnVisibility(upcomingSession[0]) && (
                  <Button
                    className="sesssionJoinBtn"
                    onClick={() => handleJoinMeeting(upcomingSession[0])}
                  >
                    ReJoin
                  </Button>
                )}

                {upcomingSession[0].status === "Scheduled" && (
                  <>
                    <Button
                      className="sesssionOtherBtns"
                      onClick={() => {
                        onRescheduleOpen();
                      }}
                    >
                      Reschedule
                    </Button>

                    <Button
                      className="sesssionOtherBtns"
                      onClick={() => {
                        onCancelOpen();
                      }}
                    >
                      Cancel
                    </Button>
                  </>
                )}
              </Box>
            </Box>
          )}

          <Box className="homeBottomBanner">
            <Grid templateColumns={{ md: "repeat(12, 1fr)", sm: "" }} gap={8}>
              <GridItem colSpan={{ xl: 4, lg: 5, md: 5, sm: 12 }}>
                <Show above="md">
                  <Box className="childNameDiv">
                    <Avatar
                      size="lg"
                      name={user?.childName}
                      src=""
                      marginRight="8px"
                    />
                    <Box>
                      <div className="childName">{user?.childName}</div>
                      <Box display="flex" className="childAge">
                        <Box display="flex" marginRight="20px">
                          <Image src={CAKE_ICON} marginRight="4px" />
                          {calculateAge(user?.childDob)}
                        </Box>
                        <Box display="flex">
                          <Image src={USER_ICON} marginRight="4px" />
                          {user?.fullName}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Show>
                <Box className="sessionCountDiv">
                  <Box width="150px" height="75px">
                    <ProgressBar
                      progress={
                        userBalance?.currentBalance
                          ? userBalance?.currentBalance
                              ?.toString()
                              .indexOf(".") === -1
                            ? Number(userBalance?.currentBalance)
                            : Number(userBalance?.currentBalance).toFixed(2)
                          : 0
                      }
                    />
                  </Box>
                  <Box className="sessionLeftText">Sessions left</Box>
                  {user?.validTill && (
                    <Box className="validTillText">
                      Valid till {getDateMonthYear1(user?.validTill)}
                    </Box>
                  )}
                  <Box className="scheduleDivText">
                    Schedule session regularly to meet your consultant and help
                    your child progress across the checkpoints
                  </Box>
                  <Box className="sessionBtnDiv" gap="16px">
                    <Button
                      className="scheduleDivBtn"
                      _hover={{
                        bg: "blue.500",
                      }}
                      onClick={() => {
                        handleSceduleSession();
                      }}
                    >
                      Schedule Session
                    </Button>
                    <Button
                      className="scheduleDivBtn"
                      _hover={{
                        bg: "blue.500",
                      }}
                      onClick={() => {
                        navigate(ROUTES_PATH.PARENT_CHOOSE_PLAN);
                      }}
                    >
                      Purchase Session
                    </Button>
                  </Box>
                </Box>
                <Box className="quotesText">
                  <Box mb="8px">
                    <Image src={QUOTES} />
                  </Box>
                  <Box mb="8px" textAlign="center">
                    Children see magic because they look for it.
                  </Box>
                  <Box>~ Christopher Moore</Box>
                </Box>
              </GridItem>

              <GridItem colSpan={{ xl: 8, lg: 7, md: 7, sm: 12 }}>
                {pathway.length > 0 ? (
                  <>
                    {pathway[0]?.checkpoints.map((checkpoint, index) => {
                      return (
                        <>
                          {checkpoint.status === "Locked" ? (
                            <Box key={index}>
                              <Image src={DOTTED_BORDER} pt="16px" m="auto" />
                              <Image
                                src={LOCKED_CHECKPOINT}
                                pt="16px"
                                m="auto"
                              />
                              <Box
                                textAlign="center"
                                className="checkpointText"
                                pt="8px"
                              >
                                Checkpoint {index + 1}
                              </Box>
                              <Image src={DOTTED_BORDER} pt="16px" m="auto" />
                            </Box>
                          ) : (
                            <Box className="checkpointDiv" key={index}>
                              <Image
                                src={FLAG}
                                className="checkpointImg"
                                width="80px"
                                height="80px"
                              />
                              <Box className="checkpointText">
                                Checkpoint {index + 1}
                              </Box>
                              <Grid
                                templateColumns="repeat(12, 1fr)"
                                columnGap={20}
                                gap={10}
                                className="progressBarDiv"
                              >
                                {checkpoint.outcomes.map((item, indx) => {
                                  return (
                                    <GridItem
                                      colSpan={4}
                                      className="checkpointServiceDiv"
                                      key={indx}
                                      onClick={() => handleCheckpointView(item)}
                                    >
                                      <Box width="96px" height="96px">
                                        <CircularProgressbarWithChildren
                                          value={
                                            item.status === "Yes"
                                              ? 100
                                              : item.status === "Almost"
                                              ? 70
                                              : 0
                                          }
                                          background={true}
                                          strokeWidth={10}
                                          backgroundPadding={0}
                                          styles={buildStyles({
                                            textColor: "red",
                                            pathColor:
                                              item.status === "No"
                                                ? "#FBC96E"
                                                : item.status === "Almost"
                                                ? "#D0B4FA"
                                                : "#3EB274",
                                            trailColor: "#F3F8FB",
                                            backgroundColor: "#FFF9EB",
                                          })}
                                        >
                                          <Image
                                            src={item.domainIcon}
                                            alt="Speech Therapy"
                                          />
                                        </CircularProgressbarWithChildren>
                                      </Box>
                                      <Box className="checkpointServiceName">
                                        {item.name}
                                      </Box>
                                    </GridItem>
                                  );
                                })}
                              </Grid>
                            </Box>
                          )}
                        </>
                      );
                    })}
                  </>
                ) : (
                  <>
                    <Box className="scheduleSessionBlock">
                      Your personalised pathway will <br /> be ready in two or
                      three sessions
                      <Button
                        className="scheduleBtn"
                        _hover={{
                          bg: "blue.500",
                        }}
                        onClick={handleSceduleSession}
                      >
                        Schedule Session
                      </Button>
                    </Box>
                    <Box className="articleTitle">Top article</Box>
                    <Grid
                      templateColumns={{ md: "repeat(8, 1fr)", sm: "" }}
                      gap="16px"
                    >
                      {popularArticles?.map((item, index) => {
                        return (
                          <GridItem
                            colSpan={{ lg: 4, sm: 12 }}
                            className="articleMainDiv"
                            key={index}
                            onClick={() => window.open(item.url)}
                            cursor="pointer"
                          >
                            <Image
                              src={item.image_url}
                              height="64px"
                              width="64px"
                            />
                            <Box pl="16px">
                              <Box className="articleName">{item.title}</Box>
                              {/* <Box className="articleDesc">
                                Brief description of what the article does and
                                how it should behave for the child
                              </Box> */}
                            </Box>
                          </GridItem>
                        );
                      })}
                    </Grid>
                    <Box className="articleTitle" mt="24px">
                      Others
                    </Box>
                    <Box display="flex" className="othersDiv">
                      {othersArray.map((item, index) => {
                        return (
                          <Box
                            key={index}
                            mr="48px"
                            className="boxMargin"
                            onClick={() => navigate(ROUTES_PATH.PARENT_EXPLORE)}
                            cursor="pointer"
                          >
                            <Image src={item.img} />
                            <Box className="othersText">{item.text}</Box>
                          </Box>
                        );
                      })}
                    </Box>
                  </>
                )}
              </GridItem>
            </Grid>
          </Box>
          <Show breakpoint="(max-width:767px)">
            <Image src={AUTH_BOTTOM_BG_MOBILE} />
          </Show>
        </>
      )}
      <RescheduledPopup
        isOpen={isRescheduleOpen}
        onClose={onRescheduleClose}
        title="Reschedule session?"
        reschedulingPerson="Parent"
        session={upcomingSession[0]}
        type="rescheduled"
      />
      <CancellationPopup
        isOpen={isCancelOpen}
        onClose={onCancelClose}
        title="Cancel session?"
        cancellingPerson="Parent"
        session={upcomingSession[0]}
        type="cancelled"
      />
    </AuthenticatedContainer>
  );
};
export default ParentHome;

import { Box, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getSuboalDomainsAction } from "../../../../modules/actions";
import { useSelector } from "react-redux";
import SingleOutcomesTemplate from "./singleOutcomesTemplate";
import Loader from "../../../../common/components/Loader";

const SingleSubgoalTemplate = ({
  props,
  navbarName,
  changedLocalstorage,
  setChangedLocalstorage,
  pathway,
  handleSelectedOutcomeCounter,
  checkpointOutcomes,
  setCheckpointOutcomes,
}) => {
  const dispatch = useDispatch();
  const { name, domainId, id, subgoalIds } = props;

  const { currentTabsSubGoals } = useSelector((state) => state?.user);
  const loaderState = useSelector((state) => state?.loader?.apiLoader);

  const [initialLoad, setInitialLoad] = useState(true);
  const [subgoalOutcomes, setSubgoalOutcomes] = useState([]);

  useEffect(() => {
    const getSubgoals = () => {
      dispatch(getSuboalDomainsAction(id));
      setInitialLoad(true);
    };
    getSubgoals();
  }, [dispatch, id]);

  useEffect(() => {
    if (loaderState === false) {
      setInitialLoad(false);
    } else {
      setInitialLoad(true);
    }
  }, [loaderState]);

  useEffect(() => {
    const setSubgoalData = () => {
      if (!currentTabsSubGoals) return;
      const updatedSubgoals = currentTabsSubGoals?.map((sg) => {
        return {
          id: sg?.id,
          name: sg?.name,
          introduction: sg?.introduction,
          goalId: sg?.goalId,
          outcomes: sg?.outcomes?.map((sgo) => {
            return {
              ...sgo,
              subgoalName: sg?.name,
              subGoalIntroduction: sg?.introduction,
              goalId: sg?.goalId,
              goalName: props.name,
              domainId: props.domainId,
              domainName: props.domainName,
              domainIcon: props.domainIcon,
              domainDescription: props.domainDescription,
            };
          }),
        };
      });
      setSubgoalOutcomes(updatedSubgoals);
    };
    setSubgoalData();
  }, [currentTabsSubGoals, props]);

  return (
    <>
      {initialLoad ? (
        <Loader text={"Loading Subgoal Outcomes"} />
      ) : (
        <Box pl="48px" pr="72px" py="16px">
          <Text
            fontWeight="500"
            fontSize="14px"
            lineHeight="20px"
            color="#141719"
          >
            Subgoals with outcomes
          </Text>
          {subgoalOutcomes.length > 0 ? (
            subgoalOutcomes?.map((s) => (
              <SingleOutcomesTemplate
                subgoals={s}
                key={s.id}
                navbarName={navbarName}
                changedLocalstorage={changedLocalstorage}
                setChangedLocalstorage={setChangedLocalstorage}
                pathway={pathway}
                handleSelectedOutcomeCounter={handleSelectedOutcomeCounter}
                checkpointOutcomes={checkpointOutcomes}
                setCheckpointOutcomes={setCheckpointOutcomes}
              />
            ))
          ) : (
            <Text color="#141719" mt={10} fontSize="20px">
              You don't have any subgoals for this outcome
            </Text>
          )}
        </Box>
      )}
    </>
  );
};

export default SingleSubgoalTemplate;

import { useState } from "react";
import { Box, Grid, GridItem, Image, Button, Text } from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  BACK_BTN,
  ROUTES_PATH,
  PDF_ICON,
  PPT_ICON,
  DOC_ICON,
  IMG_ICON,
  VIDEO_ICON,
} from "../../../constant";
import OutcomeModal from "../../../components/OutcomeModal";
import { addParentOutcomeSttaus } from "../../../modules/actions";
import AuthenticatedContainer from "../../../container/Authenticated";
import "./exercise.css";

const Exercise = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const outcome = useSelector((state) => state.outcomes);
  const user = useSelector((state) => state.user.profile);
  const [isOpen, setIsOpen] = useState(false);
  const [count, setCount] = useState(0);

  // useEffect(() => {
  //   checkResourcesPresent(outcome.exercises[0].resources);
  // }, []);

  const handleExercise = () => {
    if (outcome?.exercises.length - 1 === count) {
      setIsOpen(!isOpen);
    } else {
      setCount(count + 1);
      window.scroll(0, 0);
    }
  };

  const handleOutcomeModalClose = (status) => {
    outcome.status = status;
    dispatch(addParentOutcomeSttaus({ outcome: outcome }));
    setIsOpen(!isOpen);
    navigate(ROUTES_PATH.PARENT_HOME);
  };
  const handleResources = () => {
    navigate(ROUTES_PATH.PARENT_EXERCISE_RESOURCE);
  };

  const OnModalClose = () => {
    setIsOpen(!isOpen);
  };
  const checkResourcesPresent = (arr) => {
    // item.url !== "" ||
    if (arr.find((item) => item.url.includes("demonstrated by the expert"))) {
      return false;
    } else {
      return true;
    }
  };
  return (
    <AuthenticatedContainer>
      <Box className="exerciseMainDiv">
        <Box className="exerciseBackText">
          <Image
            src={BACK_BTN}
            mr="8px"
            onClick={() => navigate(ROUTES_PATH.PARENT_HOME)}
            cursor="pointer"
          />
          <Text
            onClick={() => navigate(ROUTES_PATH.PARENT_HOME)}
            cursor="pointer"
          >
            Back to level
          </Text>
        </Box>
        <Grid
          templateColumns={{ md: "repeat(12, 1fr)", sm: "repeat(10, 1fr)" }}
        >
          <GridItem
            // colStart={{ md: 1, lg: 1 }}
            // colEnd={{ md: 13, lg: 13 }}
            colSpan={12}
            className="exerciseGrid"
          >
            {outcome?.exercises?.length === 0 ? (
              <Box className="exerciseTitle" mt="100px">
                Opps! There is no exercise for this outcome
              </Box>
            ) : (
              <>
                <Box className="exerciseTitle">{outcome.name}</Box>
                <Box className="exerciseDescp">
                  {outcome.goalName + " - " + outcome.subgoalName}
                </Box>
                <Box display="flex" mb="20px">
                  {/* <Box className="exerciseNo">Introduction</Box> */}
                  <Box className="exerciseSubGoalIntro">
                    {outcome.subGoalIntroduction}
                  </Box>
                </Box>
                <Box display="flex" className="mainDivBorders">
                  <Box className="exerciseNo">Skills To Be Learnt</Box>
                  <Box className="exerciseSubGoalIntro">
                    {outcome.skillsToBeLearnt}
                  </Box>
                </Box>
                <Box display="flex" className="mainDivBorders">
                  <Box className="exerciseNo">Required Skills</Box>
                  <Box className="exerciseSubGoalIntro">
                    {outcome.requiredSkills}
                  </Box>
                </Box>
                <Box display="flex" className="mainDivBorders">
                  <Box className="exerciseNo">Child Goal</Box>
                  <Box className="exerciseSubGoalIntro">
                    {outcome.childGoal}
                  </Box>
                </Box>
                <Box className="mainDivBorders">
                  <Box className="exerciseTitle">
                    Exercise #{outcome?.exercises[count]?.id}
                  </Box>
                  <Box className="exerciseDescp">
                    {outcome?.exercises[0]?.task}
                  </Box>
                  <Box display="flex">
                    {outcome.exercises[count].resources.length > 0 && (
                      <Box className="exerciseSubTitle"> Resources :</Box>
                    )}

                    {checkResourcesPresent(outcome.exercises[count].resources)
                      ? outcome.exercises[count].resources.map(
                          (resource, index) => {
                            return (
                              <Box
                                gap={8}
                                boxShadow="0px 4px 8px 0px rgba(0, 0, 0, 0.16)"
                                // border="1px solid #a6b8c4"
                                borderRadius="10px"
                                cursor="pointer"
                                p="10px"
                                onClick={() => window.open(resource.url)}
                              >
                                <Image
                                  h="48px"
                                  w="48px"
                                  key={index}
                                  src={
                                    resource.type === "application/pdf"
                                      ? PDF_ICON
                                      : resource.type.includes(
                                          "presentation"
                                        ) ||
                                        resource.type ===
                                          "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
                                        resource.type ===
                                          "application/vnd.google-apps.presentation"
                                      ? PPT_ICON
                                      : resource.type === "image/png"
                                      ? IMG_ICON
                                      : resource.type === "video/mp4"
                                      ? VIDEO_ICON
                                      : DOC_ICON
                                  }
                                />
                                <Box className="exerciseSubGoalIntro">
                                  {resource.name}
                                </Box>
                              </Box>
                            );
                          }
                        )
                      : null}
                  </Box>
                </Box>

                <Box className="mainDivBorders">
                  <Box className="exerciseTitle">Rationale</Box>
                  <Box className="exerciseSubGoalIntro">
                    {outcome.rationale}
                  </Box>
                </Box>
                {/* <Image src={EXERCISE_IMG} /> */}
                <Box gap={8} className="exerciseBtnDiv">
                  {/* {checkResourcesPresent(outcome.exercises[count].resources)
                    ? // <Button
                      //   className="resourceBtn"
                      //   _hover={{
                      //     bg: "blue.500",
                      //   }}
                      //   onClick={() => handleResources()}
                      // >
                      //   Resources
                      // </Button>
                      outcome.exercises[count].resources.map(
                        (resource, index) => {
                        
                          return (
                            <Image
                              h="48px"
                              w="48px"
                              key={index}
                              onClick={() => window.open(resource.url)}
                              src={
                                resource.type === "application/pdf"
                                  ? PDF_ICON
                                  : resource.type.includes("presentation") ||
                                    resource.type ===
                                      "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
                                    resource.type ===
                                      "application/vnd.google-apps.presentation"
                                  ? PPT_ICON
                                  : resource.type === "image/png"
                                  ? IMG_ICON
                                  : resource.type === "video/mp4"
                                  ? VIDEO_ICON
                                  : DOC_ICON
                              }
                            />
                          );
                        }
                      )
                    : null} */}

                  <Button
                    className="nextBtn"
                    _hover={{
                      bg: "blue.500",
                    }}
                    onClick={handleExercise}
                  >
                    Next
                  </Button>
                </Box>
              </>
            )}
          </GridItem>
        </Grid>
        <OutcomeModal
          isOpen={isOpen}
          onClose={handleOutcomeModalClose}
          user={user}
          outcome={outcome}
          OnModalClose={OnModalClose}
        />
      </Box>
    </AuthenticatedContainer>
  );
};
export default Exercise;

import { Box, Image, Text, Link } from "@chakra-ui/react";
import { NavLink as RouterLink, useLocation } from "react-router-dom";
import {
  CALENDER_BLANK_ACTIVE_MOBILE,
  CALENDER_BLANK_MOBILE,
  CHARTLINE_ACTIVE_MOBILE,
  CHARTLINE_MOBILE,
  HOME_ACTIVE_MOBILE,
  HOME_MOBILE,
  ROUTES_PATH,
  SPARKLE_ACTIVE_MOBILE,
  SPARKLE_MOBILE,
  USERLIST_ACTIVE_MOBILE,
  USERLIST_MOBILE,
} from "../../constant";
import "./mobileBottomNavbar.css";

const parentMobileLinks = [
  {
    label: "Home",
    img: HOME_MOBILE,
    activeImg: HOME_ACTIVE_MOBILE,
    route: ROUTES_PATH.PARENT_HOME,
  },
  {
    label: "Progress",
    img: CHARTLINE_MOBILE,
    activeImg: CHARTLINE_ACTIVE_MOBILE,
    route: ROUTES_PATH.PARENT_PROGRESS,
  },
  {
    label: "Session",
    img: CALENDER_BLANK_MOBILE,
    activeImg: CALENDER_BLANK_ACTIVE_MOBILE,
    route: ROUTES_PATH.PARENT_SESSION,
  },
  {
    label: "Explore",
    img: SPARKLE_MOBILE,
    activeImg: SPARKLE_ACTIVE_MOBILE,
    route: ROUTES_PATH.PARENT_EXPLORE,
  },
  {
    label: "My Profile",
    img: USERLIST_MOBILE,
    activeImg: USERLIST_ACTIVE_MOBILE,
    route: ROUTES_PATH.PARENT_PROFILE,
  },
];

const MobileBottomNavbar = () => {
  const location = useLocation();

  return (
    <Box className="bottomNavbar">
      {parentMobileLinks.map((navItem, index) => {
        return (
          <Link
            as={RouterLink}
            px={2}
            py={1}
            rounded={"md"}
            _hover={{
              textDecoration: "none",
              color: "#2B6CB0 !important",
            }}
            _activeLink={{
              textDecoration: "none",
              color: "#2B6CB0 !important",
            }}
            to={navItem.route}
            className="navItems"
            key={index}
          >
            <Box className="navbarItemsDiv">
              <Image
                src={
                  location.pathname === navItem.route
                    ? navItem.activeImg
                    : navItem.img
                }
              />
              <Text className="navbarText">{navItem.label}</Text>
            </Box>
          </Link>
        );
      })}
    </Box>
  );
};
export default MobileBottomNavbar;

import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Box, Image, Show } from "@chakra-ui/react";
import { AUTH_BOTTOM_BG } from "../../../constant";
import { Stepper } from "./Steps/steps";
import UnauthenticatedContainer from "../../../container/Unauthenticated";
import { getUserProfile, toggleAPILoader } from "../../../modules/actions";
import MobileNotAllowedScreen from "../../../common/components/MobileNotAllowed";

const Onboarding = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(toggleAPILoader(false));
    const token = localStorage.getItem("idToken");
    dispatch(getUserProfile(token));
  }, []);

  return (
    <UnauthenticatedContainer>
      <MobileNotAllowedScreen />
      <Show breakpoint="(min-width:767px)">
        <Box mt={10} px={{ sm: "50px", md: "80px", lg: "200px", xl: "300px" }}>
          {/* Steps Area */}
          <Stepper />
        </Box>
        <Box zIndex={-50000} className="layoutBottomBanner">
          <Image src={AUTH_BOTTOM_BG} />
        </Box>
      </Show>
    </UnauthenticatedContainer>
  );
};
export default Onboarding;

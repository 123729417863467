import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { leaveStatusOptions } from "../../../../constant/selectOptions";
import OverlayComponent from "../TabComponents/overlayComponent";
import { getExpertLeaves, toggleAPILoader } from "../../../../modules/actions";
import LeavesApplier from "../TabComponents/leavesApplier";
import {
  getDayNumber,
  getMonthName,
  getCurrentYear,
  getDateTime,
} from "../../../../utils/customMoment";
import { applyLeavesService } from "../../../../modules/services";
import { Toaster } from "../../../../common/components/Toaster";
import Loader from "../../../../common/components/Loader";
import "../myProfile.css";

const Leaves = () => {
  const dispatch = useDispatch();
  const leaves = useSelector((state) => state.user?.expertData?.leaves?.data);
  const user = useSelector((state) => state.user.profile);
  const apiLoader = useSelector((state) => state.loader.apiLoader);
  const [selectedStatus, setSelectedStatus] = useState({
    status: "Upcoming",
  });

  const [overlayOpen, setOverlayOpen] = useState(false);
  const [applyLeaveData, setApplyLeaveData] = useState({
    reason: "",
    reasonError: "",
    weekday: "",
    weekdayError: "",
    startDate: "",
    startDateError: "",
    endDate: "",
    endDateError: "",
    endTime: "",
    endTimeError: "",
    startTime: "",
    startTimeError: "",
  });
  const [workingHours, setWorkingHours] = useState([]);
  const [closer, setCloser] = useState(false);

  const handleStatusChange = (value) => {
    setSelectedStatus({ status: value });
  };

  useEffect(() => {
    setWorkingHours(user?.workingHours);
    dispatch(
      getExpertLeaves(selectedStatus, {
        filterDate: moment().format("YYYY/MM/DD"),
      })
    );
  }, []);

  useEffect(() => {
    // api call for {selectedStatus} first render
    dispatch(
      getExpertLeaves(selectedStatus, {
        filterDate: moment().format("YYYY/MM/DD"),
      })
    );
  }, [selectedStatus]);

  const selectStyles = {
    control: (styles) => ({
      ...styles,
      border: "none",
      outline: "none",
      borderRadius: "10px",
      boxShadow: "none",
      width: "full",
      // padding: '0px 12px',
      cursor: "pointer",
    }),
    dropdownIndicator: (prev, { selectProps: { menuIsOpen } }) => ({
      ...prev,
      "> svg": {
        transitionDuration: "normal",
        transform: `rotate(${menuIsOpen ? -180 : 0}deg)`,
        color: "#4299E1",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? "#E9F6FF"
          : isFocused
          ? "#F3F8FB"
          : undefined,
        color: isDisabled ? "#ccc" : isSelected ? "#4299E1" : "#141719",
        padding: "10px",
        cursor: isDisabled ? "not-allowed" : "pointer",
        fontFamily: "Inter",
        fontSize: "14px",
        lineHeight: "20px",
        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? "#E9F6FF"
              : "#E9F6FF"
            : undefined,
        },
      };
    },
    singleValue: (styles) => ({
      ...styles,
      color: "#141719",
      fontWeight: "600",
    }),
    menuList: (styles) => ({
      ...styles,
      borderRadius: "8px",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.16)",
    }),
  };

  function calculateDays(startSec, endSec) {
    // let date1 = new Date(startSec);
    let date1 = new Date(
      startSec._seconds * 1000 + startSec._nanoseconds / 1000000
    );

    // let date2 = new Date(endSec);
    let date2 = new Date(
      endSec._seconds * 1000 + endSec._nanoseconds / 1000000
    );
    const diffInTime = date2?.getTime() - date1?.getTime();
    const diffInDays = String(diffInTime / 86400000)?.split(".")[0];

    if (diffInDays === "1") {
      return `${diffInDays} day`;
    } else if (diffInDays === "0") {
      return `1 day`;
    } else {
      return `${diffInDays} days`;
    }
  }

  const handleApplyLeave = () => {
    const validateReason = !applyLeaveData.reason
      ? "Please enter a reason"
      : "";

    const validateStartDate = !applyLeaveData.startDate
      ? "Please choose start date"
      : moment(applyLeaveData.startDate).isSameOrBefore(moment(), "day")
      ? "Please choose future dates"
      : "";

    const validateStartTime = !applyLeaveData.startTime
      ? "Please choose start time"
      : "";

    const validateEndDate = !applyLeaveData.endDate
      ? "Please choose end date"
      : moment(applyLeaveData.endDate).isBefore(
          moment(applyLeaveData.startDate),
          "day"
        )
      ? "Please choose future dates"
      : "";

    const validateEndTime = !applyLeaveData.endTime
      ? "Please choose end time"
      : "";

    setApplyLeaveData({
      ...applyLeaveData,
      reasonError: validateReason,
      startDateError: validateStartDate,
      endDateError: validateEndDate,
      startTimeError: validateStartTime,
      endTimeError: validateEndTime,
    });
    if (
      applyLeaveData.reason !== "" &&
      applyLeaveData.startDate !== "" &&
      applyLeaveData.endDate !== "" &&
      applyLeaveData.startTime !== "" &&
      applyLeaveData.endTime !== "" &&
      !validateEndDate &&
      !validateStartDate
    ) {
      const data = {
        reason: applyLeaveData.reason,
        startDateTime: moment(new Date(applyLeaveData.startDate)).add(
          applyLeaveData.startTime
        ),
        endDateTime: moment(new Date(applyLeaveData.endDate)).add(
          applyLeaveData.endTime
        ),
      };
      dispatch(toggleAPILoader(true));
      applyLeavesService(data)
        .then((res) => {
          Toaster("Leaves applied successfully", "success");
          dispatch(toggleAPILoader(false));
          dispatch(
            getExpertLeaves(
              { status: "Upcoming" },
              {
                filterDate: moment().format("YYYY/MM/DD"),
              }
            )
          );
          handleClearData();
          setOverlayOpen(false);
        })
        .catch((error) => {
          console.log(error);
          dispatch(toggleAPILoader(false));
          Toaster(error.response.data.message, "error");
        });
    }
  };
  const handleClearData = () => {
    setApplyLeaveData({
      reason: "",
      reasonError: "",
      weekday: "",
      weekdayError: "",
      startDate: "",
      startDateError: "",
      endDate: "",
      endDateError: "",
      endTime: "",
      endTimeError: "",
      startTime: "",
      startTimeError: "",
    });
  };

  return (
    <Box id="mainWrapper" px="16px" py="24px">
      {overlayOpen && (
        <OverlayComponent
          setOverlayOpen={setOverlayOpen}
          closer={true}
          title={"Apply for leave"}
          handleNextStep={handleApplyLeave}
          handleClearData={handleClearData}
          apiLoader={apiLoader}
          btnDisabled={
            applyLeaveData.reason === "" ||
            applyLeaveData.endDate === "" ||
            applyLeaveData.startDate === "" ||
            applyLeaveData.startTime === "" ||
            applyLeaveData.endTime === ""
          }
        >
          <LeavesApplier
            setCloser={setCloser}
            workingHours={workingHours}
            setApplyLeaveData={setApplyLeaveData}
            applyLeaveData={applyLeaveData}
          />
        </OverlayComponent>
      )}
      <Text className="txnsTabText1">Leaves</Text>
      <Text className="txnsTabText2">Apply or view upcoming leaves</Text>
      <Flex mt="16px" gap="16px">
        <Select
          useBasicStyles
          options={leaveStatusOptions}
          chakraStyles={selectStyles}
          defaultValue={{ label: "Upcoming", value: "Upcoming" }}
          name="leaveStatus"
          className="txnsTabSelect"
          onChange={(e) => handleStatusChange(e.value)}
        />
        <Button
          onClick={() => setOverlayOpen(true)}
          className="leavesApplyLeaveBtn"
          _hover={{ bg: "white", cursor: "pointer" }}
        >
          Apply Leave
        </Button>
      </Flex>
      {apiLoader ? (
        <Loader text={"Loading leaves...."} />
      ) : (
        <Box mt="24px">
          {leaves &&
            leaves?.map((item) => (
              <Box key={item.id} className="txnsSecondMapFlex">
                <Flex className="leavesMapFlex">
                  <Text>
                    {getDayNumber(item.leaveStartDateTime) +
                      " " +
                      getMonthName(item.leaveStartDateTime) +
                      ", " +
                      getCurrentYear(item.leaveStartDateTime) +
                      " " +
                      getDateTime(item.leaveEndDateTime)}
                  </Text>
                  <Text>-</Text>
                  <Text>
                    {getDayNumber(item.leaveEndDateTime) +
                      " " +
                      getMonthName(item.leaveEndDateTime) +
                      ", " +
                      getCurrentYear(item.leaveEndDateTime) +
                      " " +
                      getDateTime(item.leaveEndDateTime)}
                  </Text>
                  <Text className="leavesMapFlexTextCalculateDays">
                    (
                    {calculateDays(
                      item.leaveStartDateTime,
                      item.leaveEndDateTime
                    )}
                    )
                  </Text>
                  {item.leaveStatus === "Approval Pending" ? (
                    <Text mt="-2px" className="leavesStatus">
                      {item.leaveStatus}
                    </Text>
                  ) : null}
                </Flex>
                <Text className="leavesMapFlexTextLeaveReason">
                  {item.leaveReason}
                </Text>
              </Box>
            ))}
        </Box>
      )}
    </Box>
  );
};

export default Leaves;

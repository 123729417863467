import {
  Avatar,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Image,
  Text,
  GridItem,
  FormErrorMessage,
} from "@chakra-ui/react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import {
  CAKE_ICON,
  OPEN_PATHWAY,
  PARENT_ICON,
  BACK_CARET,
  NEXT_CARET,
} from "../../../../constant";
import Pathway from "../Tabs/pathway";
import Domain from "../Tabs/domain";
import { useDispatch, useSelector } from "react-redux";
import SessionHistory from "../Tabs/sessionHistory";
import ParentJournal from "../Tabs/parentJournal";
import Notes from "../Tabs/notes";
import OverlayComponent from "../../MyProfile/TabComponents/overlayComponent";
import { Select } from "chakra-react-select";
import "react-calendar/dist/Calendar.css";
import {
  getExpertLeaves,
  getTherapistSessions,
  toggleAPILoader,
  getRescheduleOpenSlots,
} from "../../../../modules/actions";
import Loader from "../../../../common/components/Loader";
import { Toaster } from "../../../../common/components/Toaster";
import { scheduleSessionExpertService } from "../../../../modules/services";
import { getDate } from "../../../../utils/customMoment";

const PathwayHandler = ({
  setShowingTable,
  selectedChild,
  setWillCreatePathway,
  pathwayExists,
  setPathwayExists,
}) => {
  const dispatch = useDispatch();
  const [overlayOpen, setOverlayOpen] = useState(false);
  const [closer, setCloser] = useState(false);

  const { apiLoader } = useSelector((state) => state?.loader);
  const userState = useSelector((state) => state?.user);
  const expertLeaves = useSelector(
    (state) => state.user?.expertData?.leaves?.data
  );

  const [sessionData, setSessionData] = useState({
    sessionType: "",
    sessionTypeError: "",
    selectedDate: "",
    selectedDateError: "",
  });

  const [serviceTypeOptions, setServiceTypeOptions] = useState([]);
  const [calendarValue, setCalendarValue] = useState(null);
  const rescheduleOpenSlots = useSelector(
    (state) => state?.rescheduleOpenSlots
  );
  const [showSlot, setShowSlot] = useState(false);
  const [activeStep, setActiveStep] = useState("Pathway");
  const [selectedSlot, setSelectedSlot] = useState("");

  const tabs = [
    {
      id: "Pathway",
      value: (
        <Pathway
          setWillCreatePathway={setWillCreatePathway}
          pathwayExists={pathwayExists}
          setPathwayExists={setPathwayExists}
          selectedChild={selectedChild}
        />
      ),
    },
    { id: "Domain", value: <Domain selectedChild={selectedChild?.id} /> },
    {
      id: "Session History",
      value: <SessionHistory selectedChild={selectedChild} />,
    },
    {
      id: "Parent Journal",
      value: <ParentJournal selectedChild={selectedChild} />,
    },
    { id: "Notes", value: <Notes selectedChild={selectedChild} /> },
  ];

  const selectStyles = {
    control: (styles) => ({
      ...styles,
      border: "1px solid #a6b8c4",
      outline: "none",
      borderRadius: "4px",
      boxShadow: "none",
      width: "full",
      cursor: "pointer",
    }),
    dropdownIndicator: (prev, { selectProps: { menuIsOpen } }) => ({
      ...prev,
      "> svg": {
        transitionDuration: "normal",
        transform: `rotate(${menuIsOpen ? -180 : 0}deg)`,
        color: "#4299E1",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? "#E9F6FF"
          : isFocused
          ? "#F3F8FB"
          : undefined,
        color: isDisabled ? "#ccc" : isSelected ? "#4299E1" : "#141719",
        padding: "10px",
        cursor: isDisabled ? "not-allowed" : "pointer",
        fontFamily: "Inter",
        fontSize: "14px",
        lineHeight: "20px",
        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? "#E9F6FF"
              : "#E9F6FF"
            : undefined,
        },
      };
    },
    singleValue: (styles) => ({
      ...styles,
      color: "#141719",
      fontWeight: "400",
    }),
    menuList: (styles) => ({
      ...styles,
      borderRadius: "4px",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.16)",
    }),
  };

  useEffect(() => {
    rescheduleOpenSlots.forEach((slot) => {
      //inserting an additional field isSelected for select-deselect ui
      slot.openSlots.forEach(function (element) {
        element.isSelected = false;
      });
    });
  }, [rescheduleOpenSlots]);

  useEffect(() => {
    const options = [];
    selectedChild?.myExperts?.map((item) => {
      if (item.educatorId === userState?.profile?.id) {
        item.service.map((service) => {
          options.push({
            value: service,
            label: service,
          });
        });
      }
    });

    setServiceTypeOptions(options);
  }, [selectedChild, userState]);

  useEffect(() => {
    dispatch(getExpertLeaves({ status: "upcoming" }));
    dispatch(
      getTherapistSessions({
        startDate: moment().startOf("d").format("YYYY-MM-DD"),
        endDate: moment().add(6, "months").endOf("d").format("YYYY-MM-DD"),
        status: "All",
      })
    );
  }, [dispatch]);

  const handleAvailableSlots = () => {
    if (calendarValue) {
      const reqData = {
        expert: userState?.profile?.id,
        startDate: calendarValue.format("YYYY/MM/DD"),
      };
      dispatch(toggleAPILoader(true));
      dispatch(getRescheduleOpenSlots(reqData));
      setShowSlot(true);
    }
  };

  const handleSlotSelection = (date, slot) => {
    let choosenSlot = {};
    rescheduleOpenSlots.map((dates) => {
      if (dates.date === date) {
        dates.openSlots.map((slotItem) => {
          if (slotItem.label === slot.label) {
            slotItem.isSelected = !slotItem.isSelected;
            if (slotItem.isSelected) {
              choosenSlot = {
                date: dates.date,
                slotStartTime: slot.value,
                slotDuration: 60,
                label: slot.label,
              };
            }
          } else {
            slotItem.isSelected = false;
          }
        });
      }
    });
    setSelectedSlot(choosenSlot);
  };

  const renderDayTileClass = ({ date }) => {
    // Check if the date should be excluded
    const hasAppliedLeave = expertLeaves?.some((leave) => {
      const currentDate = moment(date);
      const leaveStartDate = moment
        .unix(leave.leaveStartDateTime._seconds)
        .startOf("d");
      const leaveEndDate = moment
        .unix(leave.leaveEndDateTime._seconds)
        .endOf("d");

      return currentDate.isBetween(leaveStartDate, leaveEndDate, null, "[]");
    });

    // Apply custom styling for leaves
    return hasAppliedLeave ? "markOnLeave disabled" : "";
  };

  const handleDataChange = (name, value, errorName) => {
    // setCloser(true);
    setSessionData({ ...sessionData, [name]: value, [errorName]: "" });
  };

  const handleCalendarChange = (event) => {
    const selectedDate = moment(event);
    setCalendarValue(selectedDate);
    handleDataChange("selectedDate", selectedDate);
    setSelectedSlot("");
    setShowSlot(false);
  };

  const handleNextStep = () => {
    if (selectedChild.currentBalance < 1) {
      Toaster(
        "This student balance is not sufficient to book session",
        "error",
        "top-left"
      );
    } else {
      const validateSessionType = !sessionData.sessionType
        ? "Please choose service Type"
        : "";

      const validateSelectedDate =
        Object.keys(selectedSlot).length < 1
          ? "Please choose perferred date and slot"
          : "";

      setSessionData({
        ...sessionData,
        sessionTypeError: validateSessionType,
        selectedDateError: validateSelectedDate,
      });

      if (!validateSessionType && !validateSelectedDate) {
        const reqData = {
          choosenSlot: selectedSlot,
          serviceType: sessionData.sessionType?.value,
          expertId: userState?.profile?.id,
          studentId: selectedChild.id,
        };

        dispatch(toggleAPILoader(true));
        scheduleSessionExpertService(reqData)
          .then((res) => {
            Toaster("Session requested!", "success", "top-left");
            dispatch(toggleAPILoader(false));
            handleFormClear();
            setOverlayOpen(false);
          })
          .catch((error) => {
            console.log(error);
            dispatch(toggleAPILoader(false));
            Toaster(error.response.data.message, "error", "top-left");
          });
      }
    }
  };
  const handleFormClear = () => {
    setSessionData({
      sessionType: "",
      sessionTypeError: "",
      selectedDate: "",
      selectedDateError: "",
    });
    setCalendarValue(null);
    setShowSlot(false);
  };
  return (
    <>
      {overlayOpen && (
        <OverlayComponent
          setOverlayOpen={setOverlayOpen}
          closer={true}
          title={"Schedule session"}
          apiLoader={apiLoader}
          handleNextStep={handleNextStep}
          btnDisabled={
            selectedSlot.length === 0 ||
            sessionData.serviceType === "" ||
            calendarValue === "" ||
            calendarValue === null
          }
          handleClearData={handleFormClear}
        >
          <Box p="16px">
            <FormControl isInvalid={sessionData.sessionTypeError}>
              <FormLabel>Session type (service)</FormLabel>
              <Select
                chakraStyles={selectStyles}
                useBasicStyles
                name="sessionType"
                defaultValue={{ label: "Select type", value: "Select type" }}
                options={serviceTypeOptions}
                onChange={(e) =>
                  handleDataChange("sessionType", e, "sessionTypeError")
                }
              />
              {sessionData.sessionTypeError && (
                <FormErrorMessage className="personalErrorMessage">
                  {sessionData.sessionTypeError}
                </FormErrorMessage>
              )}
            </FormControl>
            <Text mt="26px">Select your preferred date</Text>
            <Flex w="full" justify="center" mt="8px">
              <Calendar
                prevLabel={<Image src={BACK_CARET} />}
                nextLabel={<Image src={NEXT_CARET} />}
                calendarType="US"
                prev2Label={null}
                next2Label={null}
                view="month"
                onChange={handleCalendarChange}
                value={calendarValue}
                tileClassName={renderDayTileClass}
                minDate={moment().add(1, "day").toDate()}
                maxDate={
                  selectedChild?.validTill
                    ? getDate(selectedChild?.validTill)
                    : ""
                }
              />
            </Flex>
            {sessionData.selectedDateError && (
              <Box className="personalErrorMessage" my="10px">
                {sessionData.selectedDateError}
              </Box>
            )}
            <Flex mt="8px" gap="8px" align="center">
              <Box className="pathwayHandlerOverlayUpcomingLeavesAboveBox"></Box>
              <Text className="pathwayHandlerOverlayUpcomingLeaves">
                Upcoming leaves
              </Text>
            </Flex>

            {calendarValue && (
              <GridItem mt="20px" textAlign="center">
                {/* <FormLabel className="personalFormLabel">Available slots</FormLabel> */}
                <Button
                  bg="#4299E1"
                  color="#FFFFFF"
                  mr="8px"
                  border="1px solid #a6b8c4"
                  onClick={() => handleAvailableSlots()}
                  className="modalButtons2"
                >
                  Get available slots
                </Button>
                {apiLoader ? (
                  <Loader />
                ) : showSlot && rescheduleOpenSlots[0]?.openSlots.length > 0 ? (
                  <Grid
                    templateColumns="repeat(12, 1fr)"
                    gap={4}
                    mb="16px"
                    className="slotsDivMain"
                    mt="20px"
                  >
                    {rescheduleOpenSlots[0]?.openSlots.length > 0 &&
                      rescheduleOpenSlots[0]?.openSlots.map((slot) => (
                        <GridItem
                          className="slotsDiv"
                          colSpan={4}
                          bg={slot.isSelected ? "#E9F6FF" : "#ffffff"}
                          onClick={() =>
                            handleSlotSelection(
                              rescheduleOpenSlots[0].date,
                              slot
                            )
                          }
                        >
                          {slot.label}
                        </GridItem>
                      ))}
                  </Grid>
                ) : (
                  <GridItem className="personalFormLabel" mt="20px">
                    {showSlot &&
                      !apiLoader &&
                      "No slots available on this day "}
                  </GridItem>
                )}
              </GridItem>
            )}
          </Box>
        </OverlayComponent>
      )}
      <Grid
        maxW="100vw"
        overflowX="clip"
        position="sticky"
        zIndex="10"
        top="0"
        row
        className="layoutBanner"
      >
        <Flex className="pathwayHandlerStudentNameBanner">
          <Text
            cursor="pointer"
            onClick={() => setShowingTable(true)}
            className="pathwayHandlerText"
            color="#141719"
          >
            My students
          </Text>
          <Image h="16px" w="16px" src={OPEN_PATHWAY} />
          <Text className="pathwayHandlerText" color="#5a646b">
            {selectedChild?.childName}
          </Text>
        </Flex>
      </Grid>
      <Box px="72px" py="16px">
        <Box className="pathwayHandlerOverflowHider" w="full"></Box>
        <Box
          className="pathwayHandlerOverflowHiderBottomBox"
          id="firstBox-NameAndProfileEdit"
        >
          <Flex
            // flexDir={{ base: "column", md: "row" }}
            justify="space-between"
            align={{ base: "start", md: "center" }}
          >
            <Flex gap="16px">
              <Avatar
                className="myProfileIndexAvatar"
                name={selectedChild?.childName}
                src={selectedChild?.image}
              />
              <Box>
                <Text className="myProfileIndexText">
                  {selectedChild?.childName}
                </Text>
                <Flex mt="4px">
                  <Flex gap="4px" mr="8px">
                    <Image className="myProfileIndexIcons" src={CAKE_ICON} />
                    <Text className="myProfileIndexIconsText">
                      {selectedChild?.age}
                    </Text>
                  </Flex>
                  <Text style={{ marginTop: "-5px" }}>.</Text>
                  <Flex gap="4px" ml="8px">
                    <Image className="myProfileIndexIcons" src={PARENT_ICON} />
                    <Text className="myProfileIndexIconsText">
                      {selectedChild?.fullName}
                    </Text>
                  </Flex>
                </Flex>
              </Box>
            </Flex>
            {(userState?.profile?.activityStatus !== "Session Disabled" ||
              userState?.profile?.activityStatus !==
                "Session & Consultation Disabled") && (
              <Button
                className="myProfileIndexFirstBoxButtonFlexBtns"
                _hover={{ bg: "white" }}
                onClick={() => setOverlayOpen(true)}
                mt="-35px"
              >
                Schedule Session
              </Button>
            )}
          </Flex>
        </Box>
        <Box
          top="240px"
          className="pathwayHandlerOverflowHiderBottomBoxEmptyBox"
        ></Box>
        <Flex
          maxW="100vw"
          overflowX="clip"
          gap="41px"
          id="secondBox-DynamicSteps"
          flexDir={{ base: "column", lg: "row" }}
        >
          <Flex
            className="myStudentIndexSecondBoxMainFlexContainer"
            minW={{ base: "100vw", lg: "208px", xl: "288px" }}
            maxW={{ lg: "288px" }}
            flexDir={{ base: "row", lg: "column" }}
            gap={{ base: "12px", lg: "16px" }}
            id="left-side"
            h="full"
            zIndex={100}
            bg="white"
            position="sticky"
            top="240px"
          >
            {tabs.map((item) => (
              <Flex key={item.id}>
                <Text
                  key={item.id}
                  className={
                    item.id === activeStep
                      ? "myProfileIndexSecondBoxFirstMapText activeTab"
                      : "myProfileIndexSecondBoxFirstMapText inActiveTab"
                  }
                  w="full"
                  _hover={{ cursor: "pointer" }}
                  rounded="full"
                  // style={{
                  //   backgroundColor:
                  //     item.id === activeStep ? "#E9F6FF" : "white",
                  //   border:
                  //     item.id === activeStep ? "#E9F6FF" : "1px solid #A6B8C4",
                  //   color: item.id === activeStep ? "#2b6cb0" : "#5a646b",
                  // }}
                  onClick={() => setActiveStep(item.id)}
                >
                  {item.id}
                </Text>
              </Flex>
            ))}
          </Flex>
          <Box
            sx={{
              "& .chakra-form__required-indicator": {
                color: "black",
              },
            }}
            w="full"
            // zIndex={10}
            id="right-side"
          >
            {tabs.map((item) => (
              <Box key={item.id}>{activeStep === item.id && item.value}</Box>
            ))}
          </Box>
        </Flex>
      </Box>
    </>
  );
};

export default PathwayHandler;

import { Box, Button, Flex, Image, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import SingleCheckpointDataIndividualTaskOverlay from "./singleCheckpointDataIndividualTaskOverlay";
import { CLOSE_PATHWAY } from "../../../../constant/images";

const SingleCheckpointDataTask = ({
  locked,
  ci,
  checkpointsData,
  setCheckpointsData,
  selectedCheckpoint,
  selectedChild,
  outcomeId,
}) => {
  const [showOverlay, setShowOverlay] = useState(false);

  const [allowOverlay, setAllowOverlay] = useState(true);

  const [checkpointOptions, setCheckpointOptions] = useState([
    {
      title: "No",
      desc: "Child is yet to start the outcome",
      selected: true,
    },
    {
      title: "Almost",
      desc: "Child is almost able to achive the outcome but has not completely achieved it yet",
      selected: false,
    },
    {
      title: "Yes",
      desc: "Child is able to achieve the outcome",
      selected: false,
    },
  ]);

  useEffect(() => {
    let counter = 0;

    for (
      let i = 0;
      i < checkpointsData[selectedCheckpoint - 1]?.outcomes.length;
      i++
    ) {
      if (
        checkpointsData[selectedCheckpoint - 1]?.outcomes[i]?.status === "Yes"
      ) {
        counter++;
      }
    }

    if (counter > 0) {
      setCheckpointsData((ch) =>
        ch?.map((c, index) =>
          index + 1 === selectedCheckpoint
            ? { ...c, availableComplete: true }
            : c
        )
      );
    } else if (counter === 0) {
      setCheckpointsData((ch) =>
        ch?.map((c, index) =>
          index + 1 === selectedCheckpoint
            ? { ...c, availableComplete: false }
            : c
        )
      );
    } else {
      return;
    }

    if (checkpointsData[selectedCheckpoint - 1]?.status === "Accomplished") {
      setAllowOverlay(false);
      const completedTasks = checkpointsData[
        selectedCheckpoint - 1
      ]?.outcomes.filter((item) => item.status === "Yes");
      const leftTasks = checkpointsData[
        selectedCheckpoint - 1
      ]?.outcomes.filter((item) => item.status !== "Yes");

      setCheckpointsData((ch) =>
        ch?.map((c, index) =>
          index + 1 === selectedCheckpoint
            ? { ...c, checkpointItems: completedTasks }
            : index + 1 === selectedCheckpoint + 1
            ? { ...c, checkpointItems: [...c.checkpointItems, ...leftTasks] }
            : c
        )
      );
    }
  }, [checkpointsData, selectedCheckpoint, setCheckpointsData]);

  const handleShowOverlay = () => {
    allowOverlay && setShowOverlay(true);
  };

  return (
    <>
      <Flex
        key={outcomeId}
        justify="space-between"
        className="myStudentsIndividualTaskContainer"
      >
        <Flex gap="20.5px">
          <Button className="singleCheckpointDataMapIdBtn">{outcomeId}</Button>
          <Flex gap="8px" flexDir="column">
            <Text className="singleCheckpointDataMapTaskName">{ci.name}</Text>
            <Text className="singleCheckpointDataMapSubGoal">
              {ci.domainName}
            </Text>
          </Flex>
        </Flex>
        {!locked && (
          <Box pos="relative">
            <Flex
              w="120px"
              mt="6px"
              justify="space-between"
              align="center"
              className="singleCheckpointDataMapBtn"
              onClick={handleShowOverlay}
              style={{
                backgroundColor:
                  (ci.status === "Almost" && "#f3ebff") ||
                  (ci.status === "Yes" && "#eff7e6") ||
                  ((ci.status === "No" || ci.status === "") && "#ffdad6"),
                color:
                  (ci.status === "Almost" && "#2f0d63") ||
                  (ci.status === "Yes" && "#024742") ||
                  ((ci.status === "No" || ci.status === "") && "#410002"),
                cursor: !allowOverlay ? "not-allowed" : "pointer",
              }}
            >
              <Text>
                {ci.status === "Yes"
                  ? "Yes"
                  : ci.status === "Almost"
                  ? "Almost"
                  : "No"}
              </Text>
              <Image src={allowOverlay && CLOSE_PATHWAY} />
            </Flex>
            {showOverlay && (
              <SingleCheckpointDataIndividualTaskOverlay
                setShowOverlay={setShowOverlay}
                checkpointsData={checkpointsData}
                setCheckpointsData={setCheckpointsData}
                selectedTask={outcomeId}
                selectedCheckpoint={selectedCheckpoint}
                checkpointOptions={checkpointOptions}
                setCheckpointOptions={setCheckpointOptions}
                selectedChild={selectedChild}
                outcome={ci}
              />
            )}
          </Box>
        )}
      </Flex>
    </>
  );
};

export default SingleCheckpointDataTask;

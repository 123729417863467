import {
  SimpleGrid,
  GridItem,
  FormControl,
  FormLabel,
  Image,
  FormErrorMessage,
  Button,
  Grid,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { BACK_CARET, NEXT_CARET } from "../../../constant";
import {
  toggleAPILoader,
  getRescheduleOpenSlots,
} from "../../../modules/actions";
import { useState, useEffect } from "react";
import Loader from "../../../common/components/Loader";
import { getDate } from "../../../utils/customMoment";

const RescheduleDates = ({
  data,
  calenderStartDate,
  handleCalendarChange,
  session,
  setSelectedSlot,
}) => {
  const dispatch = useDispatch();
  const apiLoader = useSelector((state) => state?.loader?.apiLoader);
  const user = useSelector((state) => state?.user?.profile);
  const rescheduleOpenSlots = useSelector(
    (state) => state?.rescheduleOpenSlots
  );
  const [showSlot, setShowSlot] = useState(false);

  useEffect(() => {
    rescheduleOpenSlots.forEach((slot) => {
      //inserting an additional field isSelected for select-deselect ui
      slot.openSlots.forEach(function (element) {
        element.isSelected = false;
      });
    });
  }, [rescheduleOpenSlots]);

  useEffect(() => {
    setShowSlot(false);
  }, [calenderStartDate]);

  const handleAvailableSlots = () => {
    if (calenderStartDate) {
      const reqData = {
        expert: session.sessionExpertId,
        startDate: calenderStartDate.format("YYYY/MM/DD"),
      };
      dispatch(toggleAPILoader(true));
      dispatch(getRescheduleOpenSlots(reqData));
      setShowSlot(true);
    }
  };

  const handleSlotSelection = (date, slot) => {
    let choosenSlot = {};
    rescheduleOpenSlots.map((dates) => {
      if (dates.date === date) {
        dates.openSlots.map((slotItem) => {
          if (slotItem.label === slot.label) {
            slotItem.isSelected = !slotItem.isSelected;
            if (slotItem.isSelected) {
              choosenSlot = {
                date: dates.date,
                slotStartTime: slot.value,
                slotDuration: 60,
                label: slot.label,
              };
            }
          } else {
            slotItem.isSelected = false;
          }
        });
      }
    });
    setSelectedSlot(choosenSlot);
  };

  return (
    <SimpleGrid gap="16px" m="16px">
      <GridItem>
        <FormControl isInvalid={data?.startDateError}>
          <FormLabel className="personalFormLabel">
            When do you want to reschedule these session?
          </FormLabel>
          <Calendar
            name="startDate"
            prevLabel={<Image src={BACK_CARET} />}
            nextLabel={<Image src={NEXT_CARET} />}
            calendarType="US"
            prev2Label={null}
            next2Label={null}
            view="month"
            onChange={handleCalendarChange}
            value={calenderStartDate}
            // tileClassName={renderDayTileClass}
            minDate={moment().add(1, "day").toDate()}
            maxDate={
              user?.role === "parent" && user?.gracePeriod
                ? getDate(user?.gracePeriod)
                : ""
            }
          />
          {data?.startDateError && (
            <FormErrorMessage className="personalErrorMessage">
              {data?.startDateError}
            </FormErrorMessage>
          )}
        </FormControl>
      </GridItem>
      {calenderStartDate && (
        <GridItem>
          {/* <FormLabel className="personalFormLabel">Available slots</FormLabel> */}
          <Button
            bg="#4299E1"
            color="#FFFFFF"
            mr="8px"
            border="1px solid #a6b8c4"
            onClick={() => handleAvailableSlots()}
            className="modalButtons2"
            disabled={!calenderStartDate}
          >
            Get available slots
          </Button>
          {apiLoader ? (
            <Loader />
          ) : rescheduleOpenSlots[0]?.openSlots.length > 0 && showSlot ? (
            <Grid
              templateColumns="repeat(12, 1fr)"
              gap={4}
              mb="16px"
              className="slotsDivMain"
              mt="20px"
            >
              {rescheduleOpenSlots[0]?.openSlots.length > 0 &&
                rescheduleOpenSlots[0]?.openSlots.map((slot) => (
                  <GridItem
                    className="slotsDiv"
                    colSpan={4}
                    bg={slot.isSelected ? "#E9F6FF" : "#ffffff"}
                    onClick={() =>
                      handleSlotSelection(rescheduleOpenSlots[0].date, slot)
                    }
                  >
                    {slot.label}
                  </GridItem>
                ))}
            </Grid>
          ) : (
            <GridItem className="personalFormLabel" mt="20px">
              {showSlot && !apiLoader && "No slots available on this day "}
            </GridItem>
          )}
        </GridItem>
      )}
    </SimpleGrid>
  );
};
export default RescheduleDates;

import * as actionTypes from "../actions/actionTypes";

export const plansData = (state = [], { type, data }) => {
  switch (type) {
    case actionTypes.SAVE_PURCHASE_PLANS:
      return data;
    default:
      return state;
  }
};

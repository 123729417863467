import React from "react";
import { Box } from "@chakra-ui/layout";
import { Flex, Heading } from "@chakra-ui/react";
import { Step, Steps } from "chakra-ui-steps";
import PersonalDetails from "./Steps/personal";
import ServiceDetails from "./Steps/service";

export const EditProfileStepper = ({
  userData,
  handleFormDataChange,
  prevStep,
  activeStep,
}) => {
  const steps = [
    {
      element: (
        <PersonalDetails
          userData={userData}
          handleFormDataChange={handleFormDataChange}
        />
      ),
    },
    {
      element: (
        <ServiceDetails
          userData={userData}
          handleFormDataChange={handleFormDataChange}
        />
      ),
    },
  ];

  return (
    <Flex flexDir="column" width="100%" backgroundColor="white" pt="16px">
      <Steps
        variant="circles-alt"
        orientation="horizontal"
        responsive={false}
        colorScheme="blue"
        sx={{
          "& .css-eb100c": {
            _completed: {
              color: "white",
            },
            color: "unset",
          },
          "& .css-1emvh1t, .css-vv8cpc": {
            fontSize: "14px",
            fontFamily: "Inter",
            mt: "8px",
            lineHeight: "20px",
          },
          "& .css-yli7i8:not(:last-child):after": {
            top: "calc(45px/2)",
            // borderBottom: 'dashed',
          },
          "& .css-1j537u4[aria-current=step]": {
            backgroundColor: "#4299E1",
            color: "white",
            border: "none",
            textColor: "white",
          },
          "& .css-1j537u4": {
            height: "32px",
            width: "32px",
          },
        }}
        activeStep={activeStep}
      >
        {steps.map(({ label, element }, index) => (
          <Step label={label} key={index}>
            <Box sx={{ p: "16px" }}>
              <Heading
                sx={{
                  "& .chakra-form__required-indicator": {
                    color: "black",
                  },
                }}
                textAlign="center"
              >
                {element}
              </Heading>
            </Box>
          </Step>
        ))}
      </Steps>
    </Flex>
  );
};

import { call, put } from "redux-saga/effects";
import * as actionCreators from "../actions";
import * as serviceCreator from "../services";
import { Toaster } from "../../common/components/Toaster";

export function* getActivityListingSagaCall(action) {
  try {
    const response = yield call(
      serviceCreator.getActivityListingService,
      action.data
    );
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.saveActivityListing(response.data.responseData));
      yield put(actionCreators.toggleMainLoader(false));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      Toaster(response.data.message, "error");
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    Toaster(error.response.data.message, "error");
  }
}

export function* getVideosListingSagaCall(action) {
  try {
    const response = yield call(serviceCreator.getVideosService, action.data);

    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.saveVideosListing(response.data.responseData));
      yield put(actionCreators.toggleMainLoader(false));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      Toaster(response.data.message, "error");
    }
  } catch (error) {
    if (error.response.data.message === "no video for this age range") {
      yield put(actionCreators.saveVideosListing([]));
    } else {
      Toaster(error.response.data.message, "error");
    }
    yield put(actionCreators.toggleMainLoader(false));
  }
}

export function* getParentingTipsSagaCall(action) {
  try {
    const response = yield call(
      serviceCreator.getParentingTipsService,
      action.data
    );
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.saveParentingTips(response.data.responseData));
      yield put(actionCreators.toggleMainLoader(false));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      Toaster(response.data.message, "error");
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    Toaster(error.response.data.message, "error");
  }
}

export function* getLiveBookingSagaCall(action) {
  try {
    const response = yield call(serviceCreator.getLiveBookingService);
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.saveLiveBooking(response.data.responseData));
      yield put(actionCreators.toggleMainLoader(false));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      Toaster(response.data.message, "error");
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    Toaster(error.response.data.message, "error");
  }
}

import { Box, Image, Flex, Grid, GridItem, Text } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { RED_CLOCK, BLUE_CALENDER } from "../../../../constant";
import {
  getDayNumber1,
  getMonthName1,
  getMonthDateYear,
} from "../../../../utils/customMoment";
import moment from "moment";
import { useState, useEffect } from "react";

const ConfirmSessions = ({
  weeklyChoosenSlots,
  setWeeklyChoosenSlots,
  handlePrevStep,
  deleteBlcokedSlotApiCall,
}) => {
  const user = useSelector((state) => state?.user?.profile);
  let [hasTimerStarted, setHasTimerStarted] = useState(false);
  let [timerMinute, setTimerMinute] = useState(0);
  let [timerSecond, setTimerSecond] = useState(0);

  useEffect(() => {
    if (hasTimerStarted === false) {
      setHasTimerStarted(true);
      let timerDuration = 180;

      // interval to show timer to the user
      setInterval(() => {
        let minute = Math.floor(timerDuration / 60);
        let second = timerDuration % 60;
        setTimerMinute(minute);
        if (second >= 10) {
          setTimerSecond(second);
        } else {
          setTimerSecond("0" + second);
        }
        timerDuration--;
      }, 1000);
    }
    const timerId = setTimeout(() => {
      setWeeklyChoosenSlots([]);
      deleteBlcokedSlotApiCall();
      handlePrevStep();
    }, 180 * 1000);

    // Clean up by clearing the timeout when the component unmounts
    return () => {
      if (hasTimerStarted) {
        clearTimeout(timerId);
      }
    };
  }, [timerMinute, timerSecond, hasTimerStarted]);

  return (
    <Box>
      <Grid className="timerBlock" templateColumns="repeat(12, 1fr)" gap={4}>
        <GridItem className="confirmSlotText" colSpan={{ base: 8, md: 9 }}>
          Please confirm your slots within 3 minutes
        </GridItem>
        <GridItem
          className="confirmSlotTimer"
          colSpan={{ base: 4, md: 3 }}
          display="flex"
        >
          <Image src={RED_CLOCK} mr="4px" />
          {timerMinute + " : " + timerSecond}
        </GridItem>
      </Grid>

      <Box mt="32px">
        <Text className="selectedSlotText">
          You have Selected {weeklyChoosenSlots.length} slots across 1 weeks.
        </Text>
        {weeklyChoosenSlots.map((dates) => (
          <Flex mt="16px">
            <Image src={BLUE_CALENDER} />
            <Text className="slotText">
              {getDayNumber1(dates.date) + " " + getMonthName1(dates.date)} -
              {dates.label}
            </Text>
          </Flex>
        ))}
      </Box>
      <Box className="transactionSummaryText">Final transaction summary</Box>
      <Box className="summaryBox">
        <Flex justifyContent="space-between" mb="20px">
          <Box className="summaryBoxLeftText">Session being booked</Box>
          <Box className="summaryBoxCount">
            {weeklyChoosenSlots.length} Session
          </Box>
        </Flex>
        <Flex justifyContent="space-between" mb="20px">
          <Box className="summaryBoxLeftText">
            Balance as of {getMonthDateYear(moment())}
          </Box>
          <Box className="summaryBoxCount">
            {" "}
            {user?.currentBalance?.toString().indexOf(".") === -1
              ? Number(user?.currentBalance)
              : Number(user?.currentBalance).toFixed(2)}{" "}
            Session
          </Box>
        </Flex>
        <Flex justifyContent="space-between" mb="18px">
          <Box className="summaryBoxLeftText">
            Balance used for session booking
          </Box>
          <Box
            className="summaryBoxCount"
            color="#BA1A1A"
            borderColor="#BA1A1A"
          >
            - {weeklyChoosenSlots.length} Session
          </Box>
        </Flex>
        <Box bg="#A6B8C4" height="1px" mb="18px"></Box>
        <Flex justifyContent="space-between">
          <Box className="summaryBoxLeftText">Net remaining balance</Box>
          <Box className="summaryBoxCount">
            {user?.currentBalance?.toString().indexOf(".") === -1
              ? Number(user?.currentBalance) - weeklyChoosenSlots.length
              : Number(user?.currentBalance).toFixed(2) -
                weeklyChoosenSlots.length}{" "}
            Session
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};
export default ConfirmSessions;

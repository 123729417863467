import { Box, Show } from "@chakra-ui/react";
import Navbar from "../../components/Navbar";
import MobileBottomNavbar from "../../components/MobileBottomNavbar";

const AuthenticatedContainer = ({ children }) => {
  return (
    <Box>
      <Navbar />
      {children}
      <Show below="lg">
        <MobileBottomNavbar />
      </Show>
    </Box>
  );
};
export default AuthenticatedContainer;

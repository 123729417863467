import React, { useState, useEffect } from "react";
import { Box } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { getChildPathway, toggleMainLoader } from "../../modules/actions";
import Loader from "../../common/components/Loader";
import SingleCheckpointData from "../../pages/Expert/MyStudents/utils/singleCheckpointData";

const SessionPathwayTab = ({ selectedSession }) => {
  const dispatch = useDispatch();
  const { mainLoader } = useSelector((state) => state?.loader);
  const pathway = useSelector((state) => state?.pathway[0]);
  const [checkpointsData, setCheckpointsData] = useState([]);

  useEffect(() => {
    dispatch(toggleMainLoader(true));
    dispatch(getChildPathway(selectedSession?.sessionStudentId));
  }, [selectedSession]);

  return (
    <Box>
      {mainLoader ? (
        <Loader text="Loading..." />
      ) : (
        pathway?.checkpoints.map((checkpoint, index) => (
          <SingleCheckpointData
            setCheckpointsData={setCheckpointsData}
            checkpointsData={checkpointsData}
            key={index}
            c={checkpoint}
            checkpointId={index + 1}
            selectedChild={{ id: selectedSession.sessionStudentId }}
            pathway={pathway}
            type="sessionPathwayTab"
          />
        ))
      )}
    </Box>
  );
};
export default SessionPathwayTab;

import { call, put } from "redux-saga/effects";
import * as actionCreators from "../actions";
import * as serviceCreator from "../services";
import { Toaster } from "../../common/components/Toaster";
import { parentLogin } from "../../utils/firebase";

export function* getUserProfileSagaCall(action) {
  try {
    const response = yield call(serviceCreator.getUserProfileService);
    if (response.status === 200 || response.status === 201) {
      let responseData;
      if (response.data.data.length > 0) {
        responseData = { ...response.data.data[0], idToken: action.data };
      } else {
        responseData = { registered: false, idToken: action.data };
      }

      yield put(actionCreators.saveUserProfile(responseData));
      yield put(actionCreators.toggleMainLoader(false));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      Toaster(response.data.message, "error");
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    Toaster(error.response.data.message, "error");
  }
}

export function* saveUserProfileImageSagaCall(action) {
  try {
    yield put(actionCreators.toggleAPILoader(true));
    if (action.data.imgUrl !== null) {
      const imgResponse = yield serviceCreator.saveUserProfileImageService(
        action.data.imgUrl
      );
      if (imgResponse.status === 200 || imgResponse.status === 201) {
        const updatedImageFormData = {
          ...action.data,
          imgUrl: imgResponse.data.data,
        };
        yield put(
          actionCreators.saveUserProfileDetails(
            updatedImageFormData,
            action.step
          )
        );
      } else {
        yield put(actionCreators.toggleAPILoader(false));
        Toaster(imgResponse.data.message, "error");
      }
    } else {
      yield put(
        actionCreators.saveUserProfileDetails(action.data, action.step)
      );
    }
  } catch (error) {
    yield put(actionCreators.toggleAPILoader(false));
    Toaster(error.response.data.message, "error");
  }
}

export function* saveUserProfileDetailsSagaCall(action) {
  try {
    const response = yield serviceCreator.saveUserProfileService(
      action.step,
      action.data
    );
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.toggleAPILoader(false));
      Toaster("Profile details saved successfully", "success");
    } else {
      yield put(actionCreators.toggleAPILoader(false));
      Toaster(response.data.message, "error");
    }
  } catch (error) {
    yield put(actionCreators.toggleAPILoader(false));
    Toaster(error.response.data.message, "error");
  }
}

export function* getExpertTransactionsSagaCall(action) {
  try {
    yield put(actionCreators.toggleAPILoader(true));
    const response = yield serviceCreator.getExpertTransactionsService(
      action.month.toLowerCase(),
      action.year
    );
    if (response.status === 200 || response.status === 201) {
      let responseData = response?.data;
      yield put(actionCreators.saveTransactionsData(responseData));
      yield put(actionCreators.toggleAPILoader(false));
    } else {
      yield put(actionCreators.toggleAPILoader(false));
      Toaster(response.data.message, "error");
    }
  } catch (error) {
    yield put(actionCreators.toggleAPILoader(false));
    Toaster(error.response.data.message, "error");
  }
}

export function* getExpertLeavesSagaCall(action) {
  try {
    yield put(actionCreators.toggleAPILoader(true));
    const response = yield serviceCreator.getExpertLeavesService(
      action?.status.status.toLowerCase(),
      action?.data
    );
    if (response.status === 200 || response.status === 201) {
      let responseData = response?.data;
      yield put(actionCreators.saveExpertData({ leaves: responseData }));
      yield put(actionCreators.toggleAPILoader(false));
    } else {
      yield put(actionCreators.toggleAPILoader(false));
      Toaster(response.data.message, "error");
    }
  } catch (error) {
    yield put(actionCreators.toggleAPILoader(false));
    Toaster(error.response.data.message, "error");
  }
}

export function* getAllStudentsSagaCall(action) {
  try {
    yield put(actionCreators.toggleAPILoader(true));
    const response = yield serviceCreator.getAllStudentsDataService();
    if (response.status === 200 || response.status === 201) {
      const { data } = response;
      yield put(actionCreators.saveAllStudentsDataAction(data.data));
      yield put(actionCreators.toggleAPILoader(false));
    } else {
      yield put(actionCreators.toggleAPILoader(false));
      Toaster(response.data.message, "error");
    }
  } catch (error) {
    console.log(error.response, "err");
    yield put(actionCreators.toggleAPILoader(false));
    Toaster(error?.response?.data.message, "error");
  }
}

export function* getStudentsDomainGoalsSagaCall(action) {
  try {
    yield put(actionCreators.toggleAPILoader(true));
    const response = yield serviceCreator.getCreatePathwayDomainGoalsService();
    if (response?.status === 200 || response?.status === 201) {
      const { data } = response;
      yield put(actionCreators.saveDomainsGoalDataAction(data?.data));
      yield put(actionCreators.toggleAPILoader(false));
    } else {
      yield put(actionCreators.toggleAPILoader(false));
      Toaster(response.data.message, "error");
    }
  } catch (error) {
    yield put(actionCreators.toggleAPILoader(false));
    Toaster(error.response.data.message, "error");
  }
}

export function* getStudentsSubGoalDomainSagaCall(action) {
  try {
    yield put(actionCreators.toggleAPILoader(true));
    const response = yield serviceCreator.getSubGoalItemsService(action?.data);
    if (response?.status === 200 || response?.status === 201) {
      const { data } = response;
      yield put(actionCreators.saveSuboalDomainsAction(data.data));
      yield put(actionCreators.toggleAPILoader(false));
    } else {
      yield put(actionCreators.toggleAPILoader(false));
      Toaster(response.data.message, "error");
    }
  } catch (error) {
    yield put(actionCreators.toggleAPILoader(false));
    Toaster(error.response.data.message, "error");
  }
}

export function* getParentSessionHistorySagaCall(action) {
  try {
    yield put(actionCreators.toggleAPILoader(true));
    const response = yield serviceCreator.getParentSessionHistoryService(
      action?.data
    );
    if (response?.status === 200 || response?.status === 201) {
      const { data } = response;
      yield put(actionCreators.saveParentSessionAction(data.data));
      yield put(actionCreators.toggleAPILoader(false));
    } else {
      yield put(actionCreators.toggleAPILoader(false));
      Toaster(response.data.message, "error");
    }
  } catch (error) {
    yield put(actionCreators.toggleAPILoader(false));
    Toaster(error.response.data.message, "error");
  }
}

export function* getParentJournalSagaCall(action) {
  try {
    yield put(actionCreators.toggleAPILoader(true));
    const response = yield serviceCreator.getParentJournalService(
      action?.id,
      action?.month,
      action?.year
    );
    if (response?.status === 200 || response?.status === 201) {
      const { data } = response;
      yield put(actionCreators.saveParentJournalAction(data.data));
      yield put(actionCreators.toggleAPILoader(false));
    } else {
      yield put(actionCreators.toggleAPILoader(false));
      Toaster(response.data.message, "error");
    }
  } catch (error) {
    yield put(actionCreators.toggleAPILoader(false));
    Toaster(error.response.data.message, "error");
  }
}

export function* getStudentNotesSagaCall(action) {
  try {
    yield put(actionCreators.toggleAPILoader(true));
    const response = yield serviceCreator.getStudentsNotesService(action?.data);
    if (response?.status === 200 || response?.status === 201) {
      const { data } = response;
      yield put(actionCreators.saveStudentsNotesAction(data.data));
      yield put(actionCreators.toggleAPILoader(false));
    } else {
      yield put(actionCreators.toggleAPILoader(false));
      Toaster(response.data.message, "error");
    }
  } catch (error) {
    yield put(actionCreators.toggleAPILoader(false));
    Toaster(error.response.data.message, "error");
  }
}

export function* writeStudentNotesSagaCall(action) {
  try {
    yield put(actionCreators.toggleAPILoader(true));
    const response = yield serviceCreator.addStudentsNotesService(action?.data);
    if (response?.status === 200 || response?.status === 201) {
      yield put(actionCreators.getStudentsNotesAction(action?.id));
    } else {
      yield put(actionCreators.toggleAPILoader(false));
      Toaster(response.data.message, "error");
    }
  } catch (error) {
    yield put(actionCreators.toggleAPILoader(false));
    Toaster(error.response.data.message, "error");
  }
}

export function* deleteStudentNotesSagaCall(action) {
  try {
    yield put(actionCreators.toggleAPILoader(true));
    const response = yield serviceCreator.deleteStudentsNotesService(
      action?.data
    );
    if (response?.status === 200 || response?.status === 201) {
      yield put(actionCreators.getStudentsNotesAction(action?.id));
    } else {
      yield put(actionCreators.toggleAPILoader(false));
      Toaster(response.data.message, "error");
    }
  } catch (error) {
    yield put(actionCreators.toggleAPILoader(false));
    Toaster(error?.response?.data?.message, "error");
  }
}

export function* updateStudentsNotesSagaCall(action) {
  try {
    yield put(actionCreators.toggleAPILoader(true));
    const response = yield serviceCreator.updateStudentsNotesService(
      action?.tokenId,
      action?.data
    );
    if (response?.status === 200 || response?.status === 201) {
      yield put(actionCreators.getStudentsNotesAction(action?.id));
    } else {
      yield put(actionCreators.toggleAPILoader(false));
      Toaster(response.data.message, "error");
    }
  } catch (error) {
    yield put(actionCreators.toggleAPILoader(false));
    Toaster(error.response.data.message, "error");
  }
}

export function* getChildsDomainSagaCall(action) {
  try {
    yield put(actionCreators.toggleAPILoader(true));
    const response = yield serviceCreator.getStudentsDomainsService(
      action?.data
    );
    if (response?.status === 200 || response?.status === 201) {
      const { data } = response;
      yield put(actionCreators.saveChildsDomainAction(data.data));
      yield put(actionCreators.toggleAPILoader(false));
    } else {
      yield put(actionCreators.toggleAPILoader(false));
      Toaster(response.data.message, "error");
    }
  } catch (error) {
    yield put(actionCreators.toggleAPILoader(false));
    Toaster(error.response.data.message, "error");
  }
}
export function* getParentTokenSagaCall(action) {
  try {
    yield put(actionCreators.toggleMainLoader(true));
    const response = yield serviceCreator.getParentCustomTokenService(
      action.token
    );
    if (response.status === 200 || response.status === 201) {
      let responseData = response?.data;
      // yield put(actionCreators.saveParentToken({ token: responseData }));

      parentLogin(responseData.responseData.user_token);
      // yield put(actionCreators.toggleAPILoader(false));
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    if (error.response.status === 401) {
      window.location.href = `${process.env.REACT_APP_KP_WEB_URL}auth/login`;
    } else {
      Toaster(error.response.data.message, "error");
    }
  }
}

export function* applyExpertLeavesSagaCall(action) {
  try {
    yield put(actionCreators.toggleAPILoader(true));
    const response = yield serviceCreator.applyLeavesService(action?.data);
    if (response?.status === 200 || response?.status === 201) {
      // yield put(actionCreators.getStudentsNotesAction(action?.id));
      Toaster(response.data.message, "success");
    } else {
      yield put(actionCreators.toggleAPILoader(false));
      Toaster(response.data.message, "error");
    }
  } catch (error) {
    yield put(actionCreators.toggleAPILoader(false));
    Toaster(error.response.data.message, "error");
  }
}

export function* getChildsExpertsSagaCall() {
  try {
    yield put(actionCreators.toggleAPILoader(true));
    const response = yield serviceCreator.getMyExpertsService();
    if (response?.status === 200 || response?.status === 201) {
      const { data } = response;
      yield put(actionCreators.saveMyExperts(data.data));
      yield put(actionCreators.toggleAPILoader(false));
    } else {
      yield put(actionCreators.toggleAPILoader(false));
      Toaster(response.data.message, "error");
    }
  } catch (error) {
    yield put(actionCreators.toggleAPILoader(false));
    Toaster(error.response.data.message, "error");
  }
}

export function* getUserDataSagaCall(action) {
  try {
    const response = yield call(serviceCreator.getUserDataService, action.data);
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.saveUserData(response.data.data[0]));
      yield put(actionCreators.toggleMainLoader(false));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      Toaster(response.data.message, "error");
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    Toaster(error.response.data.message, "error");
  }
}

export function* getPopularArticlesSagaCall() {
  try {
    const response = yield call(serviceCreator.getPopularArticlesService);
    if (response.status === 200 || response.status === 201) {
      yield put(
        actionCreators.savePopularArticles(
          response.data?.responseData?.articles
        )
      );
      yield put(actionCreators.toggleMainLoader(false));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      Toaster(response.data.message, "error");
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    Toaster(error.response.data.message, "error");
  }
}

export function* getBalanceDetailsSagaCall() {
  try {
    const response = yield call(serviceCreator.getBalanceDetailsService);
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.saveBalanceDetails(response.data.data[0]));
      yield put(actionCreators.toggleSessionLoader(false));
    } else {
      yield put(actionCreators.toggleSessionLoader(false));
      Toaster(response.data.message, "error");
    }
  } catch (error) {
    yield put(actionCreators.toggleSessionLoader(false));
    Toaster(error.response.data.message, "error");
  }
}

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  GridItem,
  Input,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import {
  areaOfConcerns,
  serviceOptions,
  languageOptions,
} from "../../../../constant/selectOptions";
import OverlayComponent from "../TabComponents/overlayComponent";
import "../myProfile.css";
import { Select } from "chakra-react-select";
import { editUserProfileService } from "../../../../modules/services";
import { Toaster } from "../../../../common/components/Toaster";
import { getUserProfile, toggleAPILoader } from "../../../../modules/actions";

const Services = () => {
  const dispatch = useDispatch();
  const reduxState = useSelector((state) => state);
  const { apiLoader } = useSelector((state) => state?.loader);
  const [servicesData, setServicesData] = useState({
    serviceTypes: [],
    noServiceTypes: false,
    condition: [],
    languages: [],
    startingAgeRange: 0,
    noStartingAgeRange: false,
    endingAgeRange: 0,
    noEndingAgeRange: false,
    yearsOfExperience: 0,
    noYearsOfExperience: false,
    noCondition: false,
    noLanguages: false,
  });

  const [overlayOpen, setOverlayOpen] = useState(false);
  const [next, setNext] = useState(false);
  const [defaultLanguages, setDefaultLanguages] = useState([]);
  const [defaultServiceTypes, setDefaultServiceTypes] = useState([]);
  const [defaultCondition, setDefaultCondition] = useState([]);
  const [closer, setCloser] = useState(false);
  // const [noServiceTypes, setNoServiceTypes] = useState(false);
  // const [noStartingAgeRange, setNoStartingAgeRange] = useState(false);
  // const [noEndingAgeRange, setNoEndingAgeRange] = useState(false);
  // const [noYearsOfExperience, setNoYearsOfExperience] = useState(false);
  // const [noCondition, setNoCondition] = useState(false);
  // const [noLanguages, setNoLanguages] = useState(false);

  useEffect(() => {
    setServicesData({
      serviceTypes: reduxState?.user?.profile?.serviceTypes || [],
      condition: reduxState?.user?.profile?.condition || [],
      languages: reduxState?.user?.profile?.languages || [],
      startingAgeRange: reduxState?.user?.profile?.startingAgeRange || 0,
      endingAgeRange: reduxState?.user?.profile?.endingAgeRange || 0,
      yearsOfExperience: reduxState?.user?.profile?.yearsOfExperience || 0,
    });
  }, []);

  function handleStringManupulation(array) {
    const length = array?.length;
    if (length === 0)
      return "You don't have any value selected for the particular field";
    if (length === 1) return array[0];
    let text = "";
    for (let i = 0; i < length - 2; i++) {
      text += `${array[i]}, `;
    }
    let finalText = text + array[length - 2] + " and " + array[length - 1];
    return finalText;
  }

  const handleServicesDataChange = (name, value) => {
    setCloser(true);
    setServicesData({ ...servicesData, [name]: value });
    setNext(true);
  };

  useEffect(() => {
    setDefaultLanguages(
      servicesData?.languages?.map((item) => {
        return {
          value: item,
          label: item,
        };
      })
    );
    setDefaultServiceTypes(
      servicesData?.serviceTypes?.map((item) => {
        return {
          value: item,
          label: item,
        };
      })
    );
    setDefaultCondition(
      servicesData?.condition?.map((item) => {
        return {
          value: item,
          label: item,
        };
      })
    );
  }, [servicesData]);

  // useEffect(() => {
  //   const {
  //     serviceTypes,
  //     condition,
  //     startingAgeRange,
  //     endingAgeRange,
  //     yearsOfExperience,
  //     languages,
  //   } = servicesData;

  //   if (
  //     !startingAgeRange ||
  //     !endingAgeRange ||
  //     !yearsOfExperience ||
  //     !serviceTypes.length > 0 ||
  //     !languages.length > 0 ||
  //     !condition.length > 0
  //   ) {
  //     setNext(false);
  //   }
  //   if (Number(yearsOfExperience) >= 100) {
  //     setNext(false);
  //   } else {
  //     setNext(true);
  //   }
  // }, [servicesData]);

  const handleNextStep = () => {
    const {
      serviceTypes,
      condition,
      startingAgeRange,
      endingAgeRange,
      yearsOfExperience,
      languages,
    } = servicesData;

    const validateServiceType = serviceTypes.length === 0;

    const validateStartingAge =
      startingAgeRange === "" ||
      Number(startingAgeRange) > 16 ||
      Number(startingAgeRange) < 0 ||
      Number(startingAgeRange) > Number(endingAgeRange);

    const validateEndingAge =
      endingAgeRange === "" ||
      Number(endingAgeRange) > 16 ||
      Number(startingAgeRange) > Number(endingAgeRange);

    const validateExperince =
      yearsOfExperience === "" || Number(yearsOfExperience) >= 100;

    setServicesData({
      ...servicesData,
      noCondition: condition?.length === 0,
      noEndingAgeRange: validateEndingAge,
      noLanguages: languages?.length === 0,
      noServiceTypes: validateServiceType,
      noStartingAgeRange: validateStartingAge,
      noYearsOfExperience: validateExperince,
    });

    if (
      !validateServiceType &&
      !validateStartingAge &&
      !validateEndingAge &&
      !validateExperince &&
      condition?.length > 0 &&
      languages?.length > 0
    ) {
      dispatch(toggleAPILoader(true));

      editUserProfileService(servicesData)
        .then((res) => {
          Toaster("Expert Services updated successfully", "success");
          dispatch(toggleAPILoader(false));
          const token = localStorage.getItem("idToken");
          dispatch(getUserProfile(token));
          setOverlayOpen(false);
        })
        .catch((error) => {
          console.log(error);
          dispatch(toggleAPILoader(false));
          Toaster(error.response.data.message, "success");
        });
    }
  };

  return (
    <Box id="mainWrapper" px="16px" py="24px">
      {overlayOpen && (
        <OverlayComponent
          setOverlayOpen={setOverlayOpen}
          closer={closer}
          setServicesData={setServicesData}
          title={"Edit services"}
          handleNextStep={handleNextStep}
          apiLoader={apiLoader}
          // btnDisabled={next}
        >
          <SimpleGrid gap="24px" p="16px">
            <GridItem>
              <FormControl isInvalid={servicesData.noServiceTypes}>
                <FormLabel className="leavesApplierFlexFormLabel">
                  Service Type
                </FormLabel>
                <Box
                  border="1px solid #A6B8C4"
                  fontWeight="medium"
                  borderRadius="4px"
                  sx={{
                    "& .css-wtf6ne": {
                      bg: "white",
                      border: "none",
                    },
                    "& .chakra-divider": {
                      opacity: 0,
                    },
                  }}
                >
                  <Select
                    isMulti
                    onChange={(e) =>
                      handleServicesDataChange(
                        "serviceTypes",
                        e.map((l) => l.value)
                      )
                    }
                    name="serviceTypes"
                    closeMenuOnSelect={false}
                    value={defaultServiceTypes}
                    selectedOptionStyle="check"
                    placeholder="Select an option"
                    className="commonFormSelect"
                    hideSelectedOptions={false}
                    selectedOptionColorScheme="green"
                    options={serviceOptions}
                  />
                </Box>

                {serviceOptions && (
                  <FormErrorMessage className="servicesErrorMessage">
                    This field cannot be empty
                  </FormErrorMessage>
                )}
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl isInvalid={servicesData.noCondition}>
                <FormLabel className="leavesApplierFlexFormLabel">
                  Condition
                </FormLabel>
                <Box
                  border="1px solid #A6B8C4"
                  fontWeight="medium"
                  borderRadius="4px"
                  sx={{
                    "& .css-wtf6ne": {
                      bg: "white",
                      border: "none",
                    },
                    "& .chakra-divider": {
                      opacity: 0,
                    },
                  }}
                >
                  <Select
                    isMulti
                    onChange={(e) =>
                      handleServicesDataChange(
                        "condition",
                        e.map((l) => l.value)
                      )
                    }
                    name="condition"
                    closeMenuOnSelect={false}
                    value={defaultCondition}
                    selectedOptionStyle="check"
                    placeholder="Select an option"
                    className="commonFormSelect"
                    hideSelectedOptions={false}
                    selectedOptionColorScheme="green"
                    options={areaOfConcerns}
                  />
                </Box>
                {servicesData.noCondition && (
                  <FormErrorMessage className="servicesErrorMessage">
                    This field cannot be empty
                  </FormErrorMessage>
                )}
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl isInvalid={servicesData.noLanguages}>
                <FormLabel className="leavesApplierFlexFormLabel">
                  Languages
                </FormLabel>
                <Box
                  borderRadius="4px"
                  border="1px solid #A6B8C4"
                  fontWeight="medium"
                  sx={{
                    "& .css-wtf6ne": {
                      bg: "white",
                      border: "none",
                    },
                    "& .chakra-divider": {
                      opacity: 0,
                    },
                  }}
                >
                  <Select
                    isMulti
                    onChange={(e) =>
                      handleServicesDataChange(
                        "languages",
                        e.map((l) => l.value)
                      )
                    }
                    name="languages"
                    closeMenuOnSelect={false}
                    value={defaultLanguages}
                    selectedOptionStyle="check"
                    placeholder="Select an option"
                    className="commonFormSelect"
                    hideSelectedOptions={false}
                    selectedOptionColorScheme="green"
                    options={languageOptions}
                  />
                </Box>
                {servicesData.noLanguages && (
                  <FormErrorMessage className="servicesErrorMessage">
                    This field cannot be empty
                  </FormErrorMessage>
                )}
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl isInvalid={servicesData.noYearsOfExperience}>
                <FormLabel className="leavesApplierFlexFormLabel">
                  Years of experience
                </FormLabel>
                <Input
                  onChange={(e) =>
                    handleServicesDataChange(e.target.name, e.target.value)
                  }
                  name="yearsOfExperience"
                  value={servicesData.yearsOfExperience}
                  type="number"
                  className="leavesApplierFlexFormInput"
                  placeholder="Enter years of experience"
                />
                {servicesData.noYearsOfExperience && (
                  <FormErrorMessage className="servicesErrorMessage">
                    Please mention valid years of experience
                  </FormErrorMessage>
                )}
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl isInvalid={servicesData.noStartingAgeRange}>
                <FormLabel className="leavesApplierFlexFormLabel">
                  Youngest client age in years (starting age)
                </FormLabel>
                <Input
                  className="leavesApplierFlexFormInput"
                  onChange={(e) =>
                    handleServicesDataChange(e.target.name, e.target.value)
                  }
                  name="startingAgeRange"
                  value={servicesData.startingAgeRange}
                  type="number"
                  placeholder="Enter years of experience"
                />
                {servicesData.noStartingAgeRange && (
                  <FormErrorMessage className="servicesErrorMessage">
                    Provide starting age less than 16
                  </FormErrorMessage>
                )}
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl isInvalid={servicesData.noEndingAgeRange}>
                <FormLabel className="leavesApplierFlexFormLabel">
                  Oldest client age in years (ending age)
                </FormLabel>
                <Input
                  className="leavesApplierFlexFormInput"
                  onChange={(e) =>
                    handleServicesDataChange(e.target.name, e.target.value)
                  }
                  name="endingAgeRange"
                  value={servicesData.endingAgeRange}
                  type="number"
                  placeholder="Enter years of experience"
                />
                {servicesData.noEndingAgeRange && (
                  <FormErrorMessage className="servicesErrorMessage">
                    Provide ending age less than 16 and greater than starting
                    age
                  </FormErrorMessage>
                )}
              </FormControl>
            </GridItem>
          </SimpleGrid>
        </OverlayComponent>
      )}
      <Text className="txnsTabText1">Services</Text>
      <Text className="txnsTabText2">
        Information about the services, conditions and languages you have
        selected to provide for
      </Text>
      <Button
        className="leavesApplyLeaveBtn"
        mt="16px"
        onClick={() => setOverlayOpen(true)}
        _hover={{ bg: "white", cursor: "pointer" }}
      >
        Edit Services
      </Button>
      <Flex className="servicesMainBodyContainer">
        <SimpleGrid rowGap="16px" gridTemplateColumns="160px 1fr">
          <GridItem colSpan="160px">
            <Text className="servicesMainBodyTitle">Service type</Text>
          </GridItem>
          <GridItem colSpan={1}>
            <Text className="servicesMainBodyTitleDesc">
              {handleStringManupulation(servicesData?.serviceTypes)}
            </Text>
          </GridItem>
          <GridItem colSpan="160px">
            <Text className="servicesMainBodyTitle">Conditions</Text>
          </GridItem>
          <GridItem colSpan={1}>
            <Text className="servicesMainBodyTitleDesc">
              {handleStringManupulation(servicesData?.condition)}
            </Text>
          </GridItem>
          <GridItem colSpan="160px">
            <Text className="servicesMainBodyTitle">Language</Text>
          </GridItem>
          <GridItem colSpan={1}>
            <Text className="servicesMainBodyTitleDesc">
              {handleStringManupulation(servicesData?.languages)}
            </Text>
          </GridItem>
          <GridItem colSpan="160px">
            <Text className="servicesMainBodyTitle">Age range</Text>
          </GridItem>
          <GridItem colSpan={1}>
            <Text className="servicesMainBodyTitleDesc">
              {servicesData?.startingAgeRange} to {servicesData?.endingAgeRange}{" "}
              year old
            </Text>
          </GridItem>
        </SimpleGrid>
      </Flex>
    </Box>
  );
};

export default Services;

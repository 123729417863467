import { useSelector } from "react-redux";
import AuthenticatedContainer from "../../../container/Authenticated";
import AllResources from "../../Expert/Resource/Tabs/AllResources";
import FileViwer from "../../../components/FileViewer";

const ExerciseResource = () => {
  const outcome = useSelector((state) => state.outcomes);

  return (
    <AuthenticatedContainer>
      <AllResources domain={outcome} type="parent"></AllResources>
      {/* <FileViwer /> */}
    </AuthenticatedContainer>
  );
};
export default ExerciseResource;

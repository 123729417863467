import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const ProgressBar = ({ progress }) => {
  return (
    <CircularProgressbar
      value={progress}
      text={progress === 0 ? "0" : progress}
      circleRatio={0.5}
      strokeWidth={15}
      styles={buildStyles({
        rotation: 1 / 2 + 1 / 4,
        strokeLinecap: "butt",
        trailColor: "#F3F8FB",
        pathColor: progress <= 3 ? "#BA1A1A" : "#3EB274",
        textColor: "#141719",
        textSize: "20px",
      })}
    />
  );
};

export default ProgressBar;

import { Box } from "@chakra-ui/react";

const SessionStatBlock = (props) => {
  return (
    <Box className="statBox">
      <Box className="statDescText">
        {props.type}completed
        <br />
        {props.month}
      </Box>
      <Box className="statCountText">{props.count}</Box>
    </Box>
  );
};
export default SessionStatBlock;

import { Box, Flex, HStack, Image } from "@chakra-ui/react";
import { LOGO, HELP } from "../../constant";
import "./unauthenticated.css";

const UnauthenticatedContainer = ({ children }) => {
  return (
    <>
      <Box
        p={4}
        background="#FFFFFF"
        boxShadow="0px 0px 2px 1px rgba(0, 0, 0, 0.16)"
      >
        <Flex h={16} alignItems={"center"} justifyContent={"space-between"}>
          <HStack spacing={8} alignItems={"center"}>
            <Box>
              <Image src={LOGO} />
            </Box>
          </HStack>
          <Flex alignItems={"center"}>
            <Image src={HELP} mr={2} cursor="pointer" />
          </Flex>
        </Flex>
      </Box>
      <Box>{children}</Box>
    </>
  );
};
export default UnauthenticatedContainer;

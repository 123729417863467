import * as actionTypes from "../actions/actionTypes";

const initialState = [];

const pathway = (state = initialState, { type, data }) => {
  switch (type) {
    case actionTypes.SAVE_CHILD_PATHWAY:
      return data;
    default:
      return state;
  }
};
export default pathway;

export const outcomes = (state = initialState, { type, data }) => {
  switch (type) {
    case actionTypes.SAVE_OUTCOME_DETAILS:
      return data;
    default:
      return state;
  }
};

export const resources = (state = initialState, { type, data }) => {
  switch (type) {
    case actionTypes.SAVE_DOMAIN_RESOURCES:
      return data;
    default:
      return state;
  }
};

export const favouriteResources = (state = initialState, { type, data }) => {
  switch (type) {
    case actionTypes.SAVE_FAVOURITE_RESOURCES:
      return data;
    default:
      return state;
  }
};

export const checkpointDocument = (state = initialState, { type, data }) => {
  switch (type) {
    case actionTypes.SAVE_DOWNLOAD_CHECKPOINT:
      return data;
    default:
      return state;
  }
};

import React,{useEffect} from "react";
import { useDispatch } from "react-redux";
import { Box, Image, Text } from "@chakra-ui/react";
import { getUserProfile,toggleAPILoader } from "../../../../modules/actions";
import { VERIFICATION_PEIDING } from "../../../../constant/images";
import "../onboarding.css";

const Pending = () => {
  const dispatch=useDispatch();
  useEffect(() => {
      dispatch(toggleAPILoader(false));
      const token= localStorage.getItem("idToken");
      dispatch(getUserProfile(token));
  }, [dispatch]);
  
  return (
    <Box className="pendingBox">
      <Image h="93.85px" w="61.88px" src={VERIFICATION_PEIDING} />
      <Text className="pendingBoxText">Verification Pending</Text>
      <Text style={{ fontSize: "16px" }}>
        Thanks for signing up. You'll get a confirmation message on your
        WhatsApp number soon.
      </Text>
    </Box>
  );
};

export default Pending;

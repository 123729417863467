import { useEffect, useState } from "react";
import {
  Box,
  Image,
  Grid,
  GridItem,
  Button,
  useDisclosure,
  Text,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AuthenticatedContainer from "../../../container/Authenticated";
import {
  ROUTES_PATH,
  BACK_BTN,
  POPULAR_TAG,
  ICON_GREEN,
  LOGO,
} from "../../../constant";
import {
  toggleMainLoader,
  getPurchasePlans,
  toggleAPILoader,
} from "../../../modules/actions";
import "./choosePlan.css";
import Loader from "../../../common/components/Loader";
import {
  createOrderService,
  handlePaymentTransactionsService,
} from "../../../modules/services";
import { Toaster } from "../../../common/components/Toaster";
import PaymentDetailsPopup from "../../../components/PaymentDetailsPopup";

const ChoosePlan = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user?.profile);
  const plans = useSelector((state) => state?.plansData);
  const mainLoader = useSelector((state) => state?.loader?.mainLoader);
  const [paymentDetails, setPaymentDetails] = useState("");
  const {
    isOpen: isPaymentDetailsOpen,
    onOpen: onPaymentDetailsOpen,
    onClose: onPaymentDetailsClose,
  } = useDisclosure();

  useEffect(() => {
    dispatch(toggleMainLoader(true));
    dispatch(getPurchasePlans());
  }, []);

  //   useEffect(() => {
  //     if (Object.keys(paymentDetails).length > 0) {
  //       onPaymentDetailsOpen();
  //     }
  //   }, [paymentDetails]);

  const handlePayNow = (plan) => {
    let receiptNumber = "Receipt#" + Math.floor(Math.random() * 5123 * 43) + 10;

    let orderDetails = {
      amount: plan.discountedPrice,
      // amount: 1,
      receipt: receiptNumber,
    };

    dispatch(toggleMainLoader(true));
    createOrderService(orderDetails)
      .then((res) => {
        const order = res.data.data;

        const options = {
          key: process.env.REACT_APP_RAZORPAY_API_KEY, // Enter the Key ID generated from the Dashboard
          // "amount": 100, // Amount is in currency subunits. Default currency is INR. Hence, 29935 refers to 29935 paise or INR 299.35.
          amount: plan.discountedPrice, // Amount is in currency subunits. Default currency is INR. Hence, 29935 refers to 29935 paise or INR 299.35.
          name: "Kinderpass Payment",
          currency: order.currency,
          order_id: order.id, //This is a sample Order ID. Create an Order using Orders API. (https://razorpay.com/docs/payment-gateway/orders/integration/#step-1-create-an-order). Refer the Checkout form table given below
          // "image": 'https://angular.io/assets/images/logos/angular/angular.png',
          image: LOGO,
          send_sms_hash: true,
          // "redirect":true,
          // "callback_url":this.ngZone.run(() => this.router.navigateByUrl("parent/failure-payment")),
          handler: function (response) {
            handlePayment(response, plan, "Successfull");
          },
          retry: {
            enabled: false,
          },
          modal: {
            escape: false,
            ondismiss: async function () {
              dispatch(toggleMainLoader(false));
              //   ref.processingPayment = false;
              // await ref.sendFailurePaymentNotification();
              // ref.ngZone.run(() => ref.router.navigateByUrl("parent/failure-payment"));
            },
          },
          notes: {
            type: "sessionPayments",
          },
          notify: {
            sms: true,
            email: true,
          },
          prefill: {
            name: user.fullName,
            email: user.email,
            contact: user.phoneNumber,
          },
          theme: {
            color: "#2874f0",
          },
          // method: {
          //   netbanking: true,
          //   card: true,
          //   upi: true ,
          //   wallet:true,
          //   paylater:false,
          //   emi:false
          // }
        };

        const rzp1 = new window.Razorpay(options);
        rzp1.open();
        rzp1.on("payment.failed", (response) => {
          handlePayment(response, plan, "failure");
        });
        // dispatch(toggleMainLoader(false));
      })
      .catch((error) => {
        console.log(error);
        dispatch(toggleMainLoader(false));
        Toaster(error.response.data.message, "error");
      });
  };
  const handlePayment = (response, plan, status) => {
    const reqData = {
      paymentId:
        status === "failure"
          ? response?.error?.metadata.hasOwnProperty("payment_id")
            ? response?.error?.metadata?.payment_id
            : "NA"
          : response.razorpay_payment_id,
      orderId:
        status === "failure"
          ? response?.error?.metadata.hasOwnProperty("order_id")
            ? response?.error?.metadata?.order_id
            : "NA"
          : response.razorpay_order_id,
      razorpay_signature:
        status === "failure" ? "" : response.razorpay_signature,
      sessionCount: plan.sessionCount,
      discountedPrice: plan.discountedPrice,
      validity: plan.validity,
      pricePerSession: plan.pricePerSession,
      status: status,
      reason: status === "failure" ? response?.error?.description : "",
    };
    dispatch(toggleMainLoader(true));
    handlePaymentTransactionsService(reqData)
      .then((res) => {
        setPaymentDetails(res.data.data);
        dispatch(toggleMainLoader(false));
        onPaymentDetailsOpen();
        // navigate(ROUTES_PATH.PARENT_HOME);
      })
      .catch((error) => {
        console.log(error);
        dispatch(toggleMainLoader(false));
        Toaster(error.response.data.message, "error");
      });
  };

  return (
    <AuthenticatedContainer>
      <PaymentDetailsPopup
        isOpen={isPaymentDetailsOpen}
        onClose={onPaymentDetailsClose}
        parent={user}
        paymentDetails={
          Object.keys(paymentDetails).length > 0 && paymentDetails
        }
      />
      {mainLoader ? (
        <Loader text="Loading, Please Wait..." />
      ) : (
        <Box className="exerciseMainDiv">
          <Box className="exerciseBackText">
            <Image
              src={BACK_BTN}
              mr="8px"
              onClick={() => navigate(ROUTES_PATH.PARENT_HOME)}
              cursor="pointer"
            />
            <Text
              onClick={() => navigate(ROUTES_PATH.PARENT_HOME)}
              cursor="pointer"
            >
              Back to level
            </Text>
          </Box>
          <Box className="exerciseTitle">
            Choose a Plan that is best for your Child{" "}
          </Box>
          <Grid
            templateColumns={{ md: "repeat(12, 1fr)", sm: "" }}
            mt="24px"
            mb={{ base: "100px", md: "0px" }}
            gap={8}
          >
            {plans?.map((plan, index) => (
              <GridItem colSpan={{ lg: 4, md: 6, sm: 12 }} key={index}>
                <div className="pricingPlanCard">
                  <div className="popularDiv">
                    <div className="discountDiv">
                      Save {plan.discountPercentage}
                    </div>
                    {plan.isPopular && (
                      <Image src={POPULAR_TAG} marginRight="-25px" />
                    )}
                  </div>

                  <div className="priceText">
                    {"₹ " + plan.discountedPrice}
                    <span className="pricingDiscountText">
                      {"₹ " + plan.price}
                    </span>
                  </div>
                  <div className="pricingValidityDiv">
                    Validity:{" "}
                    <span style={{ color: "#141719", fontWeight: "600" }}>
                      {plan.validity + " Days"}
                    </span>
                  </div>
                  <div className="pricingCols mb16">
                    <div>
                      <Image src={ICON_GREEN} height="24px" width="24px" />
                    </div>
                    <div className="pricingDetailsText">
                      {plan.sessionCount + " Sessions"}
                    </div>
                  </div>
                  <div className="pricingCols mb16">
                    <div>
                      <Image src={ICON_GREEN} height="24px" width="24px" />
                    </div>
                    <div className="pricingDetailsText">
                      {"₹ " + plan.pricePerSession + " Per session"}
                    </div>
                  </div>
                  <div className="pricingCols mb16">
                    <div>
                      <Image src={ICON_GREEN} height="24px" width="24px" />
                    </div>
                    <div className="pricingDetailsText">
                      You save {"₹ " + plan.amountSaved}
                    </div>
                  </div>
                  <Button
                    className={
                      plan.isPopular ? "activeLearnMoreBtn" : "LearnMoreBtn"
                    }
                    onClick={() => {
                      handlePayNow(plan);
                    }}
                  >
                    {"Pay ₹" + plan.discountedPrice}
                  </Button>
                </div>
              </GridItem>
            ))}
          </Grid>
        </Box>
      )}
    </AuthenticatedContainer>
  );
};
export default ChoosePlan;

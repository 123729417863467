import {
  Box,
  Checkbox,
  Flex,
  Image,
  Text,
  Tag,
  Avatar,
  TagLabel,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  CHECKED,
  CLOSE_PATHWAY,
  OPEN_PATHWAY,
  ONGOING,
  ACCOMPLISHED,
} from "../../../../constant";

const SingleOutcomesTemplate = ({
  subgoals,
  handleSelectedOutcomeCounter,
  checkpointOutcomes,
  setCheckpointOutcomes,
}) => {
  const [showCheckpoints, setShowCheckpoints] = useState(false);
  const [checkedCounter, setCheckedCounter] = useState(0);
  const [isCheckedData, setIsCheckedData] = useState(null);

  // to handle selection of an outcome
  const handleCheck = (outcome, index) => {
    let newCheckedData = isCheckedData;
    let newCheckpointOutcomes = checkpointOutcomes;
    handleSelectedOutcomeCounter(isCheckedData[index].checked);
    newCheckedData[index].checked = !isCheckedData[index].checked;
    newCheckedData[index].status = "Locked";
    if (!isCheckedData[index].checked) {
      const objectIndex = newCheckpointOutcomes.findIndex(
        (obj) => obj.id === outcome.id
      );
      if (objectIndex !== -1) {
        newCheckpointOutcomes.splice(objectIndex, 1);
      }
      setCheckedCounter(checkedCounter - 1);
    } else {
      newCheckpointOutcomes.push(outcome);
      setCheckedCounter(checkedCounter + 1);
    }

    setIsCheckedData(newCheckedData);
    setCheckpointOutcomes(newCheckpointOutcomes);
  };

  // to count the total outcomes selected in a single outcome box
  useEffect(() => {
    let mappedCheck;
    if (checkpointOutcomes) {
      let goalSelectedcnt = 0;
      checkpointOutcomes.map((outcome) => {
        if (outcome.subGoalId === subgoals?.id) {
          goalSelectedcnt++;
        }
      });
      const totalOutcomes = subgoals?.outcomes;
      mappedCheck = totalOutcomes?.map((to) => {
        let status;
        const checked = checkpointOutcomes.some((so) => {
          status = so?.id === to?.id ? so.checkpointStatus : "Locked";
          return so?.id === to?.id;
        });
        return { checked, status };
      });
      setIsCheckedData(mappedCheck);
      setCheckedCounter(goalSelectedcnt);
    } else {
      const totalOutcomes = subgoals?.outcomes;
      mappedCheck = totalOutcomes?.map((to) => {
        return { checked: false, status: "Locked" };
      });

      setIsCheckedData(mappedCheck);
    }
  }, [subgoals, checkpointOutcomes]);

  return (
    <Box className="pathwayCreationMainBodyContainer">
      <Flex align="center" justify="space-between">
        <Flex gap="8px" align="center">
          <Image
            // style={{ transform: "translateY(-2px)" }}
            src={showCheckpoints ? CLOSE_PATHWAY : OPEN_PATHWAY}
            cursor="pointer"
            onClick={() => setShowCheckpoints(!showCheckpoints)}
          />
          <Text
            fontWeight="600"
            fontSize="16px"
            lineHeight="24px"
            color="#141719"
          >
            {subgoals?.name}
          </Text>
        </Flex>
        {checkedCounter > 0 && (
          <Flex
            p="2px 12px 2px 6px"
            bg="#f3ebff"
            borderRadius="24px"
            align="center"
            gap="4px"
          >
            <Image h="16px" w="16px" src={CHECKED} />
            <Text
              fontWeight="400"
              fontSize="14px"
              lineHeight="20px"
              color="#2f0d63"
            >
              {checkedCounter}
            </Text>
          </Flex>
        )}
      </Flex>
      {showCheckpoints && (
        <Box mt="8px">
          <Text
            mx="32px"
            fontWeight="400"
            fontSize="14px"
            lineHeight="20px"
            color="#5a646b"
          >
            {subgoals?.introduction}
          </Text>
          {subgoals?.outcomes?.length > 0 ? (
            <Flex mt="16px" flexDir="column" gap="16px">
              {subgoals?.outcomes.map((outcome, index) => (
                <Flex
                  key={outcome?.id}
                  p="16px"
                  justify="space-between"
                  className="pathwayCreationOutcomeMainBodyContainer"
                >
                  <Text
                    fontWeight="600"
                    fontSize="14px"
                    lineHeight="20px"
                    color="#141719"
                  >
                    {outcome?.name}
                  </Text>

                  {isCheckedData[index].checked &&
                  isCheckedData[index].status !== "Locked" ? (
                    <Tag
                      className="txnsSecondMapFlexBoxTag"
                      style={{
                        backgroundColor: "#ffffff",
                      }}
                    >
                      <Avatar
                        className="txnsSecondMapFlexBoxTagAvatar"
                        src={
                          (isCheckedData[index]?.status === "Ongoing" &&
                            ONGOING) ||
                          (isCheckedData[index]?.status === "Accomplished" &&
                            ACCOMPLISHED) ||
                          (isCheckedData[index]?.status === "Accomplished" &&
                            ACCOMPLISHED)
                        }
                      />
                      <TagLabel
                        style={{
                          color:
                            (isCheckedData[index]?.status === "Ongoing" &&
                              "#8751d9") ||
                            (isCheckedData[index]?.status === "Accomplished" &&
                              "#3eb274") ||
                            (isCheckedData[index]?.status === "Accomplished" &&
                              "#3eb274"),
                        }}
                      >
                        {isCheckedData[index]?.status}
                      </TagLabel>
                    </Tag>
                  ) : (
                    <Checkbox
                      isChecked={isCheckedData[index].checked}
                      onChange={() => handleCheck(outcome, index)}
                      colorScheme="purple"
                      size="lg"
                    ></Checkbox>
                  )}
                </Flex>
              ))}
            </Flex>
          ) : (
            <Text color="#141719" mt={10} fontSize="20px">
              You don't have any outcomes of the selected subgoal.
            </Text>
          )}
        </Box>
      )}
    </Box>
  );
};

export default SingleOutcomesTemplate;

import {
  Avatar,
  Box,
  Button,
  Flex,
  Image,
  Tag,
  TagLabel,
  Text,
} from "@chakra-ui/react";
import React from "react";
import {
  BACK_BTN,
  ONGOING,
  ACCOMPLISHED,
  LOCKED_PATHWAY,
} from "../../../../constant/images";

const SingleDomainPage = ({ data, setIndividualContent, title, desc }) => {
  return (
    <Box id="mainWrapper" px="16px" py="24px">
      <Button
        bg="white"
        _hover={{ bg: "white" }}
        onClick={() => setIndividualContent(false)}
      >
        <Flex gap="8px">
          <Image src={BACK_BTN} />{" "}
          <Text className="singleDomainPageBackBtn">Back</Text>
        </Flex>
      </Button>
      <Text mt="16px" className="txnsTabText1">
        {title}
      </Text>
      <Text className="txnsTabText2">{desc}</Text>
      {data?.length > 0 ? (
        <Box mt="24px">
          {data?.map((ci) => (
            <Box key={ci.id}>
              <Flex className="txnsSecondMapFlex">
                <Flex gap="8px" flexDir="column">
                  <Text className="singleDomainPageMapTaskName">
                    {ci.taskName}
                  </Text>
                  <Text className="singleDomainPageMapSubGoal">
                    {ci.subgoalOf}
                  </Text>
                </Flex>
                <Tag
                  className="txnsSecondMapFlexBoxTag"
                  style={{
                    backgroundColor: "#ffffff",
                  }}
                  minW="max-content"
                >
                  <Avatar
                    className="txnsSecondMapFlexBoxTagAvatar"
                    src={
                      (ci?.status === "Ongoing" && ONGOING) ||
                      (ci?.status === "Accomplished" && ACCOMPLISHED) ||
                      (ci?.status === "Locked" && LOCKED_PATHWAY)
                    }
                  />
                  <TagLabel
                    style={{
                      color:
                        (ci?.status === "Ongoing" && "#8751d9") ||
                        (ci?.status === "Accomplished" && "#3eb274") ||
                        (ci?.status === "Accomplished" && "#3eb274"),
                    }}
                  >
                    {ci?.status}
                  </TagLabel>
                </Tag>
              </Flex>
            </Box>
          ))}
        </Box>
      ) : (
        <Text className="txnsTabText2">
          You don't have any active checkpoints for this domain.
        </Text>
      )}
    </Box>
  );
};

export default SingleDomainPage;

import * as actionTypes from "../actions/actionTypes";

export const activityListing = (state = [], { type, data }) => {
  switch (type) {
    case actionTypes.SAVE_ACTIVITY_LISTING:
      return data;
    default:
      return state;
  }
};

export const videosListing = (state = [], { type, data }) => {
  switch (type) {
    case actionTypes.SAVE_VIDEO_LISTING:
      return data;
    default:
      return state;
  }
};

export const parentingTips = (state = [], { type, data }) => {
  switch (type) {
    case actionTypes.SAVE_PARENTING_TIPS:
      return data;
    default:
      return state;
  }
};

export const liveBooking = (state = [], { type, data }) => {
  switch (type) {
    case actionTypes.SAVE_LIVE_BOOKING:
      return data;
    default:
      return state;
  }
};

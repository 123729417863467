import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../utils/updateObjects";

const initialState = {
  mainLoader: false,
  apiLoader: false,
  sessionLoader: false,
  feedbackLoader: false,
};

const loader = (state = initialState, { type, data }) => {
  switch (type) {
    case actionTypes.SHOW_OR_HIDE_MAIN_LOADER:
      return updateObject(state, {
        mainLoader: data,
      });
    case actionTypes.SHOW_OR_HIDE_API_LOADER:
      return updateObject(state, {
        apiLoader: data,
      });
    case actionTypes.SHOW_OR_HIDE_SESSION_LOADER:
      return updateObject(state, {
        sessionLoader: data,
      });
    case actionTypes.SHOW_OR_HIDE_FEEDBACK_LOADER:
      return updateObject(state, {
        feedbackLoader: data,
      });
    default:
      return state;
  }
};
export default loader;

export const modalOpen = (state = false, { type, data }) => {
  switch (type) {
    case actionTypes.CHECK_MODAL_OPEN:
      return data;
    default:
      return state;
  }
};

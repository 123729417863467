import { useEffect } from "react";
import { Box, GridItem, Grid, Image, Show } from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import {
  COMPLETE_CHECKPOINT,
  COMPLETE_CHECKPOINT_DASH,
  HORIZONTAL_CHECKPOINT_DASH,
  LOCK_CHECKPOINT,
  ONGOING_CHECKPOINT,
  VERTICLE_CHECKPOINT_DASH,
  VERTICLE_COMPLETE_CHECKPOINT_DASH,
} from "../../../constant";
import { getChildPathway, toggleMainLoader } from "../../../modules/actions";

// const checkpoints=[{status:"Accomplished"},{status:"Accomplished"},{status:"Accomplished"},{status:"Ongoing"},{status:"Locked"},{status:"Locked"}]

const ParentPathway = () => {
  const user = useSelector((state) => state.user?.profile);
  const checkpoints = useSelector((state) => state.pathway[0]?.checkpoints);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(toggleMainLoader(true));
    dispatch(getChildPathway(user?.id));
  }, []);

  const chunks = checkpoints?.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / 5);
    item["id"] = index + 1;
    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [];
    }

    resultArray[chunkIndex].push(item);
    return resultArray;
  }, []);
  const chunksMob = checkpoints?.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / 2);
    item["id"] = index + 1;
    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [];
    }

    resultArray[chunkIndex].push(item);
    return resultArray;
  }, []);

  return (
    <Box p="24px 16px">
      <Box className="pathwayHeading">Pathway</Box>
      <Box
        className="pathwaySubHeading"
        w={{ md: "640px", sm: "100%" }}
        pb="48px"
      >
        Provides an overview of the child’s progress across the 8 domains.
        Prioritise domains which have less progress as areas that need
        improvement.
      </Box>
      {/* <Grid
        pt="48px"
        templateColumns={{ md: "repeat(5, 1fr)", sm: "repeat(2, 1fr)" }}
      >
        {checkpoints?.map((checkpoint, indx) => {
          return (
            <GridItem colSpan={1} key={indx} maxWidth="max-content">
              <Box display="flex">
                <Image
                  w="64px"
                  h="64px"
                  mx="12px"
                  src={
                    checkpoint.status === "Accomplished"
                      ? COMPLETE_CHECKPOINT
                      : checkpoint.status === "Ongoing"
                      ? ONGOING_CHECKPOINT
                      : LOCK_CHECKPOINT
                  }
                />
                {indx + 1 !== checkpoints.length && (indx + 1) % 5 !== 0 && (
                  <Box display="flex">
                    <Image
                      w="105px"
                      src={
                        checkpoint.status === "Accomplished"
                          ? COMPLETE_CHECKPOINT_DASH
                          : HORIZONTAL_CHECKPOINT_DASH
                      }
                    />
                  </Box>
                )}
              </Box>
              <Box className="checkpointText">Checkpoint{indx + 1}</Box>
              {(indx + 1) % 5 === 0 && (
                <Box display="flex" justifyContent="center">
                  <Image
                    h="40px"
                    src={
                      checkpoint.status === "Accomplished"
                        ? VERTICLE_COMPLETE_CHECKPOINT_DASH
                        : VERTICLE_CHECKPOINT_DASH
                    }
                  />
                </Box>
              )}
            </GridItem>
          );
        })}
      </Grid> */}
      <Show above="md">
        {chunks?.map((row, rowIndex) => (
          <Grid
            key={rowIndex}
            templateColumns={{ md: "repeat(5, 1fr)", sm: "repeat(2, 1fr)" }}
            className="gridRow"
          >
            {row?.map((checkpoint, indx) => {
              return (
                <GridItem
                  colSpan={1}
                  key={indx}
                  maxWidth="max-content"
                  direction="ltr"
                >
                  <Box display="flex">
                    <Image
                      w="64px"
                      h="64px"
                      mx="12px"
                      src={
                        checkpoint.status === "Accomplished"
                          ? COMPLETE_CHECKPOINT
                          : checkpoint.status === "Ongoing"
                          ? ONGOING_CHECKPOINT
                          : LOCK_CHECKPOINT
                      }
                    />
                    {checkpoint.id !== checkpoints.length &&
                      (indx + 1) % 5 !== 0 && (
                        <Box display="flex">
                          <Image
                            w="105px"
                            src={
                              checkpoint.status === "Accomplished"
                                ? COMPLETE_CHECKPOINT_DASH
                                : HORIZONTAL_CHECKPOINT_DASH
                            }
                          />
                        </Box>
                      )}
                  </Box>
                  <Box
                    className="checkpointText"
                    textAlign={rowIndex % 2 ? "right" : "left"}
                  >
                    Checkpoint {checkpoint.id}
                  </Box>
                  {(indx + 1) % 5 === 0 &&
                    checkpoint.id !== checkpoints.length && (
                      <Box display="flex" justifyContent="center" my="16px">
                        <Image
                          h="40px"
                          src={
                            checkpoint.status === "Accomplished"
                              ? VERTICLE_COMPLETE_CHECKPOINT_DASH
                              : VERTICLE_CHECKPOINT_DASH
                          }
                        />
                      </Box>
                    )}
                </GridItem>
              );
            })}
          </Grid>
        ))}
      </Show>
      <Show breakpoint="(max-width:767px)">
        {chunksMob?.map((row, rowIndex) => (
          <Grid
            key={rowIndex}
            templateColumns="repeat(2, 1fr)"
            className="gridRow"
          >
            {row?.map((checkpoint, indx) => {
              return (
                <GridItem
                  colSpan={1}
                  key={indx}
                  maxWidth="max-content"
                  direction="ltr"
                >
                  <Box display="flex">
                    <Image
                      w="64px"
                      h="64px"
                      mx="12px"
                      src={
                        checkpoint.status === "Accomplished"
                          ? COMPLETE_CHECKPOINT
                          : checkpoint.status === "Ongoing"
                          ? ONGOING_CHECKPOINT
                          : LOCK_CHECKPOINT
                      }
                    />
                    {checkpoint.id !== checkpoints.length &&
                      (indx + 1) % 2 !== 0 && (
                        <Box display="flex">
                          <Image
                            w="105px"
                            src={
                              checkpoint.status === "Accomplished"
                                ? COMPLETE_CHECKPOINT_DASH
                                : HORIZONTAL_CHECKPOINT_DASH
                            }
                          />
                        </Box>
                      )}
                  </Box>
                  <Box
                    className="checkpointText"
                    textAlign={rowIndex % 2 ? "right" : "left"}
                  >
                    Checkpoint {checkpoint.id}
                  </Box>
                  {(indx + 1) % 2 === 0 &&
                    checkpoint.id !== checkpoints.length && (
                      <Box display="flex" justifyContent="center" my="16px">
                        <Image
                          h="40px"
                          src={
                            checkpoint.status === "Accomplished"
                              ? VERTICLE_COMPLETE_CHECKPOINT_DASH
                              : VERTICLE_CHECKPOINT_DASH
                          }
                        />
                      </Box>
                    )}
                </GridItem>
              );
            })}
          </Grid>
        ))}
      </Show>
    </Box>
  );
};
export default ParentPathway;

import moment from "moment";
import {
  Box,
  Text,
  Image,
  Flex,
  Grid,
  GridItem,
  InputGroup,
  Input,
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { BOY, CHILD_INFO, GIRL, primaryConcerns } from "../../../../constant";

const childPresentOptions = ["Yes", "Yes, but wasn't able to interact", "No"];
const childGenderOptions = [
  { value: "Boy", icon: BOY },
  { value: "Girl", icon: GIRL },
];
const YesNoOptions = ["Yes", "No"];

const ChildInformation = ({
  childInfo,
  handleFormDataChange,
  defaultPrimaryConcerns,
}) => {
  return (
    <Box className="specialistDiv">
      <Flex mb="24px">
        <Image src={CHILD_INFO} alt="Child Information" mr="8px" />
        <Text className="tabHeading">Child Information</Text>
      </Flex>
      <Grid templateColumns="repeat(5, 1fr)" gap="16px" alignItems="center">
        <GridItem colSpan={1}>
          <Text className="acrFormLabel"> Child Present?</Text>
        </GridItem>
        <GridItem colSpan={4}>
          <Flex
            className="childPresentTab"
            style={{
              borderColor: childInfo?.childPresentError ? "#ba1a1a" : "#D8E5EE",
            }}
          >
            {childPresentOptions?.map((value, index) => (
              <Text
                key={index}
                className={
                  childInfo.childPresent === value
                    ? "childPresentTabActive"
                    : "childPresentTabInActive"
                }
                cursor="pointer"
                onClick={() =>
                  handleFormDataChange(
                    "childInfo",
                    "childPresent",
                    value,
                    "childPresentError"
                  )
                }
              >
                {value}
              </Text>
            ))}
          </Flex>
          {childInfo?.childPresentError && (
            <Text className="personalErrorMessage" mt="5px" ml="15px">
              {childInfo?.childPresentError}
            </Text>
          )}
        </GridItem>
      </Grid>
      <Grid
        templateColumns="repeat(5, 1fr)"
        gap="16px"
        alignItems="center"
        mt="16px"
      >
        <GridItem colSpan={1}>
          <Text className="acrFormLabel">Child Name</Text>
        </GridItem>
        <GridItem colSpan={3}>
          <InputGroup>
            <Input
              onChange={(e) =>
                handleFormDataChange(
                  "childInfo",
                  e.target.name,
                  e.target.value,
                  "childNameError"
                )
              }
              name="childName"
              value={childInfo?.childName}
              placeholder="Enter Child Name"
              maxLength={50}
              className="acrFormInput"
              style={{
                borderColor: childInfo?.childNameError ? "#ba1a1a" : "#D8E5EE",
              }}
            />
          </InputGroup>
          {childInfo?.childNameError && (
            <Text className="personalErrorMessage" mt="5px" ml="15px">
              {childInfo?.childNameError}
            </Text>
          )}
        </GridItem>
      </Grid>
      <Grid
        templateColumns="repeat(5, 1fr)"
        gap="16px"
        alignItems="center"
        mt="16px"
      >
        <GridItem colSpan={1}>
          <Text className="acrFormLabel">Child DOB</Text>
        </GridItem>
        <GridItem colSpan={3}>
          <InputGroup>
            <Input
              onChange={(e) =>
                handleFormDataChange(
                  "childInfo",
                  e.target.name,
                  e.target.value,
                  "childDobError"
                )
              }
              type="date"
              name="childDob"
              value={childInfo?.childDob}
              placeholder="DD/MM/YYYY"
              className="acrFormInput"
              max={moment().format("YYYY-MM-DD")}
              style={{
                borderColor: childInfo?.childDobError ? "#ba1a1a" : "#D8E5EE",
              }}
            />
          </InputGroup>
          {childInfo?.childDobError && (
            <Text className="personalErrorMessage" mt="5px" ml="15px">
              {childInfo?.childDobError}
            </Text>
          )}
        </GridItem>
      </Grid>
      <Grid
        templateColumns="repeat(5, 1fr)"
        gap="16px"
        alignItems="center"
        mt="16px"
      >
        <GridItem colSpan={1}>
          <Text className="acrFormLabel"> Child Gender</Text>
        </GridItem>
        <GridItem colSpan={3}>
          <Flex
            className="childPresentTab"
            style={{
              borderColor: childInfo?.childGenderError ? "#ba1a1a" : "#D8E5EE",
            }}
          >
            {childGenderOptions?.map((value, index) => (
              <Flex
                key={index}
                className={
                  childInfo.childGender === value.value
                    ? "childPresentTabActive genderTab"
                    : "childPresentTabInActive genderTab"
                }
                cursor="pointer"
                onClick={() =>
                  handleFormDataChange(
                    "childInfo",
                    "childGender",
                    value.value,
                    "childGenderError"
                  )
                }
              >
                <Image src={value.icon} alt={value.value} mr="8px" />
                <Text>{value.value}</Text>
              </Flex>
            ))}
          </Flex>
          {childInfo?.childGenderError && (
            <Text className="personalErrorMessage" mt="5px" ml="15px">
              {childInfo?.childGenderError}
            </Text>
          )}
        </GridItem>
      </Grid>
      <Grid
        templateColumns="repeat(5, 1fr)"
        gap="16px"
        alignItems="center"
        mt="16px"
      >
        <GridItem colSpan={1}>
          <Text className="acrFormLabel">Guardian Present?</Text>
        </GridItem>
        <GridItem colSpan={3}>
          <Flex
            className="childPresentTab"
            style={{
              borderColor: childInfo?.guardianPresentError
                ? "#ba1a1a"
                : "#D8E5EE",
            }}
          >
            {YesNoOptions?.map((value, index) => (
              <Text
                key={index}
                className={
                  childInfo.guardianPresent === value
                    ? "childPresentTabActive"
                    : "childPresentTabInActive"
                }
                cursor="pointer"
                onClick={() =>
                  handleFormDataChange(
                    "childInfo",
                    "guardianPresent",
                    value,
                    "guardianPresentError"
                  )
                }
              >
                {value}
              </Text>
            ))}
          </Flex>
          {childInfo?.guardianPresentError && (
            <Text className="personalErrorMessage" mt="5px" ml="15px">
              {childInfo?.guardianPresentError}
            </Text>
          )}
        </GridItem>
      </Grid>
      <Text className="subHeading2" mt="24px">
        Stated Requirement
      </Text>
      <Grid
        templateColumns="repeat(5, 1fr)"
        gap="16px"
        alignItems="flex-start"
        mt="16px"
      >
        <GridItem colSpan={1}>
          <Text className="acrFormLabel">Primary Concerns</Text>
        </GridItem>
        <GridItem colSpan={3}>
          <Box
            border="1px solid #D8E5EE"
            fontWeight="medium"
            borderRadius={25}
            backgroundColor="white"
            sx={{
              "& .css-wtf6ne": {
                bg: "white",
                border: "none",
              },
              "& .chakra-divider": {
                opacity: 0,
              },
            }}
          >
            <Select
              isMulti
              onChange={(e) =>
                handleFormDataChange(
                  "childInfo",
                  "primaryConcerns",
                  e.map((l) => l.value, "primaryConcernsError")
                )
              }
              name="primaryConcerns"
              closeMenuOnSelect={false}
              value={defaultPrimaryConcerns}
              selectedOptionStyle="check"
              placeholder="Add Primary Concerns"
              className="acrFormSelect"
              hideSelectedOptions={false}
              selectedOptionColorScheme="green"
              options={primaryConcerns}
              style={{
                borderColor: childInfo?.primaryConcernsError
                  ? "#ba1a1a"
                  : "#D8E5EE",
              }}
            />
          </Box>
          {childInfo.primaryConcernsError && (
            <Text className="personalErrorMessage" mt="5px" ml="15px">
              {childInfo.primaryConcernsError}
            </Text>
          )}
        </GridItem>
      </Grid>
    </Box>
  );
};
export default ChildInformation;

import {
  Modal,
  ModalOverlay,
  ModalContent,
  Box,
  Button,
} from "@chakra-ui/react";
import "./outcomeModal.css";

const OutcomeModal = ({ isOpen, onClose, user, outcome, OnModalClose }) => {
  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={OnModalClose}
        isCentered
        borderRadius="16px"
        // closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent p="24px">
          <Box className="outcomeModalHeader">
            Is {user.childName} able to achieve the below outcome?
          </Box>
          {/* <ModalCloseButton /> */}
          <Box className="outcomeModalSubHeading">{outcome.name}</Box>

          <Box display="flex" justifyContent="center">
            <Button
              onClick={() => onClose("No")}
              className="outcomeModalButton"
              mr="16px"
            >
              No
            </Button>
            <Button
              className="outcomeModalButton"
              mr="16px"
              onClick={() => onClose("Almost")}
            >
              Almost
            </Button>
            <Button
              className="outcomeModalButton"
              onClick={() => onClose("Yes")}
            >
              Yes
            </Button>
          </Box>
        </ModalContent>
      </Modal>
    </>
  );
};
export default OutcomeModal;

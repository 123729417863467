import { useState } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Grid,
  Flex,
  Avatar,
  Text,
  Button,
  Image,
  useDisclosure,
} from "@chakra-ui/react";
import ValidityExpiredPopup from "../../../components/ValidityExpiredPopup";
import AuthenticatedContainer from "../../../container/Authenticated";
import Domain from "../../Expert/MyStudents/Tabs/domain";
import ParentPathway from "../../Parent/Pathway";
import ScheduleSession from "../ScheduleSession";
import { CAKE_ICON, USER_ICON } from "../../../constant";
import { calculateAge } from "../../../utils/customHooks";
import { isBeforeTodaysDate } from "../../../utils/customMoment";
import "./progress.css";

const ParentProgress = () => {
  const user = useSelector((state) => state.user?.profile);
  const [overlayOpen, setOverlayOpen] = useState(false);
  const [closer, setCloser] = useState(false);
  const [activeStep, setActiveStep] = useState("Domains");

  const {
    isOpen: isValidityOpen,
    onOpen: onValidityOpen,
    onClose: onValidityClose,
  } = useDisclosure();

  const tabs = [
    {
      id: "Domains",
      value: <Domain selectedChild={user?.id} type="parentProgress" />,
    },
    { id: "Pathway", value: <ParentPathway /> },
  ];
  const handleSceduleSession = () => {
    (user?.validTill && isBeforeTodaysDate(user?.validTill)) ||
    user?.currentBalance < 1
      ? onValidityOpen()
      : setOverlayOpen(true);
  };
  return (
    <Box>
      <AuthenticatedContainer>
        <ValidityExpiredPopup
          isOpen={isValidityOpen}
          onClose={onValidityClose}
          parent={user}
        />
        {overlayOpen && (
          <ScheduleSession setOverlayOpen={setOverlayOpen} closer={closer} />
        )}
        <Grid
          maxW="100vw"
          overflowX="clip"
          position="sticky"
          zIndex="10"
          top="0"
          row
          className="layoutBanner"
        ></Grid>
        <Box px={{ md: "72px", sm: "16px" }} py="16px" mb="100px">
          <Box
            bg="white"
            zIndex="5"
            h="140px"
            w="full"
            pos="fixed"
            top="98px"
            className="desktopDisplay"
          ></Box>
          <Box
            bg="white"
            zIndex="1000"
            position="sticky"
            top="144px"
            id="firstBox-NameAndProfileEdit"
            className="desktopDisplay"
          >
            <Flex
              flexDir={{ base: "column", lg: "row" }}
              justify="space-between"
              align={{ base: "start", lg: "center" }}
            >
              <Flex gap="16px">
                <Avatar
                  className="myProfileIndexAvatar"
                  name={user?.childName}
                  src={user?.image}
                />
                <Box>
                  <Text className="myProfileIndexText">{user?.childName}</Text>
                  <Box display="flex" mt="4px">
                    <Box display="flex" marginRight="20px">
                      <Image src={CAKE_ICON} marginRight="4px" />
                      {calculateAge(user?.childDob)}
                    </Box>
                    <Box display="flex">
                      <Image src={USER_ICON} marginRight="4px" />
                      {user?.fullName}
                    </Box>
                  </Box>
                </Box>
              </Flex>
              <Flex
                ml={{ base: "110px", lg: "0" }}
                mb={{ base: "20px", lg: "0" }}
                mt={{ lg: "-35px" }}
                className="myProfileIndexFirstBoxButtonFlex"
              >
                <Button
                  className="myProfileIndexFirstBoxButtonFlexBtns"
                  _hover={{ bg: "white" }}
                  onClick={() => {
                    handleSceduleSession();
                  }}
                >
                  Schedule session
                </Button>
                {/* <Button
                  onClick={() => {
                    window.open("https://www.google.com");
                  }}
                  className="myProfileIndexFirstBoxButtonFlexBtns"
                  _hover={{ bg: "white" }}
                >
                  Help
                </Button> */}
              </Flex>
            </Flex>
          </Box>
          <Box
            w="100vw"
            pos="sticky"
            top={{ base: "300px", lg: "240px" }}
            className="profileBoxShadow"
            bg="white"
            zIndex={1000}
          ></Box>
          <Flex
            id="secondBox-DynamicSteps"
            flexDir={{ base: "column", lg: "row" }}
          >
            <Flex
              className="myProfileIndexSecondBoxMainFlexContainer"
              minW={{ base: "full", lg: "288px" }}
              flexDir={{ base: "row", lg: "column" }}
              gap={{ base: "20px", lg: "16px" }}
              id="left-side"
              h="full"
              // zIndex={100}
              bg="white"
              position="sticky"
              top={{ base: "300px", xs: "0px", sm: "0px", lg: "240px" }}
            >
              {tabs.map((item) => (
                <Flex key={item.id}>
                  <Text
                    className={
                      item.id === activeStep
                        ? "myProfileIndexSecondBoxFirstMapText activeTab"
                        : "myProfileIndexSecondBoxFirstMapText inActiveTab"
                    }
                    w="full"
                    _hover={{ cursor: "pointer" }}
                    rounded={{ base: "none", md: "full" }}
                    // backgroundColor={{
                    //   md: item.id === activeStep ? "#E9F6FF" : "white",
                    // }}
                    // border={{
                    //   md:
                    //     item.id === activeStep
                    //       ? "#E9F6FF"
                    //       : "1px solid #A6B8C4",
                    // }}
                    // color={{
                    //   base: item.id === activeStep ? "#4299E1" : "#5a646b",
                    //   md: item.id === activeStep ? "#2b6cb0" : "#5a646b",
                    // }}
                    // borderBottom={{
                    //   base: item.id === activeStep ? "4px solid #4299E1" : "",
                    //   md: "",
                    // }}
                    onClick={() => {
                      setActiveStep(item.id);
                      sessionStorage.setItem(
                        "activeParentProgressTab",
                        JSON.stringify(activeStep)
                      );
                    }}
                  >
                    {item.id}
                  </Text>
                </Flex>
              ))}
            </Flex>
            <Box
              sx={{
                "& .chakra-form__required-indicator": {
                  color: "black",
                },
              }}
              // zIndex={-10}
              w="full"
              id="right-side"
              mb={{ base: "100px", md: "0px" }}
            >
              {tabs.map((item) => (
                <Box key={item.id}>{activeStep === item.id && item.value}</Box>
              ))}
            </Box>
          </Flex>
        </Box>
      </AuthenticatedContainer>
    </Box>
  );
};

export default ParentProgress;

import { getMomentDate1 } from "./customMoment";

export const calculateAge = (dateOfBirth) => {
  if (dateOfBirth) {
    const today = new Date();
    const birthDate = new Date(getMomentDate1(dateOfBirth));

    let todayYear = Number.parseFloat(today.getFullYear());
    let todayMonth = Number.parseFloat(today.getMonth() + 1);
    const todayDate = Number.parseFloat(today.getDate());

    const bdayYear = Number.parseFloat(birthDate.getFullYear());
    const bdayMonth = Number.parseFloat(birthDate.getMonth() + 1);
    const bdayDate = Number.parseFloat(birthDate.getDate());

    let day = 0;
    let year = 0;
    let month = 0;
    let age = "";

    if (todayDate < bdayDate) {
      day = todayDate - bdayDate + 30 + "days";
      todayMonth = todayMonth - 1;
    } else {
      day = todayDate - bdayDate + "days";
    }

    if (todayMonth < bdayMonth) {
      month = todayMonth - bdayMonth + 12;
      todayYear = todayYear - 1;
    } else {
      month = todayMonth - bdayMonth;
    }
    month = month + (month === 1 ? " month" : " months");

    year = todayYear - bdayYear;
    year = year + (year === 1 ? " year" : " years");

    if (year === "0 years") {
      age = month;
    } else if (month === "0 months") {
      age = year;
    } else {
      age = year + " " + month;
    }
    return age;
  }
};

export const calculateAgeInMonths = (dateOfBirth) => {
  if (dateOfBirth) {
    const today = new Date();
    const birthDate = new Date(getMomentDate1(dateOfBirth));

    let todayYear = Number.parseFloat(today.getFullYear());
    let todayMonth = Number.parseFloat(today.getMonth() + 1);

    const bdayYear = Number.parseFloat(birthDate.getFullYear());
    const bdayMonth = Number.parseFloat(birthDate.getMonth() + 1);

    let year = 0;
    let month = 0;

    if (todayMonth < bdayMonth) {
      month = todayMonth - bdayMonth + 12;
      todayYear = todayYear - 1;
    } else {
      month = todayMonth - bdayMonth;
    }

    year = todayYear - bdayYear;

    const totalMonths = year * 12 + month;
    return totalMonths;
  }
};

export const handleStringManupulation = (array) => {
  if (array) {
    const length = array?.length;
    if (length === 0) return "-";
    if (length === 1) return array[0];
    let text = "";
    for (let i = 0; i < length - 2; i++) {
      text += `${array[i]}, `;
    }
    let finalText = text + array[length - 2] + " and " + array[length - 1];
    return finalText;
  }
};
// function to convert current time of any time zone into current time of indian time zone.
export const istTime = (date) => {
  let ISTOffset = 330; // IST offset UTC +5:30
  let currentDate = new Date(date);
  let currentOffset = currentDate.getTimezoneOffset();

  let exactSessionDate = new Date(
    currentDate.getTime() + (ISTOffset + currentOffset) * 60000
  );
  return exactSessionDate;
};
// function to get the exact session date aacording to IST while retrieving data from db.
export const istTime2 = (date) => {
  let currentDate = new Date(date);

  let localSessionDateString = currentDate.toLocaleString("en-US", {
    timeZone: "Asia/Kolkata",
  });

  const sessionDateAndTimePart = localSessionDateString.split(",");
  const sessionDateParts = sessionDateAndTimePart[0].split("/");

  // converting sessionDate according to IST
  let sessionyear = parseInt(sessionDateParts[2], 10);
  let sessionmonth = parseInt(sessionDateParts[0], 10);
  sessionmonth -= 1;
  let sessiondayCount = parseInt(sessionDateParts[1], 10);

  // This will be the exact session date time as IST.
  const localSessionDate = new Date(
    sessionyear,
    sessionmonth,
    sessiondayCount,
    0,
    0,
    0
  );

  return localSessionDate;
};

export const getPreviousYearsArray = () => {
  const currentYear = new Date().getFullYear();
  const startYear = 2020;
  const yearsArray = [];

  for (let year = currentYear; year >= startYear; year--) {
    yearsArray.push({ value: year, label: year });
  }

  return yearsArray;
};

import { useMemo } from "react";
import {
  Box,
  Text,
  Image,
  Flex,
  Textarea,
  Grid,
  GridItem,
  Input,
  InputGroup,
} from "@chakra-ui/react";
import {
  OBSERVATIONS,
  specialEducationRemediationEvalution,
  behavioralCounsellingEvalution,
  motorSkills,
  speechLanguageEvalution,
} from "../../../../constant";

const Obervations = ({ observations, handleFormDataChange, serviceType }) => {
  const domainSpecificObservations = useMemo(() => {
    let skillsData = observations?.domainSpecificSkills
      ? observations?.domainSpecificSkills
      : serviceType === "Parent Counselling" ||
        serviceType === "Family Training" ||
        serviceType === "Child Counselling" ||
        serviceType === "Public Speaking Class" ||
        serviceType === "Behavior Therapy"
      ? behavioralCounsellingEvalution
      : serviceType === "Remedial Intervention" ||
        serviceType === "Special Education - STEM"
      ? specialEducationRemediationEvalution
      : serviceType === "Speech Therapy"
      ? speechLanguageEvalution
      : motorSkills;
    return skillsData;
  }, [serviceType, observations?.domainSpecificSkills]);

  console.log(observations, "observations");
  return (
    <Box className="specialistDiv">
      <Flex mb="24px">
        <Image src={OBSERVATIONS} alt="Observations" mr="8px" />
        <Text className="tabHeading">Observations</Text>
      </Flex>
      <Text className="observationsHeading">Summary/Overall Evaluation</Text>
      <Text className="observationsDesc">
        Based on the information shared by the parents at the time of the
        consultation and observation, following are the reasons why the child
        displays the above-mentioned concerns-
      </Text>
      <Textarea
        onChange={(e) => {
          handleFormDataChange(
            "observations",
            e.target.name,
            e.target.value,
            "summaryError"
          );
          // handleNumOfWords(e);
        }}
        name="summary"
        value={observations.summary}
        // maxLength={350}
        border="1px solid #D8E5EE"
        placeholder="1. Explain in points"
        className="personalShortBio"
        // resize="none"
        // backgroundColor="white"
        style={{
          borderColor: observations?.summaryError ? "#ba1a1a" : "#D8E5EE",
        }}
      />
      {observations?.summaryError && (
        <Text className="personalErrorMessage" mt="5px">
          {observations?.summaryError}
        </Text>
      )}
      <Text className="observationsHeading" mt="24px">
        Domain-Specific Observations ({serviceType})
      </Text>
      {domainSpecificObservations?.map((skills, index) => {
        return (
          <Box key={index}>
            <Text className="subHeading2">{skills.mainSkill}</Text>
            {skills.data.map((subSkill, index) => (
              <Grid
                key={index}
                templateColumns="repeat(5, 1fr)"
                gap="16px"
                alignItems="flex-start"
                mt="24px"
              >
                <GridItem colSpan={1}>
                  <Text className="acrFormLabel">{subSkill.skillName}</Text>
                </GridItem>
                <GridItem colSpan={3}>
                  <Flex className="childPresentTab">
                    {[1, 2, 3, 4, 5]?.map((value, index) => (
                      <Text
                        key={index}
                        className={
                          subSkill?.rating === value
                            ? "childPresentTabActive"
                            : "childPresentTabInActive"
                        }
                        cursor="pointer"
                        onClick={() => {
                          subSkill.rating = value;
                          handleFormDataChange(
                            "observations",
                            "domainSpecificSkills",
                            domainSpecificObservations,
                            "domainSpecificSkillsError"
                          );
                        }}
                      >
                        {value}
                      </Text>
                    ))}
                  </Flex>
                  {subSkill?.rating < 4 && (
                    <>
                      <InputGroup mt="8px">
                        <Input
                          onChange={(e) => {
                            subSkill.reason = e.target.value;
                            handleFormDataChange(
                              "observations",
                              "domainSpecificSkills",
                              domainSpecificObservations,
                              "domainSpecificSkillsError"
                            );
                          }}
                          name="skillsReason"
                          value={subSkill?.reason}
                          placeholder="Comment your reason..."
                          maxLength={50}
                          className="acrFormInput"
                        />
                      </InputGroup>{" "}
                      {subSkill?.skillReasonError && (
                        <Text
                          className="personalErrorMessage"
                          mt="5px"
                          ml="15px"
                        >
                          {subSkill?.skillReasonError}
                        </Text>
                      )}
                      {/* {observations?.domainSpecificSkillsError && (
                        <Text
                          className="personalErrorMessage"
                          mt="5px"
                          ml="15px"
                        >
                          {observations?.domainSpecificSkillsError}
                        </Text>
                      )} */}
                    </>
                  )}
                  {observations?.domainSpecificSkillsError && (
                    <Text className="personalErrorMessage" mt="5px" ml="15px">
                      {observations?.domainSpecificSkillsError}
                    </Text>
                  )}
                  {subSkill?.skillNameError && (
                    <Text className="personalErrorMessage" mt="5px" ml="15px">
                      {subSkill?.skillNameError}
                    </Text>
                  )}
                </GridItem>
              </Grid>
            ))}
          </Box>
        );
      })}
    </Box>
  );
};
export default Obervations;

import {
  Box,
  Button,
  Flex,
  Image,
  Text,
  Tooltip,
  Tag,
  TagLabel,
  Avatar,
} from "@chakra-ui/react";
import React, { useState } from "react";
import OutcomeMoveAndDelete from "./outcomeMoveAndDelete";
import {
  DRAG,
  ONGOING,
  ACCOMPLISHED,
  LOCKED_PATHWAY,
} from "../../../../../constant";

const SingleOutcome = ({
  c,
  totatCheckpoints,
  mainArrayIndex,
  index,
  setChangedLocalstorage,
  setOutcomeData,
  outcomeData,
}) => {
  const [showEditHide, setShowEditHide] = useState(false);

  return (
    <Flex gap="8px" align="center">
      <Image src={DRAG} style={{ transform: "translateY(5px)" }} />
      <Flex
        w="full"
        mt="16px"
        border="1px solid #d8e5ee"
        borderRadius="4px"
        p="12px"
        pos="relative"
        justify="space-between"
        align="center"
        key={c?.id}
      >
        <Flex gap="12px">
          <Button
            px="8px"
            py="6px"
            borderRadius="24px"
            bg="#e9f6ff"
            _hover={{ bg: "#e9f6ff" }}
          >
            {index + 1}
          </Button>
          <Box>
            <Text
              fontWeight="600"
              fontSize="14px"
              lineHeight="20px"
              letterSpacing="0.1px"
              color="#141719"
              mb={0}
            >
              {c?.name}
            </Text>
            <Tooltip
              label={c?.domainDescription}
              hasArrow
              className="tooltipCss"
            >
              <Text
                fontWeight="400"
                fontSize="12px"
                letterSpacing="0.4px"
                lineHeight="16px"
                color="#5a646b"
                mt="6px"
                cursor="pointer"
              >
                {c?.domainName}
              </Text>
            </Tooltip>
          </Box>
        </Flex>
        {c.checkpointStatus && c.checkpointStatus !== "Locked" ? (
          <Tag
            className="txnsSecondMapFlexBoxTag"
            style={{
              backgroundColor: "#ffffff",
            }}
          >
            <Avatar
              className="txnsSecondMapFlexBoxTagAvatar"
              src={
                (c.checkpointStatus === "Ongoing" && ONGOING) ||
                (c.checkpointStatus === "Locked" && LOCKED_PATHWAY) ||
                (c.checkpointStatus === "Accomplished" && ACCOMPLISHED)
              }
            />
            <TagLabel
              style={{
                color:
                  (c.checkpointStatus === "Ongoing" && "#8751d9") ||
                  (c.checkpointStatus === "Accomplished" && "#3eb274") ||
                  (c.checkpointStatus === "Accomplished" && "#3eb274"),
              }}
            >
              {c.checkpointStatus}
            </TagLabel>
          </Tag>
        ) : (
          <Flex
            onClick={() => setShowEditHide(true)}
            className="singleNoteDotFlex"
          >
            <Text transform="translate(3px, 4px)">.</Text>
            <Text transform="translate(3px, 4px)">.</Text>
            <Text transform="translate(3px, 4px)">.</Text>
          </Flex>
        )}

        {showEditHide && (
          <OutcomeMoveAndDelete
            mainArrayIndex={mainArrayIndex}
            totatCheckpoints={totatCheckpoints}
            setShowEditHide={setShowEditHide}
            internalArray={c}
            setChangedLocalstorage={setChangedLocalstorage}
            setOutcomeData={setOutcomeData}
            outcomeData={outcomeData}
          />
        )}
      </Flex>
    </Flex>
  );
};

export default SingleOutcome;

import { Box, Flex, Text, useDisclosure, useToast } from "@chakra-ui/react";
import React, { useState } from "react";
import EditHide from "./editHide";
import ModalContainer from "./modalContainer";
import { useDispatch } from "react-redux";
import {
  deleteStudentsNotesAction,
  updateStudentsNotesAction,
} from "../../../../modules/actions";

const SingleNote = ({ c, chat, setChat, childId, parentId, expertName }) => {
  const toast = useToast();
  const dispatch = useDispatch();
  const [showEditHide, setShowEditHide] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [whichModal, setWhichModal] = useState(null);

  const toastTemplate = (title, status) => {
    toast({
      position: "bottom-left",
      title: title,
      status: status,
      isClosable: true,
      duration: 5000,
    });
  };

  const handleSecondaryButtonClick = (id, newMsgForEdit) => {
    const handleEdit = () => {
      const dataObject = {
        content: newMsgForEdit,
        parentId,
        expertName,
      };
      dispatch(updateStudentsNotesAction(id, dataObject, parentId));
      toastTemplate("Note Saved", "success");
    };
    const handleDelete = () => {
      const newNotes = chat.filter((c) => c.id !== id);
      setChat(newNotes);
      dispatch(deleteStudentsNotesAction(id, childId));
      toastTemplate("Notes Deleted", "error");
    };
    whichModal === "edit" ? handleEdit() : handleDelete();
  };

  return (
    <>
      <Flex key={c.id} className="txnsSecondMapFlex singleNoteFlex">
        <Box>
          <div
            className="singleNoteDangerousHTMLDiv"
            dangerouslySetInnerHTML={{ __html: c.msg }}
          ></div>
          <Flex gap="8px" align="center">
            <Text className="singleNoteDate">{c.date}</Text>
            <Text transform="translateY(-4px)" color="#5a646b">
              .
            </Text>
            <Text className="singleNoteBy">{c.by}</Text>
          </Flex>
        </Box>
        <Flex
          onClick={() => setShowEditHide(true)}
          className="singleNoteDotFlex"
        >
          <Text transform="translate(3px, -4px)">.</Text>
          <Text transform="translate(3px, -4px)">.</Text>
          <Text transform="translate(3px, -4px)">.</Text>
        </Flex>
        {showEditHide && (
          <EditHide
            onOpen={onOpen}
            setWhichModal={setWhichModal}
            setShowEditHide={setShowEditHide}
          />
        )}
      </Flex>
      <ModalContainer
        isOpen={isOpen}
        onClose={onClose}
        whichModal={whichModal}
        msg={c.msg}
        id={c.id}
        handleSecondaryButtonClick={handleSecondaryButtonClick}
      />
    </>
  );
};

export default SingleNote;

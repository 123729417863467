import React from "react";
import { Provider } from "react-redux";
import {
  ChakraProvider,
  extendTheme,
  withDefaultProps,
} from "@chakra-ui/react";
import { StepsTheme as Steps } from 'chakra-ui-steps'

import store from "../utils/store";
import App from "./App";

const Root = () => {
  const theme = extendTheme(
    {
      fonts: {
        heading: `'Inter', sans-serif`,
        body: `'Inter', sans-serif`,
      },
      components: {
        Steps,
      }
    },
    withDefaultProps({
      defaultProps: {
        background: "#FFFFFF",
        border: "1px solid #D8E5EE",
        borderRadius: "4px",
      },
      components: ["Input", "NumberInput", "PinInput"],
    })
  );

  return (
    <>
      <Provider store={store}>
        <div>
          {/* <BrowserRouter> */}
          <ChakraProvider theme={theme}>
            <App />
          </ChakraProvider>
          {/* </BrowserRouter> */}
        </div>
      </Provider>
    </>
  );
};
export default Root;

import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../utils/updateObjects";

const initialState = {
  profile: {},
};

const user = (state = initialState, { type, data }) => {
  switch (type) {
    case actionTypes.SAVE_USER_PROFILE:
      return updateObject(state, { profile: data });
    case actionTypes.SAVE_EXPERT_DATA:
      return updateObject(state, {
        expertData: { ...state.expertData, ...data },
      });
    case actionTypes.SAVE_ALL_STUDENTS_DATA:
      return updateObject(state, { students: data });
    case actionTypes.SAVE_DOMAINS_GOAL_DATA:
      return updateObject(state, { domainGoals: data });
    case actionTypes.SAVE_DOMAIN_SUB_GOALS_DATA:
      return updateObject(state, { currentTabsSubGoals: data });
    case actionTypes.SAVE_PARENT_SESSION_HISTORY:
      return updateObject(state, { sessionHistory: data });
    case actionTypes.SAVE_PARENT_JOURNAL:
      return updateObject(state, { parentJournal: data });
    case actionTypes.SAVE_DOMAIN_DATA:
      return updateObject(state, { domain: data });
    case actionTypes.SAVE_STUDENTS_NOTES:
      return updateObject(state, { notes: data });
    case actionTypes.SAVE_CHILDS_DOMAIN_DATA:
      return updateObject(state, { childDomains: data });
    default:
      return state;
  }
};
export default user;

export const token = (state = {}, { type, data }) => {
  switch (type) {
    case actionTypes.SAVE_PARENT_TOKEN:
      return data;
    default:
      return state;
  }
};

export const childExperts = (state = [], { type, data }) => {
  switch (type) {
    case actionTypes.SAVE_MY_EXPERTS:
      return data;
    default:
      return state;
  }
};
export const transactions = (state = [], { type, data }) => {
  switch (type) {
    case actionTypes.SAVE_EXPERT_TRANSACTIONS:
      return data;
    default:
      return state;
  }
};

export const userData = (state = [], { type, data }) => {
  switch (type) {
    case actionTypes.SAVE_USER_DATA:
      return data;
    default:
      return state;
  }
};

export const popularArticles = (state = [], { type, data }) => {
  switch (type) {
    case actionTypes.SAVE_POPULAR_ARTICLE:
      return data;
    default:
      return state;
  }
};

export const userBalance = (state = [], { type, data }) => {
  switch (type) {
    case actionTypes.SAVE_BALANCE_DETAILS:
      return data;
    default:
      return state;
  }
};

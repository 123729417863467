import moment from "moment/moment";

export const getWeekStartDate = () => {
  return moment().startOf("week").format("YYYY-MM-DD");
};

export const getWeekEndDate = () => {
  return moment().endOf("week").format("YYYY-MM-DD");
};
export const getWeekStartDateForDate = (date) => {
  return date.startOf("week").format("YYYY-MM-DD");
};

export const getWeekEndDateForDate = (date) => {
  return date.endOf("week").format("YYYY-MM-DD");
};

export const getNextWeekDate = (date) => {
  return moment(date).add("week", 1).format("YYYY-MM-DD");
};
export const getPreviousWeekDate = (date) => {
  return moment(date).subtract("week", 1).format("YYYY-MM-DD");
};

export const getDayNumber = (date) => {
  const formatDate = new Date(
    date._seconds * 1000 + date._nanoseconds / 1000000
  );
  return moment(formatDate).date();
};
export const getDayNumber1 = (date) => {
  // const formatDate = new Date(
  //   date._seconds * 1000 + date._nanoseconds / 1000000
  // );
  return moment(date).date();
};
export const getMonthName = (date) => {
  const formatDate = new Date(
    date._seconds * 1000 + date._nanoseconds / 1000000
  );

  return moment(formatDate).format("MMM");
};

export const getDatewithDay = (date) => {
  if (date) {
    const formatDate = new Date(
      date._seconds * 1000 + date._nanoseconds / 1000000
    );

    return moment(formatDate).format("D MMMM, dddd");
  }
};
export const getMonthName1 = (date) => {
  // const formatDate = new Date(
  //   date._seconds * 1000 + date._nanoseconds / 1000000
  // );

  return moment(date).format("MMM");
};
export const getDayName = (date) => {
  const formatDate = new Date(
    date._seconds * 1000 + date._nanoseconds / 1000000
  );

  return moment(formatDate).format("ddd");
};
export const getDayName1 = (date) => {
  // const formatDate = new Date(
  //   date._seconds * 1000 + date._nanoseconds / 1000000
  // );

  return moment(date).format("ddd");
};
export const getWeekNo = (date) => {
  return Math.ceil(moment(date).date() / 7);
};

export const getFullMonthName = (date) => {
  // const formatDate = new Date(
  //   date._seconds * 1000 + date._nanoseconds / 1000000
  // );

  return moment(date).format("MMMM");
};

export const getCurrentYear = (date) => {
  // const formatDate = new Date(
  //   date._seconds * 1000 + date._nanoseconds / 1000000
  // );

  return moment(date).format("YYYY");
};

export const getDateMonthYear = (date) => {
  return moment(date).format("D MMM, YYYY");
};

export const getMonthDateYear = (date) => {
  return moment(date).format("MMM D, YYYY");
};
export const getMonthDateYear1 = (date) => {
  const formatDate = new Date(
    date._seconds * 1000 + date._nanoseconds / 1000000
  );
  return moment(formatDate).format("MMM D, YYYY");
};
export const getDateMonthYear1 = (date) => {
  const formatDate = new Date(
    date._seconds * 1000 + date._nanoseconds / 1000000
  );
  return moment(formatDate).format("D MMM, YYYY");
};
export const getDateTime = (date) => {
  const formatDate = new Date(
    date._seconds * 1000 + date._nanoseconds / 1000000
  );
  return moment(formatDate).format("HH:mm");
};

export const isTodaysDate = (date) => {
  return moment().format("YYYY-MM-DD") === date;
};

export const getMomentDate = (date) => {
  return moment(date.valueOf()).format("YYYY-MM-DD");
};

export const getMomentDateFormat = (date) => {
  return moment(date.valueOf()).format("MM-DD-YYYY");
};
export const getMomentDate1 = (date) => {
  const formatDate = new Date(
    date._seconds * 1000 + date._nanoseconds / 1000000
  );
  return moment(formatDate.valueOf()).format("YYYY-MM-DD");
};
export const getCurrentMonthName = () => {
  return moment().format("MMM");
};
export const getCurrentYearValue = () => {
  return moment().format("YYYY");
};

export const compareTwoDates = (d1, d2, compareType) => {
  const date1 = moment(d1);
  const date2 = moment(d2);

  switch (compareType) {
    case "isSameDay":
      return date1.isSame(date2, "day");
    case "isSameOrBefore":
      return date1.isSameOrBefore(date2, "day");
    default:
      return null;
  }
};

export const getTimestampDate = (date) => {
  return moment(date).utc();
};

export const getDate = (date) => {
  const formatDate = new Date(
    date._seconds * 1000 + date._nanoseconds / 1000000
  );
  return formatDate;
};

export const isSameOrBeforeTodaysDate = (date) => {
  const formatDate = new Date(
    date._seconds * 1000 + date._nanoseconds / 1000000
  );
  const date1 = moment(formatDate);
  const date2 = moment();

  return date1.isSameOrBefore(date2, "minute");
};

export const isBeforeTodaysDate = (date) => {
  const formatDate = new Date(
    date._seconds * 1000 + date._nanoseconds / 1000000
  );
  const date1 = moment(formatDate);
  const date2 = moment();

  return date1.isBefore(date2, "day");
};

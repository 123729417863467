import * as firebaseui from "firebaseui";
import firebase from "firebase/compat/app";
import { Timestamp } from "firebase/firestore";
import store from "./store";
import {
  getUserProfile,
  toggleAPILoader,
  toggleMainLoader,
  logoutSuccess,
} from "../modules/actions";
import { ROUTES_PATH } from "../constant";
import { saveToken } from "./localStorage";
import { Toaster } from "../common/components/Toaster";

//Firebase Config
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

// FirebaseUI config
export const uiConfig = {
  signInFlow: "popup",
  signInSuccessUrl:
    process.env.REACT_APP_CONNECT_WEB_URL + ROUTES_PATH.PARENT_SIGNIN,
  signInOptions: [
    {
      requireDisplayName: false,
      forceSameDevice: false,
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
      signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
    },
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    {
      provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
      recaptchaParameters: {
        size: "invisible",
      },
      defaultCountry: "IN",
    },
  ],
  credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO,
  // Terms of service url/callback.
  tosUrl: process.env.REACT_APP_KP_WEB_URL + "terms-and-conditions",
  // Privacy policy url/callback.
  privacyPolicyUrl: function () {
    window.location.assign(process.env.REACT_APP_KP_WEB_URL + "privacy-policy");
  },
};

firebase.initializeApp(firebaseConfig);

// Initialize the FirebaseUI Widget using Firebase.
var ui =
  firebaseui.auth.AuthUI.getInstance() ||
  new firebaseui.auth.AuthUI(firebase.auth());

export const firebaseSignInFunction = () => {
  // The start method will wait until the DOM is loaded.
  ui.start("#firebaseui-auth-container", uiConfig);
};

export const getIdToken = () => {
  firebase
    .auth()
    .currentUser?.getIdToken(/* forceRefresh */ true)
    .then(function (idToken) {
      // Send token to your backend via HTTPS
      // ...
      saveToken(idToken);
      store.dispatch(toggleMainLoader(true));
      store.dispatch(getUserProfile(idToken));
    })
    .catch(function (error) {
      // Handle error
      console.log(error);
      store.dispatch(toggleMainLoader(true));
      store.dispatch(toggleAPILoader(true));
    });
};

export const checkAuth = () => {
  firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      store.dispatch(toggleMainLoader(true));
      getIdToken();
      // window.location.href = "http://localhost:3000" + ROUTES_PATH.PARENT_HOME;
    } else {
      window.location.href = `${process.env.REACT_APP_KP_WEB_URL}auth/login`;
    }
  });
};

export const refreshIdToken = () => {
  firebase
    .auth()
    .currentUser?.getIdToken(/* forceRefresh */ true)
    .then(function (idToken) {
      // Send token to your backend via HTTPS
      // ...

      saveToken(idToken);
    })
    .catch(function (error) {
      // Handle error
      console.log(error);
      Toaster(
        "Need to signout as your session expired.Please signin again",
        "info"
      );
      signout();
    });
};

export const parentLogin = (token) => {
  firebase
    .auth()
    .signInWithCustomToken(token)
    .then((userCredential) => {
      // Signed in
      firebase.auth().onAuthStateChanged((user) => {
        // this.checkIfRegisteredUser(user);
        if (user) {
          store.dispatch(toggleMainLoader(true));
          getIdToken();
        }
      });
    })
    .catch((error) => {
      console.log(error);
    });
};

export const signout = () => {
  firebase
    .auth()
    .signOut()
    .then(function () {
      // Sign-out successful.
      store.dispatch(logoutSuccess());
    })
    .catch(function (error) {
      // An error happened.
      console.log(error);
    });
};

export const getFirestoreDate = (date) => {
  return Timestamp.fromDate(new Date(date));
};

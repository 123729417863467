import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  Text,
  Tag,
  TagLabel,
  Avatar,
  useDisclosure,
  Spinner,
} from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Select } from "chakra-react-select";
import moment from "moment";
import { leaveStatusOptions } from "../../../../constant/selectOptions";
import ProgressBar from "../../../../components/ProgressBar";
import {
  getDateMonthYear1,
  getMomentDate1,
  isBeforeTodaysDate,
} from "../../../../utils/customMoment";
import {
  getParentSessions,
  toggleMainLoader,
  toggleAPILoader,
  getBalanceDetails,
  toggleSessionLoader,
} from "../../../../modules/actions";
import {
  ROUTES_PATH,
  ATTENDED,
  SCHEDULED,
  CANCELLED,
  RESCHEDULED,
  PARTIALLY_ATTENDED,
  ORANGE_WARNING,
  NO_SHOW,
} from "../../../../constant";
import Loader from "../../../../common/components/Loader";
import CancellationPopup from "../../../../components/CancellationPopup";
import RescheduledPopup from "../../../../components/RescheduledPopup";
import { istTime, istTime2 } from "../../../../utils/customHooks";
import { Toaster } from "../../../../common/components/Toaster";
import {
  confirmSessionRequestService,
  rejectSessionRequestService,
  startMeetingService,
} from "../../../../modules/services";
import ValidityExpiredPopup from "../../../../components/ValidityExpiredPopup";

const ParentSessions = ({ setOverlayOpen, loader }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { mainLoader, sessionLoader, apiLoader } = useSelector(
    (state) => state?.loader
  );
  const user = useSelector((state) => state?.user?.profile);
  const userBalance = useSelector((state) => state.userBalance);
  const sessions = useSelector((state) => state?.parentSessions);

  const [selectedStatus, setSelectedStatus] = useState({
    status: "Upcoming",
  });
  const [selectedSession, setSelectedSession] = useState("");

  const {
    isOpen: isRescheduleOpen,
    onOpen: onRescheduleOpen,
    onClose: onRescheduleClose,
  } = useDisclosure();
  const {
    isOpen: isCancelOpen,
    onOpen: onCancelOpen,
    onClose: onCancelClose,
  } = useDisclosure();

  const {
    isOpen: isValidityOpen,
    onOpen: onValidityOpen,
    onClose: onValidityClose,
  } = useDisclosure();

  const selectStyles = {
    control: (styles) => ({
      ...styles,
      border: "none",
      outline: "none",
      borderRadius: "10px",
      boxShadow: "none",
      width: "full",
      cursor: "pointer",
    }),
    dropdownIndicator: (prev, { selectProps: { menuIsOpen } }) => ({
      ...prev,
      "> svg": {
        transitionDuration: "normal",
        transform: `rotate(${menuIsOpen ? -180 : 0}deg)`,
        color: "#4299E1",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? "#E9F6FF"
          : isFocused
          ? "#F3F8FB"
          : undefined,
        color: isDisabled ? "#ccc" : isSelected ? "#4299E1" : "#141719",
        padding: "10px",
        cursor: isDisabled ? "not-allowed" : "pointer",
        fontFamily: "Inter",
        fontSize: "14px",
        lineHeight: "20px",
        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? "#E9F6FF"
              : "#E9F6FF"
            : undefined,
        },
      };
    },
    singleValue: (styles) => ({
      ...styles,
      color: "#141719",
      fontWeight: "600",
    }),
    menuList: (styles) => ({
      ...styles,
      borderRadius: "8px",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.16)",
    }),
  };

  useEffect(() => {
    dispatch(toggleMainLoader(true));
    dispatch(
      getParentSessions(selectedStatus, {
        filterDate: moment().format("YYYY/MM/DD"),
      })
    );
  }, [selectedStatus]);

  useEffect(() => {
    dispatch(toggleSessionLoader(true));
    dispatch(getBalanceDetails());
  }, []);

  const handleStatusChange = (value) => {
    setSelectedStatus({ status: value });
  };

  const joinButtonVisibility = (session) => {
    if (
      !session?.attendedBy.parent &&
      (session["status"] === "Scheduled" ||
        session["status"] === "Expert Joined")
    ) {
      const sessionDate = new Date(
        session.sessionDate._seconds * 1000 +
          session.sessionDate._nanoseconds / 1000000
      );
      let exactSessionDate = istTime2(sessionDate);

      let joinBtnEndTime = new Date(sessionDate);
      let exactJoinEndTime = istTime2(joinBtnEndTime);

      // startTime is HH:MM
      let startTime = session.startTime.split(":");
      const startingHour = parseInt(startTime[0], 10);
      const startingMinute = parseInt(startTime[1], 10);

      //Convert start time to minutes and add to session date to get exact start date time.
      exactSessionDate.setMinutes(
        exactSessionDate.getMinutes() + startingHour * 60 + startingMinute - 5
      );

      exactJoinEndTime.setMinutes(
        exactJoinEndTime.getMinutes() + startingHour * 60 + startingMinute + 15
      );

      //Check if now is greater or equal to session start date time
      // let currentTime = new Date();
      // let now = this.helper.istTime(currentTime);

      // let nowTimeStamp = firebase.firestore.Timestamp.now()
      // let now = new Date(nowTimeStamp.toDate())

      let nowTime = new Date().toLocaleString("en-US", {
        timeZone: "Asia/Kolkata",
      });
      let now = new Date(nowTime);

      if (
        now.getTime() >= exactSessionDate.getTime() &&
        now.getTime() <= exactJoinEndTime.getTime()
      ) {
        //session start time has crossed
        return true;
      }
    }
    return false;
  };
  const consultationCancelButtonVisibility = (session) => {
    if (session.status == "Scheduled") {
      let sessionDate = getMomentDate1(session.sessionDate);

      let exactSessionDate = istTime2(sessionDate);

      let joinBtnEndTime = new Date(sessionDate);
      let exactJoinEndTime = istTime2(joinBtnEndTime);

      // startTime is HH:MM
      let startTime = session.startTime.split(":");
      const startingHour = parseInt(startTime[0], 10);
      const startingMinute = parseInt(startTime[1], 10);

      //Convert start time to minutes and add to session date to get exact start date time.
      exactSessionDate.setMinutes(
        exactSessionDate.getMinutes() + startingHour * 60 + startingMinute - 5
      );
      exactJoinEndTime.setMinutes(
        exactJoinEndTime.getMinutes() + startingHour * 60 + startingMinute + 15
      );

      let currentTime = new Date();
      let now = istTime(currentTime);

      //Check if now is greater or equal to session start date time
      if (now.getTime() <= exactSessionDate.getTime()) {
        //session start time has crossed
        return true;
      }
    }
    return false;
  };
  const rejoinBtnVisibility = (session) => {
    if (
      session.attendedBy.parent &&
      (session["status"] === "Expert Joined" ||
        session["status"] === "Parent Joined" ||
        session["status"] === "Ongoing")
    ) {
      const sessionDate = new Date(
        session.sessionDate._seconds * 1000 +
          session.sessionDate._nanoseconds / 1000000
      );
      let exactSessionDate = istTime2(sessionDate);

      // startTime is HH:MM
      let endTime = session.endTime.split(":");
      const endingHour = parseInt(endTime[0], 10);
      const endingMinute = parseInt(endTime[1], 10);

      //Convert end time to minutes and add to session date to get exact end date time.
      exactSessionDate.setMinutes(
        exactSessionDate.getMinutes() + endingHour * 60 + endingMinute
      );

      let currentTime = new Date();
      let now = istTime(currentTime);

      //Check if now is smaller or equal to session end date time
      if (
        session.sessionType !== "Free Consultation" &&
        session.sessionType !== "Paid Consultation"
      ) {
        if (now.getTime() <= exactSessionDate.getTime() - 10 * 60 * 1000) {
          //session end time has not crossed
          return true;
        }
      } else {
        if (now.getTime() <= exactSessionDate.getTime()) {
          //session end time has not crossed
          return true;
        }
      }
    }
    return false;
  };

  const handleJoinMeeting = async (selectedSession) => {
    const reqData = {
      sessionId: selectedSession.id,
      role: user?.role,
    };
    dispatch(toggleAPILoader(true));
    startMeetingService(reqData)
      .then((res) => {
        const session = res.data.data;
        dispatch(toggleAPILoader(false));
        window.open(session["zoomJoinUrl"]);
      })
      .catch((error) => {
        console.log(error);
        dispatch(toggleAPILoader(false));
        Toaster(error.response.data.message, "error");
      });
  };
  const handleConfirmSessionRequest = (selectedSession) => {
    const reqData = {
      sessionId: selectedSession.id,
    };
    dispatch(toggleMainLoader(true));
    confirmSessionRequestService(reqData)
      .then((res) => {
        Toaster("Session request accepted!", "success");
        dispatch(
          getParentSessions(selectedStatus, {
            filterDate: moment().format("YYYY/MM/DD"),
          })
        );
      })
      .catch((error) => {
        console.log(error);
        dispatch(toggleMainLoader(false));
        Toaster(error.response.data.message, "error");
      });
  };

  const handleRejectSessionRequest = (selectedSession) => {
    const reqData = {
      sessionId: selectedSession.id,
    };
    dispatch(toggleMainLoader(true));
    rejectSessionRequestService(reqData)
      .then((res) => {
        Toaster("Session request rejected!", "success");
        dispatch(
          getParentSessions(selectedStatus, {
            filterDate: moment().format("YYYY/MM/DD"),
          })
        );
      })
      .catch((error) => {
        console.log(error);
        dispatch(toggleMainLoader(false));
        Toaster(error.response.data.message, "error");
      });
  };
  const handleSceduleSession = () => {
    (user?.validTill && isBeforeTodaysDate(user?.validTill)) ||
    userBalance?.currentBalance < 1
      ? onValidityOpen()
      : setOverlayOpen(true);
  };
  return (
    <Box p={{ base: "24px 0px", md: "24px 16px" }}>
      <ValidityExpiredPopup
        isOpen={isValidityOpen}
        onClose={onValidityClose}
        parent={user}
      />
      <Box className="pathwayHeading">Sessions</Box>
      <Box className="pathwaySubHeading" w={{ md: "640px", sm: "100%" }}>
        Schedule sessions with your consultant to track progress and unlock new
        levels to-do. Regular schedule will ensure personalised experience and
        good progress in your child’s growth
      </Box>
      {sessionLoader ? (
        <Loader text="Loading, Please Wait..." />
      ) : (
        <>
          <Box className="parentSessionCountDiv">
            <Box width="150px" height="75px">
              <ProgressBar
                progress={
                  userBalance?.currentBalance
                    ? userBalance?.currentBalance?.toString().indexOf(".") ===
                      -1
                      ? Number(userBalance?.currentBalance)
                      : Number(userBalance?.currentBalance).toFixed(2)
                    : 0
                }
              />
            </Box>
            <Box className="sessionLeftText">Sessions left</Box>
            {user?.validTill && (
              <Box className="validTillText">
                Valid till {getDateMonthYear1(user?.validTill)}
              </Box>
            )}
            <Box className="scheduleDivText">
              Schedule session regularly to meet your consultant and help your
              child progress across the checkpoints
            </Box>
            <Box className="sessionBtnDiv" gap="16px">
              <Button
                className="scheduleDivBtn"
                _hover={{
                  bg: "blue.500",
                }}
                onClick={() => handleSceduleSession()}
              >
                Schedule Session
              </Button>
              <Button
                className="scheduleDivBtn"
                _hover={{
                  bg: "blue.500",
                }}
                onClick={() => {
                  navigate(ROUTES_PATH.PARENT_CHOOSE_PLAN);
                }}
              >
                Purchase Session
              </Button>
            </Box>
          </Box>
          <Flex mt="16px" gap="16px">
            <Select
              useBasicStyles
              options={leaveStatusOptions}
              chakraStyles={selectStyles}
              defaultValue={{ label: "Upcoming", value: "upcoming" }}
              name="sessionStatus"
              className="txnsTabSelect"
              onChange={(e) => handleStatusChange(e.value)}
            />
          </Flex>
        </>
      )}

      {mainLoader ? (
        <Loader text="Loading, Please Wait..." />
      ) : (
        <Box mt="24px">
          {sessions.map((singleSession, index) => (
            <Flex key={index} className="txnsSecondMapFlex">
              <Box>
                <Flex align="center">
                  <Text className="txnsSecondMapFlexBoxFlexText1">
                    {singleSession?.sessionStudentName} /{" "}
                    {singleSession?.sessionExpertName}
                  </Text>
                </Flex>
                <Flex className="txnsSecondMapFlexBoxFlexFlex1">
                  {getDateMonthYear1(singleSession?.sessionDate)}

                  <Text mt="-3px" mb={0}>
                    .
                  </Text>
                  <Text mb={0}>
                    {singleSession?.startTime + " - " + singleSession?.endTime}
                  </Text>
                </Flex>
              </Box>
              {singleSession.status === "Session Request" ? (
                <Box className="sessionBtnsDiv">
                  <Button
                    className="sesssionJoinBtn"
                    onClick={() => {
                      handleConfirmSessionRequest(singleSession);
                    }}
                  >
                    Confirm
                  </Button>

                  <Button
                    className="sesssionOtherBtns"
                    onClick={() => {
                      handleRejectSessionRequest(singleSession);
                    }}
                  >
                    Reject
                  </Button>

                  <Button
                    className="sesssionOtherBtns"
                    onClick={() => {
                      window.open("https://wa.me/+919606719826");
                    }}
                  >
                    Contact Support
                  </Button>
                </Box>
              ) : singleSession.status !== "Rescheduled" &&
                singleSession.status !== "Cancelled" &&
                singleSession.status !== "REQUEST CANCELLED" &&
                singleSession.status !== "Session Request Expired" &&
                singleSession.status !== "Rejected Session Request" &&
                selectedStatus.status === "Upcoming" ? (
                <Box className="sessionBtnsDiv">
                  {joinButtonVisibility(singleSession) && (
                    <Button
                      className="sesssionJoinBtn"
                      onClick={() => handleJoinMeeting(singleSession)}
                    >
                      {apiLoader ? <Spinner /> : "Join"}
                    </Button>
                  )}
                  {rejoinBtnVisibility(singleSession) && (
                    <Button
                      className="sesssionJoinBtn"
                      onClick={() => handleJoinMeeting(singleSession)}
                    >
                      {apiLoader ? <Spinner /> : "ReJoin"}
                    </Button>
                  )}

                  {singleSession.status === "Scheduled" && (
                    <>
                      <Button
                        className="sesssionOtherBtns"
                        onClick={() => {
                          setSelectedSession(singleSession);
                          onRescheduleOpen();
                        }}
                      >
                        Reschedule
                      </Button>

                      <Button
                        className="sesssionOtherBtns"
                        onClick={() => {
                          setSelectedSession(singleSession);
                          onCancelOpen();
                        }}
                      >
                        Cancel
                      </Button>
                    </>
                  )}
                  {singleSession.status === "Partially Attended" && (
                    <Tag
                      className="txnsSecondMapFlexBoxTag"
                      style={{
                        backgroundColor:
                          ((singleSession?.status === "Successfull" ||
                            singleSession?.status === "Attended" ||
                            singleSession?.status === "Partially Attended") &&
                            "#EFF7E6") ||
                          (singleSession?.status === "Cancelled" &&
                            "#FFDAD6") ||
                          (singleSession?.status === "Scheduled" &&
                            "#F3EBFF") ||
                          (singleSession?.status === "Rescheduled" &&
                            "#FEDFA0"),
                      }}
                    >
                      <Avatar
                        className="txnsSecondMapFlexBoxTagAvatar"
                        src={
                          ((singleSession?.status === "Successfull" ||
                            singleSession?.status === "Attended") &&
                            ATTENDED) ||
                          (singleSession?.status === "Partially Attended" &&
                            PARTIALLY_ATTENDED) ||
                          (singleSession?.status === "Cancelled" &&
                            CANCELLED) ||
                          (singleSession?.status === "Scheduled" &&
                            SCHEDULED) ||
                          (singleSession?.status === "Rescheduled" &&
                            RESCHEDULED)
                        }
                      />
                      <TagLabel
                        style={{
                          color:
                            ((singleSession?.status === "Successfull" ||
                              singleSession?.status === "Attended" ||
                              singleSession?.status === "Partially Attended") &&
                              "#024742") ||
                            (singleSession?.status === "Cancelled" &&
                              "#410002") ||
                            (singleSession?.status === "Scheduled" &&
                              "#2F0D63") ||
                            (singleSession?.status === "Rescheduled" &&
                              "#734110"),
                        }}
                      >
                        {singleSession?.status}
                      </TagLabel>
                    </Tag>
                  )}
                </Box>
              ) : (
                <Tag
                  className="txnsSecondMapFlexBoxTag"
                  style={{
                    backgroundColor:
                      ((singleSession?.status === "Successfull" ||
                        singleSession?.status === "Attended" ||
                        singleSession?.status === "Partially Attended") &&
                        "#EFF7E6") ||
                      ((singleSession?.status === "Cancelled" ||
                        singleSession?.status === "noShow-Parent" ||
                        singleSession?.status === "noShow-Expert") &&
                        "#FFDAD6") ||
                      (singleSession?.status === "Scheduled" && "#F3EBFF") ||
                      (singleSession?.status === "Rescheduled" && "#FEDFA0") ||
                      ((singleSession?.status === "Session Request" ||
                        singleSession?.status === "Session Request Expired" ||
                        singleSession?.status === "Rejected Session Request" ||
                        singleSession.status === "REQUEST CANCELLED") &&
                        "#FEDFA0"),
                  }}
                >
                  <Avatar
                    className="txnsSecondMapFlexBoxTagAvatar"
                    src={
                      ((singleSession?.status === "Successfull" ||
                        singleSession?.status === "Attended") &&
                        ATTENDED) ||
                      (singleSession?.status === "Partially Attended" &&
                        PARTIALLY_ATTENDED) ||
                      (singleSession?.status === "Cancelled" && CANCELLED) ||
                      (singleSession?.status === "Scheduled" && SCHEDULED) ||
                      (singleSession?.status === "Rescheduled" &&
                        RESCHEDULED) ||
                      ((singleSession?.status === "Session Request" ||
                        singleSession?.status === "Session Request Expired" ||
                        singleSession?.status === "Rejected Session Request" ||
                        singleSession.status === "REQUEST CANCELLED") &&
                        ORANGE_WARNING) ||
                      ((singleSession?.status === "noShow-Parent" ||
                        singleSession?.status === "noShow-Expert") &&
                        NO_SHOW)
                    }
                  />
                  <TagLabel
                    style={{
                      color:
                        ((singleSession?.status === "Successfull" ||
                          singleSession?.status === "Attended" ||
                          singleSession?.status === "Partially Attended") &&
                          "#024742") ||
                        ((singleSession?.status === "Cancelled" ||
                          singleSession?.status === "noShow-Parent" ||
                          singleSession?.status === "noShow-Expert") &&
                          "#410002") ||
                        (singleSession?.status === "Scheduled" && "#2F0D63") ||
                        (singleSession?.status === "Rescheduled" &&
                          "#734110") ||
                        ((singleSession?.status === "Session Request" ||
                          singleSession?.status === "Session Request Expired" ||
                          singleSession?.status ===
                            "Rejected Session Request" ||
                          singleSession.status === "REQUEST CANCELLED") &&
                          "#734110"),
                    }}
                  >
                    {singleSession?.status}
                  </TagLabel>
                </Tag>
              )}
            </Flex>
          ))}
        </Box>
      )}
      <RescheduledPopup
        isOpen={isRescheduleOpen}
        onClose={onRescheduleClose}
        title="Reschedule session?"
        reschedulingPerson="Parent"
        session={selectedSession}
        type="rescheduled"
      />
      <CancellationPopup
        isOpen={isCancelOpen}
        onClose={onCancelClose}
        title="Cancel session?"
        cancellingPerson="Parent"
        session={selectedSession}
        type="cancelled"
      />
    </Box>
  );
};
export default ParentSessions;

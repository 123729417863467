import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import AuthenticatedContainer from "../../../container/Authenticated";
import Transactions from "./Tabs/transactions";
import Leaves from "./Tabs/leaves";
import Services from "./Tabs/services";
import WorkingHours from "./Tabs/workingHours";
import {
  Avatar,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  SimpleGrid,
  Tag,
  TagLabel,
  Text,
  Show,
} from "@chakra-ui/react";
import { EmailIcon, PhoneIcon } from "@chakra-ui/icons";
import "./myProfile.css";
import {
  validateEmail,
  validateFullName,
  validatePhoneNumber,
} from "../../../utils/validations";
import {
  DELETE_ICON,
  ADD_PLUS_ICON,
  UPLOAD_ICON,
  NO_USER_IMAGE,
} from "../../../constant/images";
import OverlayComponent from "./TabComponents/overlayComponent";
import LeavesApplier from "./TabComponents/leavesApplier";
import {
  getExpertLeaves,
  toggleAPILoader,
  getUserProfile,
} from "../../../modules/actions";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import moment from "moment";
import MobileNotAllowedScreen from "../../../common/components/MobileNotAllowed";
import {
  applyLeavesService,
  editUserProfileService,
  saveUserProfileImageService,
} from "../../../modules/services";
import { Toaster } from "../../../common/components/Toaster";
import { getMomentDate1 } from "../../../utils/customMoment";

const MyProfile = () => {
  const dispatch = useDispatch();
  const inputFile = useRef(null);
  const userProfile = useSelector((state) => state?.user?.profile);
  const { apiLoader } = useSelector((state) => state?.loader);
  const [next, setNext] = useState(false);

  const [activeStep, setActiveStep] = useState("Transactions");
  const [applyLeaveData, setApplyLeaveData] = useState({
    reason: "",
    reasonError: "",
    weekday: "",
    weekdayError: "",
    startDate: "",
    startDateError: "",
    endDate: "",
    endDateError: "",
    endTime: "",
    endTimeError: "",
    startTime: "",
    startTimeError: "",
  });

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contact: "",
    countryCode: "",
    image: "",
    img: NO_USER_IMAGE,
    rciNumber: "",
    dob: "",
    imageError: "",
  });

  const [noName, setNoName] = useState(false);
  const [noDob, setNoDob] = useState(false);
  const [noEmail, setNoEmail] = useState(false);
  const [noNumber, setNoNumber] = useState(false);
  const [noRciNumber, setNoRciNumber] = useState(false);

  const [qualificationsData, setQualificationsData] = useState([
    {
      id: 1,
      degree: "",
      fieldOfStudy: "",
      certifyingOrganisation: "",
      noDegree: false,
      noField: false,
      noCertifyingOrganization: false,
    },
  ]);

  const [whichOverlayToOpen, setWhichOverlayToOpen] = useState("");

  const [overlayOpen, setOverlayOpen] = useState(false);
  const [closer, setCloser] = useState(false);
  const [workingHoursData, setWorkingHoursData] = useState([]);

  useEffect(() => {
    setWorkingHoursData(userProfile?.workingHours);
  }, [userProfile?.workingHours]);

  useEffect(() => {
    const formatDate = getMomentDate1(userProfile?.dateOfBirth);

    setFormData({
      name: userProfile?.fullName || "",
      email: userProfile?.email || "",
      contact: userProfile?.phoneNumber || "",
      countryCode: userProfile?.countryCode || "",
      image: userProfile?.imgUrl || "",
      dob: formatDate || "",
      rciNumber: userProfile?.rciNumber || "",
    });

    const formattedQualifications =
      userProfile?.qualifications?.length > 0
        ? userProfile?.qualifications?.map((item, index) => {
            return {
              id: index + 1,
              degree: item?.degree,
              fieldOfStudy: item?.fieldOfStudy,
              certifyingOrganisation: item?.certifyingOrganisation,
              noDegree: item?.degree ? false : true,
              noField: item?.fieldOfStudy ? false : true,
              noCertifyingOrganization: item?.certifyingOrganisation
                ? false
                : true,
            };
          })
        : [];

    setQualificationsData(formattedQualifications || []);
  }, [userProfile]);

  useEffect(() => {
    const { name, email, contact, dob, rciNumber } = formData;
    let flag = false;
    qualificationsData?.map((q) => {
      if (
        !q?.degree ||
        !q?.fieldOfStudy ||
        !q?.certifyingOrganisation ||
        !validateFullName(name)
      )
        return (flag = false);
      return (flag = true);
    });
    if (flag) setNext(true);
    if (!name || !dob || !email || !contact || !rciNumber || !flag) {
      setNext(false);
    } else {
      setNext(true);
    }
  }, [formData, qualificationsData]);

  const handleFormDataChange = (name, value) => {
    if (name === "countryCode") {
      setFormData({ ...formData, [name]: "+" + value, contact: "" });
    } else if (name === "contact") {
      setFormData({ ...formData, [name]: value });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleQualificationsChange = (id, name, value) => {
    setQualificationsData((prev) =>
      prev.map((q) => (q.id === id ? { ...q, [name]: value } : q))
    );
  };

  const handleAdd = () => {
    setQualificationsData((prev) => [
      ...prev,
      {
        id: qualificationsData?.length + 1,
        degree: "",
        fieldOfStudy: "",
        certifyingOrganisation: "",
        noDegree: false,
        noField: false,
        noCertifyingOrganization: false,
      },
    ]);
  };

  const handleDelete = (id) => {
    if (qualificationsData?.length === 1) return;
    setQualificationsData((prev) => prev?.filter((q) => q?.id !== id));
  };

  const handleNextStep = () => {
    const { name, email, contact, dob, rciNumber, image, img } = formData;

    setNoName(!validateFullName(name));
    setNoRciNumber(rciNumber === "");
    setNoDob(dob === "" || moment(dob).isSameOrAfter(moment(), "day"));
    setNoEmail(!validateEmail(email));
    setNoNumber(!validatePhoneNumber(contact));
    const dateValidate = moment(dob).isSameOrAfter(moment(), "day");

    setQualificationsData((prev) =>
      prev.map((item) => {
        return {
          ...item,
          noDegree: item?.degree === "",
          noField: item?.fieldOfStudy === "",
          noCertifyingOrganization: item?.certifyingOrganisation === "",
        };
      })
    );
    let flag = false;
    qualificationsData?.map((q) => {
      if (!q?.degree || !q?.fieldOfStudy || !q?.certifyingOrganisation)
        return (flag = false);
      return (flag = true);
    });
    // if (!flag) return;

    const reqData = {
      name: name,
      fullName: name,
      dateOfBirth: dob,
      qualifications: [],
      rciNumber: rciNumber,
    };
    qualificationsData?.map((item) => {
      reqData["qualifications"].push({
        certifyingOrganisation: item.certifyingOrganisation,
        degree: item.degree,
        fieldOfStudy: item.fieldOfStudy,
      });
    });

    if (
      name &&
      dob &&
      rciNumber &&
      flag &&
      validateFullName(name) &&
      !dateValidate
    ) {
      let formDataImage = new FormData();
      formDataImage.append("image", img);

      if (image !== "" && image !== userProfile?.imgUrl) {
        dispatch(toggleAPILoader(true));
        saveUserProfileImageService(formDataImage)
          .then((res) => {
            reqData["imgUrl"] = res.data.data;

            handleEditProfile(reqData);
          })
          .catch((error) => {
            console.log(error);
            dispatch(toggleAPILoader(false));
            Toaster(error.response.data.message, "error");
          });
      } else {
        handleEditProfile(reqData);
      }
    }
  };
  const handleEditProfile = (reqData) => {
    if (
      reqData.name !== userProfile?.name ||
      reqData.rciNumber !== userProfile?.rciNumber ||
      !_.isEqual(reqData.qualifications, userProfile?.qualifications) ||
      reqData.dateOfBirth !== getMomentDate1(userProfile.dateOfBirth) ||
      reqData.imgUrl !== userProfile?.imgUrl
    ) {
      dispatch(toggleAPILoader(true));
      editUserProfileService(reqData)
        .then((res) => {
          Toaster("Expert profile updated successfully", "success");
          dispatch(toggleAPILoader(false));
          const token = localStorage.getItem("idToken");
          dispatch(getUserProfile(token));
          setOverlayOpen(false);
        })
        .catch((error) => {
          console.log(error);
          dispatch(toggleAPILoader(false));
          Toaster(error.response.data.message, "error");
        });
    } else {
      setOverlayOpen(false);
    }
  };
  const tabs = [
    { id: "Transactions", value: <Transactions /> },
    { id: "Leaves", value: <Leaves /> },
    { id: "Services", value: <Services /> },
    { id: "Working Hours", value: <WorkingHours /> },
  ];
  const handleApplyLeave = () => {
    const validateReason = !applyLeaveData.reason
      ? "Please enter a reason"
      : "";

    const validateStartDate = !applyLeaveData.startDate
      ? "Please choose start date"
      : moment(applyLeaveData.startDate).isSameOrBefore(moment(), "day")
      ? "Please choose future dates"
      : "";

    const validateStartTime = !applyLeaveData.startTime
      ? "Please choose start time"
      : "";

    const validateEndDate = !applyLeaveData.endDate
      ? "Please choose end date"
      : moment(applyLeaveData.endDate).isBefore(
          moment(applyLeaveData.startDate),
          "day"
        )
      ? "Please choose future dates"
      : "";

    const validateEndTime = !applyLeaveData.endTime
      ? "Please choose end time"
      : "";

    setApplyLeaveData({
      ...applyLeaveData,
      reasonError: validateReason,
      startDateError: validateStartDate,
      endDateError: validateEndDate,
      startTimeError: validateStartTime,
      endTimeError: validateEndTime,
    });
    if (
      applyLeaveData.reason !== "" &&
      applyLeaveData.startDate !== "" &&
      applyLeaveData.endDate !== "" &&
      applyLeaveData.startTime !== "" &&
      applyLeaveData.endTime !== "" &&
      !validateEndDate &&
      !validateStartDate
    ) {
      const data = {
        reason: applyLeaveData.reason,
        startDateTime: moment(new Date(applyLeaveData.startDate)).add(
          applyLeaveData.startTime
        ),
        endDateTime: moment(new Date(applyLeaveData.endDate)).add(
          applyLeaveData.endTime
        ),
      };
      dispatch(toggleAPILoader(true));
      applyLeavesService(data)
        .then((res) => {
          Toaster("Leaves applied successfully", "success");
          dispatch(toggleAPILoader(false));
          handleClearData();
          dispatch(
            getExpertLeaves("upcoming", {
              filterDate: moment().format("YYYY/MM/DD"),
            })
          );
          setOverlayOpen(false);
        })
        .catch((error) => {
          console.log(error);
          dispatch(toggleAPILoader(false));
          Toaster(error.response.data.message, "error");
        });
    }
  };
  const handleClearData = () => {
    setApplyLeaveData({
      reason: "",
      reasonError: "",
      weekday: "",
      weekdayError: "",
      startDate: "",
      startDateError: "",
      endDate: "",
      endDateError: "",
      endTime: "",
      endTimeError: "",
      startTime: "",
      startTimeError: "",
    });
  };
  const handleEditProfileClearData = () => {
    setNoName(false);
    setNoDob(false);
    setNoEmail(false);
    setNoNumber(false);
    setNoRciNumber(false);

    const formatDate = getMomentDate1(userProfile?.dateOfBirth);

    setFormData({
      name: userProfile?.fullName || "",
      email: userProfile?.email || "",
      contact: userProfile?.phoneNumber || "",
      countryCode: userProfile?.countryCode || "",
      image: userProfile?.imgUrl || "",
      imageError: "",
      dob: formatDate || "",
      rciNumber: userProfile?.rciNumber || "",
    });

    const formattedQualifications =
      userProfile?.qualifications?.length > 0
        ? userProfile?.qualifications?.map((item, index) => {
            return {
              id: index + 1,
              degree: item?.degree,
              fieldOfStudy: item?.fieldOfStudy,
              certifyingOrganisation: item?.certifyingOrganisation,
              noDegree: item?.degree ? false : true,
              noField: item?.fieldOfStudy ? false : true,
              noCertifyingOrganization: item?.certifyingOrganisation
                ? false
                : true,
            };
          })
        : [];

    setQualificationsData(formattedQualifications || []);
  };

  const onUploadImage = () => {
    inputFile.current.click();
  };

  const handleFileUpload = (e) => {
    const { files } = e.target;
    if (files && files.length) {
      if (files[0].size >= 5242880) {
        setFormData({
          ...formData,
          imageError: "Please upload an image with max size 5mb",
        });
        return;
      }

      setFormData({
        ...formData,
        image: URL.createObjectURL(files[0]),
        img: files[0],
      });
    }
  };

  return (
    <Box>
      <AuthenticatedContainer>
        <MobileNotAllowedScreen />
        <Show breakpoint="(min-width:767px)">
          {overlayOpen && whichOverlayToOpen === "ApplyLeave" && (
            <OverlayComponent
              setOverlayOpen={setOverlayOpen}
              closer={closer}
              title={"Apply for leave"}
              handleNextStep={handleApplyLeave}
              handleClearData={handleClearData}
              apiLoader={apiLoader}
              btnDisabled={
                applyLeaveData.reason === "" ||
                applyLeaveData.endDate === "" ||
                applyLeaveData.startDate === "" ||
                applyLeaveData.startTime === "" ||
                applyLeaveData.endTime === ""
              }
            >
              <LeavesApplier
                setCloser={setCloser}
                workingHours={workingHoursData}
                setApplyLeaveData={setApplyLeaveData}
                applyLeaveData={applyLeaveData}
              />
            </OverlayComponent>
          )}
          {overlayOpen && whichOverlayToOpen === "EditProfile" && (
            <OverlayComponent
              setOverlayOpen={setOverlayOpen}
              closer={true}
              title="Edit profile"
              handleNextStep={handleNextStep}
              apiLoader={apiLoader}
              next={next}
              handleClearData={handleEditProfileClearData}
            >
              <Text className="indexEditProfileText1">Personal</Text>
              <SimpleGrid gap="24px" mt="24px" p="0 16px 16px 16px">
                <GridItem>
                  <FormControl isInvalid={formData.imageError}>
                    <FormLabel className="personalFormLabel">
                      Profile Picture
                    </FormLabel>

                    <Avatar
                      height="96px"
                      width="96px"
                      name={formData?.name}
                      src={formData?.image}
                    />

                    <Flex gap="8px" color="#4299E1" alignItems="center">
                      <Image src={UPLOAD_ICON} />
                      <Input
                        onChange={handleFileUpload}
                        ref={inputFile}
                        type="file"
                        display="none"
                        accept="image/jpg, image/png, image/jpeg"
                      />
                      <span
                        onClick={onUploadImage}
                        style={{ cursor: "pointer", marginTop: "3px" }}
                      >
                        Edit Picture
                      </span>
                    </Flex>
                    <Box>
                      <Text color="#5A646B" className="personalImageValidators">
                        PNG, JPG files are suppported. Max file size: 5mb
                      </Text>
                      {formData.imageError && (
                        <Text
                          mt={0}
                          color="#BA1A1A"
                          className="personalImageValidators"
                        >
                          {formData.imageError}
                        </Text>
                      )}
                    </Box>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl isInvalid={noName}>
                    <FormLabel className="personalFormLabel">
                      Full Name
                    </FormLabel>
                    <InputGroup>
                      <Input
                        onChange={(e) =>
                          handleFormDataChange(e.target.name, e.target.value)
                        }
                        name="name"
                        value={formData?.name}
                        placeholder="Enter your full name"
                        maxLength={30}
                        border="1px solid #A6B8C4"
                        className="myProfileFormInput"
                      />
                    </InputGroup>
                    {noName && (
                      <FormErrorMessage className="personalErrorMessage">
                        Please enter your full name
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl isInvalid={noDob}>
                    <FormLabel className="personalFormLabel">
                      Date Of Birth
                    </FormLabel>
                    <InputGroup>
                      <Input
                        onChange={(e) =>
                          handleFormDataChange(e.target.name, e.target.value)
                        }
                        type="date"
                        name="dob"
                        value={formData?.dob}
                        id="dob"
                        placeholder="Enter your DOB"
                        className="myProfileFormInput"
                        border="1px solid #A6B8C4"
                        cursor="text"
                        max={new Date().toISOString().split("T")[0]}
                      />
                    </InputGroup>
                    {noDob && (
                      <FormErrorMessage className="personalErrorMessage">
                        Please enter your valid date of birth
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl isInvalid={noEmail}>
                    <FormLabel className="personalFormLabel">
                      Email Address
                    </FormLabel>
                    <InputGroup>
                      <Input
                        onChange={(e) =>
                          handleFormDataChange(e.target.name, e.target.value)
                        }
                        disabled
                        type="email"
                        placeholder="mailto:ankurtestexpert@gmail.com"
                        maxLength={60}
                        className="myProfileFormInput"
                        name="email"
                        value={formData?.email}
                        border="1px solid #A6B8C4"
                      />
                    </InputGroup>
                    {noEmail && (
                      <FormErrorMessage className="personalErrorMessage">
                        Please enter your email address
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </GridItem>
                <GridItem zIndex={50}>
                  <FormControl isInvalid={noNumber}>
                    <FormLabel className="personalFormLabel">
                      Phone Number
                    </FormLabel>
                    <InputGroup>
                      <InputLeftElement
                        sx={{
                          "& .form-control": {
                            border: "1px solid white",
                            display: "none",
                          },
                          "& .selected-flag": {
                            zIndex: 500,
                            backgroundColor: "white",
                            width: "82px",
                            padding: "0 0 0 25px",
                          },
                        }}
                        children={
                          <PhoneInput
                            name="countryCode"
                            onChange={(e) =>
                              handleFormDataChange("countryCode", e)
                            }
                            disabled
                            country={"in"}
                            value={formData?.countryCode}
                            className="myProfilCustomPhoneInput"
                          />
                        }
                      />
                      <Input
                        className="personalPhoneInput"
                        id="phoneInput2"
                        name="contact"
                        type="number"
                        disabled
                        value={formData?.contact}
                        placeholder="00000 00000"
                        onChange={(e) =>
                          handleFormDataChange(e.target.name, e.target.value)
                        }
                        border="1px solid #A6B8C4"
                      />
                    </InputGroup>
                    {noNumber && (
                      <FormErrorMessage className="personalErrorMessage">
                        Please enter your phone number
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </GridItem>
                <GridItem pb="24px" borderBottom="1px solid #D8E5EE">
                  <FormControl isInvalid={noRciNumber}>
                    <FormLabel className="personalFormLabel">
                      RCI Number
                    </FormLabel>
                    <InputGroup>
                      <Input
                        onChange={(e) =>
                          handleFormDataChange(e.target.name, e.target.value)
                        }
                        name="rciNumber"
                        value={
                          formData.rciNumber === "" ? "" : formData.rciNumber
                        }
                        placeholder="No RCI number provided"
                        maxLength={30}
                        border="1px solid #A6B8C4"
                        className="myProfileFormInput"
                      />
                    </InputGroup>
                    {noRciNumber && (
                      <FormErrorMessage className="personalErrorMessage">
                        Please enter your RCI Number
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </GridItem>
              </SimpleGrid>

              <Text className="indexEditProfileText1">Qualification</Text>
              {qualificationsData?.map((item, index) => (
                <Box
                  key={index}
                  className="indexEditProfileQualificationsDataMapBox"
                >
                  <Flex justify="space-between" align="center">
                    <Text className="indexEditProfileQualificationsDataMapBoxText">
                      Qualification {index + 1}
                    </Text>
                    {qualificationsData?.length > 1 && (
                      <Image
                        className="indexEditProfileQualificationsDataMapBoxImage"
                        src={DELETE_ICON}
                        onClick={() => handleDelete(item.id)}
                      />
                    )}
                  </Flex>
                  <SimpleGrid gap="16px" mt="16px">
                    <GridItem>
                      <FormControl isInvalid={item.noDegree}>
                        <FormLabel className="personalFormLabel">
                          Degree/certificate
                        </FormLabel>
                        <InputGroup>
                          <Input
                            onChange={(e) =>
                              handleQualificationsChange(
                                item.id,
                                e.target.name,
                                e.target.value
                              )
                            }
                            name="degree"
                            value={item.degree}
                            className="indexEditProfileQualificationsDataMapBoxInput"
                            placeholder="Mention degree or certificate"
                          />
                        </InputGroup>
                        {item.noDegree && (
                          <FormErrorMessage className="qualificationsErrorMessage">
                            This field cannot be empty
                          </FormErrorMessage>
                        )}
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl isInvalid={item.noField}>
                        <FormLabel className="personalFormLabel">
                          Field of study
                        </FormLabel>
                        <InputGroup>
                          <Input
                            onChange={(e) =>
                              handleQualificationsChange(
                                item.id,
                                e.target.name,
                                e.target.value
                              )
                            }
                            name="fieldOfStudy"
                            className="indexEditProfileQualificationsDataMapBoxInput"
                            value={item.fieldOfStudy}
                            placeholder="Field Of Study"
                          />
                        </InputGroup>
                        {item.noField && (
                          <FormErrorMessage className="qualificationsErrorMessage">
                            This field cannot be empty
                          </FormErrorMessage>
                        )}
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl isInvalid={item.noCertifyingOrganization}>
                        <FormLabel className="personalFormLabel">
                          Specialisation certifying organisation (Name of the
                          institute)
                        </FormLabel>
                        <InputGroup>
                          <Input
                            onChange={(e) =>
                              handleQualificationsChange(
                                item.id,
                                e.target.name,
                                e.target.value
                              )
                            }
                            name="certifyingOrganisation"
                            className="indexEditProfileQualificationsDataMapBoxInput"
                            value={item.certifyingOrganisation}
                            placeholder="Certifying Organisation"
                          />
                        </InputGroup>
                        {item.noCertifyingOrganization && (
                          <FormErrorMessage className="qualificationsErrorMessage">
                            This field cannot be empty
                          </FormErrorMessage>
                        )}
                      </FormControl>
                    </GridItem>
                  </SimpleGrid>
                </Box>
              ))}
              <Tag onClick={handleAdd} className="indexTag" borderRadius="full">
                <Avatar mr="8px" h="24px" w="24px" src={ADD_PLUS_ICON} />
                <TagLabel>Add qualification</TagLabel>
              </Tag>
            </OverlayComponent>
          )}
          <Grid
            maxW="100vw"
            overflowX="clip"
            position="sticky"
            zIndex="10"
            top="0"
            row
            className="layoutBanner"
          ></Grid>
          <Box px="72px" py="16px">
            <Box
              bg="white"
              zIndex="5"
              h="130px"
              w="full"
              pos="fixed"
              top="98px"
            ></Box>
            <Box
              bg="white"
              zIndex="1000"
              position="sticky"
              top="144px"
              id="firstBox-NameAndProfileEdit"
            >
              <Flex
                // flexDir={{ base: "column", lg: "row" }}
                justify="space-between"
                align={{ base: "start", lg: "center" }}
              >
                <Flex gap="16px">
                  <Avatar
                    className="myProfileIndexAvatar"
                    name={userProfile?.fullName}
                    src={userProfile?.imgUrl}
                  />
                  <Box>
                    <Text className="myProfileIndexText">
                      {userProfile?.fullName}
                    </Text>
                    <Flex mt="4px">
                      <Flex gap="4px" mr="8px">
                        <EmailIcon
                          style={{ color: "#4299E1" }}
                          className="myProfileIndexIcons"
                        />
                        <Text className="myProfileIndexIconsText">
                          {userProfile?.email}
                        </Text>
                      </Flex>
                      {/* <Text style={{ marginTop: "-5px" }}>.</Text> */}
                      <Flex gap="4px" ml="8px">
                        <PhoneIcon
                          style={{ color: "#8751D9" }}
                          className="myProfileIndexIcons"
                        />
                        <Text className="myProfileIndexIconsText">
                          {userProfile?.countryCode}
                          {userProfile?.phoneNumber}
                        </Text>
                      </Flex>
                    </Flex>
                  </Box>
                </Flex>
                <Flex
                  ml={{ base: "110px", lg: "0" }}
                  mb={{ base: "20px", lg: "0" }}
                  mt={{ lg: "-35px" }}
                  className="myProfileIndexFirstBoxButtonFlex"
                >
                  <Button
                    className="myProfileIndexFirstBoxButtonFlexBtns"
                    _hover={{ bg: "white" }}
                    onClick={() => {
                      setWhichOverlayToOpen("ApplyLeave");
                      setOverlayOpen(true);
                    }}
                    width="100%"
                  >
                    Apply Leave
                  </Button>
                  <Button
                    onClick={() => {
                      setWhichOverlayToOpen("EditProfile");
                      setOverlayOpen(true);
                    }}
                    className="myProfileIndexFirstBoxButtonFlexBtns"
                    _hover={{ bg: "white" }}
                    width="100%"
                  >
                    Edit Profile
                  </Button>
                </Flex>
              </Flex>
            </Box>
            <Box
              w="100vw"
              pos="sticky"
              top={{ base: "300px", lg: "240px" }}
              style={{
                boxShadow: "0px 1px 2px 1px rgba(0, 0, 0, 0.16)",
                transform: "translateX(-72px)",
              }}
              bg="white"
              zIndex={1000}
            ></Box>
            <Flex
              id="secondBox-DynamicSteps"
              flexDir={{ base: "column", lg: "row" }}
            >
              <Flex
                className="myProfileIndexSecondBoxMainFlexContainer"
                minW={{ base: "full", lg: "288px" }}
                flexDir={{ base: "row", lg: "column" }}
                gap={{ base: "20px", lg: "16px" }}
                id="left-side"
                h="full"
                // zIndex={100}
                bg="white"
                position="sticky"
                top={{ base: "300px", lg: "240px" }}
              >
                {tabs.map((item) => (
                  <Flex key={item.id}>
                    <Text
                      className={
                        item.id === activeStep
                          ? "myProfileIndexSecondBoxFirstMapText activeTab"
                          : "myProfileIndexSecondBoxFirstMapText inActiveTab"
                      }
                      w="full"
                      _hover={{ cursor: "pointer" }}
                      rounded={{ base: "none", md: "full" }}
                      onClick={() => setActiveStep(item.id)}
                    >
                      {item.id}
                    </Text>
                  </Flex>
                ))}
              </Flex>
              <Box
                sx={{
                  "& .chakra-form__required-indicator": {
                    color: "black",
                  },
                }}
                // zIndex={-10}
                w="full"
                id="right-side"
              >
                {tabs.map((item) => (
                  <Box key={item.id}>
                    {activeStep === item.id && item.value}
                  </Box>
                ))}
              </Box>
            </Flex>
          </Box>
        </Show>
      </AuthenticatedContainer>
    </Box>
  );
};

export default MyProfile;

import { Box, Button, Flex, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Toaster } from "../../../../common/components/Toaster";
import { changeOutcomeStatusService } from "../../../../modules/services";
import {
  getChildPathway,
  toggleAPILoader,
  toggleMainLoader,
} from "../../../../modules/actions";

const SingleCheckpointDataIndividualTaskOverlay = ({
  setShowOverlay,
  checkpointsData,
  setCheckpointsData,
  selectedTask,
  selectedCheckpoint,
  checkpointOptions,
  setCheckpointOptions,
  selectedChild,
  outcome,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const status =
      checkpointsData[selectedCheckpoint - 1].outcomes[selectedTask - 1].status;

    if (status) {
      const newOptions = checkpointOptions.map((option) => {
        if (option.title === status) {
          option.selected = true;
        } else {
          option.selected = false;
        }
        return option;
      });

      setCheckpointOptions(newOptions);
    }
  }, [checkpointsData, selectedCheckpoint, selectedTask]);

  const changeObjectiveStatus = (status) => {
    setCheckpointsData((prev) =>
      prev?.map((cd) => ({
        ...cd,
        checkpointItems: cd.checkpointItems.map((ci) =>
          ci.id === selectedTask
            ? {
                ...ci,
                completed: status,
              }
            : ci
        ),
      }))
    );

    setShowOverlay(false);

    // setCheckpointOptions((prev) =>
    //   prev?.map((co) => (co.selected ? { ...co, selected: false } : co))
    // );
    const newOptions = checkpointOptions.map((option) => {
      if (option.title === status) {
        option.selected = true;
      } else {
        option.selected = false;
      }
      return option;
    });

    setCheckpointOptions(newOptions);

    // make complete checkpoint active logic
    // keep the current active checkpoint or the current start checkpoint active toggled open

    const data = {
      userId: selectedChild.id,
      status: status,
      id: outcome.id,
    };

    dispatch(toggleAPILoader(true));
    changeOutcomeStatusService(data, selectedCheckpoint)
      .then((res) => {
        Toaster(res.data.message, "success", "top-left");
        dispatch(toggleAPILoader(false));
        dispatch(toggleMainLoader(true));
        dispatch(getChildPathway(selectedChild?.id));
      })
      .catch((error) => {
        console.log(error);
        dispatch(toggleAPILoader(false));
        Toaster(error.response.data.message, "error", "top-left");
      });
  };

  return (
    <>
      <Box
        onClick={() => setShowOverlay(false)}
        className="editHideOverlayBox"
      ></Box>
      <Box className="checkpointTaskOverlayBox">
        {checkpointOptions?.map(
          (co) =>
            !co.selected && (
              <Box key={co.title} mt="12px">
                <Button
                  style={{
                    backgroundColor:
                      (co.title === "Almost" && "#f3ebff") ||
                      (co.title === "Yes" && "#eff7e6") ||
                      (co.title === "No" && "#ffdad6"),
                    color:
                      (co.title === "Almost" && "#2f0d63") ||
                      (co.title === "Yes" && "#024742") ||
                      (co.title === "No" && "#410002"),
                  }}
                  borderRadius="10px"
                  px="12px"
                  py="12px"
                  onClick={() => changeObjectiveStatus(co.title)}
                >
                  {co.title}
                </Button>
                <Text
                  fontSize="12px"
                  mt="8px"
                  fontWeight="400"
                  lineHeight="16px"
                  color="#3d464d"
                >
                  {co.desc}
                </Text>
              </Box>
            )
        )}
      </Box>
    </>
  );
};

export default SingleCheckpointDataIndividualTaskOverlay;

import { ModalBody, ModalHeader } from "@chakra-ui/react";

const PopUpDetails = ({ title, message, session, type }) => {
  return (
    <>
      <ModalHeader className="popupHeader">{title}</ModalHeader>
      <ModalBody className="popupBody">
        Session between {session.sessionStudentName} and{" "}
        {session.sessionExpertName} will be {type} and{" "}
        <span style={{ color: "#BA1A1A" }}>{message}</span>
      </ModalBody>
    </>
  );
};
export default PopUpDetails;

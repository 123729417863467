import {
  Modal,
  GridItem,
  ModalContent,
  ModalOverlay,
  Image,
  ModalFooter,
  Button,
} from "@chakra-ui/react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { BACK_CARET, NEXT_CARET } from "../../constant";

const SessionsGoToPopup = ({
  isOpen,
  onClose,
  handleCalendarChange,
  calenderStartDate,
  handleGoNow,
}) => {
  return (
    <Modal
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      // closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent borderRadius="16px">
        <GridItem>
          <Calendar
            name="startDate"
            prevLabel={<Image src={BACK_CARET} />}
            nextLabel={<Image src={NEXT_CARET} />}
            calendarType="US"
            prev2Label={null}
            next2Label={null}
            view="month"
            className="react-calender-border"
            onChange={handleCalendarChange}
            value={calenderStartDate}
          />
        </GridItem>
        <ModalFooter
          mt="16px"
          borderTop="1px solid #a6b8c4"
          justifyContent="space-between"
        >
          <Button
            bg="white"
            mr="8px"
            border="1px solid #a6b8c4"
            onClick={() => {
              handleGoNow("Today");
              onClose();
            }}
            className="modalButtons2"
          >
            Today
          </Button>
          <Button
            _hover={{
              bg: "#4299E1",
              color: "#FFFFFF",
            }}
            className="modalButtons2"
            bg="#4299E1"
            color="#FFFFFF"
            onClick={() => {
              handleGoNow("Gonow");
              onClose();
            }}
          >
            Go Now
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
export default SessionsGoToPopup;

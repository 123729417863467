import { useState, useEffect } from "react";
import { Box, Flex, Tag, Text, TagLabel, Avatar, Show } from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { Select } from "chakra-react-select";
import { monthOptions } from "../../../../constant/selectOptions";
import {
  getCurrentMonthName,
  getCurrentYearValue,
  getDayNumber1,
  getMonthName1,
  getCurrentYear,
} from "../../../../utils/customMoment";
import {
  ATTENDED,
  SCHEDULED,
  CANCELLED,
  RESCHEDULED,
  PARTIALLY_ATTENDED,
  WALLET,
} from "../../../../constant/images";
import { getExpertTransactions } from "../../../../modules/actions";
import Loader from "../../../../common/components/Loader";
import { getPreviousYearsArray } from "../../../../utils/customHooks";

const ParentTransactions = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.profile);
  const transactions = useSelector((state) => state?.transactions?.data);
  const apiLoader = useSelector((state) => state?.loader.apiLoader);
  const [selectedData, setSelectedData] = useState({
    month: getCurrentMonthName(),
    year: getCurrentYearValue(),
  });

  const selectStyles = {
    control: (styles) => ({
      ...styles,
      border: "none",
      outline: "none",
      borderRadius: "10px",
      boxShadow: "none",
      width: "full",
      // padding: '0px 12px',
      cursor: "pointer",
    }),
    dropdownIndicator: (prev, { selectProps: { menuIsOpen } }) => ({
      ...prev,
      "> svg": {
        transitionDuration: "normal",
        transform: `rotate(${menuIsOpen ? -180 : 0}deg)`,
        color: "#4299E1",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? "#E9F6FF"
          : isFocused
          ? "#F3F8FB"
          : undefined,
        color: isDisabled ? "#ccc" : isSelected ? "#4299E1" : "#141719",
        padding: "10px",
        cursor: isDisabled ? "not-allowed" : "pointer",
        fontFamily: "Inter",
        fontSize: "14px",
        lineHeight: "20px",
        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? "#E9F6FF"
              : "#E9F6FF"
            : undefined,
        },
      };
    },
    singleValue: (styles) => ({
      ...styles,
      color: "#141719",
      fontWeight: "600",
    }),
    menuList: (styles) => ({
      ...styles,
      borderRadius: "8px",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.16)",
    }),
  };

  useEffect(() => {
    dispatch(getExpertTransactions(selectedData.month, selectedData.year));
  }, [selectedData]);

  const handleCurrentDatesChange = (name, value) => {
    setSelectedData({ ...selectedData, [name]: value });
  };

  const dateHeads = transactions?.transactions
    ? Object.keys(transactions?.transactions)
    : [];

  return (
    <Box p={{ base: "24px 0px", md: "24px 16px" }}>
      <Box className="pathwayHeading">Transactions</Box>
      <Box className="pathwaySubHeading" w={{ md: "640px", sm: "100%" }}>
        Record of transactions on sessions, based on status of your connect with
        the consultant.
        {/* Know more about transactions in transactions policy */}
      </Box>
      <Box className="sesssionBalanceDiv">
        <Text className="balanceTitle">Your current sessions balance</Text>
        <Text className="balanceCountText">
          {user?.currentBalance?.toString().indexOf(".") === -1
            ? Number(user?.currentBalance)
            : Number(user?.currentBalance).toFixed(2)}
        </Text>
      </Box>
      {apiLoader ? (
        <Loader text="Loading, Please Wait..." />
      ) : (
        <>
          <Flex mt="16px" gap="16px">
            <Select
              useBasicStyles
              options={monthOptions}
              className="txnsTabSelect"
              value={{ label: selectedData.month, value: selectedData.month }}
              chakraStyles={selectStyles}
              name="month"
              onChange={(e) => handleCurrentDatesChange("month", e.value)}
            />
            <Select
              useBasicStyles
              chakraStyles={selectStyles}
              className="txnsTabSelect"
              value={{ label: selectedData.year, value: selectedData.year }}
              name="year"
              options={getPreviousYearsArray()}
              onChange={(e) => handleCurrentDatesChange("year", e.value)}
            />
          </Flex>
          <Box>
            {dateHeads.map((item, index) => (
              <Box key={index}>
                <Text className="txnsFirstMapBoxText">
                  {getDayNumber1(item) +
                    " " +
                    getMonthName1(item) +
                    ", " +
                    getCurrentYear(item)}
                </Text>
                {transactions?.transactions[item].map(
                  (singleSession, index) => (
                    <Flex key={index} className="txnsSecondMapFlex">
                      <Box>
                        <Flex align="center">
                          <Text className="txnsSecondMapFlexBoxFlexText1">
                            {singleSession?.transactionType ===
                            "renewalTransaction"
                              ? "+" + Number(singleSession?.sessions)
                              : Number(singleSession?.transactionValue).toFixed(
                                  2
                                )}
                          </Text>
                          <Text className="txnsSecondMapFlexBoxFlexText2">
                            {singleSession?.transactionType}
                          </Text>
                          <Show breakpoint="(min-width:370px)">
                            {singleSession?.transactionType ===
                              "renewalTransaction" && (
                              <Text
                                className="txnsSecondMapFlexBoxFlexText2"
                                bg={
                                  singleSession?.paymentStatus === "failure"
                                    ? "#FFDAD6"
                                    : "#B6EFA0"
                                }
                                color={
                                  singleSession?.paymentStatus === "failure"
                                    ? "#410002"
                                    : "#024742"
                                }
                              >
                                {singleSession?.paymentStatus}
                              </Text>
                            )}
                          </Show>
                          {/* <Show breakpoint="(max-width:767px)">
                            {singleSession?.transactionType ===
                              "renewalTransaction" && (
                              <Text
                                className="txnsSecondMapFlexBoxFlexText2"
                                bg={
                                  singleSession?.paymentStatus === "failure"
                                    ? "#FFDAD6"
                                    : "#B6EFA0"
                                }
                                color={
                                  singleSession?.paymentStatus === "failure"
                                    ? "#410002"
                                    : "#024742"
                                }
                              >
                                {singleSession?.paymentStatus}
                              </Text>
                            )}
                          </Show> */}
                        </Flex>
                        <Flex
                          className="txnsSecondMapFlexBoxFlexFlex1"
                          flexDirection={{ base: "column", md: "row" }}
                        >
                          {singleSession?.transactionType ===
                          "renewalTransaction" ? (
                            <Text mb={0}>
                              Rs {singleSession?.amount} /{" "}
                              {singleSession?.validity} days
                            </Text>
                          ) : (
                            <Text mb={0}>
                              {user?.childName} /{" "}
                              {singleSession?.sessionExpertName}
                            </Text>
                          )}

                          <Text
                            mt="-3px"
                            mb={0}
                            display={{ base: "none", md: "block" }}
                          >
                            .
                          </Text>
                          <Text mb={0}>{singleSession?.reason}</Text>
                        </Flex>
                      </Box>
                      <Tag
                        className="txnsSecondMapFlexBoxTag"
                        style={{
                          backgroundColor:
                            ((singleSession?.session?.status ===
                              "Successfull" ||
                              singleSession?.session?.status ===
                                "Partially Attended") &&
                              "#EFF7E6") ||
                            (singleSession?.session?.status === "Cancelled" &&
                              "#FFDAD6") ||
                            ((singleSession?.session?.status === "Scheduled" ||
                              singleSession?.reason ===
                                "Scheduled by Parent") &&
                              "#F3EBFF") ||
                            (singleSession?.transactionType ===
                              "renewalTransaction" &&
                              "#E9F6FF"),
                        }}
                      >
                        <Avatar
                          className="txnsSecondMapFlexBoxTagAvatar"
                          src={
                            (singleSession?.session?.status === "Successfull" &&
                              ATTENDED) ||
                            (singleSession?.session?.status ===
                              "Partially Attended" &&
                              PARTIALLY_ATTENDED) ||
                            (singleSession?.session?.status === "Cancelled" &&
                              CANCELLED) ||
                            ((singleSession?.session?.status === "Scheduled" ||
                              singleSession?.reason ===
                                "Scheduled by Parent") &&
                              SCHEDULED) ||
                            (singleSession?.transactionType ===
                              "renewalTransaction" &&
                              WALLET)
                          }
                        />
                        <TagLabel
                          style={{
                            color:
                              ((singleSession?.session?.status ===
                                "Successfull" ||
                                singleSession?.session?.status ===
                                  "Partially Attended") &&
                                "#024742") ||
                              (singleSession?.session?.status === "Cancelled" &&
                                "#410002") ||
                              ((singleSession?.session?.status ===
                                "Scheduled" ||
                                singleSession?.reason ===
                                  "Scheduled by Parent") &&
                                "#2F0D63") ||
                              (singleSession?.transactionType ===
                                "renewalTransaction" &&
                                "#1A365D"),
                          }}
                        >
                          {singleSession?.transactionType ===
                          "renewalTransaction"
                            ? "Purchase"
                            : singleSession?.reason === "Scheduled by Parent"
                            ? "Scheduled"
                            : singleSession?.session?.status}
                        </TagLabel>
                      </Tag>
                      <Show breakpoint="(max-width:369px)">
                        {singleSession?.transactionType ===
                          "renewalTransaction" && (
                          <Text
                            className="txnsSecondMapFlexBoxFlexText2"
                            bg={
                              singleSession?.paymentStatus === "failure"
                                ? "#FFDAD6"
                                : "#B6EFA0"
                            }
                            color={
                              singleSession?.paymentStatus === "failure"
                                ? "#410002"
                                : "#024742"
                            }
                          >
                            {singleSession?.paymentStatus}
                          </Text>
                        )}
                      </Show>
                    </Flex>
                  )
                )}
              </Box>
            ))}
          </Box>
        </>
      )}
    </Box>
  );
};
export default ParentTransactions;

import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";

const ConfirmationPopup = ({
  isOpen,
  onClose,
  title,
  handleSecondaryButtonClick,
  body,
  btnText1,
  btnText2,
}) => {
  return (
    <Modal
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent mx="16px">
        <ModalHeader className="modalHeader">{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{body}</ModalBody>

        <ModalFooter mt="16px" borderTop="1px solid #a6b8c4">
          <Button
            bg="white"
            color="#BA1A1A"
            mr="8px"
            border="1px solid #a6b8c4"
            onClick={() => handleSecondaryButtonClick()}
            className="modalButtons2"
          >
            {btnText1}
          </Button>
          <Button
            _hover={{
              bg: "#4299E1",
              color: "#FFFFFF",
            }}
            className="modalButtons2"
            bg="#4299E1"
            color="#FFFFFF"
            onClick={onClose}
          >
            {btnText2}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmationPopup;

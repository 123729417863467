import { useEffect, useState } from "react";
import { Box, Text, Image, Flex, Grid, GridItem } from "@chakra-ui/react";
import {
  getWeekNo,
  getDayNumber1,
  getMonthName1,
  getDayName1,
} from "../../../../utils/customMoment";
import {
  CARET_CIRCLE_LEFT,
  CARET_CIRCLE_RIGHT,
  INFO_GRAY,
  BLUE_CALENDER,
} from "../../../../constant";

const SlotDetails = ({
  weeklyChoosenSlots,
  openSlots,
  setWeeklyChoosenSlots,
}) => {
  const [weeklySlots, setWeeklySlots] = useState([]);
  let [index, setIndex] = useState({ startIndex: 0, endIndex: 6 });
  //   let [endIndex, setEndIndex] = useState(6);

  useEffect(() => {
    let currentWeeklySlots = [];
    openSlots.forEach((slot) => {
      //inserting an additional field isSelected for select-deselect ui
      slot.openSlots.forEach(function (element) {
        element.isSelected = element.isSelected ? element.isSelected : false;
      });
      //inserting data for a week
      if (
        index.startIndex <= openSlots.indexOf(slot) &&
        openSlots.indexOf(slot) <= index.endIndex
      ) {
        currentWeeklySlots.push(slot);
      }
    });
    setWeeklySlots(currentWeeklySlots);
  }, [openSlots, index]);

  //Weekly pagination of the slots
  const onClickPreviousWeek = () => {
    if (index.startIndex > 0) {
      let endNo = index.startIndex - 1;
      let startNo = endNo - 6;
      setIndex({
        startIndex: startNo,
        endIndex: endNo,
      });
    }
  };

  const onClickNextWeek = () => {
    if (index.endIndex < openSlots.length - 1) {
      let startNo = index.endIndex + 1;
      let endNo = startNo + 6;
      setIndex({
        startIndex: startNo,
        endIndex: endNo,
      });
    }
  };

  const handleSlotSelection = (date, slot) => {
    let currentWeeklySlots = [...weeklySlots];
    let choosenSlot = {};
    currentWeeklySlots.map((dates) => {
      if (dates.date === date) {
        dates.openSlots.map((slotItem) => {
          if (slotItem.label === slot.label) {
            slotItem.isSelected = !slotItem.isSelected;
            choosenSlot = {
              date: dates.date,
              slotStartTime: slot.value,
              slotDuration: 60,
              label: slot.label,
            };
          }
        });
      }

      if (Object.keys(choosenSlot).length > 0) {
        const found = weeklyChoosenSlots.some(
          (el) => el.date === choosenSlot.date && el.label === choosenSlot.label
        );
        const index = weeklyChoosenSlots.findIndex(
          (p) => p.date === choosenSlot.date && p.label === choosenSlot.label
        );

        if (found) {
          let newSlot = weeklyChoosenSlots;
          newSlot.splice(index, 1);
          setWeeklyChoosenSlots([...newSlot]);
          choosenSlot = {};
        } else {
          setWeeklyChoosenSlots([...weeklyChoosenSlots, choosenSlot]);
        }
      }
    });

    setWeeklySlots(currentWeeklySlots);
  };

  return (
    <Box>
      <Flex className="weekHeading">
        <Image
          src={CARET_CIRCLE_LEFT}
          onClick={index.startIndex > 0 ? () => onClickPreviousWeek() : null}
          opacity={index.startIndex > 0 ? 1 : 0}
          cursor="pointer"
          height="32px"
          width="32px"
        />
        <Text>Week {getWeekNo(weeklySlots[0]?.date)}</Text>

        <Image
          src={CARET_CIRCLE_RIGHT}
          onClick={
            index.endIndex < openSlots.length - 1
              ? () => onClickNextWeek()
              : null
          }
          opacity={index.endIndex < openSlots.length - 1 ? 1 : 0}
          cursor="pointer"
          height="32px"
          width="32px"
        />
      </Flex>
      {weeklySlots.map((dates) => {
        return (
          <>
            <Flex mb="18px">
              <Text className="sessionDateNo">{getDayNumber1(dates.date)}</Text>
              <Text className="monthDayText">
                {getMonthName1(dates.date) + " "}-
                {" " + getDayName1(dates.date)}
              </Text>
            </Flex>
            <Text className="availableSlotText">
              {dates.openSlots.length === 0
                ? "No slots available on this day"
                : "Select available slots"}
            </Text>

            <Grid
              templateColumns="repeat(12, 1fr)"
              gap={4}
              mb="16px"
              className="slotsDivMain"
            >
              {dates.openSlots.length > 0 &&
                dates.openSlots.map((slot, index) => (
                  <GridItem
                    key={index}
                    className="slotsDiv"
                    colSpan={4}
                    bg={slot.isSelected ? "#E9F6FF" : "#ffffff"}
                    onClick={() => handleSlotSelection(dates.date, slot)}
                  >
                    {slot.label}
                  </GridItem>
                ))}
            </Grid>
          </>
        );
      })}

      <Flex className="slotInfoDiv">
        <Image src={INFO_GRAY} />
        <Box>
          <Text className="slotsInfoText">
            We recommend you to book 2 sessions per week
          </Text>
          <Text className="selectedSlotText">
            You have Selected {weeklyChoosenSlots.length} slots across weeks.
          </Text>
          {weeklyChoosenSlots.map((dates, index) => (
            <Flex mt="16px" key={index}>
              <Image src={BLUE_CALENDER} />
              <Text className="slotText">
                {getDayNumber1(dates.date) + " " + getMonthName1(dates.date)} -
                {dates.label}
              </Text>
            </Flex>
          ))}
        </Box>
      </Flex>
    </Box>
  );
};
export default SlotDetails;

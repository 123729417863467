import { Box, Button, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import SingleNote from "../utils/singleNote";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  getStudentsNotesAction,
  writeStudentsNotesAction,
} from "../../../../modules/actions";
import Loader from "../../../../common/components/Loader";
const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "mention",
  "emoji",
];
const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ list: "ordered" }, { list: "bullet" }],
    ["bold", "italic", "underline"],
    [{ color: [] }, { background: [] }],
    // [{ script: 'sub' }, { script: 'super' }],
    [{ align: [] }],
    ["link", "blockquote", "emoji"],
    ["clean"],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};
const Notes = ({ selectedChild }) => {
  const dispatch = useDispatch();

  const { notes, profile } = useSelector((state) => state?.user);
  const loaderState = useSelector((state) => state?.loader?.apiLoader);

  const [chat, setChat] = useState([]);

  const handleDateFormat = (dateString) => {
    const dateArray = String(new Date(dateString * 1000)).split(" ");
    return `${dateArray[2]} ${dateArray[1]}, ${dateArray[3]}`;
  };

  useEffect(() => {
    const createChatData = () => {
      if (notes === null || notes?.length === 0) return;
      const updatedChatData = notes?.map((n) => {
        return {
          id: n?.id,
          msg: n?.content,
          date: handleDateFormat(n?.createdOn?._seconds),
          by: n?.expertName || "Anonymous",
        };
      });
      setChat(updatedChatData);
    };
    createChatData();
  }, [notes]);

  useEffect(() => {
    dispatch(getStudentsNotesAction(selectedChild?.id));
  }, [dispatch, selectedChild]);

  const [rteValue, setRteValue] = useState("");
  const [showToolbar, setShowToolbar] = useState("none");
  const [showContainerBottomBorder, setShowContainerBottomBorder] =
    useState("1px solid #a6b8c4");

  const handleSaveNote = () => {
    const dataObject = {
      content: rteValue,
      parentId: selectedChild?.id,
      expertName: profile?.name,
    };
    dispatch(writeStudentsNotesAction(dataObject, selectedChild?.id));
    setRteValue("");
  };

  return (
    <Box id="mainWrapper" px="16px" py="24px">
      <Text className="txnsTabText1">Notes</Text>
      <Text className="txnsTabText2" lineHeight="24px">
        Notes about the child shared by you or other therapists.
      </Text>
      <Box
        pos="relative"
        sx={{
          "& .ql-toolbar": {
            display: showToolbar,
          },
          "& .ql-toolbar-button": {
            display: showToolbar,
          },
          "& .ql-container": {
            borderBottom: showContainerBottomBorder,
          },
          "& .ql-editor": {
            width: "calc(100vh - 10px)",
          },
        }}
      >
        <ReactQuill
          style={{ marginTop: "24px" }}
          theme="snow"
          formats={formats}
          // modules={modules}
          placeholder="Type Here..."
          value={rteValue}
          onBlur={() => {
            setShowToolbar("none");
            setShowContainerBottomBorder("1px solid #a6b8c4");
          }}
          onFocus={() => {
            setShowToolbar("block");
            setShowContainerBottomBorder("none");
          }}
          onChange={setRteValue}
        />
        <Button
          className="ql-toolbar-button notesSaveBtn"
          onClick={handleSaveNote}
          isDisabled={
            rteValue === "<p><br></p>" || rteValue === "" ? true : false
          }
        >
          Save
        </Button>
      </Box>
      {loaderState ? (
        <Loader text={"Loading notes...."} />
      ) : chat?.length > 0 ? (
        <>
          {chat?.map((c) => (
            <SingleNote
              key={c.id}
              setChat={setChat}
              chat={chat}
              c={c}
              childId={selectedChild?.id}
              parentId={selectedChild?.id}
              expertName={profile?.name}
            />
          ))}
        </>
      ) : // <Text color="#141719" mt={10} fontSize="20px">
      //   You don't have any notes yet.
      // </Text>
      null}
    </Box>
  );
};

export default Notes;

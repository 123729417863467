import { Box, Spinner, Text } from "@chakra-ui/react";
import "./loader.css";

const Loader = ({ text }) => {
  return (
    <Box className="loaderMainDiv">
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="#F3F8FB"
        color="#4299E1"
        size="xl"
      />
      <Text className="loaderText">{text}</Text>
    </Box>
  );
};
export default Loader;

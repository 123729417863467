import { Box, Flex, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import SingleSubgoalTemplate from "./singleSubgoalTemplate";

const SingleNavbarTabsTemplate = ({
  navbarContent,
  navbarName,
  changedLocalstorage,
  setChangedLocalstorage,
  domainContent,
  pathway,
  handleSelectedOutcomeCounter,
  checkpointOutcomes,
  setCheckpointOutcomes,
}) => {
  const [activeStep, setActiveStep] = useState(null);
  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    const formatTabs = () => {
      if (navbarContent === null) return;
      let newTabs = navbarContent?.goals?.map((goal, index) => {
        return {
          name: goal?.name,
          id: goal?.id,
          orderBy: goal?.orderBy ? goal?.orderBy : index,
          domainId: goal?.domainId,
          subgoalIds: goal?.subGoalIds,
          domainName: domainContent.name,
          domainIcon: domainContent.icon,
          domainDescription: domainContent.description,
        };
      });

      newTabs = newTabs.sort((a, b) => a.orderBy - b.orderBy);
      setActiveStep(newTabs[0]?.id);
      setTabs(newTabs);
    };
    formatTabs();
  }, [navbarContent]);

  return (
    <Box>
      <Flex id="secondBox-DynamicSteps" flexDir={{ base: "column", lg: "row" }}>
        <Flex
          className="myProfileIndexSecondBoxMainFlexContainer"
          minW={{ base: "100vw", lg: "208px", xl: "288px" }}
          maxW={{ lg: "288px" }}
          flexDir={{ base: "row", lg: "column" }}
          gap={{ base: "5px", lg: "16px" }}
          id="left-side"
          h="full"
          ml="72px"
          // zIndex={100}
          bg="white"
          position="sticky"
          top={{ base: "204.9px", lg: "204px" }}
        >
          <Text
            fontWeight="500"
            display={{ base: "none", lg: "block" }}
            fontSize="14px"
            lineHeight="20px"
            color="#141719"
          >
            Goals
          </Text>
          {tabs.map((item) => (
            <Flex key={item?.id}>
              <Text
                w={{ base: "max-content", lg: "full" }}
                fontSize={{ base: "10px", lg: "14px" }}
                fontWeight={{ base: "500", lg: "600" }}
                lineHeight={{ lg: "20px" }}
                letterSpacing={{ lg: "0.1px" }}
                p={{ base: "5px 7px", lg: "10px 16px" }}
                _hover={{ cursor: "pointer" }}
                rounded="full"
                style={{
                  backgroundColor:
                    item?.id === activeStep ? "#E9F6FF" : "white",
                  border:
                    item?.id === activeStep ? "#E9F6FF" : "1px solid #A6B8C4",
                  color: item?.id === activeStep ? "#2b6cb0" : "#5a646b",
                }}
                onClick={() => setActiveStep(item?.id)}
              >
                {item?.name}
              </Text>
            </Flex>
          ))}
        </Flex>
        <Box
          sx={{
            "& .chakra-form__required-indicator": {
              color: "black",
            },
          }}
          borderLeft="1px solid #d8e5ee"
          // zIndex={-10}
          mb="75px"
          w="full"
          id="right-side"
        >
          {tabs.map((item) => (
            <Box key={item.id}>
              {activeStep === item.id && (
                <SingleSubgoalTemplate
                  props={item}
                  navbarName={navbarName}
                  changedLocalstorage={changedLocalstorage}
                  setChangedLocalstorage={setChangedLocalstorage}
                  pathway={pathway}
                  handleSelectedOutcomeCounter={handleSelectedOutcomeCounter}
                  checkpointOutcomes={checkpointOutcomes}
                  setCheckpointOutcomes={setCheckpointOutcomes}
                />
              )}
            </Box>
          ))}
        </Box>
      </Flex>
    </Box>
  );
};

export default SingleNavbarTabsTemplate;

import { call, put } from "redux-saga/effects";
import * as actionCreators from "../actions";
import * as serviceCreator from "../services";
import { Toaster } from "../../common/components/Toaster";

export function* getPurchasePlansSagaCall(action) {
  try {
    const response = yield call(serviceCreator.getPurchasePlansService);
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.savePurchasePlans(response.data.data));
      yield put(actionCreators.toggleMainLoader(false));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      Toaster(response.data.message, "error");
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    Toaster(error.response.data.message, "error");
  }
}

import { Box, Flex, Image } from "@chakra-ui/react";
import React, { useState } from "react";
import { PLAY } from "../../../constant";

const VideoPlayer = ({ v, index }) => {
  const [playing, setPlaying] = useState(false);

  function pausePlayHandler(index) {
    const videoPlayer = document.querySelectorAll(".video-player");
    if (videoPlayer[index].paused) {
      videoPlayer[index].play();
      setPlaying(true);
    } else {
      videoPlayer[index].pause();
      setPlaying(false);
    }
  }

  return (
    <>
      <Box pos="relative" w="342px" height="202px">
        <Flex
          justify="center"
          align="center"
          hidden={playing}
          zIndex={playing ? 0 : 5}
          id="play-btn"
          h="full"
          pos="absolute"
          w="full"
        >
          <Image
            onClick={() => pausePlayHandler(index)}
            cursor="pointer"
            src={PLAY}
          />
        </Flex>
        <video
          controls={playing}
          onPause={() => setPlaying(false)}
          className="video-player"
          style={{ zIndex: 4 }}
          src={v}
        ></video>
      </Box>
    </>
  );
};

export default VideoPlayer;

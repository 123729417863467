import * as ActionTypes from "./actionTypes";

export const getPurchasePlans = () => ({
  type: ActionTypes.GET_PURCHASE_PLANS,
});

export const savePurchasePlans = (data) => ({
  type: ActionTypes.SAVE_PURCHASE_PLANS,
  data,
});

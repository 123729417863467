import axios from "../../axios";
import * as url from "../../utils/urls";

export const getUserProfileService = () => {
  return axios.get(`${url.getUserProfileUrl}`, null);
};

export const saveUserProfileService = (step, data) => {
  return axios.post(`${url.saveUserProfileUrl}${step}`, data);
};

export const saveUserProfileImageService = (data) => {
  return axios.post(`${url.saveUserProfileImageUrl}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const getTherapistSessionService = (data) => {
  let reqParams = "?startDate=" + data.startDate;
  if (data.endDate) {
    reqParams = reqParams + "&endDate=" + data.endDate;
  }
  if (data.status) {
    reqParams = reqParams + "&status=" + data.status;
  }
  return axios.get(`${url.getTherapistSessionsUrl + reqParams}`, null);
};

export const getExpertTransactionsService = (month, year) => {
  return axios.get(`${url.getExpertTransactions}month=${month}&year=${year}`);
};

export const getExpertLeavesService = (status, data) => {
  return axios.get(`${url.getExpertLeaves}${status}`, data);
};

export const getParentCustomTokenService = (token) => {
  const config = { headers: { token: token } };
  return axios.get(`${url.getParentCustomTokenUrl}`, config);
};

export const getChildPathwayService = (parentId) => {
  return axios.get(`${url.getChildPathwayUrl}?parentId=${parentId}`);
};
export const getAllStudentsDataService = async () => {
  return axios.get(`${url.getAllStudentsUrl}`);
};

export const getStudentsPathwayService = async (parentID) => {
  return axios.get(`${url.getStudentsPathwayUrl}${parentID}`);
};

export const getCreatePathwayDomainGoalsService = async () => {
  return axios.get(`${url.getCreatePathwayDomainGoalsUrl}`);
};

export const getSubGoalItemsService = (id) => {
  return axios.get(`${url.getCreatePathwaySubGoalUrl}${id}`);
};

export const getParentSessionHistoryService = (id) => {
  return axios.get(`${url.getParentSessionUrl}${id}`);
};

export const getParentJournalService = (id, month, year) => {
  return axios.get(
    `${url.getParentJournalUrl}userId=${id}&month=${month}&year=${year}`
  );
};

export const getStudentsNotesService = (id) => {
  return axios.get(`${url.getStudentNotesUrl}${id}`);
};

export const addStudentsNotesService = (data) => {
  return axios.post(`${url.writeStudentNotesUrl}`, data);
};

export const deleteStudentsNotesService = (data) => {
  return axios.delete(`${url.deleteStudentNotesUrl}${data}`);
};

export const updateStudentsNotesService = (noteId, data) => {
  return axios.patch(`${url.updateStudentNotesUrl}${noteId}`, data);
};

export const getStudentsDomainsService = (data) => {
  return axios.get(`${url.getChildDomainsUrl}${data}`);
};

export const addParentOutcomeStatusService = (data) => {
  return axios.post(`${url.addParentOutcomeStatusUrl}`, data);
};

export const applyLeavesService = (data) => {
  return axios.post(`${url.applyLeaves}`, data);
};

export const editUserProfileService = (data) => {
  return axios.patch(`${url.editUserProfile}`, data);
};

export const createPathwayService = (data) => {
  return axios.post(`${url.createPathwayUrl}`, data);
};

export const startCheckpointService = (data) => {
  return axios.post(
    `${url.startCheckpointUrl}${data.checkpointId}?userId=${data.userId}`
  );
};

export const changeOutcomeStatusService = (data, checkpointId) => {
  return axios.patch(`${url.changeOutcomeStatusUrl}${checkpointId}`, data);
};

export const completeCheckpointService = (data) => {
  return axios.patch(
    `${url.completeCheckpointUrl}${data.checkpointId}?userId=${data.userId}`
  );
};

export const getDomainResourcesService = (data) => {
  return axios.get(`${url.getDomainResourcesUrl}${data}`);
};

export const getFavouriteResourcesService = () => {
  return axios.get(`${url.getFavouriteResourcesUrl}`);
};

export const addFavouriteResourceService = (data) => {
  return axios.patch(`${url.addFavouriteResourcesUrl}${data}`);
};

export const getMyExpertsService = () => {
  return axios.get(`${url.getMyExperts}`);
};

export const getParentSessionsService = (status, data) => {
  return axios.get(`${url.getParentSessions}${status}`, data);
};

export const getOpenSlotsService = (data) => {
  return axios.post(`${url.getOpenSlots}`, data);
};

export const scheduleSessionService = (data) => {
  return axios.post(`${url.scheduleSessionUrl}`, data);
};
export const cancelSessionService = (data) => {
  return axios.post(`${url.cancelSessionUrl}`, data);
};

export const getRescheduleOpenSlotsService = (data) => {
  return axios.post(`${url.getRescheduleOpenSlotsUrl}`, data);
};

export const rescheduleSessionService = (data) => {
  return axios.post(`${url.rescheduleSessionUrl}`, data);
};

export const getUserDataService = (id) => {
  return axios.get(`${url.getUserDataUrl}${id}`);
};

export const getLastAttendedSessionService = (id) => {
  return axios.get(`${url.getLastAttendedSessionUrl}${id}`);
};

export const addFeedbackService = (data) => {
  return axios.post(`${url.addFeedbackUrl}`, data);
};

export const getSessionByIdService = (id) => {
  return axios.get(`${url.getSessionByIdUrl}${id}`);
};

export const scheduleSessionExpertService = (data) => {
  return axios.post(`${url.scheduleSessionExpertUrl}`, data);
};

export const startMeetingService = (data) => {
  return axios.post(`${url.startMeetingUrl}`, data);
};

export const uploadChildDocsService = (data) => {
  return axios.post(`${url.uploadChildDocs}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const deleteDocumentService = (data) => {
  return axios.delete(`${url.deleteDocument}${data.docId}/${data.docIndex}`);
};

export const getPurchasePlansService = () => {
  return axios.get(`${url.getPurchasePlans}`);
};

export const createOrderService = (data) => {
  return axios.post(`${url.createOrder}`, data);
};

export const handlePaymentTransactionsService = (data) => {
  return axios.post(`${url.handlePaymentTransactions}`, data);
};

export const confirmSessionRequestService = (data) => {
  return axios.post(`${url.confirmSessionRequestUrl}`, data);
};

export const rejectSessionRequestService = (data) => {
  return axios.post(`${url.rejectSessionRequestUrl}`, data);
};

export const getParentUpcomingSessionService = (data) => {
  return axios.get(`${url.getParentUpcomingSession}`, data);
};

export const getActivityListingService = (data) => {
  let reqParams = "?page_number=" + data.pageNo;
  if (data.fromAge) {
    reqParams = reqParams + "&from_age=" + data.fromAge;
  }
  if (data.toAge) {
    reqParams = reqParams + "&to_age=" + data.toAge;
  }
  if (data.pageSize) {
    reqParams = reqParams + "&page_size=" + data.pageSize;
  }
  const token = localStorage.getItem("kpToken");
  const config = { headers: { Authorization: token } };
  return axios.get(`${url.getActivityListingUrl + reqParams}`, config);
};

export const getVideosService = (data) => {
  let reqParams = "?page_number=" + data.pageNo;
  if (data.fromAge) {
    reqParams = reqParams + "&from_age=" + data.fromAge;
  }
  if (data.toAge) {
    reqParams = reqParams + "&to_age=" + data.toAge;
  }
  if (data.pageSize) {
    reqParams = reqParams + "&page_size=" + data.pageSize;
  }
  const token = localStorage.getItem("kpToken");
  const config = { headers: { token: token } };
  return axios.get(`${url.getVideosUrl + reqParams}`, config);
};

export const getParentingTipsService = (data) => {
  const token = localStorage.getItem("kpToken");
  const config = { headers: { token: token } };

  let reqParams = "?page_no=" + data.pageNo;
  if (data.stage) {
    reqParams = reqParams + "&stage=" + data.stage;
  }
  if (data.subStage) {
    reqParams = reqParams + "&subStage=" + data.subStage;
  }

  return axios.get(`${url.getParentingTipsUrl + reqParams}`, config);
};

export const getLiveBookingService = () => {
  const token = localStorage.getItem("kpToken");
  const config = { headers: { token: token } };
  return axios.get(`${url.getLiveBookingUrl}`, config);
};

export const joinConsulatationService = (id) => {
  return axios.get(`${url.joinConsulatationUrl}${id}`);
};

export const getPopularArticlesService = () => {
  const token = localStorage.getItem("kpToken");
  const config = { headers: { token: token } };
  return axios.get(`${url.getPopularArticleUrl}`, config);
};

export const getBalanceDetailsService = () => {
  return axios.get(`${url.getBalanceDetailsUrl}`);
};

export const blockSlotService = (data) => {
  return axios.post(`${url.blockSlotUrl}`, data);
};

export const deleteBlockSlotService = (data) => {
  return axios.post(`${url.deleteblockSlotUrl}`, data);
};

export const getConsulatationByIdService = (id) => {
  return axios.get(`${url.getConsultationByIdUrl}${id}`);
};

export const updateAcrService = (step, data) => {
  return axios.post(`${url.updateAcrUrl}${step}`, data);
};

export const getConsulatationReportService = (id) => {
  return axios.get(`${url.getConsultationReportUrl}${id}`);
};

export const downloadCheckpointService = (id) => {
  return axios.get(`${url.downloadCheckpointUrl}${id}`);
};

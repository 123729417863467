import * as ActionTypes from "./actionTypes";

export const getActivityListing = (data) => ({
  type: ActionTypes.GET_ACTIVITY_LISTING,
  data,
});

export const saveActivityListing = (data) => ({
  type: ActionTypes.SAVE_ACTIVITY_LISTING,
  data,
});

export const getVideosListing = (data) => ({
  type: ActionTypes.GET_VIDEO_LISTING,
  data,
});

export const saveVideosListing = (data) => ({
  type: ActionTypes.SAVE_VIDEO_LISTING,
  data,
});

export const getParentingTips = (data) => ({
  type: ActionTypes.GET_PARENTING_TIPS,
  data,
});

export const saveParentingTips = (data) => ({
  type: ActionTypes.SAVE_PARENTING_TIPS,
  data,
});

export const getLiveBooking = () => ({
  type: ActionTypes.GET_LIVE_BOOKING,
});

export const saveLiveBooking = (data) => ({
  type: ActionTypes.SAVE_LIVE_BOOKING,
  data,
});

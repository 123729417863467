import { useEffect, useState } from "react";
import { Box, Text, Image, Grid, GridItem } from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import AuthenticatedContainer from "../../../container/Authenticated";
import {
  ARROW_CIRCLE_LEFT,
  ARROW_CIRCLE_LEFT_ACTIVE,
  ARROW_CIRCLE_RIGHT,
  ARROW_CIRCLE_RIGHT_ACTIVE,
  LAYOUT_BANNER,
  ROUTES_PATH,
  childAge,
} from "../../../constant";
import "./explore.css";
import { useDispatch } from "react-redux";
import {
  getActivityListing,
  getLiveBooking,
  getParentingTips,
  getVideosListing,
  toggleMainLoader,
} from "../../../modules/actions";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { calculateAgeInMonths } from "../../../utils/customHooks";

const Explore = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user?.profile);
  const activityListing = useSelector((state) => state.activityListing);
  const videosListing = useSelector((state) => state.videosListing);
  const parentingTips = useSelector((state) => state.parentingTips);
  const liveBooking = useSelector((state) => state.liveBooking);
  const [articlePageNo, setArticlePageNo] = useState(1);
  const [fromAge, setFromAge] = useState(37);
  const [toAge, setToAge] = useState(60);
  const [stages, setStages] = useState("preschool-and-above");
  const [subStages, setSubStages] = useState("");

  const selectStyles = {
    control: (styles) => ({
      ...styles,
      border: "none",
      outline: "none",
      borderRadius: "10px",
      boxShadow: "none",
      width: "full",
      // padding: '0px 12px',
      cursor: "pointer",
    }),
    dropdownIndicator: (prev, { selectProps: { menuIsOpen } }) => ({
      ...prev,
      "> svg": {
        transitionDuration: "normal",
        transform: `rotate(${menuIsOpen ? -180 : 0}deg)`,
        color: "#4299E1",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? "#ffffff"
          : isSelected
          ? "#E9F6FF"
          : isFocused
          ? "#ffffff"
          : "#ffffff",
        color: isDisabled ? "#ccc" : isSelected ? "#4299E1" : "#141719",
        padding: "10px",
        cursor: isDisabled ? "not-allowed" : "pointer",
        fontFamily: "Inter",
        fontSize: "14px",
        lineHeight: "20px",
        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? "#E9F6FF"
              : "#E9F6FF"
            : undefined,
        },
        ":hover": {
          ...styles[":hover"],
          backgroundColor: "#F3F8FB",
          // ? isSelected
          //   ? "#E9F6FF"
          //   : "#E9F6FF"
          // : undefined,
        },
      };
    },
    singleValue: (styles) => ({
      ...styles,
      color: "#141719",
      fontWeight: "600",
    }),
    menuList: (styles) => ({
      ...styles,
      borderRadius: "8px",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.16)",
    }),
  };

  useEffect(() => {
    const months = calculateAgeInMonths(user?.childDob);
    if (months <= 0 && months >= 6) {
      setFromAge(0);
      setToAge(6);
    } else if (months <= 7 && months >= 18) {
      setFromAge(7);
      setToAge(18);
    } else if (months <= 19 && months >= 36) {
      setFromAge(19);
      setToAge(36);
    } else if (months <= 37 && months >= 60) {
      setFromAge(37);
      setToAge(60);
    }
    if (months <= 0 && months >= 12) {
      setStages("first-year");
      if (months <= 0 && months >= 3) {
        setSubStages("1-3months");
      } else if (months <= 4 && months >= 6) {
        setSubStages("4-6months");
      } else if (months <= 7 && months >= 9) {
        setSubStages("7-9months");
      } else if (months <= 10 && months >= 12) {
        setSubStages("10-12months");
      }
    } else if (months <= 13 && months >= 24) {
      setStages("second-year");
      if (months <= 13 && months >= 15) {
        setSubStages("13-15months");
      } else if (months <= 16 && months >= 18) {
        setSubStages("16-18months");
      } else if (months <= 19 && months >= 21) {
        setSubStages("19-21months");
      } else if (months <= 22 && months >= 24) {
        setSubStages("22-24months");
      }
    } else if (months <= 25 && months >= 36) {
      setStages("third-year");
      if (months <= 25 && months >= 27) {
        setSubStages("25-27months");
      } else if (months <= 28 && months >= 30) {
        setSubStages("28-30months");
      } else if (months <= 31 && months >= 33) {
        setSubStages("31-33months");
      } else if (months <= 34 && months >= 36) {
        setSubStages("34-36months");
      }
    }
  }, []);

  useEffect(() => {
    const reqData = {
      pageNo: 1,
      fromAge: fromAge,
      toAge: toAge,
      pageSize: 4,
    };
    dispatch(toggleMainLoader(true));
    dispatch(getLiveBooking());
    dispatch(getActivityListing(reqData));
    dispatch(getVideosListing(reqData));
  }, [fromAge, toAge]);

  useEffect(() => {
    const parentingTipsData = {
      stage: stages,
      subStage: subStages,
      pageNo: articlePageNo,
    };
    dispatch(getParentingTips(parentingTipsData));
  }, [stages, subStages]);

  const handleAgeChange = (value) => {
    const age = value.split("-");

    setFromAge(age[0]);
    setToAge(age[1]);
  };

  const handleNextStep = (type) => {
    dispatch(toggleMainLoader(true));
    if (type === "activity") {
      const reqData = {
        pageNo: activityListing.current_page + 1,
        fromAge: fromAge,
        toAge: toAge,
        pageSize: 4,
      };
      dispatch(getActivityListing(reqData));
    } else if (type === "video") {
      const reqData = {
        pageNo: videosListing.current_page + 1,
        fromAge: fromAge,
        toAge: toAge,
        pageSize: 4,
      };
      dispatch(getVideosListing(reqData));
    } else if (type === "article") {
      const parentingTipsData = {
        stage: stages,
        subStage: subStages,
        pageNo: articlePageNo + 1,
      };
      setArticlePageNo(articlePageNo + 1);
      dispatch(getParentingTips(parentingTipsData));
    }
  };

  const handlePrevStep = (type) => {
    dispatch(toggleMainLoader(true));
    if (type === "activity") {
      const reqData = {
        pageNo: activityListing.current_page - 1,
        fromAge: fromAge,
        toAge: toAge,
        pageSize: 4,
      };
      dispatch(getActivityListing(reqData));
    } else if (type === "video") {
      const reqData = {
        pageNo: videosListing.current_page - 1,
        fromAge: fromAge,
        toAge: toAge,
        pageSize: 4,
      };
      dispatch(getVideosListing(reqData));
    } else if (type === "article") {
      const parentingTipsData = {
        stage: stages,
        subStage: subStages,
        pageNo: articlePageNo - 1,
      };
      setArticlePageNo(articlePageNo - 1);
      dispatch(getParentingTips(parentingTipsData));
    }
  };
  return (
    <AuthenticatedContainer>
      <Image src={LAYOUT_BANNER} width="100%" />
      <Box className="headingBlock">
        <Text className="exploreText">Explore</Text>
        <Select
          useBasicStyles
          placeholder="Age"
          options={childAge}
          chakraStyles={selectStyles}
          value={{ label: fromAge + "-" + toAge, value: fromAge + "-" + toAge }}
          name="age"
          className="ageDropDown"
          onChange={(e) => handleAgeChange(e.value)}
          isSearchable={false}
          // menuIsOpen
        />
      </Box>

      <Box
        px={{ base: "20px", md: "72px" }}
        py="24px"
        mb={{ base: "100px", md: "0px" }}
      >
        {/* Workshops */}
        {liveBooking?.booking?.length > 0 && (
          <>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Text className="topicHeading">Workshops</Text>
              {/* <Box display="flex">
            <Image
              src={
                liveBooking?.booking
                  ? ARROW_CIRCLE_LEFT_ACTIVE
                  : ARROW_CIRCLE_LEFT
              }
              onClick={
                activityListing?.has_previous
                  ? () => handlePrevStep("activity")
                  : null
              }
              color={"red"}
            />
            <Image
              src={
                activityListing?.has_next
                  ? ARROW_CIRCLE_RIGHT_ACTIVE
                  : ARROW_CIRCLE_RIGHT
              }
              onClick={
                activityListing?.has_next
                  ? () => handleNextStep("activity")
                  : null
              }
            />
          </Box> */}
            </Box>
            <Grid
              templateColumns={{ md: "repeat(12, 1fr)", sm: "" }}
              gap="48px"
              className="exploreGrid"
            >
              {liveBooking?.booking?.map((slot, index) => (
                <GridItem
                  colSpan={3}
                  className="childDocDiv"
                  key={index}
                  onClick={() =>
                    navigate(ROUTES_PATH.PARENT_LIVE_BOOKING, { state: slot })
                  }
                  cursor="pointer"
                >
                  <Image
                    src={slot.listing.image_url}
                    height="150px"
                    width="240px"
                    objectFit="cover"
                    background="#E9F6FF"
                  />
                  <Box p="12px">
                    <Box className="childDocTitleDiv">{slot.listing.title}</Box>
                    <Text
                      className="txnsTabText2"
                      color="#3D464D"
                      mt="16px !important"
                    >
                      {slot.listing.session_startdate +
                        " - " +
                        slot.listing.session_enddate}
                    </Text>
                  </Box>
                </GridItem>
              ))}
            </Grid>
          </>
        )}

        {/* Activities */}
        {activityListing?.activities?.length > 0 && (
          <>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Text className="topicHeading">Activities</Text>
              <Box display="flex">
                <Image
                  src={
                    activityListing?.has_previous
                      ? ARROW_CIRCLE_LEFT_ACTIVE
                      : ARROW_CIRCLE_LEFT
                  }
                  onClick={
                    activityListing?.has_previous
                      ? () => handlePrevStep("activity")
                      : null
                  }
                  color={"red"}
                  cursor={
                    activityListing?.has_previous ? "pointer" : "not-allowed"
                  }
                  height="32px"
                  width="32px"
                />
                <Image
                  src={
                    activityListing?.has_next
                      ? ARROW_CIRCLE_RIGHT_ACTIVE
                      : ARROW_CIRCLE_RIGHT
                  }
                  onClick={
                    activityListing?.has_next
                      ? () => handleNextStep("activity")
                      : null
                  }
                  cursor={activityListing?.has_next ? "pointer" : "not-allowed"}
                  height="32px"
                  width="32px"
                />
              </Box>
            </Box>
            <Grid
              templateColumns={{ md: "repeat(12, 1fr)", sm: "" }}
              gap="48px"
              className="exploreGrid"
            >
              {activityListing?.activities?.map((activity, index) => (
                <GridItem
                  colSpan={3}
                  className="childDocDiv"
                  key={index}
                  onClick={() =>
                    navigate(ROUTES_PATH.PARENT_ACTIVITIES, { state: activity })
                  }
                  cursor="pointer"
                >
                  <Image
                    src={`http://soicalprod.s3.ap-southeast-1.amazonaws.com/stg_activityimages/compressed2/${activity.id}.${activity.title}.jpg`}
                    height="150px"
                    width="240px"
                    objectFit="cover"
                    background="#E9F6FF"
                  />
                  <Box p="12px">
                    <Box className="childDocTitleDiv">{activity.title}</Box>
                    <Text
                      className="txnsTabText2"
                      color="#3D464D"
                      mt="16px !important"
                    >
                      {activity.suggested_time}
                    </Text>
                  </Box>
                </GridItem>
              ))}
            </Grid>
          </>
        )}

        {/* Videos */}
        {videosListing?.response?.length && (
          <>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Text className="topicHeading">Videos - Playful Learning**</Text>
              <Box display="flex">
                <Image
                  src={
                    videosListing?.has_previous
                      ? ARROW_CIRCLE_LEFT_ACTIVE
                      : ARROW_CIRCLE_LEFT
                  }
                  onClick={
                    videosListing?.has_previous
                      ? () => handlePrevStep("video")
                      : null
                  }
                  color={"red"}
                  cursor={
                    activityListing?.has_previous ? "pointer" : "not-allowed"
                  }
                />
                <Image
                  src={
                    videosListing?.has_next
                      ? ARROW_CIRCLE_RIGHT_ACTIVE
                      : ARROW_CIRCLE_RIGHT
                  }
                  onClick={
                    videosListing?.has_next
                      ? () => handleNextStep("video")
                      : null
                  }
                  cursor={activityListing?.has_next ? "pointer" : "not-allowed"}
                />
              </Box>
            </Box>
            <Grid
              templateColumns={{ md: "repeat(12, 1fr)", sm: "" }}
              gap="48px"
              className="exploreGrid"
            >
              {videosListing?.response?.map((videos, index) => (
                <GridItem
                  colSpan={3}
                  className="childDocDiv"
                  key={index}
                  // cursor="pointer"
                >
                  <iframe
                    width="100%"
                    src={"https://player.vimeo.com/video/" + videos.vimeo_id}
                    frameBorder="0"
                    allow="autoplay; encrypted-media"
                    allowFullScreen
                    title={videos.title}
                  ></iframe>
                  <Box p="12px">
                    <Box className="childDocTitleDiv">{videos.title}</Box>
                  </Box>
                </GridItem>
              ))}
            </Grid>
          </>
        )}

        {/* Articles */}
        {parentingTips?.articles?.length > 0 && (
          <>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Text className="topicHeading">Articles</Text>
              <Box display="flex">
                <Image
                  src={
                    articlePageNo > 1
                      ? ARROW_CIRCLE_LEFT_ACTIVE
                      : ARROW_CIRCLE_LEFT
                  }
                  onClick={
                    articlePageNo > 1 ? () => handlePrevStep("article") : null
                  }
                  cursor={articlePageNo > 1 ? "pointer" : "not-allowed"}
                />
                <Image
                  src={
                    articlePageNo < parentingTips.total_pages
                      ? ARROW_CIRCLE_RIGHT_ACTIVE
                      : ARROW_CIRCLE_RIGHT
                  }
                  onClick={
                    articlePageNo < parentingTips.total_pages
                      ? () => handleNextStep("article")
                      : null
                  }
                  cursor={
                    articlePageNo < parentingTips.total_pages
                      ? "pointer"
                      : "not-allowed"
                  }
                />
              </Box>
            </Box>
            <Grid
              templateColumns={{ md: "repeat(12, 1fr)", sm: "" }}
              gap="48px"
              className="exploreGrid"
            >
              {parentingTips?.articles?.map((article, index) => (
                <GridItem
                  colSpan={{ "2xl": 3, xl: 4, lg: 4, md: 4, sm: 12 }}
                  className="childDocDiv"
                  key={index}
                  onClick={() =>
                    navigate(ROUTES_PATH.PARENT_ARTICLES, { state: article })
                  }
                  cursor="pointer"
                >
                  <Image
                    src={article.cover_image_url}
                    height="150px"
                    width="240px"
                    objectFit="cover"
                    background="#E9F6FF"
                  />
                  <Box p="12px">
                    <Box className="childDocTitleDiv">{article.title}</Box>
                  </Box>
                </GridItem>
              ))}
            </Grid>
          </>
        )}
      </Box>
    </AuthenticatedContainer>
  );
};
export default Explore;

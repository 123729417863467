import { useEffect, useState } from "react";
import { Grid, GridItem, Show } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import AuthenticatedContainer from "../../../container/Authenticated";
import ScheduleHeader from "../../../components/ScheduleHeader";
import ScheduleCalenderHeader from "../../../components/ScheduleCalenderHeader";
import ScheduleCalenderBody from "../../../components/ScheduleCalenderBody";
import Loader from "../../../common/components/Loader";
import {
  getStudentsDataAction,
  getTherapistSessions,
} from "../../../modules/actions";
import {
  getNextWeekDate,
  getWeekEndDate,
  getWeekStartDate,
  getPreviousWeekDate,
  getWeekStartDateForDate,
  getWeekEndDateForDate,
} from "../../../utils/customMoment";
import SessionOverlay from "../SessionOverlay";
import "./schedule.css";
import { toggleSessionLoader } from "../../../modules/actions/userActions";
import MobileNotAllowedScreen from "../../../common/components/MobileNotAllowed";

const Schedule = () => {
  const dispatch = useDispatch();
  const therapistSessions = useSelector(
    (state) => state?.therapistSessions?.sessions
  );
  const sessionLoader = useSelector((state) => state?.loader?.sessionLoader);
  const user = useSelector((state) => state?.user?.profile);
  const [status, setSelectedOption] = useState({ value: "", label: "All" });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [overlayOpen, setOverlayOpen] = useState(false);
  const [selectedSession, setSelectedSession] = useState("");
  const [calenderStartDate, setCalenderStartDate] = useState();

  useEffect(() => {
    setStartDate(getWeekStartDate());
    setEndDate(getWeekEndDate());
  }, []);

  useEffect(() => {
    if (startDate && endDate) handleSessionsApiCall();
  }, [status, startDate, endDate]);

  useEffect(() => {
    dispatch(getStudentsDataAction());
  }, [dispatch]);

  const handleCalendarChange = (event) => {
    const selectedDate = moment(event);
    setCalenderStartDate(selectedDate);
  };
  const handleGoNow = (type) => {
    if (type === "Today") {
      setStartDate(getWeekStartDate());
      setEndDate(getWeekEndDate());
    } else {
      setStartDate(getWeekStartDateForDate(calenderStartDate));
      setEndDate(getWeekEndDateForDate(calenderStartDate));
    }
  };

  const handleSessionsApiCall = () => {
    const reqData = {
      startDate: startDate,
      endDate: endDate,
      status: status.value,
    };

    dispatch(toggleSessionLoader(true));
    dispatch(getTherapistSessions(reqData));
  };
  const handleNextWeekClick = () => {
    setStartDate(getNextWeekDate(startDate));
    setEndDate(getNextWeekDate(endDate));
  };
  const handlePrevWeekClick = () => {
    setStartDate(getPreviousWeekDate(startDate));
    setEndDate(getPreviousWeekDate(endDate));
  };

  return (
    <AuthenticatedContainer>
      <MobileNotAllowedScreen />
      <Show breakpoint="(min-width:767px)">
        {overlayOpen && (
          <SessionOverlay
            setOverlayOpen={setOverlayOpen}
            selectedSession={selectedSession}
            therapistSessions={therapistSessions}
            setSelectedSession={setSelectedSession}
            reqData={{
              startDate: startDate,
              endDate: endDate,
              status: status.value,
            }}
          />
        )}
        <Grid row className="layoutBanner" px="71px" py="24px">
          <GridItem className="mainScheduleBox">
            <ScheduleHeader
              countData={therapistSessions?.currentMonthCount}
              expertName={user?.fullName}
              loader={sessionLoader}
            />
            <ScheduleCalenderHeader
              statusChange={setSelectedOption}
              status={status}
              startDate={startDate}
              handleNextWeekClick={handleNextWeekClick}
              handlePrevWeekClick={handlePrevWeekClick}
              calenderStartDate={calenderStartDate}
              handleCalendarChange={handleCalendarChange}
              handleGoNow={handleGoNow}
            />
            {sessionLoader ? (
              <Loader text="“Take regular breaks between sessions”" />
            ) : (
              <ScheduleCalenderBody
                sessionsData={therapistSessions?.sessions}
                setOverlayOpen={setOverlayOpen}
                setSelectedSession={setSelectedSession}
                selectedSession={selectedSession}
              />
            )}
          </GridItem>
        </Grid>
      </Show>
    </AuthenticatedContainer>
  );
};
export default Schedule;

import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Tag, Avatar, TagLabel } from "@chakra-ui/react";
import { getParentSessionAction } from "../../modules/actions";
import { getDateMonthYear1 } from "../../utils/customMoment";
import {
  ATTENDED,
  SCHEDULED,
  CANCELLED,
  RESCHEDULED,
  PARTIALLY_ATTENDED,
  NO_SHOW,
} from "../../constant/images";
import Loader from "../../common/components/Loader";

const SessionHistoryTab = ({ selectedSession }) => {
  const dispatch = useDispatch();
  const apiLoader = useSelector((state) => state?.loader?.apiLoader);
  const sessions = useSelector((state) => state?.user?.sessionHistory);

  useEffect(() => {
    dispatch(getParentSessionAction(selectedSession?.sessionStudentId));
  }, [selectedSession]);

  return (
    <>
      {apiLoader ? (
        <Loader text={"Loading Session History"} />
      ) : (
        sessions?.map((session, index) => (
          <Box className="historyTabDiv" key={index}>
            <Box>
              <Box className="historyTabDate">
                {getDateMonthYear1(session?.sessionDate)}
              </Box>
              <Box className="historyTabName">
                {session.sessionStudentName} / {session.sessionExpertName}
              </Box>
            </Box>

            <Tag
              className="txnsSecondMapFlexBoxTag"
              style={{
                backgroundColor:
                  ((session?.status === "Successfull" ||
                    session?.status === "Attended" ||
                    session?.status === "Partially Attended") &&
                    "#EFF7E6") ||
                  (session?.status === "Cancelled" && "#FFDAD6") ||
                  (session?.status === "Scheduled" && "#F3EBFF") ||
                  (session?.status === "Rescheduled" && "#FEDFA0") ||
                  (session?.status === "noShow-Parent" && "#ffffff"),
                border:
                  session?.status === "noShow-Parent" && "1px solid #BA1A1A",
              }}
            >
              <Avatar
                className="txnsSecondMapFlexBoxTagAvatar"
                src={
                  ((session?.status === "Successfull" ||
                    session?.status === "Attended") &&
                    ATTENDED) ||
                  (session?.status === "Partially Attended" &&
                    PARTIALLY_ATTENDED) ||
                  (session?.status === "Cancelled" && CANCELLED) ||
                  (session?.status === "Scheduled" && SCHEDULED) ||
                  (session?.status === "Rescheduled" && RESCHEDULED) ||
                  (session?.status === "noShow-Parent" && NO_SHOW)
                }
              />
              <TagLabel
                style={{
                  color:
                    ((session?.status === "Successfull" ||
                      session?.status === "Attended" ||
                      session?.status === "Partially Attended") &&
                      "#024742") ||
                    (session?.status === "Cancelled" && "#410002") ||
                    (session?.status === "Scheduled" && "#2F0D63") ||
                    (session?.status === "Rescheduled" && "#734110") ||
                    (session?.status === "noShow-Parent" && "#BA1A1A"),
                }}
              >
                {session?.status}
              </TagLabel>
            </Tag>
          </Box>
        ))
      )}
    </>
  );
};
export default SessionHistoryTab;

import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Text,
  Show,
} from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ROUTES_PATH } from "../../../constant";
import GroupCard from "./groupCard";
import VideoPlayer from "./videoPlayer";
import { editUserProfileService } from "../../../modules/services";
import { getUserProfile, toggleAPILoader } from "../../../modules/actions";
import { videos, whatsappGroupData } from "../../../constant";
import MobileNotAllowedScreen from "../../../common/components/MobileNotAllowed";

const FirstLoginToDashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleGoToDashboard = () => {
    dispatch(toggleAPILoader(true));
    editUserProfileService({ isRegisteredFirst: false })
      .then((res) => {
        dispatch(toggleAPILoader(false));
        const token = localStorage.getItem("idToken");
        dispatch(getUserProfile(token));
        navigate(ROUTES_PATH.SCHEDULE);
      })
      .catch((error) => {
        console.log(error);
        dispatch(toggleAPILoader(false));
        navigate(ROUTES_PATH.SCHEDULE);
      });
  };
  return (
    <>
      <MobileNotAllowedScreen />
      <Show breakpoint="(min-width:767px)">
        <Grid row className="layoutBanner" px="71px" py="24px">
          <GridItem className="mainScheduleBox">
            <Flex
              p="16px"
              borderBottom="1px solid #D8E5EE"
              justify="space-between"
              align="center"
            >
              <Box>
                <Text
                  fontWeight="600"
                  fontSize="24px"
                  lineHeight="32px"
                  color="#141719"
                  mb={0}
                >
                  Hey there 👋
                </Text>
                <Text
                  mt="8px"
                  fontWeight="400"
                  fontSize="14px"
                  lineHeight="20px"
                  color="#5a646b"
                >
                  Welcome to the team! We are excited to have you on board.
                </Text>
              </Box>
              <Button
                fontWeight="600"
                fontSize="14px"
                lineHeight="20px"
                color="white"
                bg="#4299e1"
                _hover={{ bg: "#4299e1" }}
                borderRadius="10px"
                px="10px"
                py="20px"
                onClick={handleGoToDashboard}
              >
                Go To Dashboard
              </Button>
            </Flex>
            <Flex
              flexDir="column"
              gap="40px"
              fontSize="16px"
              fontWeight="400"
              lineHeight="24px"
              color="#000000"
              p="16px"
            >
              <Text fontSize="16px" fontWeight="400" lineHeight="24px">
                We want to make sure you are in the loop with all the latest
                company updates and training sessions, so we invite you to join
                our WhatsApp groups. Our groups are awesome - we share work and
                fun stuff and have a monthly learning circle where we exchange
                ideas and knowledge. No spam, just great conversations!
              </Text>
              <Flex wrap="wrap" gap="40px">
                {whatsappGroupData.map((dg, index) => (
                  <Box key={index}>
                    <Text
                      fontWeight="600"
                      fontSize="16px"
                      lineHeight="24px"
                      letterSpacing="0.15px"
                      color="black"
                      mb="16px"
                    >
                      {dg.main}:
                    </Text>
                    <GroupCard key={index} categories={dg.categories} />
                  </Box>
                ))}
              </Flex>
              <Text fontSize="16px" fontWeight="400" lineHeight="24px">
                Looking forward to having you in the group! 🚀
              </Text>
              <Box>
                <Text
                  fontSize="16px"
                  fontWeight="600"
                  lineHeight="24px"
                  letterSpacing="0.15px"
                >
                  Training & Onboarding Sessions:
                </Text>
                <Flex mt="16px" justify="center" flexWrap="wrap" gap="16px">
                  {videos.map((v, index) => (
                    <VideoPlayer v={v} index={index} key={index} />
                  ))}
                </Flex>
              </Box>
            </Flex>
          </GridItem>
        </Grid>
      </Show>
    </>
  );
};

export default FirstLoginToDashboard;

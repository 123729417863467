import {
  useNavigate,
  NavLink as RouterLink,
  useLocation,
} from "react-router-dom";
import {
  Box,
  Flex,
  Avatar,
  HStack,
  Link,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure,
  useColorModeValue,
  Stack,
  Image,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon, ChevronDownIcon } from "@chakra-ui/icons";
import { connect, useDispatch } from "react-redux";
import { signout } from "../../utils/firebase";
import * as actionCreator from "../../modules/actions";
import { bindActionCreators } from "redux";
import {
  LOGO,
  CALENDER_BLANK,
  BABY,
  BOOKS,
  USER_LIST,
  HELP,
  SEARCH,
  NOTIFICATION,
  ROUTES_PATH,
  ACTIVE_CALENDER_BLANK,
  ACTIVE_BABY,
  ACTIVE_BOOKS,
  ACTIVE_USER_LIST,
  ACTIVE_HOME,
  HOME,
  CHARTLINE,
  ACTIVE_CHARTLINE,
  SPARKLE,
  ACTIVE_SPARKLE,
} from "../../constant";
import "./navbar.css";
import { useEffect, useState } from "react";

const expertLinks = [
  {
    label: "Schedule",
    img: CALENDER_BLANK,
    activeImg: ACTIVE_CALENDER_BLANK,
    route: ROUTES_PATH.SCHEDULE,
  },
  {
    label: "My students",
    img: BABY,
    activeImg: ACTIVE_BABY,
    route: ROUTES_PATH.MY_STUDENTS,
  },
  {
    label: "Resources",
    img: BOOKS,
    activeImg: ACTIVE_BOOKS,
    route: ROUTES_PATH.RESOURCE,
  },
  {
    label: "My Profile",
    img: USER_LIST,
    activeImg: ACTIVE_USER_LIST,
    route: ROUTES_PATH.PROFILE,
  },
];

const NavLink = ({ children }) => {
  const location = useLocation();

  return (
    <Link
      as={RouterLink}
      px={2}
      py={1}
      rounded={"md"}
      _hover={{
        textDecoration: "none",
        bg: useColorModeValue("#E9F6FF", "#E9F6FF"),
        borderRadius: "40px",
        color: "#2B6CB0 !important",
        padding: "8px 16px",
      }}
      _activeLink={{
        textDecoration: "none",
        bg: useColorModeValue("#E9F6FF", "#E9F6FF"),
        borderRadius: "40px",
        color: "#2B6CB0 !important",
        padding: "8px 16px",
      }}
      to={children.route}
      className="navItems"
    >
      <Box display="flex">
        <Image
          src={
            location.pathname === children.route
              ? children.activeImg
              : children.img
          }
          mr={2}
        />
        {children.label}
      </Box>
    </Link>
  );
};

const Navbar = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [links, setLinks] = useState(expertLinks);
  let parentLinks = [
    {
      label: "Home",
      img: HOME,
      activeImg: ACTIVE_HOME,
      route: ROUTES_PATH.PARENT_HOME,
    },

    {
      label: "Session",
      img: CALENDER_BLANK,
      activeImg: ACTIVE_CALENDER_BLANK,
      route: ROUTES_PATH.PARENT_SESSION,
    },
    {
      label: "Explore",
      img: SPARKLE,
      activeImg: ACTIVE_SPARKLE,
      route: ROUTES_PATH.PARENT_EXPLORE,
    },
    {
      label: "My Profile",
      img: USER_LIST,
      activeImg: ACTIVE_USER_LIST,
      route: ROUTES_PATH.PARENT_PROFILE,
    },
  ];

  useEffect(() => {
    if (props.user.role === "expert") {
      setLinks(expertLinks);
    } else if (props.user.role === "parent") {
      dispatch(actionCreator.toggleMainLoader(true));
      dispatch(actionCreator.getChildPathway(props?.user?.id));
      setLinks(parentLinks);
    }
  }, []);
  useEffect(() => {
    if (props.user.role === "expert") {
      setLinks(expertLinks);
    } else if (props.user.role === "parent") {
      setLinks(parentLinks);
    }
  }, [props.user.role]);

  useEffect(() => {
    if (props.user.role === "parent") {
      if (
        props.pathway.length > 0 &&
        !Object.keys(parentLinks).includes("Progress")
      ) {
        parentLinks.splice(1, 0, {
          label: "Progress",
          img: CHARTLINE,
          activeImg: ACTIVE_CHARTLINE,
          route: ROUTES_PATH.PARENT_PROGRESS,
        });
        setLinks(parentLinks);
      }
    }
  }, [props.pathway, parentLinks, props.user.role]);

  return (
    <>
      <Box
        p={4}
        background="#FFFFFF"
        boxShadow="0px 0px 2px 1px rgba(0, 0, 0, 0.16)"
        position="sticky"
        top="0"
        zIndex="2000000"
      >
        <Flex alignItems={"center"} justifyContent={"space-between"}>
          {/* <Show above="md">
            <IconButton
              size={"md"}
              icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
              aria-label={"Open Menu"}
              display={{ sm: "none", xs: "none" }}
              onClick={isOpen ? onClose : onOpen}
            />
          </Show> */}

          <HStack spacing={8} alignItems={"center"}>
            <Box>
              <Image
                src={LOGO}
                onClick={() => navigate(ROUTES_PATH.SCHEDULE)}
                cursor="pointer"
              />
            </Box>
            <HStack
              as={"nav"}
              spacing={4}
              display={{ base: "none", lg: "flex" }}
            >
              {links.map((link, index) => (
                <NavLink key={index}>{link}</NavLink>
              ))}
            </HStack>
          </HStack>
          <Flex alignItems={"center"}>
            {/* {props?.user?.role === "expert" && (
              <Image boxSize="40px" src={SEARCH} mr={2} />
            )} */}
            {/* <Image boxSize="40px" src={NOTIFICATION} mr={2} />
            <Image src={HELP} mr={2} /> */}

            <Menu>
              <MenuButton
                as={Button}
                rounded={"full"}
                variant={"link"}
                cursor={"pointer"}
                minW={0}
              >
                <Box
                  className={
                    props.user.role === "parent"
                      ? "parentUsernameDiv"
                      : "expertUsernameDiv"
                  }
                >
                  <Avatar
                    size="sm"
                    name={props.user.fullName}
                    src={props.user.imgUrl}
                    marginRight="8px"
                  />
                  {props?.user?.role === "parent" && (
                    <>
                      <Box className="username">{props.user.fullName}</Box>
                      <ChevronDownIcon className="hideMob" />
                    </>
                  )}
                </Box>
              </MenuButton>
              <MenuList>
                {/* <MenuItem>Link 1</MenuItem>
                <MenuItem>Link 2</MenuItem>
                <MenuDivider /> */}
                <MenuItem onClick={signout}>Logout</MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </Flex>

        {isOpen ? (
          <Box pb={4} display={{ md: "none" }}>
            <Stack as={"nav"} spacing={4}>
              {links.map((link, index) => (
                <NavLink key={index}>{link}</NavLink>
              ))}
            </Stack>
          </Box>
        ) : null}
      </Box>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.user.profile,
    pathway: state.pathway,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      logoutSuccess: actionCreator.logoutSuccess,
    },
    dispatch
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(Navbar);

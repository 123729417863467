import { Image, Text, Box } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import AuthenticatedContainer from "../../../container/Authenticated";
import { BACK_BTN, ROUTES_PATH } from "../../../constant";

const BookingDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <AuthenticatedContainer>
      <Box px="70px" py="24px" mb="100px">
        <Box className="exerciseBackText" mb="10px">
          <Image
            src={BACK_BTN}
            mr="8px"
            onClick={() => navigate(ROUTES_PATH.PARENT_EXPLORE)}
            cursor="pointer"
          />
          <Text
            onClick={() => navigate(ROUTES_PATH.PARENT_EXPLORE)}
            cursor="pointer"
          >
            Go Back
          </Text>
        </Box>
        <Image
          src={location.state.listing.image_url}
          width="100%"
          objectFit="cover"
          height="450px"
        />

        <Text className="topicHeading" mt="24px">
          {location.state.listing.title}
        </Text>
        <Text className="exploreSubText">
          {location.state.listing.description}
        </Text>

        <Text className="topicHeading" mt="24px">
          Dates :{" "}
          <span className="exploreSubText">
            {location.state.listing.session_startdate +
              " - " +
              location.state.listing.session_enddate}
          </span>
        </Text>

        <Text className="topicHeading" mt="24px">
          Timings :{" "}
          <span className="exploreSubText">
            {location.state.slot.slot_time_from +
              " - " +
              location.state.slot.slot_time_to +
              " (" +
              location.state.slot.old_timezone_abbreviations +
              ")"}
          </span>
        </Text>

        <Text className="topicHeading" mt="24px">
          Venue :{" "}
          <span className="exploreSubText">{location.state.listing.venue}</span>
        </Text>
      </Box>
    </AuthenticatedContainer>
  );
};
export default BookingDetails;

import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../utils/updateObjects";

const initialState = {
  sessions: {},
};

const therapistSessions = (state = initialState, { type, data }) => {
  switch (type) {
    case actionTypes.SAVE_THARAPIST_SESSIONS:
      return updateObject(state, { sessions: data });
    default:
      return state;
  }
};
export default therapistSessions;

export const parentSessions = (state = [], { type, data }) => {
  switch (type) {
    case actionTypes.SAVE_PARENT_SESSIONS:
      return data;
    default:
      return state;
  }
};

export const openSlots = (state = [], { type, data }) => {
  switch (type) {
    case actionTypes.SAVE_OPEN_SLOTS:
      return data;
    default:
      return state;
  }
};

export const rescheduleOpenSlots = (state = [], { type, data }) => {
  switch (type) {
    case actionTypes.SAVE_RESCHEDULE_OPEN_SLOTS:
      return data;
    default:
      return state;
  }
};

export const lastAttentedSession = (state = [], { type, data }) => {
  switch (type) {
    case actionTypes.SAVE_LAST_ATTENDED_SESSION:
      return data;
    default:
      return state;
  }
};

export const sessionById = (state = {}, { type, data }) => {
  switch (type) {
    case actionTypes.SAVE_SESSION_BY_ID:
      return data;
    default:
      return state;
  }
};

export const meetingDetails = (state = {}, { type, data }) => {
  switch (type) {
    case actionTypes.SAVE_START_MEETING:
      return data;
    default:
      return state;
  }
};

export const upcomingSession = (state = {}, { type, data }) => {
  switch (type) {
    case actionTypes.SAVE_PARENT_UPCOMING_SESSION:
      return data;
    default:
      return state;
  }
};

export const consultationById = (state = {}, { type, data }) => {
  switch (type) {
    case actionTypes.SAVE_CONSULTATION_BY_ID:
      return data;
    default:
      return state;
  }
};

export const consultationReport = (state = {}, { type, data }) => {
  switch (type) {
    case actionTypes.SAVE_CONSULTATION_REPORT:
      return data;
    default:
      return state;
  }
};

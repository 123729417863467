import { useEffect, useState } from "react";
import { Box, Flex, Image, Stack, Heading, Show } from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import firebase from "firebase/compat/app";
import "firebaseui/dist/firebaseui.css";
import UnauthenticatedContainer from "../../../container/Unauthenticated";
import Loader from "../../../common/components/Loader";
import { AUTH_BOTTOM_BG } from "../../../constant";
import { firebaseSignInFunction, getIdToken } from "../../../utils/firebase";
import { toggleMainLoader } from "../../../modules/actions";
import MobileNotAllowedScreen from "../../../common/components/MobileNotAllowed";
import "./login.css";

const Login = () => {
  const dispatch = useDispatch();
  const loader = useSelector((state) => state.loader);
  const [user, setUser] = useState(null);

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      setUser(user);
      if (user) {
        dispatch(toggleMainLoader(true));
        getIdToken();
      } else {
        firebaseSignInFunction();
      }
    });
  }, []);

  return (
    <UnauthenticatedContainer>
      <MobileNotAllowedScreen />
      <Show breakpoint="(min-width:767px)">
        <Flex align={"center"} justify={"center"}>
          <Stack spacing={8} mx={"auto"} maxW={"lg"} py={10} px={6}>
            {loader.mainLoader ? (
              <Loader text="“Please wait....”" />
            ) : (
              <Box
                rounded={"lg"}
                bg="#ffffff"
                p={8}
                border="1px solid #D8E5EE"
                borderRadius="12px"
              >
                <Stack mb="24px">
                  <Heading className="loginHeading">
                    Welcome To KinderPass!
                  </Heading>
                  {/* <Text className="loginSubHeading">
                Please enter your Email Address to continue
              </Text> */}
                </Stack>
                <div id="firebaseui-auth-container"></div>

                {/* <Stack spacing={4}>
              <FormControl id="email" mb="8px">
                <FormLabel className="inputLabel">Email Address *</FormLabel>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<EmailIcon color="gray.400" />}
                  />
                  <Input
                    type="email"
                    placeholder="Enter email address"
                    size="md"
                    // isInvalid
                    errorBorderColor="red.300"
                    _placeholder={{
                      color: "#91A2AE",
                      fontSize: "14px",
                      lineHeight: "20px",
                      fontWeight: "400",
                    }}
                  />
                </InputGroup>
              </FormControl>

              <Stack spacing={10}>
                <Button
                  className="loginBtn"
                  _hover={{
                    bg: "blue.500",
                  }}
                >
                  Continue
                </Button>
              </Stack>
              <p className="separator">or continue with</p>

              <Stack spacing={10}>
                <Button
                  className="secondLoginBtn"
                  _hover={{
                    bg: "blue.500",
                  }}
                >
                  <Image src={PHONE} alt="" mr="8px" />
                  Mobile Number
                </Button>
              </Stack>
              <Stack spacing={10}>
                <Button
                  className="thirdLoginBtn"
                  _hover={{
                    bg: "blue.500",
                  }}
                >
                  <Image src={GOGGLE_LOGO} alt="" mr="8px" />
                  Continue with Google
                </Button>
              </Stack>
            </Stack> */}
              </Box>
            )}
          </Stack>
        </Flex>
        <Box className="layoutBottomBanner">
          <Image src={AUTH_BOTTOM_BG} />
        </Box>
      </Show>
    </UnauthenticatedContainer>
  );
};
export default Login;

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSteps } from "chakra-ui-steps";
import { Box, Button, Flex, Image, Spinner, Text } from "@chakra-ui/react";
import { CLOSE } from "../../../constant/images";
import { validateName, validateFullName } from "../../../utils/validations";
import { EditProfileStepper } from "./editProfileStepper";
import { Toaster } from "../../../common/components/Toaster";
import { toggleAPILoader, getUserProfile } from "../../../modules/actions";
import { editUserProfileService } from "../../../modules/services";
import { getMomentDate1 } from "../../../utils/customMoment";
import "./editProfile.css";

const EditProfile = ({ setOverlayOpen }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.profile);
  const apiLoader = useSelector((state) => state?.loader?.apiLoader);
  const { nextStep, prevStep, activeStep } = useSteps({
    initialStep: 0,
  });
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [userData, setUserData] = useState({
    fullName: "",
    fullNameError: "",
    email: "",
    phoneNumber: "",
    countryCode: "",
    whatsAppNumber: "",
    location: "",
    locationError: "",
    relation: "",
    relationError: "",
    childName: "",
    childNameError: "",
    gender: "",
    genderError: "",
    childDob: "",
    childDobError: "",
    preemie: false,
    bornWeeks: "",
    bornWeeksError: "",
    services: [],
    servicesError: "",
    ageRange: "",
    ageRangeError: "",
    languages: [],
    languagesError: "",
  });

  useEffect(() => {
    setUserData({
      ...userData,
      fullName: user?.fullName || "",
      email: user?.email || "",
      phoneNumber: user?.phoneNumber || "",
      countryCode: user?.countryCode || "",
      whatsAppNumber: user?.whatsappNumber || "",
      childDob: getMomentDate1(user?.childDob) || "",
      location: user?.location || "",
      relation: user?.relationWithChild || "",
      preemie: user?.preemie || false,
      bornWeeks: user?.bornWeeks || 0,
      childName: user?.childName || "",
      gender: user?.childGender || "",
      services: user?.conditions,
      ageRange: user?.ageRange,
      languages: user?.languages,
    });
  }, [user]);

  const handleFormDataChange = (name, value, errorName) => {
    setBtnDisabled(false);
    setUserData({ ...userData, [name]: value, [errorName]: "" });
  };

  const handleOverlayCloser = () => {
    setOverlayOpen(false);
  };

  const handleNextStep = () => {
    const {
      fullName,
      location,
      relation,
      childDob,
      childName,
      gender,
      preemie,
      bornWeeks,
      services,
      languages,
      ageRange,
    } = userData;

    if (activeStep === 0) {
      const validateFullNm = !validateFullName(fullName)
        ? "Please enter valid full name"
        : "";
      const validateLocation = !validateName(location)
        ? "Please enter valid location"
        : "";
      const validateRelation = !relation ? "Please choose relation" : "";
      const validateChildName = !validateName(childName)
        ? "Please enter valid child name"
        : "";
      const validateGender = !gender ? "Please choose gender" : "";
      const validateChildDob = !childDob ? "Please enter your child's dob" : "";
      const validateBornWeeks =
        preemie && (!bornWeeks || Number(bornWeeks) === 0)
          ? "Please enter the valid born weeks"
          : preemie && Number(bornWeeks) >= 37
          ? "Born weeks should be less than 37 weeks"
          : "";

      setUserData({
        ...userData,
        fullNameError: validateFullNm,
        locationError: validateLocation,
        relationError: validateRelation,
        childNameError: validateChildName,
        genderError: validateGender,
        childDobError: validateChildDob,
        bornWeeksError: validateBornWeeks,
      });

      if (
        !validateFullNm &&
        !validateLocation &&
        !validateRelation &&
        !validateChildDob &&
        !validateGender &&
        !validateChildName &&
        !validateBornWeeks
      ) {
        nextStep();
      }
    } else {
      const validateServices =
        services?.length === 0 ? "Please choose required services" : "";
      const validateAgeRange = !ageRange
        ? "Please choose age rage of child"
        : "";
      const validateLanguages =
        languages?.length === 0 ? "Please choose languages" : "";

      setUserData({
        ...userData,
        servicesError: validateServices,
        ageRangeError: validateAgeRange,
        languagesError: validateLanguages,
      });

      const reqData = {
        fullName,
        location,
        relationWithChild: relation,
        childDob: userData?.childDob,
        childName,
        childGender: gender,
        preemie,
        bornWeeks,
        conditions: services,
        languages,
        ageRange,
      };

      if (!validateServices && !validateAgeRange && !validateLanguages) {
        editprofileApiCall(reqData);
      }
    }
  };

  const editprofileApiCall = (reqData) => {
    dispatch(toggleAPILoader(true));
    editUserProfileService(reqData)
      .then((res) => {
        Toaster("Parent profile updated successfully", "success");
        dispatch(toggleAPILoader(false));
        const token = localStorage.getItem("idToken");
        dispatch(getUserProfile(token));
        setOverlayOpen(false);
      })
      .catch((error) => {
        console.log(error);
        dispatch(toggleAPILoader(false));
        Toaster(error.response.data.message, "error");
      });
  };

  return (
    <Box>
      <Box className="overlayComponentMainBox"></Box>
      <Box className="overlayComponentContentBoxParent">
        <Flex justifyContent="space-between">
          <Text className="editProfileTitle">Edit Profile</Text>
          <Image
            className="overlayComponentContentBoxFlexImage"
            onClick={handleOverlayCloser}
            src={CLOSE}
          />
        </Flex>
        <Flex className="overlayComponentContentBoxFlexParent"></Flex>
        <Box className="overlayComponentContentBoxChildren">
          <EditProfileStepper
            userData={userData}
            handleFormDataChange={handleFormDataChange}
            prevStep={prevStep}
            activeStep={activeStep}
          />
        </Box>
        <Flex className="overlayComponentContentBoxBtnsFlex">
          <Button
            onClick={activeStep === 0 ? handleOverlayCloser : prevStep}
            className="overlayComponentContentBoxBtn1"
          >
            Back
          </Button>
          {activeStep === 0 ? (
            <Button
              className="overlayComponentContentBoxBtn2"
              color={
                apiLoader ||
                (userData?.fullNameError &&
                  userData?.locationError &&
                  userData?.relationError &&
                  userData?.childNameError &&
                  userData?.genderError &&
                  userData?.childDobError &&
                  userData?.bornWeeksError)
                  ? "#91A2AE"
                  : "white"
              }
              bg={
                apiLoader ||
                (userData?.fullNameError &&
                  userData?.locationError &&
                  userData?.relationError &&
                  userData?.childNameError &&
                  userData?.genderError &&
                  userData?.childDobError &&
                  userData?.bornWeeksError)
                  ? "#F3F8FB"
                  : "#4299E1"
              }
              onClick={handleNextStep}
              isDisabled={
                userData?.fullNameError &&
                userData?.locationError &&
                userData?.relationError &&
                userData?.childNameError &&
                userData?.genderError &&
                userData?.childDobError &&
                userData?.bornWeeksError
              }
            >
              {apiLoader ? <Spinner /> : <Text>Next</Text>}
            </Button>
          ) : (
            <Button
              className="overlayComponentContentBoxBtn2"
              color={
                apiLoader ||
                btnDisabled ||
                (userData?.fullNameError &&
                  userData?.locationError &&
                  userData?.relationError &&
                  userData?.childNameError &&
                  userData?.genderError &&
                  userData?.childDobError &&
                  userData?.bornWeeksError)
                  ? "#91A2AE"
                  : "white"
              }
              bg={
                apiLoader ||
                btnDisabled ||
                (userData?.fullNameError &&
                  userData?.locationError &&
                  userData?.relationError &&
                  userData?.childNameError &&
                  userData?.genderError &&
                  userData?.childDobError &&
                  userData?.bornWeeksError)
                  ? "#F3F8FB"
                  : "#4299E1"
              }
              onClick={handleNextStep}
              isDisabled={
                btnDisabled ||
                (userData?.fullNameError &&
                  userData?.locationError &&
                  userData?.relationError &&
                  userData?.childNameError &&
                  userData?.genderError &&
                  userData?.childDobError &&
                  userData?.bornWeeksError)
              }
            >
              {apiLoader ? <Spinner /> : <Text>Save</Text>}
            </Button>
          )}
        </Flex>
      </Box>
    </Box>
  );
};

export default EditProfile;

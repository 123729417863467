import { call, put } from "redux-saga/effects";
import * as actionCreators from "../actions";
import * as serviceCreator from "../services";
import { Toaster } from "../../common/components/Toaster";

export function* getChildPathwaySagaCall(action) {
  try {
    const response = yield call(
      serviceCreator.getChildPathwayService,
      action.data
    );
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.saveChildPathway(response.data.data));
      yield put(actionCreators.toggleMainLoader(false));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      Toaster(response.data.message, "error");
    }
  } catch (error) {
    console.log(error, "eror");
    yield put(actionCreators.toggleMainLoader(false));
    Toaster(error?.response?.data?.message, "error");
  }
}

export function* addParentOutcomeStatusSagaCall(action) {
  try {
    yield put(actionCreators.toggleAPILoader(true));
    const response = yield serviceCreator.addParentOutcomeStatusService(
      action?.data
    );
    if (response?.status === 200 || response?.status === 201) {
      yield put(actionCreators.toggleAPILoader(false));
      Toaster(response.data.message, "success");
    } else {
      yield put(actionCreators.toggleAPILoader(false));
      Toaster(response.data.message, "error");
    }
  } catch (error) {
    yield put(actionCreators.toggleAPILoader(false));
    Toaster(error.response.data.message, "error");
  }
}

export function* getDomainResourcesSagaCall(action) {
  try {
    const response = yield call(
      serviceCreator.getDomainResourcesService,
      action.data
    );
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.saveDomainResources(response.data.data));
      yield put(actionCreators.toggleMainLoader(false));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      Toaster(response.data.message, "error");
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    Toaster(error.response.data.message, "error");
  }
}

export function* getFavouriteResourcesSagaCall(action) {
  try {
    const response = yield call(serviceCreator.getFavouriteResourcesService);
    if (response.status === 200 || response.status === 201) {
      yield put(actionCreators.saveFavouriteResources(response.data.data));
      yield put(actionCreators.toggleMainLoader(false));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      Toaster(response.data.message, "error");
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    Toaster(error.response.data.message, "error");
  }
}

export function* downloadCheckpointSagaCall(action) {
  try {
    const response = yield call(
      serviceCreator.downloadCheckpointService,
      action.data
    );

    if (response.status === 200 || response.status === 201) {
      // Create a link element
      const link = document.createElement("a");

      // Set the href attribute of the link to the temporary URL
      link.href = response.data.data;

      // Set the download attribute of the link to the file name
      link.setAttribute("download", "report");

      // Append the link to the document body
      document.body.appendChild(link);

      // Click the link to trigger the file download
      link.click();

      // Remove the link from the document body
      document.body.removeChild(link);
      yield put(actionCreators.saveDownloadCheckpoint(response.data.data));
      yield put(actionCreators.toggleMainLoader(false));
    } else {
      yield put(actionCreators.toggleMainLoader(false));
      Toaster(response.data.message, "error");
    }
  } catch (error) {
    yield put(actionCreators.toggleMainLoader(false));
    Toaster(error.response.data.message, "error");
  }
}

import {
  Box,
  Text,
  SimpleGrid,
  GridItem,
  FormControl,
  FormLabel,
  FormErrorMessage,
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import {
  childAgeRangeOptions,
  languageOptions,
  parentServicesOptions,
} from "../../../../constant";
import "../editProfile.css";

const ServiceDetails = ({ userData, handleFormDataChange }) => {
  return (
    <Box>
      <Text className="parentEditProfileHeading">Service Preferences</Text>
      <SimpleGrid gap="16px" mt="16px">
        <GridItem>
          <FormControl isInvalid={userData?.servicesError}>
            <FormLabel className="personalFormLabel">Services</FormLabel>
            <Select
              isMulti
              onChange={(e) => {
                handleFormDataChange(
                  "services",
                  e.map((l) => l.value),
                  "servicesError"
                );
              }}
              name="services"
              value={userData?.services?.map((item) => {
                return {
                  value: item,
                  label: item,
                };
              })}
              selectedOptionStyle="check"
              placeholder="Select required services"
              className="profileFormSelect"
              hideSelectedOptions={false}
              selectedOptionColorScheme="green"
              options={parentServicesOptions}
              isSearchable={false}
            />

            {userData?.servicesError && (
              <FormErrorMessage className="personalErrorMessage">
                {userData?.servicesError}
              </FormErrorMessage>
            )}
          </FormControl>
        </GridItem>

        <GridItem>
          <FormControl isInvalid={userData?.ageRangeError}>
            <FormLabel className="personalFormLabel">
              Child’s age range
            </FormLabel>

            <Select
              onChange={(e) =>
                handleFormDataChange("ageRange", e.value, "ageRangeError")
              }
              name="ageRange"
              value={{
                value: userData?.ageRange,
                label: userData?.ageRange,
              }}
              selectedOptionStyle="check"
              placeholder="Select your child's age range"
              className="profileFormSelect"
              hideSelectedOptions={false}
              selectedOptionColorScheme="green"
              options={childAgeRangeOptions}
              isSearchable={false}
            />

            {userData?.ageRangeError && (
              <FormErrorMessage className="personalErrorMessage">
                {userData?.ageRangeError}
              </FormErrorMessage>
            )}
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={userData?.languagesError}>
            <FormLabel className="personalFormLabel">Language</FormLabel>

            <Select
              isMulti
              onChange={(e) =>
                handleFormDataChange(
                  "languages",
                  e.map((l) => l.value),
                  "languagesError"
                )
              }
              name="languages"
              value={userData?.languages?.map((item) => {
                return {
                  value: item,
                  label: item,
                };
              })}
              selectedOptionStyle="check"
              placeholder="Select preferred languages"
              className="profileFormSelect"
              hideSelectedOptions={false}
              selectedOptionColorScheme="green"
              options={languageOptions}
              isSearchable={false}
            />

            {userData?.languagesError && (
              <FormErrorMessage className="personalErrorMessage">
                {userData?.languagesError}
              </FormErrorMessage>
            )}
          </FormControl>
        </GridItem>
      </SimpleGrid>
    </Box>
  );
};
export default ServiceDetails;

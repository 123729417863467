import { Box, Button, Flex, Grid, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import SingleDomainCard from "../utils/singleDomainCard";
import SingleDomainPage from "../utils/singleDomainPage";
import { useDispatch } from "react-redux";
import { getChildsDomainAction } from "../../../../modules/actions";
import { useSelector } from "react-redux";
import Loader from "../../../../common/components/Loader";

const Domain = ({ selectedChild, type }) => {
  const dispatch = useDispatch();
  const loaderState = useSelector((state) => state?.loader?.apiLoader);
  const { childDomains } = useSelector((state) => state?.user);

  const [domainsData, setDomainsData] = useState([]);

  const returnColorForTask = (task) => {
    if (task === "Social, communication & language") {
      return ["#f3ebff", "#b186f3"];
    }
    if (task === "Therapy Readiness & Speech") {
      return ["#ffebf5", "#f687b3"];
    }
    if (task === "Physical Skills") {
      return ["#fff9eb", "#fab84b"];
    }
    if (task === "Emotional wellness") {
      return ["#ffebf5", "#f687b3"];
    }
    if (task === "Self-Help Personal") {
      return ["#eff7e6", "#7cd177"];
    }
    if (task === "Numeracy & Logic") {
      return ["#eff7e6", "#7cd177"];
    }
    if (task === "Cognitive / General awareness") {
      return ["#ffebf5", "#F687B3"];
    }
    if (task === "Literacy and Grammar") {
      return ["#eff7e6", "#90CAF4"];
    }
  };

  useEffect(() => {
    const createDomainsData = () => {
      if (childDomains === null || childDomains === []) return;
      const updatedDomainsData = childDomains?.map((cd) => {
        return {
          id: cd?.id,
          image: cd?.icon,
          title: cd?.name,
          desc:
            cd?.description ||
            "Description of what this domain measures and how the parent can help in 3 lines max.",
          levelsAssigned: cd?.assignedOutcomes,
          levelsCompleted: cd?.completedOutcomes,
          levelsAssignedColor: returnColorForTask(cd?.name)[0],
          levelsCompletedColor: returnColorForTask(cd?.name)[1],
          data: cd?.outcomes?.map((cdo) => {
            return {
              id: cdo?.id,
              taskName: cdo?.name,
              subgoalOf: cdo?.domainName,
              status: cdo?.checkpointStatus,
            };
          }),
        };
      });
      setDomainsData(updatedDomainsData);
    };
    createDomainsData();
  }, [childDomains]);

  const [individualContent, setIndividualContent] = useState(false);
  const [page, setPage] = useState(null);

  useEffect(() => {
    dispatch(getChildsDomainAction(selectedChild));
  }, [dispatch, selectedChild]);

  return (
    <>
      {loaderState ? (
        <Loader text={"Loading domain details"} />
      ) : (
        <>
          {individualContent ? (
            <SingleDomainPage
              setIndividualContent={setIndividualContent}
              data={domainsData[page].data}
              title={domainsData[page].title}
              desc={domainsData[page].desc}
            />
          ) : (
            <Box id="mainWrapper" px="16px" py="24px">
              <Text className="txnsTabText1">Domains</Text>
              <Text w={{ md: "640px", sm: "100%" }} className="txnsTabText2">
                Provides an overview of the child's progress across 8 domains.
                Prioritize domains which have less progress as areas that need
                improvement.
              </Text>
              {/* {type !== "parentProgress" && (
                <Button
                  mt="8px"
                  className="leavesApplyLeaveBtn"
                  _hover={{ bg: "white" }}
                >
                  Learn More
                </Button>
              )} */}

              {domainsData?.length > 0 ? (
                <Grid
                  templateColumns={{ md: "repeat(12, 1fr)", sm: "" }}
                  // flexWrap="wrap"
                  justify="start"
                  className="domainSimpleGrid"
                >
                  {domainsData.map((d, index) => (
                    <SingleDomainCard
                      key={d.id}
                      d={d}
                      index={index}
                      setPage={setPage}
                      setIndividualContent={setIndividualContent}
                    />
                  ))}
                </Grid>
              ) : (
                <Text color="#141719" mt={10} fontSize="20px">
                  You don't have any active domains for the child
                </Text>
              )}
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default Domain;

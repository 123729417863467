import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  ModalFooter,
  ModalCloseButton,
  Button,
  Image,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { NO_SHOW, ROUTES_PATH } from "../../constant";
import "./paymentDetailsPopup.css";

const ValidityExpiredPopup = ({ isOpen, onClose, parent }) => {
  const navigate = useNavigate();

  const handleTopupBalance = () => {
    navigate(ROUTES_PATH.PARENT_CHOOSE_PLAN);
  };
  return (
    <Modal
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      // closeOnOverlayClick={false}
      size="sm"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader className="paymentDetailsModalHeader">
          <Image src={NO_SHOW} mr="4px" height="18px" width="18px" />
          {parent?.currentBalance <= 0
            ? "No Balance"
            : "Pack Validity Expired!"}
        </ModalHeader>

        <ModalCloseButton />
        <ModalBody className="popupBody">
          {parent?.currentBalance < 1
            ? "Unfortunately, you have exhausted your session balance. "
            : "Unfortunately, your plan is expired. "}
          If you wish to continue taking sessions with your KinderPass Experts,
          please purchase more sessions.
          <br />
          <br />
          {parent?.currentBalance < 1 ? (
            ""
          ) : (
            <>
              You are in a grace period.Your remaining balnace is{" "}
              {parent.currentBalance}.
            </>
          )}
        </ModalBody>

        <ModalFooter mt="16px" borderTop="1px solid #a6b8c4">
          <Button
            _hover={{
              bg: "#4299E1",
              color: "#FFFFFF",
            }}
            className="modalButtons2"
            bg="#4299E1"
            color="#FFFFFF"
            onClick={() => {
              handleTopupBalance();
            }}
          >
            Top Up Balance
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
export default ValidityExpiredPopup;

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Avatar,
  Box,
  Button,
  Flex,
  Grid,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { EmailIcon, PhoneIcon } from "@chakra-ui/icons";
import AuthenticatedContainer from "../../../container/Authenticated";
import Expert from "./Tabs/expert";
import ParentChild from "./Tabs/child";
import EditProfile from "../EditProfile";
import {
  toggleMainLoader,
  getUserProfile,
  toggleSessionLoader,
  getBalanceDetails,
} from "../../../modules/actions";
import ConfirmationPopup from "../../../components/ConfirmationPopup";
import { signout } from "../../../utils/firebase";

const MyProfile = () => {
  const dispatch = useDispatch();
  const reduxState = useSelector((state) => state?.user?.profile);
  const { mainLoader, sessionLoader } = useSelector((state) => state?.loader);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [activeStep, setActiveStep] = useState("Child");
  const [overlayOpen, setOverlayOpen] = useState(false);

  const tabs = [
    {
      id: "Child",
      value: <ParentChild loader={mainLoader || sessionLoader} />,
    },
    { id: "Expert", value: <Expert /> },
  ];

  useEffect(() => {
    dispatch(toggleMainLoader(true));
    const token = localStorage.getItem("idToken");
    dispatch(getUserProfile(token));
    dispatch(toggleSessionLoader(true));
    dispatch(getBalanceDetails());
  }, []);

  return (
    <AuthenticatedContainer>
      {overlayOpen && <EditProfile setOverlayOpen={setOverlayOpen} />}
      <Grid
        maxW="100vw"
        overflowX="clip"
        position="sticky"
        zIndex="10"
        top="0"
        row
        className="profileLayoutBanner"
      ></Grid>
      <Box px={{ base: "16px", md: "72px" }} py="16px">
        <Box
          bg="white"
          zIndex="5"
          h={{ base: "0px", md: "130px" }}
          w="full"
          pos={{ base: "unset", md: "fixed" }}
          top="98px"
        ></Box>
        <Box
          bg="white"
          zIndex="1000"
          pos={{ base: "unset", md: "sticky" }}
          top={{ base: "95px", md: "171px" }}
          id="firstBox-NameAndProfileEdit"
        >
          <Flex
            flexDir={{ base: "column", lg: "row" }}
            justify="space-between"
            align={{ base: "start", lg: "center" }}
          >
            <Flex gap="16px" flexDir={{ base: "column", md: "row" }}>
              <Avatar
                className="myProfileIndexAvatar"
                name={reduxState?.fullName}
                src={reduxState?.image}
              />
              <Box>
                <Text className="myProfileIndexText">
                  {reduxState?.fullName}
                </Text>
                <Flex
                  mt={{ base: "8px", md: "4px" }}
                  flexDir={{ base: "column", md: "row" }}
                >
                  <Flex gap="4px" mr="8px">
                    <EmailIcon
                      style={{ color: "#4299E1" }}
                      className="myProfileIndexIcons"
                    />
                    <Text className="myProfileIndexIconsText">
                      {reduxState?.email}
                    </Text>
                  </Flex>
                  {/* <Text style={{ marginTop: "-5px" }}>.</Text> */}
                  <Flex
                    gap="4px"
                    ml={{ base: "0px", md: "8px" }}
                    mt={{ base: "8px", md: "0px" }}
                  >
                    <PhoneIcon
                      style={{ color: "#8751D9" }}
                      className="myProfileIndexIcons"
                    />
                    <Text className="myProfileIndexIconsText">
                      {/* {reduxState?.countryCode} */}
                      {reduxState?.phoneNumber}
                    </Text>
                  </Flex>
                </Flex>
              </Box>
            </Flex>
            <Flex
              ml={{ base: "0px", md: "110px" }}
              mb={{ base: "20px", lg: "0" }}
              mt={{ base: "16px", lg: "-35px" }}
              className="myProfileIndexFirstBoxButtonFlex"
            >
              <Button
                onClick={() => {
                  setOverlayOpen(true);
                }}
                className="myProfileIndexFirstBoxButtonFlexBtns"
                _hover={{ bg: "white" }}
                width="100%"
              >
                Edit Profile
              </Button>
              <Button
                className="myProfileIndexFirstBoxButtonFlexBtns"
                _hover={{ bg: "white" }}
                onClick={onOpen}
                width="100%"
              >
                Logout
              </Button>
            </Flex>
          </Flex>
        </Box>
        <Box
          w="100vw"
          pos={{ base: "unset", md: "sticky" }}
          top={{ base: "300px", lg: "240px" }}
          bg="white"
          zIndex={1000}
          boxShadow="0px 1px 2px 1px rgba(0, 0, 0, 0.16)"
          transform={{ base: "translateX(-15px)", md: "translateX(-72px)" }}
        ></Box>
        <Flex
          id="secondBox-DynamicSteps"
          flexDir={{ base: "column", lg: "row" }}
        >
          <Flex
            className="myProfileIndexSecondBoxMainFlexContainer"
            minW={{ base: "full", lg: "288px" }}
            flexDir={{ base: "row", lg: "column" }}
            gap={{ base: "20px", lg: "16px" }}
            id="left-side"
            h="full"
            // zIndex={100}
            bg="white"
            pos={{ base: "unset", md: "sticky" }}
            top={{ base: "300px", lg: "240px" }}
          >
            {tabs.map((item) => (
              <Flex key={item.id}>
                <Text
                  className={
                    item.id === activeStep
                      ? "myProfileIndexSecondBoxFirstMapText activeTab"
                      : "myProfileIndexSecondBoxFirstMapText inActiveTab"
                  }
                  w="full"
                  _hover={{ cursor: "pointer" }}
                  rounded={{ base: "none", md: "full" }}
                  onClick={() => setActiveStep(item.id)}
                >
                  {item.id}
                </Text>
              </Flex>
            ))}
          </Flex>
          <Box
            sx={{
              "& .chakra-form__required-indicator": {
                color: "black",
              },
            }}
            w="full"
            id="right-side"
            mb={{ base: "100px", md: "0px" }}
          >
            {tabs.map((item) => (
              <Box key={item.id}>{activeStep === item.id && item.value}</Box>
            ))}
          </Box>
        </Flex>
      </Box>
      <ConfirmationPopup
        isOpen={isOpen}
        onClose={onClose}
        title="Are you sure want to logout?"
        body="You will be logged out from the portal and you can come back anytime with login details."
        handleSecondaryButtonClick={signout}
        btnText1="Logout"
        btnText2="Cancel"
      />
    </AuthenticatedContainer>
  );
};
export default MyProfile;

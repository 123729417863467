import * as ActionTypes from "./actionTypes";

export const getTherapistSessions = (data) => ({
  type: ActionTypes.GET_THERAPIST_SESSIONS,
  data,
});

export const saveTherapistSessions = (data) => ({
  type: ActionTypes.SAVE_THARAPIST_SESSIONS,
  data,
});

export const getParentSessions = (status, data) => ({
  type: ActionTypes.GET_PARENT_SESSIONS,
  status,
  data,
});

export const saveParentSessions = (data) => ({
  type: ActionTypes.SAVE_PARENT_SESSIONS,
  data,
});

export const getOpenSlots = (data, func) => ({
  type: ActionTypes.GET_OPEN_SLOTS,
  data,
  func,
});

export const saveOpenSlots = (data) => ({
  type: ActionTypes.SAVE_OPEN_SLOTS,
  data,
});

export const getRescheduleOpenSlots = (data) => ({
  type: ActionTypes.GET_RESCHEDULE_OPEN_SLOTS,
  data,
});

export const saveRescheduleOpenSlots = (data) => ({
  type: ActionTypes.SAVE_RESCHEDULE_OPEN_SLOTS,
  data,
});

export const getLastAttendedSession = (data) => ({
  type: ActionTypes.GET_LAST_ATTENDED_SESSION,
  data,
});

export const saveLastAttendedSession = (data) => ({
  type: ActionTypes.SAVE_LAST_ATTENDED_SESSION,
  data,
});

export const getSessionById = (data) => ({
  type: ActionTypes.GET_SESSION_BY_ID,
  data,
});

export const saveSessionById = (data) => ({
  type: ActionTypes.SAVE_SESSION_BY_ID,
  data,
});

export const startMeeting = (data) => ({
  type: ActionTypes.START_MEETING,
  data,
});

export const saveStartMeeting = (data) => ({
  type: ActionTypes.SAVE_START_MEETING,
  data,
});

export const getParentUpcomingSession = (data) => ({
  type: ActionTypes.GET_PARENT_UPCOMING_SESSION,
  data,
});

export const saveParentUpcomingSession = (data) => ({
  type: ActionTypes.SAVE_PARENT_UPCOMING_SESSION,
  data,
});

export const joinConsulatation = (data) => ({
  type: ActionTypes.GET_JOIN_CONSULATATION,
  data,
});

export const saveJoinConsulatation = (data) => ({
  type: ActionTypes.SAVE_JOIN_CONSULATATION,
  data,
});

export const getConsultationById = (data) => ({
  type: ActionTypes.GET_CONSULTATION_BY_ID,
  data,
});

export const saveConsultationById = (data) => ({
  type: ActionTypes.SAVE_CONSULTATION_BY_ID,
  data,
});

export const getConsultationReport = (data) => ({
  type: ActionTypes.GET_CONSULTATION_REPORT,
  data,
});

export const saveConsultationReport = (data) => ({
  type: ActionTypes.SAVE_CONSULTATION_REPORT,
  data,
});

import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  GridItem,
  Text,
  Flex,
  Image,
  Textarea,
  Button,
  Spinner,
  useDisclosure,
} from "@chakra-ui/react";
import UnauthenticatedContainer from "../../container/Unauthenticated";
import {
  DISAPPOINTED_FACE,
  DISAPPOINTED_FACE_ACTIVE,
  ROUTES_PATH,
  SLIGHTLY_SMILING_FACE,
  SLIGHTLY_SMILING_FACE_ACTIVE,
  SMILINGWITH_HEART_SHAPED_EYES,
  SMILINGWITH_HEART_SHAPED_EYES_ACTIVE,
  SMILINGWITH_OPEN_MOUTH,
  SMILINGWITH_OPEN_MOUTH_ACTIVE,
  WORRIED_FACE,
  WORRIED_FACE_ACTIVE,
} from "../../constant";
import { getDatewithDay, getDate } from "../../utils/customMoment";
import FeedbackPopup from "../../components/FeedbackPopup";
import { Toaster } from "../../common/components/Toaster";
import { addFeedbackService } from "../../modules/services";
import {
  getSessionById,
  toggleFeddbackLoader,
  toggleMainLoader,
} from "../../modules/actions";
import { feedbackImprovementFields } from "../../constant/selectOptions";
import "./feedback.css";
import Loader from "../../common/components/Loader";

const feedbackImgs = [
  { img: DISAPPOINTED_FACE, value: 1, activeImg: DISAPPOINTED_FACE_ACTIVE },
  { img: WORRIED_FACE, value: 2, activeImg: WORRIED_FACE_ACTIVE },
  {
    img: SLIGHTLY_SMILING_FACE,
    value: 3,
    activeImg: SLIGHTLY_SMILING_FACE_ACTIVE,
  },
  {
    img: SMILINGWITH_OPEN_MOUTH,
    value: 4,
    activeImg: SMILINGWITH_OPEN_MOUTH_ACTIVE,
  },
  {
    img: SMILINGWITH_HEART_SHAPED_EYES,
    value: 5,
    activeImg: SMILINGWITH_HEART_SHAPED_EYES_ACTIVE,
  },
];
const FeedbackPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  let { sessionId, userId } = useParams();

  const { mainLoader, feedbackLoader } = useSelector((state) => state?.loader);
  const session = useSelector((state) => state?.sessionById);

  const [feedback, setFeedback] = useState({
    rating: "",
    ratingError: "",
    comment: "",
    commentError: "",
    improvement_fields: [],
  });

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (sessionId) {
      dispatch(toggleMainLoader(true));
      dispatch(getSessionById(sessionId));
    }
  }, [sessionId]);

  useEffect(() => {
    if (
      (Object.keys(session).length > 0 &&
        session.constructor === Object &&
        session?.feedback[pathname.includes("expert") ? "expert" : "parent"]
          .feedback) ||
      (Object.keys(session).length > 0 &&
        session.constructor === Object &&
        getDate(session?.feedback?.parent.expiryTime) < new Date())
    ) {
      navigate(ROUTES_PATH.SIGNIN);
    }
  }, [session]);

  const handleFeedbackDataChange = (name, value, errorName) => {
    setFeedback({ ...feedback, [name]: value, [errorName]: "" });
  };
  const handleImprovementFields = (value) => {
    let fields = feedback.improvement_fields;
    if (fields.includes(value)) {
      fields.splice(fields.indexOf(value), 1);
    } else {
      fields.push(value);
    }
    setFeedback({ ...feedback, improvement_fields: fields });
  };
  const handleFeedbackSubmit = () => {
    const { comment, rating, improvement_fields } = feedback;

    const validateRating = !rating ? "Please choose rating smiley" : "";

    setFeedback({
      ...feedback,
      ratingError: validateRating,
    });
    if (!validateRating) {
      const reqData = {
        rating,
        comment,
        improvement_fields,
        sessionId: session.id,
        role: pathname.includes("expert") ? "expert" : "parent",
      };
      dispatch(toggleFeddbackLoader(true));
      addFeedbackService(reqData)
        .then((res) => {
          dispatch(toggleFeddbackLoader(false));
          onOpen();
        })
        .catch((error) => {
          console.log(error);
          dispatch(toggleFeddbackLoader(false));
          Toaster(error.response.data.message, "error");
        });
    }
  };

  return (
    <UnauthenticatedContainer>
      {mainLoader ? (
        <Loader text="Loading..." />
      ) : (
        <Box className="feedbackMainDiv">
          <Grid templateColumns="repeat(12, 1fr)">
            <GridItem colSpan={4} colStart={5} className="feedbackFormDiv">
              <Text className="feedbackHeading">Rate your last session *</Text>
              <Text className="feedbackSubHeading">
                {session.serviceType} with{" "}
                {pathname.includes("expert")
                  ? session.sessionStudentName
                  : session.sessionExpertName}{" "}
                on {getDatewithDay(session.sessionDate)} at {session.startTime}
              </Text>
              <Flex mt="24px" justifyContent="space-between" height="50px">
                {feedbackImgs.map((item, index) => (
                  <Image
                    height={feedback.rating === item.value ? "50px" : "40px"}
                    src={
                      feedback.rating === item.value ? item.activeImg : item.img
                    }
                    key={index}
                    cursor="pointer"
                    onClick={() =>
                      handleFeedbackDataChange(
                        "rating",
                        item.value,
                        "ratingError"
                      )
                    }
                  />
                ))}
              </Flex>
              {feedback?.ratingError && (
                <Box className="personalErrorMessage">
                  {feedback?.ratingError}
                </Box>
              )}
              <Text className="feedbackHeading" mt="24px">
                Tell us what can be improved?
              </Text>
              <Grid display="flex" flexWrap="wrap" gap="8px" mb="24px">
                {feedbackImprovementFields.map((item, index) => (
                  <GridItem
                    key={index}
                    className="imporvedFields"
                    onClick={() => handleImprovementFields(item)}
                    style={
                      feedback.improvement_fields.includes(item)
                        ? {
                            backgroundColor: "#4299E1",
                            color: "#FFFFFF",
                          }
                        : {}
                    }
                  >
                    {item}
                  </GridItem>
                ))}
              </Grid>
              <Textarea
                maxLength={250}
                name="comment"
                value={feedback?.comment}
                onChange={(e) =>
                  handleFeedbackDataChange(
                    "comment",
                    e.target.value,
                    "commentError"
                  )
                }
                placeholder="Tell us how can we improve..."
                size="sm"
              />
              <Button
                w="100%"
                _hover={{
                  bg: "#4299E1",
                  color: "#FFFFFF",
                }}
                className="feedbackSubmit"
                onClick={() => {
                  handleFeedbackSubmit();
                }}
              >
                {feedbackLoader ? <Spinner /> : "Submit"}
              </Button>
            </GridItem>
          </Grid>
        </Box>
      )}

      <FeedbackPopup
        isOpen={isOpen}
        onClose={onClose}
        feedback={feedback}
        thankYouOpen={true}
      />
    </UnauthenticatedContainer>
  );
};

export default FeedbackPage;

import { Grid, GridItem, Heading, Text } from "@chakra-ui/react";
import moment from "moment";
import SessionStatBlock from "../../common/components/SessionStatBlock";
import "./scheduleHeader.css";

const ScheduleHeader = ({ countData, expertName, loader }) => {
  return (
    <Grid templateColumns="repeat(8, 1fr)" gap={4}>
      <GridItem colSpan={3} p={4} className="expertNameBox">
        <Heading className="expertName">Welcome back, {expertName}</Heading>
        <Text className="subHeading">Here is your schedule for this week</Text>
      </GridItem>
      {!loader && (
        <>
          <GridItem colStart={5} colEnd={7} p={4}>
            <SessionStatBlock
              type="Sessions "
              month={moment().format("MMMM") + " " + moment().format("YYYY")}
              count={countData?.sessions}
            />
          </GridItem>
          <GridItem colStart={7} colEnd={9} p={4}>
            <SessionStatBlock
              type="Consultations "
              month={moment().format("MMMM") + " " + moment().format("YYYY")}
              count={countData?.consultations}
            />
          </GridItem>
        </>
      )}
    </Grid>
  );
};
export default ScheduleHeader;

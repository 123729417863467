import { GENERAL_GROUP } from "./images";

export const sessionStatus = [
  { value: "", label: "All" },
  { value: "Attended", label: "Attended" },
  { value: "Cancelled", label: "Cancelled" },
  { value: "Scheduled", label: "Scheduled" },
  { value: "Rescheduled", label: "Rescheduled" },
  { value: "Partially Attended", label: "Partially attended" },
  // { value: "Needs ACR update", label: "Needs ACR update" },
  { value: "noShow-Parent", label: "No show by parent" },
  { value: "noShow-Expert", label: "No show by expert" },
];

export const languageOptions = [
  { value: "English", label: "English" },
  { value: "Hindi", label: "Hindi" },
  { value: "Marathi", label: "Marathi" },
  { value: "Gujarati", label: "Gujarati" },
  { value: "Telugu", label: "Telugu" },
  { value: "Tamil", label: "Tamil" },
  { value: "Kannada", label: "Kannada" },
  { value: "Malayalam", label: "Malayalam" },
  { value: "Bengali", label: "Bengali" },
  // { value: "Manipuri", label: "Manipuri" },
  // { value: "Odiya", label: "Odiya" },
  // { value: "Haryanvi", label: "Haryanvi" },
  // { value: "Marwari", label: "Marwari" },
  // { value: "Tulu", label: "Tulu" },
  // { value: "Dogri", label: "Dogri" },
  // { value: "Santali", label: "Santali" },
  // { value: "Assamese", label: "Assamese" },
  // { value: "Maithili", label: "Maithili" },
  // { value: "Kashmiri", label: "Kashmiri" },
  // { value: "Sindhi", label: "Sindhi" },
  // { value: "Konkani", label: "Konkani" },
  // { value: "Rajasthani", label: "Rajasthani" },
  // { value: "Urdu", label: "Urdu" },
  // { value: "Punjabi", label: "Punjabi" },
  // { value: "Nepali", label: "Nepali" },
  { value: "Others", label: "Others" },
];

export const areaOfConcerns = [
  { value: "Hearing Impairment", label: "Hearing Impairment" },
  { value: "Speech Delay", label: "Speech Delay" },
  { value: "Dysartria", label: "Dysartria" },
  {
    value: "Orofacial Myofunctional Disorders (OMD)",
    label: "Orofacial Myofunctional Disorders (OMD)",
  },
  { value: "Speech Sound Disorders", label: "Speech Sound Disorders" },
  { value: "Stuttering", label: "Stuttering" },
  { value: "Voice Disorders", label: "Voice Disorders" },
  {
    value: "Autism Spectrum Disorder (ASD)",
    label: "Autism Spectrum Disorder (ASD)",
  },
  {
    value: "Attention Deficit Hyperactivity Disorder (ADHD)",
    label: "Attention Deficit Hyperactivity Disorder (ADHD)",
  },
  {
    value: "Intellectual Disablity (ID)",
    label: "Intellectual Disablity (ID)",
  },
  { value: "Mental Retardation (MR)", label: "Mental Retardation (MR)" },
  { value: "Global Development Delay", label: "Global Development Delay" },
  { value: "Down Syndrome (DS)", label: "Down Syndrome (DS)" },
  { value: "Cerebral Palsy (CP)", label: "Cerebral Palsy (CP)" },
  { value: "Dyslexia", label: "Dyslexia" },
  { value: "Spina Bifida", label: "Spina Bifida" },
  { value: "Slow learning", label: "Slow learning" },
  { value: "Anxiety", label: "Anxiety" },
  { value: "Depression", label: "Depression" },
  { value: "Eating Disorders", label: "Eating Disorders" },
  { value: "OCD", label: "OCD" },
  { value: "Trauma-related Disorders", label: "Trauma-related Disorders" },
  {
    value: "Disruptive Behaviour Disorders",
    label: "Disruptive Behaviour Disorders",
  },
  { value: "Vision Impairment", label: "Vision Impairment" },
  { value: "Low Vision", label: "Low Vision" },
  { value: "Others", label: "Others" },
];

export const serviceOptions = [
  { value: "Parent Counselling", label: "Parent Counselling" },
  { value: "Remedial Intervention", label: "Remedial Intervention" },
  { value: "Family Training", label: "Family Training" },
  { value: "Special Education - STEM", label: "Special Education - STEM" },
  { value: "Behavior Therapy", label: "Behavior Therapy" },
  { value: "Speech Therapy", label: "Speech Therapy" },
  { value: "Child Counselling", label: "Child Counselling" },
  { value: "Formal Assessments", label: "Formal Assessments" },
  { value: "Occupational Therapy", label: "Occupational Therapy" },
  { value: "Public Speaking Class", label: "Public Speaking Class" },
  { value: "Other", label: "Other" },
];

export const workingDaysOptions = [
  { value: "Monday", label: "Monday" },
  { value: "Tuesday", label: "Tuesday" },
  { value: "Wednesday", label: "Wednesday" },
  { value: "Thursday", label: "Thursday" },
  { value: "Friday", label: "Friday" },
  { value: "Saturday", label: "Saturday" },
  { value: "Sunday", label: "Sunday" },
];

export const weekDaysOptions = [
  { value: "sun", label: "Sunday" },
  { value: "mon", label: "Monday" },
  { value: "tue", label: "Tuesday" },
  { value: "wed", label: "Wednesday" },
  { value: "thu", label: "Thursday" },
  { value: "fri", label: "Friday" },
  { value: "sat", label: "Saturday" },
];

export const smallWeekDaysOptions = [
  { value: "Sun", label: "Sun" },
  { value: "Mon", label: "Mon" },
  { value: "Tue", label: "Tue" },
  { value: "Wed", label: "Wed" },
  { value: "Thu", label: "Thu" },
  { value: "Fri", label: "Fri" },
  { value: "Sat", label: "Sat" },
];

export const monthOptions = [
  { value: "January", label: "January" },
  { value: "February", label: "February" },
  { value: "March", label: "March" },
  { value: "April", label: "April" },
  { value: "May", label: "May" },
  { value: "June", label: "June" },
  { value: "July", label: "July" },
  { value: "August", label: "August" },
  { value: "September", label: "September" },
  { value: "October", label: "October" },
  { value: "November", label: "November" },
  { value: "December", label: "December" },
];

export const leaveStatusOptions = [
  { value: "Upcoming", label: "Upcoming" },
  { value: "Past", label: "Past" },
];

export const videos = [
  "https://soicalprod.s3.ap-southeast-1.amazonaws.com/expertVideos/Divya+ppt_compressed+(1).mp4",
  "https://soicalprod.s3.ap-southeast-1.amazonaws.com/expertVideos/Introduction+to+the+KP+consultation+process+flow+(1).mp4",
  "https://soicalprod.s3.ap-southeast-1.amazonaws.com/expertVideos/Part+2-+consultation+process+flow+(1).mp4",
  "https://soicalprod.s3.ap-southeast-1.amazonaws.com/expertVideos/Part+3-+Consultation+process+flow+(2).mp4",
];
export const whatsappGroupData = [
  {
    main: "General WhatsApp group",
    categories: [
      {
        title: "General group",
        image: GENERAL_GROUP,
        link: "https://chat.whatsapp.com/F36JensNYlFKZ28Ka3oVBl",
      },
    ],
  },
  // {
  //   main: "Domain specific groups",
  //   categories: [
  //     { title: "Speech therapist", image: SPEECH_GROUP },
  //     {
  //       title: "Behavioural therapist, Child counsellor, Special educator",
  //       image: BEHAVIOUR_GROUP,
  //     },
  //     { title: "Occupational therapist", image: OCCUPATIONAL_GROUP },
  //   ],
  // },
];
export const genderOptions = [
  { value: "male", label: "Boy" },
  { value: "female", label: "Girl" },
];

export const relationOptions = [
  {
    value: "mother",
    label: "Mother",
  },
  {
    value: "father",
    label: "Father",
  },
];

export const parentServicesOptions = [
  {
    value: "Speech & Communication",
    label: "Speech & Communication",
  },
  { value: "Focus & Attention", label: "Focus & Attention" },
  {
    value: "Cognition & Logical thinking",
    label: "Cognition & Logical thinking",
  },
  {
    value: "Self-care/ Activities of daily living",
    label: "Self-care/ Activities of daily living",
  },
  {
    value: "Social-Emotional Interactions",
    label: "Social-Emotional Interactions",
  },
  {
    value: "Sensory Processing",
    label: "Sensory Processing",
  },
  {
    value: "Academic Slow Learning",
    label: "Academic Slow Learning",
  },
  {
    value: "Physical/Motor Skills",
    label: "Physical/Motor Skills",
  },
  {
    value: "Others",
    label: "Others",
  },
];

export const childAgeRangeOptions = [
  {
    value: "0-2",
    label: "0-2",
  },

  {
    value: "2-6",
    label: "2-6",
  },

  {
    value: "6-12",
    label: "6-12",
  },

  {
    value: "12-15",
    label: "12-15",
  },

  {
    value: "15-20",
    label: "15-20",
  },

  {
    value: "20+",
    label: "20+",
  },
];

export const cancelRescheduleReasons = [
  {
    value: "Child is unavailable",
    label: "Child is unavailable",
  },

  {
    value: "Academic commitments",
    label: "Academic commitments",
  },

  {
    value: "Technical difficulties",
    label: "Technical difficulties",
  },

  {
    value: "Running late",
    label: "Running late",
  },

  {
    value: "Medical emergency",
    label: "Medical emergency",
  },

  {
    value: "Personal emergency",
    label: "Personal emergency",
  },

  {
    value: "Out of town",
    label: "Out of town",
  },
];

export const feedbackImprovementFields = [
  "Time slots",
  "Services",
  "Punctuality",
  "Communication",
  "Transparency",
];

export const cancelRescheduleReasonsExpert = [
  {
    value: "Technical difficulties",
    label: "Technical difficulties",
  },
  {
    value: "Personal emergency",
    label: "Personal emergency",
  },
  {
    value: "Medical emergency",
    label: "Medical emergency",
  },
  {
    value: "On leave",
    label: "On leave",
  },
  {
    value: "Frequency 1 session a week",
    label: "Frequency 1 session a week",
  },
  {
    value: "Frequency 1 session a fortnight",
    label: "Frequency 1 session a fortnight",
  },
  {
    value: "Time slot reserved for another client",
    label: "Time slot reserved for another client",
  },
];

export const childAge = [
  {
    value: "0-6",
    label: "0-6",
  },
  {
    value: "7-18",
    label: "7-18",
  },
  {
    value: "19-36",
    label: "19-36",
  },
  {
    value: "37-60",
    label: "37-60",
  },
  // {
  //   value: ">60",
  //   label: ">60",
  // },
];

export const primaryConcerns = [
  {
    value: "Lacks speech clarity",
    label: "Lacks speech clarity",
  },
  {
    value: "Delayed motor milestones",
    label: "Delayed motor milestones",
  },
  {
    value: "Delayed speech milestones",
    label: "Delayed speech milestones",
  },
  {
    value: "Stutters",
    label: "Stutters",
  },
  {
    value: "Stammers",
    label: "Stammers",
  },
  {
    value: "Misarticulation",
    label: "Misarticulation",
  },
  {
    value: "Lacks muscle tone",
    label: "Lacks muscle tone",
  },
  {
    value: "Displays auditory seeking behaviour",
    label: "Displays auditory seeking behaviour",
  },
  {
    value: "Displays auditory avoidsnce behaviour",
    label: "Displays auditory avoidsnce behaviour",
  },
  {
    value: "Displays prorpioceptory sensory concerns",
    label: "Displays prorpioceptory sensory concerns",
  },
  {
    value: "Displays vestibular sensory concerns",
    label: "Displays vestibular sensory concerns",
  },
  {
    value: "Displays tactile avoidance behaviour",
    label: "Displays tactile avoidance behaviour",
  },
  {
    value: "Displays tactile seeking behaviour",
    label: "Displays tactile seeking behaviour",
  },
  {
    value: "Lacks sitting tolerance",
    label: "Lacks sitting tolerance",
  },
  {
    value: "Hyperactive",
    label: "Hyperactive",
  },
  {
    value: "Restlessness",
    label: "Restlessness",
  },
  {
    value: "Fidgety",
    label: "Fidgety",
  },
  {
    value: "Squirms on the seat",
    label: "Squirms on the seat",
  },
  {
    value: "Lacks sustained attention span",
    label: "Lacks sustained attention span",
  },
  {
    value: "Lacks focus",
    label: "Lacks focus",
  },
  {
    value: "Loss of appetite",
    label: "Loss of appetite",
  },
  {
    value: "Disturbed sleep cycle",
    label: "Disturbed sleep cycle",
  },
  {
    value: "Sweaty palms",
    label: "Sweaty palms",
  },
  {
    value: "Remains low and cries",
    label: "Remains low and cries",
  },
  {
    value: "Lacks sufficient semantic skills",
    label: "Lacks sufficient semantic skills",
  },
  {
    value: "Engages in an overflow of negative thoughts juth",
    label: "Engages in an overflow of negative thoughts juth",
  },
  {
    value: "Engages in overflow of thoughts",
    label: "Engages in overflow of thoughts",
  },
  {
    value: "Poor gait stability",
    label: "Poor gait stability",
  },
  {
    value: "Lacks balance and coordination",
    label: "Lacks balance and coordination",
  },
  {
    value: "Displays low self esteem",
    label: "Displays low self esteem",
  },
  {
    value: "Avoids going out",
    label: "Avoids going out",
  },
  {
    value: "Decreased socialization",
    label: "Decreased socialization",
  },
  {
    value: "Illegible writing",
    label: "Illegible writing",
  },
  {
    value: "Displays mirror imaging",
    label: "Displays mirror imaging",
  },
  {
    value: "Displays difficulty in comprehending math calculations/operations",
    label: "Displays difficulty in comprehending math calculations/operations",
  },
  {
    value: "Cannot differentiate left from right",
    label: "Cannot differentiate left from right",
  },
  {
    value: "Poor memory retention",
    label: "Poor memory retention",
  },
  {
    value: "Poor awareness on activities of daily living",
    label: "Poor awareness on activities of daily living",
  },
  {
    value: "Poor awareness on concept of money",
    label: "Poor awareness on concept of money",
  },
  {
    value: "Poor eye contact",
    label: "Poor eye contact",
  },
  {
    value: "Poor response to name call",
    label: "Poor response to name call",
  },
];

export const diagnosis = [
  {
    value: "Autism Spectrum Disorder",
    label: "Autism Spectrum Disorder",
  },
  {
    value: "Attention Deficit Hyperactivity Disorder",
    label: "Attention Deficit Hyperactivity Disorder",
  },
  {
    value: "Sensory Processing Disorder",
    label: "Sensory Processing Disorder",
  },
  {
    value: "Speech-Language Disorder",
    label: "Speech-Language Disorder",
  },
  {
    value: "Spoken Language Disorder",
    label: "Spoken Language Disorder",
  },
  {
    value: "Cerebral Palsy",
    label: "Cerebral Palsy",
  },
  {
    value: "Down's Syndrome",
    label: "Down's Syndrome",
  },
  {
    value: "Intellectual Disability",
    label: "Intellectual Disability",
  },
  {
    value: "Learning disability",
    label: "Learning disability",
  },
  {
    value: "Pervasive Developmental Disorder",
    label: "Pervasive Developmental Disorder",
  },
  {
    value: "Asperger's Syndrome",
    label: "Asperger's Syndrome",
  },
  {
    value: "Clinical Depression",
    label: "Clinical Depression",
  },
  {
    value: "Anxiety Disorder",
    label: "Anxiety Disorder",
  },
  {
    value: "Developmental Delay",
    label: "Developmental Delay",
  },
  {
    value: "Global Developmental Delay",
    label: "Global Developmental Delay",
  },
  {
    value: "Fragile X Syndrome",
    label: "Fragile X Syndrome",
  },
  {
    value: "Tourette's Syndrome",
    label: "Tourette's Syndrome",
  },
  {
    value: "Spina Bifida",
    label: "Spina Bifida",
  },
  {
    value: "Hydrocephalus",
    label: "Hydrocephalus",
  },
  {
    value: "Auditory Processing Disorder",
    label: "Auditory Processing Disorder",
  },
  {
    value: "Muscular Dystrophy",
    label: "Muscular Dystrophy",
  },
  {
    value: "Oppositional Defiant Disorder",
    label: "Oppositional Defiant Disorder",
  },
  {
    value: "Post Traumatic Stress Disorder",
    label: "Post Traumatic Stress Disorder",
  },
  {
    value: "Seizure Disorder",
    label: "Seizure Disorder",
  },
  {
    value: "High Muscle tone",
    label: "High Muscle tone",
  },
  {
    value: "Fine motor Delays",
    label: "Fine motor Delays",
  },
  {
    value: "Feeding Difficulty",
    label: "Feeding Difficulty",
  },
  {
    value: "Stroke",
    label: "Stroke",
  },
  {
    value: "Digestive Disorder",
    label: "Digestive Disorder",
  },
  {
    value: "Articulation Disorder",
    label: "Articulation Disorder",
  },
  {
    value: "Disfluency Disorder",
    label: "Disfluency Disorder",
  },
  {
    value: "Phonological Disorders",
    label: "Phonological Disorders",
  },
  {
    value: "Childhood Apraxia of Speech",
    label: "Childhood Apraxia of Speech",
  },
  {
    value: "Dysarthria",
    label: "Dysarthria",
  },
  {
    value: "Orofacial Myofunctional Disorders",
    label: "Orofacial Myofunctional Disorders",
  },
  {
    value: "Speech Sound Disorders",
    label: "Speech Sound Disorders",
  },
  {
    value: "Stuttering",
    label: "Stuttering",
  },
];

export const sessionNo = [
  {
    value: 1,
    label: 1,
  },
  {
    value: 2,
    label: 2,
  },
  {
    value: 3,
    label: 3,
  },
  {
    value: 4,
    label: 4,
  },
  {
    value: 5,
    label: 5,
  },
];

export const cusultationReportServices = [
  { value: "Parent Counselling", label: "Parent Counselling" },
  { value: "Remedial Intervention", label: "Remedial Intervention" },
  { value: "Family Training", label: "Family Training" },
  { value: "Special Education - STEM", label: "Special Education - STEM" },
  { value: "Behavior Therapy", label: "Behavior Therapy" },
  { value: "Speech Therapy", label: "Speech Therapy" },
  { value: "Child Counselling", label: "Child Counselling" },
  { value: "Occupational Therapy", label: "Occupational Therapy" },
  { value: "Public Speaking Class", label: "Public Speaking Class" },
  // { value: "Other", label: "Other" },
];

export const specialEducationRemediationEvalution = [
  {
    mainSkill: "Self-help skills",
    data: [
      {
        skillName: "Eating skills",
        description:
          "Eats with spoon/hand/to be fed/ eats independently, holds head up while eating, opens mouth when fed, swallows a spoonful of soft food that does not require chewing, picks up dry food, eats mixed food",
      },
      {
        skillName: "Drinking skills",
        description:
          "Holds bottle independently, sits on chair or lap while drinking",
      },
      {
        skillName: "Toileting skills",
        description:
          "Indicates for use of washroom, defecates in the washroom, brushes teeth, takes a bath",
      },
      {
        skillName: "Dressing skills",
        description:
          "Pulls pants, wears clothes, can zip and unzip, button and unbutton, putting on/removing shoes",
      },
    ],
  },
  {
    mainSkill: "Basic Cognitive Abilities",
    data: [
      {
        skillName: "Eye Contact",
        description: "",
      },
      {
        skillName: "Sitting behavior/Visual contact with task",
        description: "",
      },
      {
        skillName: "Attention span",
        description: "",
      },
      {
        skillName: "Name call response",
        description: "",
      },
      {
        skillName: "Tends to daydream",
        description: "",
      },
      {
        skillName: "Fidgets/Squirms",
        description: "",
      },
    ],
  },
  {
    mainSkill: "Communication and Socialization skills",
    data: [
      {
        skillName: "Greeting skills",
        description: "",
      },
      {
        skillName: "Turn-taking skills (Verbal and gestural)",
        description: "",
      },
      {
        skillName: "Requesting skills",
        description: "",
      },
      {
        skillName: "Following basic commands",
        description: "",
      },
      {
        skillName: "Awareness of lexical categories",
        description:
          "Child is aware of basic concepts-body parts/primary colors, fruits/vegetables",
      },
      {
        skillName: "Peer interaction",
        description:
          "Likes being around family/peers or avoids being around family/peers",
      },
      {
        skillName: "Self-esteem",
        description: "",
      },
    ],
  },
  {
    mainSkill: "Academic performance",
    data: [
      {
        skillName: "Studying and Analytic skills",
        description: "Child has difficulty remembering information",
      },
      {
        skillName: "Reading",
        description: "",
      },
      {
        skillName: "Spelling",
        description: "",
      },
      {
        skillName: "Writing",
        description:
          "Spacing, spelling, rotations, transpositions, mirror imaging, messy, lacks sufficient grammar and syntax",
      },

      {
        skillName: "Copying",
        description: "",
      },
    ],
  },
  {
    mainSkill: "Visual-motor difficulties",
    data: [
      {
        skillName: "Coordination",
        description: "Clumsy, displays poor coordination",
      },
      {
        skillName: "Eye- hand coordination",
        description: "",
      },
      {
        skillName: "Visual coordination for reading",
        description: "Loses place/ track while reading",
      },
    ],
  },
];

export const behavioralCounsellingEvalution = [
  {
    mainSkill: "Social skills",
    data: [
      {
        skillName: "Social smile",
        description: "",
      },
      {
        skillName: "Recognition of parents",
        description: "",
      },
      {
        skillName: "Discrimination of strangers",
        description: "",
      },
      {
        skillName: "Solo Play/Group play/Pretend play",
        description: "",
      },
      {
        skillName: "Avoids mingling with family/peers",
        description: "",
      },
      {
        skillName: "Repetitive behavior",
        description: "",
      },
      {
        skillName: "Verbally expresses feelings",
        description: "",
      },
      {
        skillName: "Screen Time Exposure",
        description: "",
      },
    ],
  },
  {
    mainSkill: "Basic Cognitive Abilities",
    data: [
      {
        skillName: "Eye Contact/ Visual contact with task",
        description: "",
      },
      {
        skillName: "Sitting behavior/Hyperactive",
        description: "",
      },
      {
        skillName: "Attention span",
        description: "",
      },
      {
        skillName: "Name call response",
        description: "",
      },
      {
        skillName: "Throws tantrums",
        description: "",
      },
      {
        skillName: "Disobedient/ Disrespectful",
        description: "",
      },
      {
        skillName: "Defiant",
        description: "",
      },
      {
        skillName: "Tends to daydream",
        description: "",
      },
      {
        skillName: "Fidgets/Squirms",
        description: "",
      },
      {
        skillName: "Aggressive and impulsive",
        description: "",
      },
    ],
  },
  {
    mainSkill: "Eating Habits",
    data: [
      {
        skillName: "Poor appetite",
        description: "",
      },
      {
        skillName: "Overeating",
        description: "",
      },
      {
        skillName: "Food Allergies",
        description: "",
      },
      {
        skillName: "Any specific diet recommended",
        description: "",
      },
    ],
  },
  {
    mainSkill: "Sleep Pattern/Anxiety/Depression",
    data: [
      {
        skillName: "Difficulty falling asleep",
        description: "",
      },
      {
        skillName: "Difficulty waking up",
        description: "",
      },
      {
        skillName: "Unable to sleep",
        description: "",
      },
      {
        skillName: "Self-esteem/ confidence",
        description: "",
      },

      {
        skillName: "Suicidal attempts/ideations",
        description: "",
      },
      {
        skillName: "Excessively sweaty palms",
        description: "",
      },
      {
        skillName: "Irrational fears",
        description: "",
      },
      {
        skillName: "Avoidance of places/people",
        description: "",
      },
      {
        skillName: "Fear of failure-Remains low and upset",
        description: "",
      },
      {
        skillName: "Dip in academic performance",
        description: "",
      },
    ],
  },
];

export const speechLanguageEvalution = [
  {
    mainSkill: "Language skills",
    data: [
      {
        skillName: "Mode of communication (Language/gestures)",
        description: "",
      },
    ],
  },
  {
    mainSkill: "Comprehensive skills",
    data: [
      {
        skillName:
          "Child knows basic lexical categories- Animals, Fruits, Vegetables and Vehicles",
        description: "",
      },
      {
        skillName: "Child follows commands(2-3 commands)",
        description: "",
      },
    ],
  },
  {
    mainSkill: "Expressive skills",
    data: [
      {
        skillName: "Names basic lexical items",
        description: "",
      },
      {
        skillName:
          "Answers simple “Who?” “What?” “Where?” and “Why?” questions",
        description: "",
      },
    ],
  },
  {
    mainSkill: "Pragmatic skills",
    data: [
      {
        skillName: "",
        description: "",
      },
    ],
  },
  {
    mainSkill: "Articulation skills",
    data: [
      {
        skillName: "Names, letters ",
        description: "",
      },
    ],
  },
  {
    mainSkill: "Fluency",
    data: [
      {
        skillName:
          "Fair blocks,hesitancy,repetition observed While Conversing, reading ,doing formal.oral academic work",
        description: "",
      },
    ],
  },
];

export const motorSkills = [
  {
    mainSkill: "Gross motor skills",
    data: [
      {
        skillName: "Throwing ball",
        description: "",
      },
      {
        skillName: "Muscle tone",
        description: "",
      },
      {
        skillName: "Muscle Strength",
        description: "",
      },
      {
        skillName: "Coordination - (catching the ball)",
        description: "",
      },
      {
        skillName: "Laddering",
        description: "",
      },
      {
        skillName: "Cycling",
        description: "",
      },
      {
        skillName: "Jumping",
        description: "",
      },
    ],
  },
  {
    mainSkill: "Fine Motor skills",
    data: [
      {
        skillName: "Zipping",
        description: "",
      },
      {
        skillName: "Reach",
        description: "",
      },
      {
        skillName: "Grasp/Grip strength",
        description: "",
      },
      {
        skillName: "In-hand manipulation",
        description: "",
      },
      {
        skillName: "Gripping",
        description: "",
      },
    ],
  },
  {
    mainSkill: "Sensory Development",
    data: [
      {
        skillName:
          "Vestibular skills (Tolerance to movement)Difficulty sitting",
        description: "",
      },
      {
        skillName: "Is accident-prone",
        description: "",
      },
      {
        skillName:
          "Tends to keep jumping-Vestibular skills (Gravitational Insecurity)",
        description: "",
      },
      {
        skillName: "Fear of falling",
        description: "",
      },
      {
        skillName: "Avoids playground equipment",
        description: "",
      },
      {
        skillName: "Avoids walking on uneven surfaces",
        description: "",
      },
      {
        skillName: "Engages in slow movements",
        description: "",
      },
      {
        skillName: "Proprioceptive skills – seeking",
        description:
          "Crashes into things deliberately Bites, pushes other children",
      },
      {
        skillName: "Proprioceptive skills – Difficulties",
        description: "Difficulty in applying appropriate amount of pressure",
      },
      {
        skillName: "Visual skills",
        description: "",
      },
      {
        skillName: "Auditory skills",
        description: "",
      },
      {
        skillName: "Tactile sensitivity",
        description: "",
      },
    ],
  },
  {
    mainSkill: "Vegetative skills",
    data: [
      {
        skillName: "Chewing",
        description: "",
      },
      {
        skillName: "Blowing",
        description: "",
      },
      {
        skillName: "Sucking",
        description: "",
      },
      {
        skillName: "Spitting",
        description: "",
      },
      {
        skillName: "Brushing",
        description: "",
      },
      {
        skillName: "Swallowing",
        description: "",
      },
    ],
  },
  {
    mainSkill: "Bowel and Bladder control",
    data: [
      {
        skillName: "",
        description: "",
      },
    ],
  },
  {
    mainSkill: "Scholastic skills",
    data: [
      {
        skillName: "Sitting tolerance",
        description: "",
      },
      {
        skillName: "Scribbling",
        description: "",
      },
      {
        skillName: "Hand painting",
        description: "",
      },
    ],
  },
  {
    mainSkill: "ADL’s(Activities of daily living)",
    data: [
      {
        skillName: "",
        description: "",
      },
    ],
  },
];

export const acrQuickTips = [
  "Poor-Dependent",
  "Learning-with physical assistance",
  "Average",
  "Does with verbal Prompts",
  "Independent/Skill mastered",
];

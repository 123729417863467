import React, { Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  // withRouter,
} from "react-router-dom";
import { connect } from "react-redux";
import { ROUTES_PATH } from "../constant";
import Schedule from "../pages/Expert/Schedule";
import Login from "../pages/Expert/Login";
import Onboarding from "../pages/Expert/Onboarding";
import MyProfile from "../pages/Expert/MyProfile";
import MyStudents from "../pages/Expert/MyStudents";
import ParentLogin from "../pages/Parent/Login";
import ParentHome from "../pages/Parent/Home";
import Exercise from "../pages/Parent/Exercise";
import FirstLoginToDashboard from "../pages/Expert/FirstLogin/firstLoginToDashboard";
import Resource from "../pages/Expert/Resource";
import ParentProgress from "../pages/Parent/Progress";
import Pending from "../pages/Expert/Onboarding/Steps/pending";
import Pathway from "../pages/Expert/MyStudents/Tabs/pathway";
import OnboardingStart from "../pages/Expert/Resource/Tabs/OnboardingStart";
import ExerciseResource from "../pages/Parent/Exercise/exerciseResource";
import ParentMyProfile from "../pages/Parent/MyProfile";
import ParentSessionsPage from "../pages/Parent/Session";
import FeedbackPage from "../pages/Feedback";
import ChoosePlan from "../pages/Parent/ChoosePlan";
import Explore from "../pages/Parent/Explore";
import ActivityDetails from "../pages/Parent/Explore/activityDetails";
import Articles from "../pages/Parent/Explore/articles";
import BookingDetails from "../pages/Parent/Explore/bookingDetails";
import ACRReport from "../pages/Expert/ACRReport";
import SupportLogin from "../pages/Support/Login";

const App = (props) => {
  let routes;
  if (
    props.isAuthenticated &&
    props.isExpertRegistered &&
    props?.user?.verified
  ) {
    if (props?.user?.role === "expert") {
      routes = (
        <Routes>
          {props?.user?.isRegisteredFirst ? (
            <>
              <Route
                path={ROUTES_PATH.EXPRT_REGISTER_SUCCESS}
                element={<FirstLoginToDashboard />}
              />
              <Route
                exact
                path={"*"}
                element={<Navigate to={ROUTES_PATH.EXPRT_REGISTER_SUCCESS} />}
              />
            </>
          ) : (
            <>
              <Route path={ROUTES_PATH.SCHEDULE} element={<Schedule />} />
              <Route path={ROUTES_PATH.PROFILE} element={<MyProfile />} />
              <Route path={ROUTES_PATH.MY_STUDENTS} element={<MyStudents />} />
              <Route path={ROUTES_PATH.RESOURCE} element={<Resource />} />
              <Route
                path={ROUTES_PATH.EXPERT_ONBOARDING_RESOURCES}
                element={<OnboardingStart />}
              />
              <Route path={ROUTES_PATH.EXPERT_PATHWAY} element={<Pathway />} />
              <Route
                path={ROUTES_PATH.EXPERT_FEEDBACK}
                element={<FeedbackPage />}
              />
              <Route
                path={ROUTES_PATH.PARENT_FEEDBACK}
                element={<FeedbackPage />}
              />
              <Route path={ROUTES_PATH.ACRREPORT} element={<ACRReport />} />
              <Route
                exact
                path={"*"}
                element={<Navigate to={ROUTES_PATH.SCHEDULE} />}
              />
            </>
          )}
        </Routes>
      );
    } else if (props?.user?.role === "parent") {
      routes = (
        <Routes>
          <Route path={ROUTES_PATH.PARENT_HOME} element={<ParentHome />} />
          <Route path={ROUTES_PATH.PARENT_EXERCISE} element={<Exercise />} />
          <Route
            path={ROUTES_PATH.EXPERT_FEEDBACK}
            element={<FeedbackPage />}
          />
          <Route
            path={ROUTES_PATH.PARENT_FEEDBACK}
            element={<FeedbackPage />}
          />
          <Route
            path={ROUTES_PATH.PARENT_EXERCISE_RESOURCE}
            element={<ExerciseResource />}
          />
          <Route
            path={ROUTES_PATH.PARENT_SESSION}
            element={<ParentSessionsPage />}
          />
          <Route
            path={ROUTES_PATH.PARENT_PROGRESS}
            element={<ParentProgress />}
          />
          <Route
            path={ROUTES_PATH.PARENT_PROFILE}
            element={<ParentMyProfile />}
          />
          <Route
            path={ROUTES_PATH.PARENT_CHOOSE_PLAN}
            element={<ChoosePlan />}
          />
          <Route path={ROUTES_PATH.PARENT_EXPLORE} element={<Explore />} />
          <Route
            path={ROUTES_PATH.PARENT_ACTIVITIES}
            element={<ActivityDetails />}
          />
          <Route path={ROUTES_PATH.PARENT_ARTICLES} element={<Articles />} />
          <Route
            path={ROUTES_PATH.PARENT_LIVE_BOOKING}
            element={<BookingDetails />}
          />
          <Route
            exact
            path={"*"}
            element={<Navigate to={ROUTES_PATH.PARENT_HOME} />}
          />
        </Routes>
      );
    } else {
      routes = (
        <Routes>
          <Route path={ROUTES_PATH.PARENT_SIGNIN} element={<ParentLogin />} />
          <Route
            exact
            path={"*"}
            element={<Navigate to={ROUTES_PATH.PARENT_SIGNIN} />}
          />
        </Routes>
      );
    }
  } else if (!props.isAuthenticated) {
    if (window.location.pathname === "/expert/signin") {
      routes = (
        <Routes>
          <Route path={ROUTES_PATH.SIGNIN} element={<Login />} />
          <Route
            path={ROUTES_PATH.EXPERT_FEEDBACK}
            element={<FeedbackPage />}
          />
          <Route
            path={ROUTES_PATH.PARENT_FEEDBACK}
            element={<FeedbackPage />}
          />
          <Route
            exact
            path={"*"}
            element={<Navigate to={ROUTES_PATH.SIGNIN} />}
          />
        </Routes>
      );
    } else if (window.location.pathname === "/support/signin") {
      routes = (
        <Routes>
          <Route path={ROUTES_PATH.SUPPORT_SIGNIN} element={<SupportLogin />} />
        </Routes>
      );
    } else {
      routes = (
        <Routes>
          <Route path={ROUTES_PATH.PARENT_SIGNIN} element={<ParentLogin />} />
          {/* <Route path={ROUTES_PATH.SIGNIN} element={<Login />} /> */}
          <Route
            exact
            path={ROUTES_PATH.PARENT_SIGNIN + "/:token"}
            element={<ParentLogin />}
          />
          <Route
            path={ROUTES_PATH.EXPERT_FEEDBACK}
            element={<FeedbackPage />}
          />
          <Route
            path={ROUTES_PATH.PARENT_FEEDBACK}
            element={<FeedbackPage />}
          />

          <Route
            exact
            path={"*"}
            element={<Navigate to={ROUTES_PATH.PARENT_SIGNIN} />}
          />
        </Routes>
      );
    }
  } else {
    if (!props?.user?.registered) {
      routes = (
        <Routes>
          <Route path={ROUTES_PATH.ONBOARDING} element={<Onboarding />} />
          <Route
            exact
            path={"*"}
            element={<Navigate to={ROUTES_PATH.ONBOARDING} />}
          />
        </Routes>
      );
    } else if (!props?.user?.verified) {
      routes = (
        <Routes>
          <Route
            path={ROUTES_PATH.EXPERT_VERIFICATION_PENDING}
            element={<Pending />}
          />
          <Route
            exact
            path={"*"}
            element={<Navigate to={ROUTES_PATH.EXPERT_VERIFICATION_PENDING} />}
          />
        </Routes>
      );
    } else if (props?.user?.isRegisteredFirst) {
      routes = (
        <Routes>
          <Route
            path={ROUTES_PATH.EXPRT_REGISTER_SUCCESS}
            element={<FirstLoginToDashboard />}
          />
          <Route
            exact
            path={"*"}
            element={<Navigate to={ROUTES_PATH.EXPRT_REGISTER_SUCCESS} />}
          />
        </Routes>
      );
    }
  }
  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>{routes}</Suspense>
    </Router>
  );
};

const mapStateToProps = (state) => {
  try {
    return {
      isAuthenticated: state.user.profile.idToken ? true : false,
      isExpertRegistered: state.user.profile.registered ? true : false,
      user: state.user.profile,
    };
  } catch (e) {
    console.log(e);
    return {
      isAuthenticated: false,
      user: state.user.profile,
      isExpertRegistered: false,
    };
  }
};
export default connect(mapStateToProps)(App);

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Avatar,
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  GridItem,
  Image,
  Input,
  SimpleGrid,
  Tag,
  TagLabel,
  Text,
} from "@chakra-ui/react";
import {
  smallWeekDaysOptions,
  weekDaysOptions,
} from "../../../../constant/selectOptions";
import "../myProfile.css";
import {
  INFO_ICON,
  DELETE_ICON,
  ADD_PLUS_ICON,
} from "../../../../constant/images";
import OverlayComponent from "../TabComponents/overlayComponent";
import { Select } from "chakra-react-select";

const WorkingHours = () => {
  const reduxState = useSelector((state) => state);
  const { apiLoader } = useSelector((state) => state?.loader);
  const [closer, setCloser] = useState(false);
  const [overlayOpen, setOverlayOpen] = useState(false);

  const [workingHoursData, setWorkingHoursData] = useState([]);
  const [next, setNext] = useState(false);

  useEffect(() => {
    setWorkingHoursData(reduxState?.user?.profile?.workingHours || []);
  }, []);

  const [timeSlotData, setTimeSlotData] = useState([
    {
      id: 1,
      weekday: "Weekday",
      startTime: "",
      endTime: "",
      noWeekDay: false,
      noStartTime: false,
      noEndTime: false,
    },
  ]);

  useEffect(() => {
    let flag = false;
    timeSlotData.map((d) => {
      if (
        !d.weekday ||
        !d.startTime ||
        !d.endTime ||
        d.noStartTime ||
        d.noEndTime ||
        d.noWeekDay
      )
        return (flag = false);
      return (flag = true);
    });
    if (flag) setNext(true);
  }, [timeSlotData]);

  const handleDataChange = (id, name, value) => {
    setCloser(true);
    setTimeSlotData((prev) =>
      prev.map((d) => (d.id === id ? { ...d, [name]: value } : d))
    );
  };

  const handleAdd = () => {
    setTimeSlotData((prev) => [
      ...prev,
      {
        id: timeSlotData.length + 1,
        weekday: "Weekday",
        startTime: "",
        endTime: "",
        noWeekDay: false,
        noStartTime: false,
        noEndTime: false,
      },
    ]);
  };

  const handleDelete = (id) => {
    if (timeSlotData?.length === 1) return;
    setTimeSlotData((prev) => prev?.filter((q) => q?.id !== id));
  };

  const handleNextStep = () => {
    setTimeSlotData((prev) =>
      prev?.map((item) => {
        return {
          ...item,
          noWeekDay: item?.weekday === "Weekday" || item?.weekday === "",
          noStartTime:
            item?.startTime === "" ||
            item?.startTime.split(":")[0] > item?.endTime.split(":")[0] ||
            (item?.startTime.split(":")[0] === item?.endTime.split(":")[0] &&
              item?.startTime.split(":")[1] > item?.endTime.split(":")[1]),
          noEndTime:
            item?.endTime === "" ||
            item?.startTime.split(":")[0] > item?.endTime.split(":")[0] ||
            (item?.startTime.split(":")[0] === item?.endTime.split(":")[0] &&
              item?.startTime.split(":")[1] > item?.endTime.split(":")[1]),
        };
      })
    );

    let flag = false;
    timeSlotData?.map((d) => {
      if (
        !d?.weekday ||
        !d?.startTime ||
        !d?.endTime ||
        d?.noStartTime ||
        d?.noEndTime ||
        d?.noWeekDay
      )
        return (flag = false);
      return (flag = true);
    });
    if (!flag) return;
    if (flag) setOverlayOpen(false);
  };

  function getWorkingDayData(day) {
    const desiredArray = workingHoursData?.filter(
      (item) => item?.weekday?.toLowerCase() === day
    );
    const length = desiredArray?.length;
    if (length === 0) return "Holiday";
    if (length === 1)
      return `${desiredArray[0]?.startTime} — ${desiredArray[0]?.endTime}`;
    let text = "";
    for (let i = 0; i < length - 1; i++) {
      text += `${desiredArray[i]?.startTime} — ${desiredArray[i]?.endTime}, `;
    }
    let finalText =
      text +
      `${desiredArray[length - 1]?.startTime} — ${
        desiredArray[length - 1]?.endTime
      }`;
    return finalText;
  }

  const selectStyles = {
    control: (styles) => ({
      ...styles,
      border: "none",
      outline: "none",
      boxShadow: "none",
      width: "full",
      // padding: '0px 12px',
      cursor: "pointer",
    }),
    dropdownIndicator: (prev, { selectProps: { menuIsOpen } }) => ({
      ...prev,
      "> svg": {
        transitionDuration: "normal",
        transform: `rotate(${menuIsOpen ? -180 : 0}deg)`,
        color: "#4299E1",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? "#E9F6FF"
          : isFocused
          ? "#F3F8FB"
          : undefined,
        color: isDisabled ? "#ccc" : isSelected ? "#4299E1" : "#141719",
        padding: "10px",
        cursor: isDisabled ? "not-allowed" : "pointer",
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "20px",
        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? "#E9F6FF"
              : "#E9F6FF"
            : undefined,
        },
      };
    },
    singleValue: (styles) => ({
      ...styles,
      color: "#141719",
      fontWeight: "600",
    }),
    menuList: (styles) => ({
      ...styles,
      borderRadius: "8px",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.16)",
    }),
  };

  return (
    <Box id="mainWrapper" px="16px" py="24px">
      {overlayOpen && (
        <OverlayComponent
          setOverlayOpen={setOverlayOpen}
          closer={closer}
          handleNextStep={handleNextStep}
          apiLoader={apiLoader}
          next={next}
          title={"Edit working hours"}
        >
          <Flex className="leavesApplierFlex">
            <Image src={INFO_ICON} />
            <Box className="workingHoursInfoTextBox">
              <Text>
                You can add the same day multiple times for multiple timings.
              </Text>
              <Text>Example: Mon 9:00 - 12:00 and Mon 13:00 - 16:00</Text>
            </Box>
          </Flex>
          {timeSlotData &&
            timeSlotData?.map((item, index) => (
              <Box key={index}>
                <FormControl
                  isInvalid={
                    item?.noWeekDay || item?.noStartTime || item?.noEndTime
                  }
                >
                  <FormLabel
                    className="leavesApplierFlexFormLabel"
                    mb={0}
                    p="16px 16px 8px 16px"
                  >
                    Working hour
                  </FormLabel>
                  <SimpleGrid
                    className="workingHoursMyProfileSimpleGrid1"
                    key={index}
                    gridTemplateColumns={{
                      base: "1fr",
                      md: "1fr 1fr 1fr auto",
                    }}
                  >
                    <GridItem
                      sx={{
                        "& div": {
                          fontWeight: 400,
                          fontSize: "14px",
                        },
                      }}
                    >
                      <Select
                        useBasicStyles
                        chakraStyles={selectStyles}
                        name="weekday"
                        onChange={(e) => {
                          handleDataChange(item.id, "weekday", e.value);
                        }}
                        value={{ value: item.weekday, label: item.weekday }}
                        placeholder="Weekday"
                        className="workingHourTabSelect"
                        options={smallWeekDaysOptions}
                      />
                    </GridItem>
                    <GridItem
                      sx={{
                        "& input": {
                          fontWeight: 400,
                          fontSize: "14px",
                        },
                      }}
                    >
                      <Input
                        type="time"
                        onChange={(e) =>
                          handleDataChange(
                            item.id,
                            e.target.name,
                            e.target.value
                          )
                        }
                        name="startTime"
                        value={item.startTime}
                        h="43px"
                        border="1px solid #A6B8C4"
                        className="workingHoursMyProfileTimeSlotBox1"
                        locale="sv-sv"
                      />
                    </GridItem>
                    <GridItem
                      sx={{
                        "& input": {
                          fontWeight: 400,
                          fontSize: "14px",
                        },
                      }}
                    >
                      <Input
                        locale="sv-sv"
                        value={item.endTime}
                        type="time"
                        className="workingHoursMyProfileTimeSlotBox1"
                        onChange={(e) =>
                          handleDataChange(
                            item.id,
                            e.target.name,
                            e.target.value
                          )
                        }
                        name="endTime"
                        h="43px"
                        border="1px solid #A6B8C4"
                      />
                    </GridItem>
                    {timeSlotData.length > 1 && (
                      <Flex justifyContent="center" alignItems="center" mt={2}>
                        <Image
                          onClick={() => handleDelete(item.id)}
                          h="24px"
                          w="24px"
                          cursor="pointer"
                          src={DELETE_ICON}
                        />
                      </Flex>
                    )}
                  </SimpleGrid>
                  {item.noWeekDay && (
                    <FormErrorMessage
                      p="8px 16px"
                      className="qualificationsErrorMessage"
                    >
                      Weekday cannot be empty
                    </FormErrorMessage>
                  )}
                  {(item.noStartTime || item.noEndTime) && !item.noWeekDay && (
                    <FormErrorMessage
                      p="8px 16px"
                      className="qualificationsErrorMessage"
                    >
                      Please fill the timings properly
                    </FormErrorMessage>
                  )}
                </FormControl>
              </Box>
            ))}
          <Tag
            onClick={handleAdd}
            className="workingHourTag"
            borderRadius="full"
          >
            <Avatar mr="8px" h="24px" w="24px" src={ADD_PLUS_ICON} />
            <TagLabel>Add Working Hour</TagLabel>
          </Tag>
        </OverlayComponent>
      )}
      <Text className="txnsTabText1">Working Hours ( IST )</Text>
      <Text className="txnsTabText2">
        Working hours let parents know what times you're working and when you're
        available for meetings.
      </Text>
      {/* <Button
        className="leavesApplyLeaveBtn"
        mt="16px"
        onClick={() => setOverlayOpen(true)}
        _hover={{ bg: "white", cursor: "pointer" }}
      >
        Edit Working Hours
      </Button> */}
      <Flex className="servicesMainBodyContainer">
        <SimpleGrid columns={1} width="100%" gap="16px">
          {weekDaysOptions.map((day, index) => (
            <Box key={index} maxW="100%">
              <Box display="flex" flexDirection="row">
                <Box width="50%" fontWeight={600}>
                  {day.label}
                </Box>
                <Box width="100%"> {getWorkingDayData(day.value)}</Box>
              </Box>
            </Box>
          ))}
        </SimpleGrid>
      </Flex>
    </Box>
  );
};

export default WorkingHours;

import { Box, Flex, GridItem, Image, Text } from "@chakra-ui/react";
import React from "react";

const SingleDomainCard = ({ d, setIndividualContent, setPage, index }) => {
  return (
    <GridItem
      colSpan={{ xl: 4, lg: 6, md: 6, sm: 12 }}
      pos="relative"
      className="singleDomainCardBox"
      onClick={() => {
        setIndividualContent(true);
        setPage(index);
      }}
    >
      <Image h="48px" w="48px" src={d.image} />
      <Text className="singleDomainCardBoxTitle">{d.title}</Text>
      <Text className="singleDomainCardBoxDesc">{d.desc}</Text>
      <Flex mb="8px" mt="72px" justify="space-between">
        <Text className="singleDomainCardBoxLvlsAssigned">
          Total levels assigned
        </Text>
        <Text className="singleDomainCardBoxLvlsAssigned2">
          {d.levelsAssigned}
        </Text>
      </Flex>
      <Flex wrap="wrap">
        {[...Array(d.levelsCompleted)].map((e, i) => (
          <Box
            className="singleDomainCardBoxDynamicArrays"
            key={i}
            bg={d.levelsCompletedColor}
            borderRadius="full"
          ></Box>
        ))}
        {[...Array(d.levelsAssigned - d.levelsCompleted)].map((e, i) => (
          <Box
            className="singleDomainCardBoxDynamicArrays"
            key={i}
            bg={d.levelsAssignedColor}
            borderRadius="full"
          ></Box>
        ))}
      </Flex>
    </GridItem>
  );
};

export default SingleDomainCard;

import { Box, Text } from "@chakra-ui/react";
import React from "react";

const EditHide = ({ setShowEditHide, setWhichModal, onOpen }) => {
  const handleModalOpen = (title) => {
    setWhichModal(title);
    setShowEditHide(false);
    onOpen();
  };

  return (
    <>
      <Box
        className="editHideOverlayBox"
        onClick={() => {
          setShowEditHide(false);
        }}
      ></Box>
      <Box className="editHideContainerBox">
        <Text
          className="editHideContainerBoxText"
          color="#141719"
          p="8px"
          onClick={() => handleModalOpen("edit")}
        >
          Edit
        </Text>
        <Text
          className="editHideContainerBoxText"
          onClick={() => handleModalOpen("delete")}
          color="#ba1a1a"
          p="0 8px 8px 8px"
        >
          Delete
        </Text>
      </Box>
    </>
  );
};

export default EditHide;

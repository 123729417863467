import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Flex, Text, Tag, Avatar, TagLabel } from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { monthOptions } from "../../../../constant/selectOptions";
import {
  ATTENDED,
  SCHEDULED,
  CANCELLED,
  RESCHEDULED,
  PARTIALLY_ATTENDED,
  ORANGE_WARNING,
  NO_SHOW,
} from "../../../../constant/images";
import { getExpertTransactions } from "../../../../modules/actions";
import "../myProfile.css";
import Loader from "../../../../common/components/Loader";
import {
  getCurrentMonthName,
  getCurrentYearValue,
  getDayNumber1,
  getMonthName1,
  getCurrentYear,
} from "../../../../utils/customMoment";
import { getPreviousYearsArray } from "../../../../utils/customHooks";

const Transactions = () => {
  const dispatch = useDispatch();
  const transactions = useSelector((state) => state?.transactions?.data);
  const apiLoader = useSelector((state) => state?.loader.apiLoader);
  const [selectedData, setSelectedData] = useState({
    month: getCurrentMonthName(),
    year: getCurrentYearValue(),
  });

  const handleCurrentDatesChange = (name, value) => {
    setSelectedData({ ...selectedData, [name]: value });
  };

  useEffect(() => {
    dispatch(getExpertTransactions(selectedData.month, selectedData.year));
  }, [selectedData]);

  const selectStyles = {
    control: (styles) => ({
      ...styles,
      border: "none",
      outline: "none",
      borderRadius: "10px",
      boxShadow: "none",
      width: "full",
      // padding: '0px 12px',
      cursor: "pointer",
    }),
    dropdownIndicator: (prev, { selectProps: { menuIsOpen } }) => ({
      ...prev,
      "> svg": {
        transitionDuration: "normal",
        transform: `rotate(${menuIsOpen ? -180 : 0}deg)`,
        color: "#4299E1",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? "#E9F6FF"
          : isFocused
          ? "#F3F8FB"
          : undefined,
        color: isDisabled ? "#ccc" : isSelected ? "#4299E1" : "#141719",
        padding: "10px",
        cursor: isDisabled ? "not-allowed" : "pointer",
        fontFamily: "Inter",
        fontSize: "14px",
        lineHeight: "20px",
        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? "#E9F6FF"
              : "#E9F6FF"
            : undefined,
        },
      };
    },
    singleValue: (styles) => ({
      ...styles,
      color: "#141719",
      fontWeight: "600",
    }),
    menuList: (styles) => ({
      ...styles,
      borderRadius: "8px",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.16)",
    }),
  };

  const dateHeads = transactions?.transactions
    ? Object.keys(transactions?.transactions)
    : [];

  return (
    <Box>
      {apiLoader ? (
        <Loader text="Loading, Please Wait..." />
      ) : (
        <Box id="mainWrapper" px="16px" py="24px">
          <Text className="txnsTabText1">Transactions</Text>
          <Text className="txnsTabText2">
            Total number of sessions or consultations earned or lost by you in
            this month
          </Text>
          <Flex mt="16px" gap="16px">
            <Select
              useBasicStyles
              options={monthOptions}
              className="txnsTabSelect"
              value={{ label: selectedData.month, value: selectedData.month }}
              chakraStyles={selectStyles}
              name="month"
              onChange={(e) => handleCurrentDatesChange("month", e.value)}
            />
            <Select
              useBasicStyles
              chakraStyles={selectStyles}
              className="txnsTabSelect"
              value={{ label: selectedData.year, value: selectedData.year }}
              name="year"
              options={getPreviousYearsArray()}
              onChange={(e) => handleCurrentDatesChange("year", e.value)}
            />
          </Flex>
          <Flex mt="24px" gap="22px">
            <Box className="txnsFlex2Box1">
              <Flex justify="space-between">
                <Box className="txnsFlex2Box1Box1">
                  <Text mb={0}>Sessions completed</Text>
                  <Text>
                    in {selectedData.month} {selectedData.year}
                  </Text>
                </Box>
                <Text className="txnsFlex2Box1Box1Text1">
                  {transactions?.sessionsCompleted}
                </Text>
              </Flex>
              <Box>
                <Text className="txnsFlex2Box1Box1Text2">
                  {" "}
                  <span className="txnsFlex2Box1Box1Text2span">
                    {Number(transactions?.sessionBalance) > 0
                      ? Number(transactions?.sessionBalance).toFixed(2)
                      : 0}
                  </span>{" "}
                  Sessions Balance
                </Text>
              </Box>
            </Box>
            <Box className="txnsFlex2Box1">
              <Flex justify="space-between">
                <Box className="txnsFlex2Box1Box1">
                  <Text mb={0}>Consultations completed</Text>
                  <Text>
                    in {selectedData.month} {selectedData.year}
                  </Text>
                </Box>
                <Text className="txnsFlex2Box1Box1Text1">
                  {transactions?.consultationsCompleted}
                </Text>
              </Flex>
              <Box>
                <Text className="txnsFlex2Box1Box1Text2">
                  {" "}
                  <span className="txnsFlex2Box1Box1Text2span">
                    {Number(transactions?.consultationBalance) > 0
                      ? Number(transactions?.consultationBalance).toFixed(2)
                      : 0}
                  </span>
                  {"  "}
                  Consultation Balance
                </Text>
              </Box>
            </Box>
          </Flex>
          <Box>
            {dateHeads.map((item, index) => (
              <Box key={index}>
                <Text className="txnsFirstMapBoxText">
                  {getDayNumber1(item) +
                    " " +
                    getMonthName1(item) +
                    ", " +
                    getCurrentYear(item)}
                </Text>
                {transactions?.transactions[item].map(
                  (singleSession, index) => (
                    <Flex key={index} className="txnsSecondMapFlex">
                      <Box>
                        <Flex align="center">
                          <Text className="txnsSecondMapFlexBoxFlexText1">
                            {Number(singleSession?.transactionValue).toFixed(2)}
                          </Text>
                          <Text className="txnsSecondMapFlexBoxFlexText2">
                            {singleSession?.transactionType}
                          </Text>
                        </Flex>
                        <Flex className="txnsSecondMapFlexBoxFlexFlex1">
                          <Text mb={0}>
                            {singleSession?.session?.sessionStudentName} /{" "}
                            {singleSession?.session?.sessionExpertName}
                          </Text>
                          <Text mt="-3px" mb={0}>
                            .
                          </Text>
                          <Text mb={0}>{singleSession?.reason}</Text>
                        </Flex>
                      </Box>
                      <Tag
                        className="txnsSecondMapFlexBoxTag"
                        style={{
                          backgroundColor:
                            ((singleSession?.session?.status ===
                              "Successfull" ||
                              singleSession?.session?.status === "Attended" ||
                              singleSession?.session?.status ===
                                "Partially Attended") &&
                              "#EFF7E6") ||
                            ((singleSession?.session?.status === "Cancelled" ||
                              singleSession?.session?.status ===
                                "noShow-Parent" ||
                              singleSession?.session?.status ===
                                "noShow-Expert") &&
                              "#FFDAD6") ||
                            (singleSession?.session?.status === "Scheduled" &&
                              "#F3EBFF") ||
                            (singleSession?.session?.status === "Rescheduled" &&
                              "#FEDFA0") ||
                            ((singleSession?.session?.status ===
                              "Session Request" ||
                              singleSession?.session?.status ===
                                "Session Request Expired" ||
                              singleSession?.session?.status ===
                                "Rejected Session Request" ||
                              singleSession.status === "REQUEST CANCELLED") &&
                              "#FEDFA0"),
                        }}
                      >
                        <Avatar
                          className="txnsSecondMapFlexBoxTagAvatar"
                          src={
                            ((singleSession?.session?.status ===
                              "Successfull" ||
                              singleSession?.session?.status === "Attended") &&
                              ATTENDED) ||
                            (singleSession?.session?.status ===
                              "Partially Attended" &&
                              PARTIALLY_ATTENDED) ||
                            (singleSession?.session?.status === "Cancelled" &&
                              CANCELLED) ||
                            (singleSession?.session?.status === "Scheduled" &&
                              SCHEDULED) ||
                            (singleSession?.session?.status === "Rescheduled" &&
                              RESCHEDULED) ||
                            ((singleSession?.session?.status ===
                              "Session Request" ||
                              singleSession?.session?.status ===
                                "Session Request Expired" ||
                              singleSession?.session?.status ===
                                "Rejected Session Request" ||
                              singleSession.status === "REQUEST CANCELLED") &&
                              ORANGE_WARNING) ||
                            ((singleSession?.session?.status ===
                              "noShow-Parent" ||
                              singleSession?.session?.status ===
                                "noShow-Expert") &&
                              NO_SHOW)
                          }
                        />
                        <TagLabel
                          style={{
                            color:
                              ((singleSession?.session?.status ===
                                "Successfull" ||
                                singleSession?.session?.status === "Attended" ||
                                singleSession?.session?.status ===
                                  "Partially Attended") &&
                                "#024742") ||
                              ((singleSession?.session?.status ===
                                "Cancelled" ||
                                singleSession?.session?.status ===
                                  "noShow-Parent" ||
                                singleSession?.session?.status ===
                                  "noShow-Expert") &&
                                "#410002") ||
                              (singleSession?.session?.status === "Scheduled" &&
                                "#2F0D63") ||
                              (singleSession?.session?.status ===
                                "Rescheduled" &&
                                "#734110") ||
                              ((singleSession?.session?.status ===
                                "Session Request" ||
                                singleSession?.session?.status ===
                                  "Session Request Expired" ||
                                singleSession?.session?.status ===
                                  "Rejected Session Request" ||
                                singleSession.status === "REQUEST CANCELLED") &&
                                "#734110"),
                          }}
                        >
                          {singleSession?.session?.status}
                        </TagLabel>
                      </Tag>
                    </Flex>
                  )
                )}
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Transactions;

import { createStandaloneToast, useToast } from "@chakra-ui/react";
import "./custom-toast.css";

export const Toaster = (message, status, pos) => {
  const { toast } = createStandaloneToast();

  return toast({
    title: message,
    status: status,
    duration: 3000,
    isClosable: true,
    position: "top-left",
  });
};

export const ToasterComp = ({ message, status }) => {
  const { toast } = useToast();

  return toast({
    title: message,
    status: status,
    duration: 3000,
    isClosable: true,
    position: "top-left",
  });
};

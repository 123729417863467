import {
  Box,
  Flex,
  Image,
  Text,
  Button,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  OPEN_PATHWAY,
  CLOSE_PATHWAY,
  LOCKED_PATHWAY,
} from "../../../../constant/images";
import SingleCheckpointDataTask from "./singleCheckpointDataTask";
import SingleCheckpointModal from "./singleCheckpointModal";
import { Toaster } from "../../../../common/components/Toaster";
import {
  completeCheckpointService,
  startCheckpointService,
} from "../../../../modules/services";
import {
  getChildPathway,
  toggleAPILoader,
  toggleMainLoader,
  toggleModalOpen,
} from "../../../../modules/actions";

const SingleCheckpointData = ({
  c,
  setCheckpointsData,
  checkpointsData,
  checkpointId,
  selectedChild,
  pathway,
  type,
}) => {
  const dispatch = useDispatch();
  const modalOpen = useSelector((state) => state?.modalOpen);
  const [showCheckpoints, setShowCheckpoints] = useState(false);

  const checkpointModalData = [
    {
      handler: `Start Checkpoint ${checkpointId}?`,
      text1: `Checkpoint ${checkpointId} will start and be unlocked for the
    child. Parents will be able to view and do exercises related to
    outcomes assigned at this checkpoint.`,
      text2: `Do you want to start checkpoint ${checkpointId}?`,
      btn: "Start",
    },
    {
      handler: "Complete Checkpoint?",
      text1: `Checkpoint ${checkpointId} will be marked as complete for the child and its current outcome status cannot be changed. Outcomes marked as 'No' or 'Almost' will be moved to checkpoint ${
        checkpointId + 1
      }`,
      text2: `Do you want to mark this checkpoint as complete?`,
      btn: "Complete",
    },
  ];

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [checkpointFunction, setCheckpointFunction] = useState(null);

  const checkYesOutcome = (arr) => {
    if (arr.find((item) => item.status === "Yes")) {
      return true;
    } else {
      return false;
    }
  };

  const selectCheckpointFunctionality = () => {
    if (
      (c.status === "Locked" && checkpointId - 1 === 0) ||
      (c.status === "Locked" &&
        pathway?.checkpoints[checkpointId - 2].status === "Accomplished")
    ) {
      dispatch(toggleModalOpen(true));
      setCheckpointFunction(checkpointModalData[0]);
      onOpen();
    } else if (c.status === "Ongoing" && checkYesOutcome(c.outcomes)) {
      dispatch(toggleModalOpen(true));
      setCheckpointFunction(checkpointModalData[1]);
      onOpen();
    } else return;
  };

  const startCheckpoint = (checkpointId) => {
    const data = {
      userId: selectedChild.id,
      checkpointId,
    };

    dispatch(toggleAPILoader(true));
    startCheckpointService(data)
      .then((res) => {
        Toaster(res.data.message, "success", modalOpen && "top-left");
        dispatch(toggleAPILoader(false));
        dispatch(toggleMainLoader(true));
        dispatch(getChildPathway(selectedChild?.id));
        // const instanceOfCheckpointsData = Array.from(checkpointsData);
        // const findObject = instanceOfCheckpointsData.filter(
        //   (item) => item.id === checkpointId
        // );
        // const targetObject = findObject[0];
        // if (!targetObject.availableUnlock || !targetObject.locked) return;
        // setCheckpointsData((ch) =>
        //   ch.map((c) => (c.id === checkpointId ? { ...c, locked: false } : c))
        // );
      })
      .catch((error) => {
        console.log(error);
        dispatch(toggleAPILoader(false));
        Toaster(error.response.data.message, "error", modalOpen && "top-left");
      });
  };

  const completeCheckpoint = (checkpointId) => {
    const data = {
      userId: selectedChild.id,
      checkpointId,
    };
    dispatch(toggleAPILoader(true));
    completeCheckpointService(data)
      .then((res) => {
        Toaster(res.data.message, "success", modalOpen && "top-left");
        dispatch(toggleAPILoader(false));
        dispatch(toggleMainLoader(true));
        dispatch(getChildPathway(selectedChild?.id));
      })
      .catch((error) => {
        console.log(error.response);
        dispatch(toggleAPILoader(false));
        Toaster(error.response.data.message, "error", modalOpen && "top-left");
      });
  };

  useEffect(() => {
    if (pathway.checkpoints[checkpointId - 1]?.status === "Ongoing") {
      setShowCheckpoints(true);
    } else {
      setShowCheckpoints(false);
    }
  }, [pathway, checkpointId]);

  return (
    <Box
      key={checkpointId}
      className={
        type === "sessionPathwayTab"
          ? "servicesMainBodyContainerForSessionTab"
          : "servicesMainBodyContainer"
      }
    >
      <Flex align="center" justify="space-between">
        <Flex gap="8px" align="center">
          <Image
            style={{ transform: "translateY(-1px)" }}
            src={showCheckpoints ? CLOSE_PATHWAY : OPEN_PATHWAY}
            cursor="pointer"
            onClick={() => setShowCheckpoints(!showCheckpoints)}
          />
          <Text className="singleCheckpointDataId">
            Checkpoint {checkpointId}
          </Text>
          {c.status === "Locked" && (
            <Button className="singleCheckpointDataLockedBtn">
              <Image src={LOCKED_PATHWAY} mr="8px" />
              <Text className="singleCheckpointDataLockedText">Locked</Text>
            </Button>
          )}
        </Flex>
        <Tooltip
          hasArrow
          label={
            c.status === "Locked" &&
            pathway?.checkpoints[checkpointId - 2]?.status !== "Accomplished" &&
            checkpointId - 1 !== 0
              ? "Unlocks when previous checkpoint is completed"
              : c.status === "Locked"
              ? "Unlock?"
              : ""
          }
          className="singleCheckpointDataTooltip"
          placement="top"
          zIndex="9"
        >
          <Button
            className="singleCheckpointDataTooltipBtn"
            onClick={selectCheckpointFunctionality}
            style={{
              backgroundColor:
                c.status === "Ongoing" && checkYesOutcome(c.outcomes)
                  ? "#FFF"
                  : (c.status === "Locked" && checkpointId - 1 === 0) ||
                    (c.status === "Locked" &&
                      pathway?.checkpoints[checkpointId - 2].status ===
                        "Accomplished")
                  ? "#4299E1"
                  : "#F3F8FB",
              color:
                c.status === "Ongoing" && checkYesOutcome(c.outcomes)
                  ? "#141719"
                  : (c.status === "Locked" && checkpointId - 1 === 0) ||
                    (c.status === "Locked" &&
                      pathway?.checkpoints[checkpointId - 2].status ===
                        "Accomplished")
                  ? "#ffffff"
                  : "#91A2AE",
              border:
                c.status === "Ongoing" &&
                checkYesOutcome(c.outcomes) &&
                "1px solid #4299e1",
              cursor:
                c.status === "Ongoing" && checkYesOutcome(c.outcomes)
                  ? "pointer"
                  : (c.status === "Locked" && checkpointId - 1 === 0) ||
                    (c.status === "Locked" &&
                      pathway?.checkpoints[checkpointId - 2].status ===
                        "Accomplished")
                  ? "pointer"
                  : "not-allowed",
            }}
          >
            {c.status === "Accomplished"
              ? "Completed"
              : c.status === "Locked"
              ? "Start checkpoint"
              : "Complete Checkpoint"}
          </Button>
        </Tooltip>
      </Flex>
      {showCheckpoints &&
        c.outcomes.map((ci, index) => (
          <SingleCheckpointDataTask
            key={index}
            checkpointsData={pathway.checkpoints}
            setCheckpointsData={setCheckpointsData}
            locked={c.status === "Locked"}
            ci={ci}
            selectedCheckpoint={checkpointId}
            selectedChild={selectedChild}
            outcomeId={index + 1}
          />
        ))}
      <SingleCheckpointModal
        checkpointFunction={checkpointFunction}
        startCheckpoint={startCheckpoint}
        completeCheckpoint={completeCheckpoint}
        checkPointNumber={checkpointId}
        isOpen={isOpen}
        setShowCheckpoints={setShowCheckpoints}
        onClose={onClose}
      />
    </Box>
  );
};

export default SingleCheckpointData;

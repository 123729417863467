import {
  Box,
  Text,
  SimpleGrid,
  GridItem,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  FormErrorMessage,
  Checkbox,
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import moment from "moment";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "../editProfile.css";
import { genderOptions, relationOptions } from "../../../../constant";

const PersonalDetails = ({ userData, handleFormDataChange }) => {
  return (
    <Box>
      <Text className="parentEditProfileHeading">Personal</Text>
      <SimpleGrid gap="16px" mt="16px">
        <GridItem>
          <FormControl isInvalid={userData?.fullNameError}>
            <FormLabel className="personalFormLabel">Full Name</FormLabel>
            <InputGroup>
              <Input
                onChange={(e) =>
                  handleFormDataChange(
                    e.target.name,
                    e.target.value,
                    "fullNameError"
                  )
                }
                name="fullName"
                value={userData?.fullName}
                placeholder="Enter your full name"
                maxLength={30}
                border="1px solid #A6B8C4"
                className="myProfileFormInput"
              />
            </InputGroup>
            {userData?.fullNameError && (
              <FormErrorMessage className="personalErrorMessage">
                {userData?.fullNameError}
              </FormErrorMessage>
            )}
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl>
            <FormLabel className="personalFormLabel">Email Address</FormLabel>
            <InputGroup>
              <Input
                onChange={(e) =>
                  handleFormDataChange(e.target.name, e.target.value)
                }
                disabled
                type="email"
                placeholder="mailto:ankurtestexpert@gmail.com"
                maxLength={60}
                className="myProfileFormInput"
                name="email"
                value={userData?.email}
                border="1px solid #A6B8C4"
              />
            </InputGroup>
          </FormControl>
        </GridItem>
        <GridItem zIndex={50}>
          <FormControl>
            <FormLabel className="personalFormLabel">Phone Number</FormLabel>
            <InputGroup>
              <PhoneInput
                name="phoneNumber"
                onChange={(e) => handleFormDataChange("phoneNumber", e)}
                disabled
                country={"in"}
                value={userData?.countryCode + userData?.phoneNumber}
              />
            </InputGroup>
          </FormControl>
        </GridItem>
        <GridItem zIndex={50}>
          <FormControl>
            <FormLabel className="personalFormLabel">WhatsApp Number</FormLabel>
            <InputGroup>
              <PhoneInput
                name="whatsAppNumber"
                onChange={(e) => handleFormDataChange("whatsAppNumber", e)}
                disabled
                country={"in"}
                value={userData?.whatsAppNumber}
              />
            </InputGroup>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={userData?.locationError}>
            <FormLabel className="personalFormLabel">Location</FormLabel>
            <InputGroup>
              <Input
                onChange={(e) =>
                  handleFormDataChange(
                    e.target.name,
                    e.target.value,
                    "locationError"
                  )
                }
                name="location"
                value={userData.location}
                placeholder="Enter your location"
                maxLength={30}
                border="1px solid #A6B8C4"
                className="myProfileFormInput"
              />
            </InputGroup>
            {userData?.locationError && (
              <FormErrorMessage className="personalErrorMessage">
                {userData?.locationError}
              </FormErrorMessage>
            )}
          </FormControl>
        </GridItem>
        <GridItem pb="24px" borderBottom="1px solid #D8E5EE">
          <FormControl isInvalid={userData?.relationError}>
            <FormLabel className="personalFormLabel">
              Relation with child
            </FormLabel>
            <Select
              onChange={(e) =>
                handleFormDataChange("relation", e.value, "relationError")
              }
              name="relation"
              value={
                userData?.relation === "mother"
                  ? { label: "Mother", value: "mother" }
                  : { label: "Father", value: "father" }
              }
              selectedOptionStyle="check"
              placeholder="Select your relation with child"
              className="profileFormSelect"
              hideSelectedOptions={false}
              selectedOptionColorScheme="green"
              options={relationOptions}
              isSearchable={false}
            />

            {userData?.relationError && (
              <FormErrorMessage className="personalErrorMessage">
                {userData?.relationError}
              </FormErrorMessage>
            )}
          </FormControl>
        </GridItem>
        <Text className="parentEditProfileHeading">Child</Text>
        <GridItem>
          <FormControl isInvalid={userData?.childNameError}>
            <FormLabel className="personalFormLabel">Child’s name</FormLabel>
            <InputGroup>
              <Input
                onChange={(e) =>
                  handleFormDataChange(
                    e.target.name,
                    e.target.value,
                    "childNameError"
                  )
                }
                name="childName"
                value={userData?.childName}
                placeholder="Enter your child name"
                maxLength={30}
                border="1px solid #A6B8C4"
                className="myProfileFormInput"
              />
            </InputGroup>
            {userData?.childNameError && (
              <FormErrorMessage className="personalErrorMessage">
                {userData?.childNameError}
              </FormErrorMessage>
            )}
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={userData?.genderError}>
            <FormLabel className="personalFormLabel">Child’s gender</FormLabel>

            <Select
              onChange={(e) =>
                handleFormDataChange("gender", e.value, "genderError")
              }
              name="gender"
              value={{
                value: userData?.gender,
                label: userData?.gender === "male" ? "Boy" : "Girl",
              }}
              selectedOptionStyle="check"
              placeholder="Select your child's gender"
              className="profileFormSelect"
              hideSelectedOptions={false}
              selectedOptionColorScheme="green"
              options={genderOptions}
              isSearchable={false}
            />

            {userData?.genderError && (
              <FormErrorMessage className="personalErrorMessage">
                {userData?.genderError}
              </FormErrorMessage>
            )}
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={userData?.childDobError}>
            <FormLabel className="personalFormLabel">Date of birth</FormLabel>
            <InputGroup>
              <Input
                onChange={(e) =>
                  handleFormDataChange(
                    e.target.name,
                    e.target.value,
                    "childDobError"
                  )
                }
                type="date"
                name="childDob"
                value={userData?.childDob}
                id="dob"
                placeholder="Enter your child's DOB"
                className="myProfileFormInput"
                border="1px solid #A6B8C4"
                max={moment().format("YYYY-MM-DD")}
              />
            </InputGroup>
            {userData?.childDobError && (
              <FormErrorMessage className="personalErrorMessage">
                {userData?.childDobError}
              </FormErrorMessage>
            )}
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl textAlign="left" isInvalid={userData?.bornWeeksError}>
            <Checkbox
              name="preemie"
              isChecked={userData?.preemie}
              className="personalFormLabel"
              onChange={(e) =>
                handleFormDataChange(
                  e.target.name,
                  e.target.checked,
                  "bornWeeksError"
                )
              }
            >
              Born at less than 37 weeks?
            </Checkbox>

            {userData?.preemie && (
              <InputGroup>
                <Input
                  onChange={(e) =>
                    handleFormDataChange(
                      e.target.name,
                      e.target.value,
                      "bornWeeksError"
                    )
                  }
                  name="bornWeeks"
                  value={userData?.bornWeeks}
                  placeholder="Enter born weeks"
                  maxLength={30}
                  border="1px solid #A6B8C4"
                  className="myProfileFormInput"
                />
              </InputGroup>
            )}
            {userData?.bornWeeksError && (
              <FormErrorMessage className="personalErrorMessage">
                {userData?.bornWeeksError}
              </FormErrorMessage>
            )}
          </FormControl>
        </GridItem>
      </SimpleGrid>
    </Box>
  );
};
export default PersonalDetails;

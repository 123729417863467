import {
  Box,
  Text,
  Image,
  Flex,
  Grid,
  GridItem,
  Input,
  InputGroup,
} from "@chakra-ui/react";
import {
  GREEN_CHECK_MARK,
  MEDICAL_HISTORY,
  PLUS_ICON_BLACK,
} from "../../../../constant";

const medicalIssues = [
  "Infections",
  "UTI",
  "High Blood Pressure",
  "Iron Deficiency",
  "Gestational Diabetes",
  "Others",
];

const postnatalMedicalIssues = [
  "Infections",
  "Breathing Problems",
  "Seizures and medication",
  "Feeding problems",
  "Neonatal Jaundice",
  "Others",
];
const babyCryOptions = ["Yes", "No", "NA"];
const birthWeightOptions = ["Below 2.5 kg", "2.5 to 3.5 kgs", "Above 3.5 Kg"];
const generationPeriodOptions = ["Full Term", "Premature"];
const developmentalMilestoneOptions = [
  "All milestones attained on time",
  "Only motor milestones delayed",
  "Only speech milestones delayed",
];
const YesNoOptions = ["Yes", "No"];

const MedicalHistory = ({
  medicalInfo,
  handleFormDataChange,
  handleMultipleSelection,
}) => {
  return (
    <Box className="specialistDiv">
      <Flex mb="24px">
        <Image src={MEDICAL_HISTORY} alt="Medical History" mr="8px" />
        <Text className="tabHeading">Medical History</Text>
      </Flex>
      <Text className="subHeading2" mt="24px">
        PreNatal
      </Text>
      <Grid
        templateColumns="repeat(5, 1fr)"
        gap="16px"
        alignItems="flex-start"
        mt="16px"
      >
        <GridItem colSpan={1}>
          <Text className="acrFormLabel"> Medical Issues</Text>
        </GridItem>
        <GridItem colSpan={4}>
          <Flex className="medicalIssuesTabsFlex" gap="8px">
            {medicalIssues?.map((value, index) => (
              <Box
                key={index}
                className={
                  medicalInfo?.prenatalMedicalIssues.includes(value)
                    ? "medicalIssuesTabs medicalIssuesTabsActive"
                    : "medicalIssuesTabs"
                }
                cursor="pointer"
                onClick={() =>
                  handleMultipleSelection(
                    "medicalInfo",
                    "prenatalMedicalIssues",
                    value,
                    "prenatalMedicalIssuesError"
                  )
                }
                style={{
                  borderColor: medicalInfo?.prenatalMedicalIssuesError
                    ? "#ba1a1a"
                    : "#D8E5EE",
                }}
              >
                {value + "   "}

                <Image
                  src={
                    medicalInfo?.prenatalMedicalIssues.includes(value)
                      ? GREEN_CHECK_MARK
                      : PLUS_ICON_BLACK
                  }
                  alt=""
                  width="16px"
                  height="16px"
                />
              </Box>
            ))}
          </Flex>
          {medicalInfo?.prenatalMedicalIssuesError && (
            <Text className="personalErrorMessage" mt="5px" ml="15px">
              {medicalInfo?.prenatalMedicalIssuesError}
            </Text>
          )}
          {medicalInfo?.prenatalMedicalIssues.includes("Others") && (
            <>
              <InputGroup mt="8px">
                <Input
                  onChange={(e) =>
                    handleFormDataChange(
                      "medicalInfo",
                      e.target.name,
                      e.target.value,
                      "otherMedicalIssuesError"
                    )
                  }
                  name="otherMedicalIssues"
                  value={medicalInfo?.otherMedicalIssues}
                  placeholder="Please elaborate"
                  maxLength={50}
                  className="acrFormInput"
                  style={{
                    borderColor: medicalInfo?.otherMedicalIssuesError
                      ? "#ba1a1a"
                      : "#D8E5EE",
                  }}
                />
              </InputGroup>
              {medicalInfo?.otherMedicalIssuesError && (
                <Text className="personalErrorMessage" mt="5px" ml="15px">
                  {medicalInfo?.otherMedicalIssuesError}
                </Text>
              )}
            </>
          )}
        </GridItem>
      </Grid>
      <Text className="subHeading2" mt="24px">
        PostNatal
      </Text>
      <Grid
        templateColumns="repeat(5, 1fr)"
        gap="16px"
        alignItems="center"
        mt="16px"
      >
        <GridItem colSpan={1}>
          <Text className="acrFormLabel">Baby Cry</Text>
        </GridItem>
        <GridItem colSpan={3}>
          <Flex
            className="childPresentTab"
            style={{
              borderColor: medicalInfo?.babyCryError ? "#ba1a1a" : "#D8E5EE",
            }}
          >
            {babyCryOptions?.map((value, index) => (
              <Text
                key={index}
                className={
                  medicalInfo?.babyCry === value
                    ? "childPresentTabActive"
                    : "childPresentTabInActive"
                }
                cursor="pointer"
                onClick={() =>
                  handleFormDataChange(
                    "medicalInfo",
                    "babyCry",
                    value,
                    "babyCryError"
                  )
                }
              >
                {value}
              </Text>
            ))}
          </Flex>
          {medicalInfo?.babyCryError && (
            <Text className="personalErrorMessage" mt="5px" ml="15px">
              {medicalInfo?.babyCryError}
            </Text>
          )}
        </GridItem>
      </Grid>
      <Grid
        templateColumns="repeat(5, 1fr)"
        gap="16px"
        alignItems="center"
        mt="24px"
      >
        <GridItem colSpan={1}>
          <Text className="acrFormLabel">Birth Weight</Text>
        </GridItem>
        <GridItem colSpan={3}>
          <Flex
            className="childPresentTab"
            style={{
              borderColor: medicalInfo?.birthWeightError
                ? "#ba1a1a"
                : "#D8E5EE",
            }}
          >
            {birthWeightOptions?.map((value, index) => (
              <Text
                key={index}
                className={
                  medicalInfo?.birthWeight === value
                    ? "childPresentTabActive"
                    : "childPresentTabInActive"
                }
                cursor="pointer"
                onClick={() =>
                  handleFormDataChange(
                    "medicalInfo",
                    "birthWeight",
                    value,
                    "birthWeightError"
                  )
                }
              >
                {value}
              </Text>
            ))}
          </Flex>
          {medicalInfo?.birthWeightError && (
            <Text className="personalErrorMessage" mt="5px" ml="15px">
              {medicalInfo?.birthWeightError}
            </Text>
          )}
        </GridItem>
      </Grid>
      <Grid
        templateColumns="repeat(5, 1fr)"
        gap="16px"
        alignItems="flex-start"
        mt="24px"
      >
        <GridItem colSpan={1}>
          <Text className="acrFormLabel">Gestation Period</Text>
        </GridItem>
        <GridItem colSpan={3}>
          <Flex
            className="childPresentTab"
            style={{
              borderColor: medicalInfo?.gestationPeriodError
                ? "#ba1a1a"
                : "#D8E5EE",
            }}
          >
            {generationPeriodOptions?.map((value, index) => (
              <Text
                key={index}
                className={
                  medicalInfo?.gestationPeriod === value
                    ? "childPresentTabActive"
                    : "childPresentTabInActive"
                }
                cursor="pointer"
                onClick={() =>
                  handleFormDataChange(
                    "medicalInfo",
                    "gestationPeriod",
                    value,
                    "gestationPeriodError"
                  )
                }
              >
                {value}
              </Text>
            ))}
          </Flex>
          {medicalInfo?.gestationPeriod === "Premature" && (
            <>
              <InputGroup mt="8px">
                <Input
                  onChange={(e) =>
                    handleFormDataChange(
                      "medicalInfo",
                      e.target.name,
                      e.target.value,
                      "prematureWeeksError"
                    )
                  }
                  name="prematureWeeks"
                  value={medicalInfo?.prematureWeeks}
                  placeholder="Born in how many weeks prematured (optional)"
                  maxLength={50}
                  className="acrFormInput"
                  style={{
                    borderColor: medicalInfo?.prematureWeeksError
                      ? "#ba1a1a"
                      : "#D8E5EE",
                  }}
                />
              </InputGroup>
              {medicalInfo?.prematureWeeksError && (
                <Text className="personalErrorMessage" mt="5px" ml="15px">
                  {medicalInfo?.prematureWeeksError}
                </Text>
              )}
            </>
          )}
          {medicalInfo?.gestationPeriodError && (
            <Text className="personalErrorMessage" mt="5px" ml="15px">
              {medicalInfo?.gestationPeriodError}
            </Text>
          )}
        </GridItem>
      </Grid>
      <Grid
        templateColumns="repeat(5, 1fr)"
        gap="16px"
        alignItems="flex-start"
        mt="24px"
      >
        <GridItem colSpan={1}>
          <Text className="acrFormLabel"> Medical Issues</Text>
        </GridItem>
        <GridItem colSpan={4}>
          <Flex className="medicalIssuesTabsFlex" gap="8px">
            {postnatalMedicalIssues?.map((value, index) => (
              <Box
                key={index}
                className={
                  medicalInfo?.postnatalMedicalIssues.includes(value)
                    ? "medicalIssuesTabs medicalIssuesTabsActive"
                    : "medicalIssuesTabs"
                }
                cursor="pointer"
                onClick={() =>
                  handleMultipleSelection(
                    "medicalInfo",
                    "postnatalMedicalIssues",
                    value,
                    "postnatalMedicalIssuesError"
                  )
                }
                style={{
                  borderColor: medicalInfo?.postnatalMedicalIssuesError
                    ? "#ba1a1a"
                    : "#D8E5EE",
                }}
              >
                {value + "   "}
                <Image
                  src={
                    medicalInfo?.postnatalMedicalIssues.includes(value)
                      ? GREEN_CHECK_MARK
                      : PLUS_ICON_BLACK
                  }
                  alt=""
                  width="16px"
                  height="16px"
                />
              </Box>
            ))}
          </Flex>
          {medicalInfo?.postnatalMedicalIssues.includes("Others") && (
            <>
              <InputGroup mt="8px">
                <Input
                  onChange={(e) =>
                    handleFormDataChange(
                      "medicalInfo",
                      e.target.name,
                      e.target.value,
                      "otherPostnatalMedicalIssuesError"
                    )
                  }
                  name="otherPostnatalMedicalIssues"
                  value={medicalInfo?.otherPostnatalMedicalIssues}
                  placeholder="Please elaborate"
                  maxLength={50}
                  className="acrFormInput"
                  style={{
                    borderColor: medicalInfo?.otherPostnatalMedicalIssuesError
                      ? "#ba1a1a"
                      : "#D8E5EE",
                  }}
                />
              </InputGroup>
              {medicalInfo?.otherPostnatalMedicalIssuesError && (
                <Text className="personalErrorMessage" mt="5px" ml="15px">
                  {medicalInfo?.otherPostnatalMedicalIssuesError}
                </Text>
              )}
            </>
          )}
          {medicalInfo?.postnatalMedicalIssuesError && (
            <Text className="personalErrorMessage" mt="5px" ml="15px">
              {medicalInfo?.postnatalMedicalIssuesError}
            </Text>
          )}
        </GridItem>
      </Grid>
      <Grid
        templateColumns="repeat(5, 1fr)"
        gap="16px"
        alignItems="flex-start"
        mt="24px"
      >
        <GridItem colSpan={1}>
          <Text className="acrFormLabel"> Developmental Milestones</Text>
        </GridItem>
        <GridItem colSpan={3}>
          <Flex className="medicalIssuesTabsFlex" gap="8px">
            {developmentalMilestoneOptions?.map((value, index) => (
              <Box
                key={index}
                className={
                  medicalInfo?.developmentalMilestones.includes(value)
                    ? "medicalIssuesTabs medicalIssuesTabsActive"
                    : "medicalIssuesTabs"
                }
                cursor="pointer"
                onClick={() =>
                  handleMultipleSelection(
                    "medicalInfo",
                    "developmentalMilestones",
                    value,
                    "developmentalMilestonesError"
                  )
                }
                style={{
                  borderColor: medicalInfo?.developmentalMilestonesError
                    ? "#ba1a1a"
                    : "#D8E5EE",
                }}
              >
                {value}
                <Image
                  src={
                    medicalInfo?.developmentalMilestones.includes(value)
                      ? GREEN_CHECK_MARK
                      : PLUS_ICON_BLACK
                  }
                  alt=""
                  width="16px"
                  height="16px"
                />
              </Box>
            ))}
          </Flex>
          {medicalInfo?.developmentalMilestonesError && (
            <Text className="personalErrorMessage" mt="5px" ml="15px">
              {medicalInfo?.developmentalMilestonesError}
            </Text>
          )}
        </GridItem>
      </Grid>
      <Grid
        templateColumns="repeat(5, 1fr)"
        gap="16px"
        alignItems="flex-start"
        mt="24px"
      >
        <GridItem colSpan={1}>
          <Text className="acrFormLabel">
            Past evaluations/ therapies and intervention
          </Text>
        </GridItem>
        <GridItem colSpan={3}>
          <Flex
            className="childPresentTab"
            style={{
              borderColor: medicalInfo?.pastevalutionsError
                ? "#ba1a1a"
                : "#D8E5EE",
            }}
          >
            {YesNoOptions?.map((value, index) => (
              <Text
                key={index}
                className={
                  medicalInfo?.pastevalutions === value
                    ? "childPresentTabActive"
                    : "childPresentTabInActive"
                }
                cursor="pointer"
                onClick={() =>
                  handleFormDataChange(
                    "medicalInfo",
                    "pastevalutions",
                    value,
                    "pastevalutionsError"
                  )
                }
              >
                {value}
              </Text>
            ))}
          </Flex>
          {medicalInfo?.pastevalutions === "Yes" && (
            <>
              <InputGroup mt="8px">
                <Input
                  onChange={(e) =>
                    handleFormDataChange(
                      "medicalInfo",
                      e.target.name,
                      e.target.value,
                      "pastevalutionsReasonError"
                    )
                  }
                  name="pastevalutionsReason"
                  value={medicalInfo?.pastevalutionsReason}
                  placeholder="Comment your reason..."
                  maxLength={500}
                  className="acrFormInput"
                  style={{
                    borderColor: medicalInfo?.pastevalutionsReasonError
                      ? "#ba1a1a"
                      : "#D8E5EE",
                  }}
                />
              </InputGroup>
              {medicalInfo?.pastevalutionsReasonError && (
                <Text className="personalErrorMessage" mt="5px" ml="15px">
                  {medicalInfo?.pastevalutionsReasonError}
                </Text>
              )}
            </>
          )}
          {medicalInfo?.pastevalutionsError && (
            <Text className="personalErrorMessage" mt="5px" ml="15px">
              {medicalInfo?.pastevalutionsError}
            </Text>
          )}
        </GridItem>
      </Grid>
    </Box>
  );
};
export default MedicalHistory;

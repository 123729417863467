import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { useSteps } from "chakra-ui-steps";
import { Box, Button, Flex, Image, Spinner, Text } from "@chakra-ui/react";
import { CLOSE } from "../../../constant/images";
import { ScheduleSessionStepper } from "./scheduleSessionStepper";
import { Toaster } from "../../../common/components/Toaster";
import {
  toggleAPILoader,
  toggleMainLoader,
  getParentSessions,
  getOpenSlots,
  getUserProfile,
  getBalanceDetails,
  toggleSessionLoader,
} from "../../../modules/actions";
import {
  blockSlotService,
  deleteBlockSlotService,
  scheduleSessionService,
} from "../../../modules/services";
import "./scheduleSession.css";

const ScheduleSession = ({ setOverlayOpen }) => {
  const dispatch = useDispatch();
  const userBalance = useSelector((state) => state.userBalance);
  const { mainLoader, apiLoader } = useSelector((state) => state?.loader);
  const openSlots = useSelector((state) => state?.openSlots);

  const [weeklyChoosenSlots, setWeeklyChoosenSlots] = useState([]);
  const { nextStep, prevStep, activeStep } = useSteps({
    initialStep: 0,
  });

  const [calenderStartDate, setCalenderStartDate] = useState();
  const [sessionData, setSessionData] = useState({
    expert: "",
    expertError: "",
    service: "",
    serviceError: "",
    noOfSessions: "",
    noOfSessionsError: "",
    startDate: "",
    startDateError: "",
  });

  useEffect(() => {
    dispatch(toggleSessionLoader(true));
    dispatch(getBalanceDetails());
  }, []);

  const handleFormDataChange = (name, value, errorName) => {
    // setBtnDisabled(false);
    setSessionData({ ...sessionData, [name]: value, [errorName]: "" });

    if (name === "noOfSessions" && value > userBalance?.currentBalance) {
      setSessionData({
        ...sessionData,
        noOfSessionsError:
          "Total sessions should not exceed your balance(" +
          userBalance?.currentBalance +
          ")",
      });
    }
  };

  const handleCalendarChange = (event) => {
    const selectedDate = moment(event);
    setCalenderStartDate(selectedDate);
    handleFormDataChange("startDate", selectedDate, "startDateError");
    setWeeklyChoosenSlots([]);
  };

  const handleNextStep = () => {
    const { expert, service, noOfSessions, startDate } = sessionData;
    if (activeStep === 0) {
      const validateExpert = !expert ? "Please choose your expert" : "";
      const validateService = !service ? "Please choose your service" : "";
      const validateNoOfSessions = !noOfSessions ? "Enter no of sessions" : "";
      const validateStartDate = !startDate ? "Please enter start date" : "";

      setSessionData({
        ...sessionData,
        expertError: validateExpert,
        serviceError: validateService,
        noOfSessionsError: validateNoOfSessions,
        startDateError: validateStartDate,
      });
      if (
        !validateExpert &&
        !validateService &&
        !validateNoOfSessions &&
        !validateStartDate
      ) {
        const reqData = {
          expert: sessionData?.expert?.expertDetails?.educatorId,
          startDate: sessionData?.startDate.format("YYYY/MM/DD"),
          noOfSessions: sessionData?.noOfSessions,
          frequencyPerWeek:
            sessionData?.expert?.expertDetails?.reccomendedFrequency,
        };
        dispatch(toggleAPILoader(true));
        dispatch(getOpenSlots(reqData, () => nextStep()));
      }
    } else if (activeStep === 1) {
      const reqData = {
        expertId: sessionData?.expert?.expertDetails?.educatorId,
        choosenSlot: weeklyChoosenSlots,
      };
      blcokSlotApiCall(reqData);
    } else {
      const reqData = {
        expert: sessionData?.expert?.expertDetails?.educatorId,
        service: sessionData?.service,
        choosenSlot: weeklyChoosenSlots,
      };
      if (weeklyChoosenSlots.length > 0) {
        scheduleSessionApiCall(reqData);
      }
    }
  };

  const scheduleSessionApiCall = (reqData) => {
    dispatch(toggleAPILoader(true));
    scheduleSessionService(reqData)
      .then((response) => {
        if (response.data.data.length === 0 && response.data.message) {
          Toaster(response.data.message, "error", "top-left");
          dispatch(toggleAPILoader(false));
        } else {
          Toaster("Sessions scheduled successfully", "success");
          dispatch(toggleAPILoader(false));
          dispatch(toggleMainLoader(true));
          dispatch(
            getParentSessions(
              { status: "upcoming" },
              {
                filterDate: moment().format("YYYY/MM/DD"),
              }
            )
          );
          const token = localStorage.getItem("idToken");
          dispatch(getUserProfile(token));
          setOverlayOpen(false);
        }
      })
      .catch((error) => {
        Toaster(error.response.data.message, "error", "top-left");
        dispatch(toggleAPILoader(false));
      });
  };

  const blcokSlotApiCall = (reqData) => {
    dispatch(toggleAPILoader(true));
    blockSlotService(reqData)
      .then((response) => {
        dispatch(toggleAPILoader(false));
        nextStep();
      })
      .catch((error) => {
        Toaster(error.response.data.message, "error", "top-left");
        dispatch(toggleAPILoader(false));
      });
  };
  const deleteBlcokedSlotApiCall = () => {
    const reqData = {
      expertId: sessionData?.expert?.expertDetails?.educatorId,
      choosenSlot: weeklyChoosenSlots,
    };
    dispatch(toggleAPILoader(true));
    deleteBlockSlotService(reqData)
      .then((response) => {
        dispatch(toggleAPILoader(false));
      })
      .catch((error) => {
        Toaster(error.response.data.message, "error", "top-left");
        dispatch(toggleAPILoader(false));
      });
  };
  const handleOverlayCloser = () => {
    if (activeStep === 1) {
      setWeeklyChoosenSlots([]);
    } else if (activeStep === 2) {
      deleteBlcokedSlotApiCall();
    }
    setOverlayOpen(false);
  };

  const handleBackBtn = () => {
    if (activeStep === 0) {
      handleOverlayCloser();
    } else {
      if (activeStep === 1) {
        setWeeklyChoosenSlots([]);
      } else {
        deleteBlcokedSlotApiCall();
      }
      prevStep();
    }
  };

  return (
    <Box>
      <Box className="overlayComponentMainBox"></Box>
      <Box className="overlayComponentContentBoxParent">
        <Flex justifyContent="space-between" alignItems="center">
          <Text className="editProfileTitle">Schedule Session</Text>
          <Image
            onClick={handleOverlayCloser}
            src={CLOSE}
            height="24px"
            width="24px"
            cursor="pointer"
            mr="16px"
          />
        </Flex>
        <Flex className="overlayComponentContentBoxFlexParent"></Flex>
        <Box className="overlayComponentContentBoxChildren">
          <ScheduleSessionStepper
            sessionData={sessionData}
            openSlots={openSlots}
            weeklyChoosenSlots={weeklyChoosenSlots}
            handleFormDataChange={handleFormDataChange}
            handleCalendarChange={handleCalendarChange}
            setWeeklyChoosenSlots={setWeeklyChoosenSlots}
            calenderStartDate={calenderStartDate}
            handlePrevStep={handleOverlayCloser}
            deleteBlcokedSlotApiCall={deleteBlcokedSlotApiCall}
            activeStep={activeStep}
          />
        </Box>
        <Flex className="overlayComponentContentBoxBtnsFlex">
          <Button
            onClick={handleBackBtn}
            className="overlayComponentContentBoxBtn1"
          >
            Back
          </Button>
          {activeStep === 0 ? (
            <Button
              className="overlayComponentContentBoxBtn2"
              color={
                apiLoader ||
                mainLoader ||
                !sessionData?.expert ||
                !sessionData?.service ||
                !sessionData?.noOfSessions ||
                !sessionData?.startDate
                  ? "#91A2AE"
                  : "white"
              }
              bg={
                apiLoader ||
                mainLoader ||
                !sessionData?.expert ||
                !sessionData?.service ||
                !sessionData?.noOfSessions ||
                !sessionData?.startDate
                  ? "#F3F8FB"
                  : "#4299E1"
              }
              onClick={handleNextStep}
              isDisabled={
                // btnDisabled ||
                !sessionData?.expert ||
                !sessionData?.service ||
                !sessionData?.noOfSessions ||
                !sessionData?.startDate
              }
            >
              {apiLoader ? <Spinner /> : <Text>Next</Text>}
            </Button>
          ) : activeStep === 1 ? (
            <Button
              className="overlayComponentContentBoxBtn2"
              color={weeklyChoosenSlots.length < 1 ? "#91A2AE" : "white"}
              bg={weeklyChoosenSlots.length < 1 ? "#F3F8FB" : "#4299E1"}
              onClick={handleNextStep}
              isDisabled={weeklyChoosenSlots.length < 1}
            >
              {apiLoader ? <Spinner /> : <Text>Next</Text>}
            </Button>
          ) : activeStep === 2 ? (
            <Button
              // className="overlayComponentContentBoxBtn2"
              color="#FFFFFF" //   ? "#91A2AE" //   sessionData?.startDateError) //   sessionData?.noOfSessionsError && //   sessionData?.serviceError && // (sessionData?.expertError && // btnDisabled || // mainLoader || // apiLoader ||
              backgroundColor="#4299E1" //   ? "#F3F8FB" //   sessionData?.startDateError) //   sessionData?.noOfSessionsError && //   sessionData?.serviceError && // (sessionData?.expertError && // btnDisabled || // mainLoader || // apiLoader ||
              onClick={handleNextStep}
              isDisabled={false}
              _hover={{
                bg: "blue.500",
              }}
            >
              {apiLoader ? <Spinner /> : <Text>Confirm</Text>}
            </Button>
          ) : null}
        </Flex>
      </Box>
    </Box>
  );
};
export default ScheduleSession;

import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Input,
  FormLabel,
  FormControl,
  Textarea,
  GridItem,
  SimpleGrid,
  Image,
  FormErrorMessage,
  InputGroup,
  Text,
  InputLeftElement,
  Spinner,
} from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import { Select } from "chakra-react-select";
import {
  validateEmail,
  validateName,
  validateFullName,
  validatePhoneNumber,
} from "../../../../utils/validations";
import { languageOptions } from "../../../../constant";
import { getUserProfile, toggleAPILoader } from "../../../../modules/actions";
import {
  saveUserProfileImageService,
  saveUserProfileService,
  editUserProfileService,
} from "../../../../modules/services";
import { useDispatch, useSelector } from "react-redux";
import { UPLOAD_ICON, NO_USER_IMAGE } from "../../../../constant/images";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "../onboarding.css";
import moment from "moment/moment";
import { Toaster } from "../../../../common/components/Toaster";
import { getMomentDate1 } from "../../../../utils/customMoment";

const Personal = ({ activeStep, nextStep, prevStep }) => {
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state?.user);
  const { apiLoader } = useSelector((state) => state?.loader);
  const inputFile = useRef(null);
  const [numOfWords, setNumOfWords] = useState(350);

  const [formData, setFormData] = useState({
    image: NO_USER_IMAGE,
    fullName: "",
    dob: "",
    email: "",
    number: "",
    languages: [],
    bio: "",
  });

  const [defaultLanguages, setDefaultLanguages] = useState([]);
  const [img, setImg] = useState(NO_USER_IMAGE);
  const [countryCode, setCountryCode] = useState(91);
  const [acceptImg, setAcceptImg] = useState(true);

  const [noFullName, setNoFullName] = useState(false);
  const [noDob, setNoDob] = useState(false);
  const [noEmail, setNoEmail] = useState(false);
  const [noNumber, setNoNumber] = useState(false);
  const [noLanguages, setNoLanguages] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("idToken");
    dispatch(getUserProfile(token));
  }, []);

  useEffect(() => {
    let formatDate;
    if (profile?.dateOfBirth) {
      formatDate = getMomentDate1(profile?.dateOfBirth);
    }
    setFormData({
      image: profile?.imgUrl ? profile?.imgUrl : NO_USER_IMAGE,
      fullName: profile?.fullName ? profile?.fullName : "",
      dob: profile?.dateOfBirth ? formatDate : "",
      email: profile?.email ? profile?.email : "",
      number: profile?.phoneNumber
        ? `${profile?.countryCode}${profile?.phoneNumber}`
        : "",
      languages: profile?.languages ? profile?.languages : [],
      bio: profile.bio ? profile.bio : "",
    });
    setImg(profile?.imgUrl ? profile?.imgUrl : NO_USER_IMAGE);
  }, [profile]);

  useEffect(() => {
    setDefaultLanguages(
      formData?.languages?.map((item) => {
        return {
          value: item,
          label: item,
        };
      })
    );
  }, [formData]);

  const onUploadImage = () => {
    inputFile.current.click();
  };

  const handleFileUpload = (e) => {
    const { files } = e.target;
    if (files && files.length) {
      if (files[0].size >= 5242880) {
        setAcceptImg(false);
        return;
      }
      setAcceptImg(true);
      setImg(URL.createObjectURL(files[0]));
      setFormData({ ...formData, image: files[0] });
    }
  };

  const onDeleteImage = () => {
    setImg(NO_USER_IMAGE);
  };

  const handleFormDataChange = (name, value) => {
    if (name === "country") {
      setCountryCode(value);
      document.getElementById("phoneInput").value = "";
    } else if (name === "number") {
      setFormData({ ...formData, [name]: "+" + countryCode + value });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleNumOfWords = (e) => {
    setNumOfWords(350 - e.target.value.length);
  };

  const handleNextStep = () => {
    const { fullName, dob, email, number, languages, bio, image } = formData;
    setNoFullName(!validateFullName(fullName));
    setNoDob(dob === "" || moment(dob).isAfter(moment(), "day"));
    setNoEmail(!validateEmail(email));
    setNoNumber(!validatePhoneNumber(number));
    setNoLanguages(languages.length === 0);
    if (
      !fullName ||
      !dob ||
      !validateEmail(email) ||
      !validateFullName(fullName) ||
      !validatePhoneNumber(number) ||
      !languages.length > 0
    )
      return;
    let formDataImage = new FormData();
    formDataImage.append("image", image);

    const dobArray = dob.split("-");
    const formattedDOB = `${dobArray[1]}-${dobArray[2]}-${dobArray[0]}`;
    const formattedFormData = {
      fullName,
      email,
      dateOfBirth: formattedDOB,
      phoneNumber: number.slice(3),
      languages,
      bio,
      countryCode: `+${countryCode}`,
    };

    if (img !== NO_USER_IMAGE && img !== profile?.imgUrl) {
      dispatch(toggleAPILoader(true));
      saveUserProfileImageService(formDataImage)
        .then((res) => {
          formattedFormData["imgUrl"] = res.data.data;
          if (profile?.id) {
            handleEditProfile(formattedFormData);
          } else {
            handleRegistration(formattedFormData);
          }
        })
        .catch((error) => {
          console.log(error);
          dispatch(toggleAPILoader(false));
          Toaster(error.response.data.message, "error");
        });
    } else {
      if (img === NO_USER_IMAGE) {
        formattedFormData["imgUrl"] = "";
      }
      if (profile?.id) {
        handleEditProfile(formattedFormData);
      } else {
        handleRegistration(formattedFormData);
      }
    }
  };

  const handleRegistration = (formattedFormData) => {
    dispatch(toggleAPILoader(true));
    saveUserProfileService(1, formattedFormData)
      .then((res) => {
        dispatch(toggleAPILoader(false));
        nextStep();
      })
      .catch((error) => {
        console.log(error);
        dispatch(toggleAPILoader(false));
        Toaster(error.response.data.message, "error");
      });
  };
  const handleEditProfile = (reqData) => {
    dispatch(toggleAPILoader(true));
    editUserProfileService(reqData)
      .then((res) => {
        dispatch(toggleAPILoader(false));
        nextStep();
      })
      .catch((error) => {
        console.log(error);
        dispatch(toggleAPILoader(false));
        Toaster(error.response.data.message, "error");
      });
  };

  return (
    <Box>
      <Flex
        display={{ base: "grid", sm: "flex" }}
        justifyContent={{ base: "center", sm: "start" }}
        gap={{ sm: "24px", base: 5 }}
      >
        <Image w="82px" h="82px" src={img} />
        <Box>
          <Flex
            ml={{ base: "-20px", sm: 0 }}
            gap="16px"
            mt={acceptImg ? 3 : 0}
            className="personalGrid"
          >
            <Flex gap="8px" color="#4299E1" alignItems="center">
              <Image src={UPLOAD_ICON} />
              <Input
                onChange={handleFileUpload}
                ref={inputFile}
                type="file"
                display="none"
                accept="image/jpg, image/png, image/jpeg"
              />
              <span
                onClick={onUploadImage}
                style={{ cursor: "pointer", marginTop: "3px" }}
              >
                Upload Picture
              </span>
            </Flex>
            <Flex
              gap="8px"
              style={{ color: img !== NO_USER_IMAGE ? "#BA1A1A" : "#91A2AE" }}
              alignItems="center"
            >
              <DeleteIcon />
              <span
                onClick={onDeleteImage}
                style={{ cursor: "pointer", marginTop: "3px" }}
              >
                Remove Picture
              </span>
            </Flex>
          </Flex>
          <Box>
            <Text color="#5A646B" className="personalImageValidators">
              PNG, JPG files are suppported. Max file size: 5mb
            </Text>
            {!acceptImg && (
              <Text mt={0} color="#BA1A1A" className="personalImageValidators">
                Please upload an image with max size 5mb
              </Text>
            )}
          </Box>
        </Box>
      </Flex>
      <SimpleGrid className="personalSimpleGrid">
        <GridItem colSpan={{ base: 2, md: 1 }}>
          <FormControl isInvalid={noFullName} isRequired>
            <FormLabel
              className="personalFormLabel"
              style={{
                color: noFullName && "#BA1A1A",
              }}
              sx={{
                "& .chakra-form__required-indicator": {
                  color: noFullName && "#BA1A1A",
                },
              }}
            >
              Full Name
            </FormLabel>
            <InputGroup>
              <Input
                onChange={(e) =>
                  handleFormDataChange(e.target.name, e.target.value)
                }
                name="fullName"
                value={formData.fullName}
                placeholder="Enter your full name"
                maxLength={30}
                border="1px solid #A6B8C4"
                className="personalFormInput"
              />
            </InputGroup>
            {noFullName && (
              <FormErrorMessage className="personalErrorMessage">
                Please enter a valid full name
              </FormErrorMessage>
            )}
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 2, md: 1 }}>
          <FormControl isInvalid={noDob} isRequired>
            <FormLabel
              className="personalFormLabel"
              style={{
                color: noDob && "#BA1A1A",
              }}
              sx={{
                "& .chakra-form__required-indicator": {
                  color: noDob && "#BA1A1A",
                },
              }}
            >
              Date Of Birth
            </FormLabel>
            <InputGroup>
              <Input
                onChange={(e) =>
                  handleFormDataChange(e.target.name, e.target.value)
                }
                type="date"
                name="dob"
                value={formData.dob}
                id="dob"
                placeholder="Enter your DOB"
                className="personalFormInput"
                border="1px solid #A6B8C4"
                max={moment().format("YYYY-MM-DD")}
              />
            </InputGroup>
            {noDob && (
              <FormErrorMessage className="personalErrorMessage">
                Please enter your valid date of birth
              </FormErrorMessage>
            )}
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 2, md: 1 }}>
          <FormControl isInvalid={noEmail} isRequired>
            <FormLabel
              className="personalFormLabel"
              style={{
                color: noEmail && "#BA1A1A",
              }}
              sx={{
                "& .chakra-form__required-indicator": {
                  color: noEmail && "#BA1A1A",
                },
              }}
            >
              Email Address
            </FormLabel>
            <InputGroup>
              <Input
                onChange={(e) =>
                  handleFormDataChange(e.target.name, e.target.value)
                }
                type="email"
                placeholder="mailto:ankurtestexpert@gmail.com"
                maxLength={60}
                className="personalFormInput"
                name="email"
                value={formData.email}
                border="1px solid #A6B8C4"
                isDisabled={profile?.email}
              />
            </InputGroup>
            {noEmail && (
              <FormErrorMessage className="personalErrorMessage">
                Please enter a valid email address
              </FormErrorMessage>
            )}
          </FormControl>
        </GridItem>
        <GridItem zIndex={50} colSpan={{ base: 2, md: 1 }}>
          <FormControl isInvalid={noNumber} isRequired>
            <FormLabel
              className="personalFormLabel"
              style={{
                color: noNumber && "#BA1A1A",
              }}
              sx={{
                "& .chakra-form__required-indicator": {
                  color: noNumber && "#BA1A1A",
                },
              }}
            >
              Phone Number
            </FormLabel>
            <InputGroup>
              <InputLeftElement
                sx={{
                  "& .form-control": {
                    border: "1px solid white",
                    display: "none",
                  },
                  "& .selected-flag": {
                    zIndex: 500,
                    backgroundColor: "white",
                    width: "82px",
                    padding: "0 0 0 25px",
                  },
                }}
                children={
                  <PhoneInput
                    name="country"
                    onChange={(e) => handleFormDataChange("country", e)}
                    country={"in"}
                    value={formData.number.slice(0, 3)}
                    className="customPhoneInput"
                    disabled={profile?.countryCode}
                  />
                }
              />
              <Input
                className="personalPhoneInput"
                id="phoneInput"
                name="number"
                type="number"
                value={formData.number.slice(3)}
                placeholder="00000 00000"
                onChange={(e) =>
                  handleFormDataChange(e.target.name, e.target.value)
                }
                border="1px solid #A6B8C4"
                isDisabled={profile?.phoneNumber}
              />
            </InputGroup>
            {noNumber && (
              <FormErrorMessage className="personalErrorMessage">
                Please enter a valid phone number
              </FormErrorMessage>
            )}
          </FormControl>
        </GridItem>
        <GridItem colSpan={2}>
          <FormControl isInvalid={noLanguages} isRequired>
            <FormLabel
              className="personalFormLabel"
              style={{
                color: noLanguages && "#BA1A1A",
              }}
              sx={{
                "& .chakra-form__required-indicator": {
                  color: noLanguages && "#BA1A1A",
                },
              }}
            >
              Languages
            </FormLabel>
            <Box
              border="1px solid #A6B8C4"
              fontWeight="medium"
              borderRadius={7}
              backgroundColor="white"
              sx={{
                "& .css-wtf6ne": {
                  bg: "white",
                  border: "none",
                },
                "& .chakra-divider": {
                  opacity: 0,
                },
              }}
            >
              <Select
                isMulti
                onChange={(e) =>
                  handleFormDataChange(
                    "languages",
                    e.map((l) => l.value)
                  )
                }
                name="languages"
                closeMenuOnSelect={false}
                value={defaultLanguages}
                selectedOptionStyle="check"
                placeholder="Select an option"
                className="commonFormSelect"
                hideSelectedOptions={false}
                selectedOptionColorScheme="green"
                options={languageOptions}
              />
            </Box>
            {noLanguages && (
              <FormErrorMessage className="personalErrorMessage">
                This field cannot be empty
              </FormErrorMessage>
            )}
          </FormControl>
        </GridItem>
        <GridItem colSpan={2}>
          <FormControl pos="relative">
            <FormLabel className="personalFormLabel">
              Short Bio ({numOfWords}/350)
              {/* <span className="personalBioWordsRemaining">
                ({numOfWords}/350)
              </span> */}
            </FormLabel>
            <Textarea
              onChange={(e) => {
                handleFormDataChange(e.target.name, e.target.value);
                handleNumOfWords(e);
              }}
              name="bio"
              value={formData.bio}
              maxLength={350}
              border="1px solid #A6B8C4"
              placeholder="Write a short bio about yourself"
              className="personalShortBio"
              resize="none"
              backgroundColor="white"
            />
            {/* <span className="personalBioWordsRemaining">
              ({numOfWords}/350)
            </span> */}
          </FormControl>
        </GridItem>
      </SimpleGrid>
      <Flex mt="40px" justify="flex-end">
        {/* <Button
          onClick={prevStep}
          color="#4299E1"
          size="sm"
          variant="ghost"
          px="40px"
          py="20px"
        >
          Back
        </Button> */}
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          position="relative"
        >
          <Button
            color={!apiLoader ? "white" : "#91A2AE"}
            size="sm"
            bg={!apiLoader ? "#4299E1" : "#F3F8FB"}
            px="40px"
            py="20px"
            onClick={handleNextStep}
          >
            {apiLoader ? (
              <Spinner
                bg="#f3f8fb"
                emptyColor="gray.200"
                color="blue.500"
                position="absolute"
              />
            ) : (
              "Next"
            )}
          </Button>
        </Box>
      </Flex>
    </Box>
  );
};

export default Personal;

import { Box, Flex, Grid, Image, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { NEXT, OPEN_PATHWAY } from "../../../../constant";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../common/components/Loader";
import SingleNavbarTabsTemplate from "./singleNavbarTabsTemplate";
import { createPathwayService } from "../../../../modules/services";
import { Toaster } from "../../../../common/components/Toaster";
import {
  getDomainsGoalDataAction,
  toggleAPILoader,
} from "../../../../modules/actions";

const LOCALSTORAGE_CHECKPOINTS_DATA_KEY = "CHECKPOINT_OUTCOMES_STUDENTS_DATA";
const LOCALSTORAGE_CONFIRM_CHECKPOINTS_DATA =
  "LOCALSTORAGE_CONFIRM_CHECKPOINTS_DATA";

const CreatePathwayTabsContent = ({
  setShowingTable,
  setWillCreatePathway,
  selectedChild,
  setProceedToConfirmPathway,
  pathway,
}) => {
  const dispatch = useDispatch();
  const [selectedNav, setSelectedNav] = useState(null);
  const [navbarContent, setNavbarContent] = useState(null);
  const [initialLoad, setInitialLoad] = useState(true);
  const [changedLocalstorage, setChangedLocalstorage] = useState(0);
  const [totalOutcomeCounter, setTotalOutcomeCounter] = useState(0);
  const [checkpointOutcomes, setCheckpointOutcomes] = useState([]);

  const { domainGoals, currentTabsSubGoals } = useSelector(
    (state) => state?.user
  );
  const loaderState = useSelector((state) => state?.loader?.apiLoader);

  useEffect(() => {
    if (loaderState === false) {
      setInitialLoad(false);
    }
  }, [loaderState]);

  useEffect(() => {
    dispatch(getDomainsGoalDataAction());
  }, [dispatch]);

  useEffect(() => {
    if (pathway?.checkpoints?.length > 0) {
      let counter = 0;
      let outcomes = [];
      pathway.checkpoints.map((checkpoint) => {
        checkpoint.outcomes.map((outcome) => {
          outcome.checkpointStatus = checkpoint.status;
          outcomes.push(outcome);
          counter++;
        });
      });

      setTotalOutcomeCounter(counter);
      setCheckpointOutcomes(outcomes);
    }
  }, [pathway]);

  // useEffect(() => {
  //   if (pathway?.checkpoints?.length < 0) {
  //     const localStorageData = localStorage.getItem(
  //       LOCALSTORAGE_CHECKPOINTS_DATA_KEY
  //     );
  //     if (!localStorageData) return;
  //     let returnedLocalStorageData = Array.from(JSON.parse(localStorageData));
  //     let counter = 0;
  //     returnedLocalStorageData?.forEach((lsd) => {
  //       lsd?.selectedOutcomes?.forEach((so) => {
  //         return counter++;
  //       });
  //     });
  //     setTotalOutcomeCounter(counter);
  //   }
  // }, [changedLocalstorage]);

  useEffect(() => {
    const createNavbarContent = () => {
      if (!domainGoals) return;
      const updatedContent = domainGoals;
      setSelectedNav(updatedContent[0]?.name);
      setNavbarContent(updatedContent);
    };
    createNavbarContent();
  }, [domainGoals]);

  function flattenArray(arrayOfArrays) {
    return arrayOfArrays.reduce((res, arr) => res.concat(arr), []);
  }

  function createArrayOfArrays(objArr) {
    const arrOfArrs = [];
    let currArr = [];
    for (let i = 0; i < objArr?.length; i++) {
      currArr.push(objArr[i]);
      if (currArr.length === 3 || i === objArr.length - 1) {
        arrOfArrs.push(currArr);
        currArr = [];
      }
    }
    return arrOfArrs;
  }

  const handleProceedFurther = () => {
    const arrOfArrs = createArrayOfArrays(checkpointOutcomes);

    localStorage.setItem(
      LOCALSTORAGE_CONFIRM_CHECKPOINTS_DATA,
      JSON.stringify(arrOfArrs)
    );

    const newarry = arrOfArrs.map((obj, index) => {
      let arr = { status: "Locked", outcomes: [] };
      obj.map((outcome) => {
        outcome.status = outcome.status ? outcome.status : "";
        arr.outcomes.push(outcome);
        arr["status"] = outcome.checkpointStatus
          ? outcome.checkpointStatus
          : "Locked";
      });
      return arr;
    });

    const data = {
      userId: selectedChild.id,
      status: "created",
      checkpoints: newarry,
    };

    dispatch(toggleAPILoader(true));
    createPathwayService(data)
      .then((res) => {
        Toaster("Pathway created successfully", "success");
        dispatch(toggleAPILoader(false));
        setProceedToConfirmPathway(true);
      })
      .catch((error) => {
        console.log(error);
        dispatch(toggleAPILoader(false));
        Toaster(error.response.data.message, "error");
      });
  };
  const handleSelectedOutcomeCounter = (flag) => {
    setTotalOutcomeCounter(
      flag ? totalOutcomeCounter - 1 : totalOutcomeCounter + 1
    );
  };
  return (
    <>
      {initialLoad ? (
        <Loader text={"Loading Pathway...."} />
      ) : (
        <Box>
          <Grid
            maxW="100vw"
            overflowX="clip"
            position="sticky"
            zIndex="10"
            top="0"
            row
            className="layoutBanner"
          >
            <Flex className="pathwayHandlerStudentNameBanner">
              <Text
                cursor="pointer"
                onClick={() => {
                  setShowingTable(true);
                  setWillCreatePathway(false);
                }}
                className="pathwayHandlerText"
                color="#141719"
              >
                My students
              </Text>
              <Image h="16px" w="16px" src={OPEN_PATHWAY} />
              <Text
                onClick={() => setWillCreatePathway(false)}
                className="pathwayHandlerText"
                cursor="pointer"
                color="#141719"
              >
                {selectedChild?.childName}
              </Text>
              <Image h="16px" w="16px" src={OPEN_PATHWAY} />
              <Text className="pathwayHandlerText" color="#5a646b">
                Create Pathway
              </Text>
            </Flex>
            <Flex justify="center">
              {navbarContent?.map((n) => (
                <Flex
                  align="center"
                  p={{ base: "0 8px", lg: "0px 12px" }}
                  bg={`${selectedNav === n?.name ? "white" : "#f3f8fb"}`}
                  cursor="pointer"
                  onClick={() => setSelectedNav(n?.name)}
                  fontWeight={{ base: "500", lg: "600" }}
                  fontSize={{ base: "11px", lg: "14px" }}
                  lineHeight={{ lg: "20px" }}
                  letterSpacing={{ lg: "0.1px" }}
                  overflowX="hidden"
                  borderBottom={`${
                    selectedNav !== n?.name && "1px solid #d8efee"
                  }`}
                  wordBreak="normal"
                  borderLeft="1px solid #D8EFEE"
                  borderRight="1px solid #D8EFEE"
                  borderTop="1px solid #D8EFEE"
                  key={n?.id}
                >
                  {n?.name}
                </Flex>
              ))}
            </Flex>
          </Grid>
          <Box
            fontWeight="500"
            fontSize="22px"
            pl="80px"
            bg="white"
            py="24px"
            position="sticky"
            top="128px"
            borderBottom="1px solid #d8e5ee"
            zIndex={1000000}
            lineHeight="28px"
          >
            {navbarContent?.map((n, index) => (
              <Box key={index}>{selectedNav === n.name && n.name}</Box>
            ))}
          </Box>
          {navbarContent?.map((n, index) => (
            <Box key={n?.id}>
              {selectedNav === n?.name && (
                <SingleNavbarTabsTemplate
                  navbarContent={navbarContent && navbarContent[index]}
                  navbarName={n?.name}
                  changedLocalstorage={changedLocalstorage}
                  setChangedLocalstorage={setChangedLocalstorage}
                  domainContent={n}
                  pathway={pathway}
                  handleSelectedOutcomeCounter={handleSelectedOutcomeCounter}
                  checkpointOutcomes={checkpointOutcomes}
                  setCheckpointOutcomes={setCheckpointOutcomes}
                />
              )}
            </Box>
          ))}
          {totalOutcomeCounter > 0 && (
            <Flex
              px="72px"
              py="16px"
              align="center"
              justify="space-between"
              pos="fixed"
              bottom={0}
              zIndex={20}
              bg="#f3ebff"
              left={0}
              right={0}
            >
              <Text
                fontWeight="600"
                fontSize="16px"
                lineHeight="24px"
                letterSpacing="0.15px"
                color="#2f0d63"
              >
                {selectedChild?.childName.split(" ")[0]}'s pathway
              </Text>
              <Flex
                cursor="pointer"
                gap="8px"
                bg="#5a25a9"
                px="16px"
                onClick={handleProceedFurther}
                py="8px"
                borderRadius="40px"
              >
                <Text
                  fontWeight="500"
                  fontSize="16px"
                  lineHeight="24px"
                  color="white"
                >
                  {totalOutcomeCounter}{" "}
                  {totalOutcomeCounter > 1 ? "Outcomes" : "Outcome"}
                </Text>
                <Image h="24px" w="24px" src={NEXT} />
              </Flex>
            </Flex>
          )}
        </Box>
      )}
    </>
  );
};

export default CreatePathwayTabsContent;

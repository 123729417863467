import React, { useState, useEffect } from "react";
import {
  SimpleGrid,
  GridItem,
  FormLabel,
  FormControl,
  Box,
  Text,
  Button,
  Icon,
  Flex,
  Input,
  Image,
  FormErrorMessage,
  Spinner,
} from "@chakra-ui/react";
import { InputGroup } from "reactstrap";
import { Select } from "chakra-react-select";
import { InfoOutlineIcon, AddIcon } from "@chakra-ui/icons";
import { workingDaysOptions } from "../../../../constant/selectOptions";
import { CANCEL_TIME_SLOT } from "../../../../constant/images";
import { toggleAPILoader } from "../../../../modules/actions";
import { saveUserProfileService } from "../../../../modules/services";
import { Toaster } from "../../../../common/components/Toaster";
import { useDispatch, useSelector } from "react-redux";
import "../onboarding.css";

const WorkingHours = ({ activeStep, nextStep, prevStep }) => {
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state.user);
  const { apiLoader } = useSelector((state) => state.loader);
  const [timeSlotData, setTimeSlotData] = useState([]);

  const handleDataChange = (id, name, value) => {
    setTimeSlotData((prev) =>
      prev.map((d) => (d.id === id ? { ...d, [name]: value } : d))
    );
  };

  const handleAdd = () => {
    setTimeSlotData((prev) => [
      ...prev,
      {
        id: prev.length + 1,
        weekday: "",
        startTime: "",
        endTime: "",
        noWeekDay: false,
        noStartTime: false,
        noEndTime: false,
      },
    ]);
  };

  useEffect(() => {
    if (profile?.workingHours !== undefined) {
      const formattedResponse =
        profile?.workingHours &&
        profile?.workingHours.map((item, index) => {
          return {
            id: index + 1,
            weekday: item?.weekday,
            noWeekDay: item?.weekday ? false : true,
            startTime: item?.startTime,
            noStartTime: item?.startTime ? false : true,
            endTime: item?.endTime,
            noEndTime: item?.endTime ? false : true,
          };
        });
      setTimeSlotData(formattedResponse);
    }
  }, [profile]);

  const handleDelete = (id) => {
    setTimeSlotData((prev) => prev.filter((q) => q.id !== id));
  };

  const handleNextStep = () => {
    let flag = false;
    if (timeSlotData.length === 0) {
      return nextStep();
    }

    setTimeSlotData((prev) =>
      prev.map((item) => {
        return {
          ...item,
          noWeekDay: item.weekday === "",
          noStartTime:
            item.startTime === "" ||
            item.startTime.split(":")[0] > item.endTime.split(":")[0] ||
            (item.startTime.split(":")[0] === item.endTime.split(":")[0] &&
              item.startTime.split(":")[1] >= item.endTime.split(":")[1]),
          noEndTime:
            item.endTime === "" ||
            item.startTime.split(":")[0] > item.endTime.split(":")[0] ||
            (item.startTime.split(":")[0] === item.endTime.split(":")[0] &&
              item.startTime.split(":")[1] >= item.endTime.split(":")[1]),
        };
      })
    );

    timeSlotData.map((d) => {
      if (
        !d.weekday ||
        !d.startTime ||
        !d.endTime ||
        d?.startTime === "" ||
        d?.startTime?.split(":")[0] > d?.endTime?.split(":")[0] ||
        (d?.startTime?.split(":")[0] === d?.endTime?.split(":")[0] &&
          d?.startTime?.split(":")[1] >= d?.endTime?.split(":")[1])
      ) {
        return (flag = false);
      }

      return (flag = true);
    });

    if (!flag) return;

    const formattedWorkingHours = timeSlotData.map((item) => {
      return {
        weekday: item.weekday.toLowerCase().substr(0, 3),
        startTime: item.startTime,
        endTime: item.endTime,
      };
    });

    dispatch(toggleAPILoader(true));
    saveUserProfileService(4, { workingHours: formattedWorkingHours })
      .then((res) => {
        dispatch(toggleAPILoader(false));
        nextStep();
      })
      .catch((error) => {
        console.log(error);
        dispatch(toggleAPILoader(false));
        Toaster(error.response.data.message, "error");
      });
  };

  const [InValidSlots, setInValidSlots] = useState([]);
  useEffect(() => {
    let notValidSlots = [];
    timeSlotData?.forEach((item) => {
      if (
        item?.startTime === "" ||
        item?.endTime === "" ||
        item?.weekday === ""
      )
        notValidSlots.push(item);
    });
    setInValidSlots(notValidSlots);
  }, [timeSlotData]);

  return (
    <Box>
      <SimpleGrid rowGap={8} columnGap={8} mt={10}>
        <GridItem>
          <Text mb={0} className="workingHoursTimeZone">
            Time Zone
          </Text>
          <Text className="workingHoursTimeZoneData">
            (GMT+5:30) India Standard Time
          </Text>
        </GridItem>
        <GridItem>
          <Flex className="workingHoursGridItemFlex1" rounded={5} gap={4}>
            <Icon color="#4299E1" as={InfoOutlineIcon} />
            <Text>
              Please Note: The requirement to get approved is by adding{" "}
              <span style={{ fontWeight: "700" }}>15 hours or more.</span> You
              are free to add more than 15 hours if your schedule permits.
              Additionally, you can add the same day for multiple timings.{" "}
              <br /> For example, Monday: 9:00 a.m. - 12:00 p.m.; Monday:
              1:00p.m. - 4:00 p.m.; and so on.
            </Text>
          </Flex>
        </GridItem>
      </SimpleGrid>
      {timeSlotData &&
        timeSlotData.map((item, index) => (
          <SimpleGrid
            className="workingHoursSimpleGrid1"
            key={index}
            gridTemplateColumns={{ base: "1fr", md: "1fr 1fr 1fr auto" }}
          >
            <GridItem>
              <FormControl isRequired isInvalid={item.noWeekDay}>
                <FormLabel>Time Slot</FormLabel>
                <Box
                  className="workingHoursTimeSlotBox1"
                  borderRadius={7}
                  sx={{
                    "& .css-wtf6ne": {
                      bg: "white",
                      border: "none",
                    },
                    "& .chakra-divider": {
                      opacity: 0,
                    },
                  }}
                >
                  <Select
                    name="weekday"
                    onChange={(e) => {
                      handleDataChange(item.id, "weekday", e.value);
                    }}
                    tabSelectsValue={item.weekday}
                    closeMenuOnSelect={false}
                    selectedOptionStyle="check"
                    placeholder="weekday"
                    hideSelectedOptions={false}
                    selectedOptionColorScheme="green"
                    options={workingDaysOptions}
                  />
                </Box>
                {item.noWeekDay && (
                  <FormErrorMessage className="qualificationsErrorMessage">
                    Weekday cannot be empty
                  </FormErrorMessage>
                )}
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl isRequired isInvalid={item.noStartTime}>
                <FormLabel>Start Time</FormLabel>
                <InputGroup>
                  <Input
                    type="time"
                    onChange={(e) =>
                      handleDataChange(item.id, e.target.name, e.target.value)
                    }
                    name="startTime"
                    value={item.startTime}
                    border="1px solid #A6B8C4"
                    locale="sv-sv"
                  />
                </InputGroup>
                {item.noStartTime && (
                  <FormErrorMessage className="qualificationsErrorMessage">
                    Please fill the timings properly
                  </FormErrorMessage>
                )}
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl isRequired isInvalid={item.noEndTime}>
                <FormLabel>End Time</FormLabel>
                <InputGroup>
                  <Input
                    value={item.endTime}
                    type="time"
                    onChange={(e) =>
                      handleDataChange(item.id, e.target.name, e.target.value)
                    }
                    name="endTime"
                    border="1px solid #A6B8C4"
                  />
                </InputGroup>
                {item.noEndTime && (
                  <FormErrorMessage className="qualificationsErrorMessage">
                    Please fill the timings properly
                  </FormErrorMessage>
                )}
              </FormControl>
            </GridItem>
            <Flex justifyContent="center" alignItems="center" mt={8}>
              <Image
                onClick={() => handleDelete(item.id)}
                h="24px"
                w="24px"
                cursor="pointer"
                src={CANCEL_TIME_SLOT}
              />
            </Flex>
          </SimpleGrid>
        ))}
      <Flex mt={5} alignItems="center" justifyContent="center">
        <Button onClick={handleAdd} color="#4299E1" fontWeight="medium">
          <AddIcon w={4} mr={2} color="#4299E1" />
          Add Working Hour
        </Button>
      </Flex>
      <Flex mt={10} width="100%" justify="flex-end" gap={4}>
        <Button
          onClick={prevStep}
          color="#4299E1"
          size="sm"
          variant="ghost"
          px="40px"
          py="20px"
        >
          Back
        </Button>
        <Button
          color={timeSlotData.length > 0 ? "white" : "#91A2AE"}
          size="sm"
          bg={
            timeSlotData.length > 0 && InValidSlots.length === 0
              ? "#4299E1"
              : "#F3F8FB"
          }
          px="40px"
          py="20px"
          onClick={timeSlotData.length > 0 ? handleNextStep : null}
          isDisabled={timeSlotData.length > 0 ? false : true}
        >
          {apiLoader ? (
            <Spinner
              bg="#f3f8fb"
              emptyColor="gray.200"
              color="blue.500"
              position="absolute"
            />
          ) : (
            "Next"
          )}
        </Button>
      </Flex>
    </Box>
  );
};

export default WorkingHours;

import { Box, Text } from "@chakra-ui/react";
import React from "react";

const LOCALSTORAGE_CHECKPOINTS_DATA_KEY = "CHECKPOINT_OUTCOMES_STUDENTS_DATA";
const LOCALSTORAGE_CONFIRM_CHECKPOINTS_DATA =
  "LOCALSTORAGE_CONFIRM_CHECKPOINTS_DATA";

const OutcomeMoveAndDelete = ({
  setShowEditHide,
  totatCheckpoints,
  mainArrayIndex,
  internalArray,
  setChangedLocalstorage,
  setOutcomeData,
  outcomeData,
}) => {
  function removeObjectById1(localStorageArray, idToRemove) {
    return localStorageArray
      ?.filter((obj) => {
        const updatedOutcomes = obj?.outcomes?.filter(
          (so) => so?.id !== idToRemove
        );
        return updatedOutcomes.length > 0;
      })
      .map((obj) => ({
        ...obj,
        outcomes: obj?.outcomes?.filter((so) => so?.id !== idToRemove),
      }));
  }

  function removeObjectById2(arrayOfArrays, idToRemove) {
    return arrayOfArrays
      .map((arr) => {
        const updatedArray = arr.outcomes.filter(
          (obj) => obj.id !== idToRemove
        );
        return updatedArray.length === 0 ? null : updatedArray;
      })
      .filter(Boolean);
  }

  function moveObjectById(arrayOfArrays, currentIndex, moveToIndex, id) {
    const currentArray = arrayOfArrays[currentIndex];
    const objectIndex = currentArray.outcomes.findIndex((obj) => obj.id === id);

    if (objectIndex !== -1) {
      const [object] = currentArray.outcomes.splice(objectIndex, 1);
      arrayOfArrays[moveToIndex].outcomes.push(object);
      if (currentArray.outcomes.length === 0) {
        arrayOfArrays.splice(currentIndex, 1);
      }
    }

    return arrayOfArrays;
  }

  const moveTo = (moveToArrayIndex, method) => {
    const currentElementId = internalArray?.id;
    if (method === "delete") {
      const updatedOutcomesData = removeObjectById1(
        outcomeData,
        currentElementId
      );
      setOutcomeData(updatedOutcomesData);
      setShowEditHide(false);
      return;
    } else if (method === "move") {
      if (moveToArrayIndex === mainArrayIndex) return setShowEditHide(false);
      const updatedArray = moveObjectById(
        outcomeData,
        mainArrayIndex,
        moveToArrayIndex,
        currentElementId
      );
      setOutcomeData(updatedArray);

      localStorage.setItem(
        LOCALSTORAGE_CONFIRM_CHECKPOINTS_DATA,
        JSON.stringify(updatedArray)
      );
      setChangedLocalstorage((prev) => prev + 1);
      setShowEditHide(false);
      return;
    }
  };

  return (
    <>
      <Box
        onClick={() => setShowEditHide(false)}
        className="editHideOverlayBox"
      ></Box>
      <Box className="editHideContainerBox">
        <Text
          color="#ba1a1a"
          fontWeight="400"
          fontSize="14px"
          lineHeight="20px"
          p="8px"
          cursor="pointer"
          borderBottom="1px solid #d8e5ee"
          onClick={() => moveTo(null, "delete")}
        >
          Remove
        </Text>
        <Text
          color="#5a646b"
          fontWeight="600"
          fontSize="12px"
          lineHeight="16px"
          p="8px 8px 0 8px"
        >
          Move
        </Text>
        {/* [...Array(totatCheckpoints)] */}
        {outcomeData?.map(
          (checkpoint, index) =>
            index !== mainArrayIndex &&
            checkpoint.status !== "Accomplished" && (
              <Text
                color="#141719"
                fontWeight="400"
                fontSize="14px"
                cursor="pointer"
                onClick={() => moveTo(index, "move")}
                lineHeight="20px"
                key={index}
                p="8px"
              >
                Checkpoint {index + 1}
              </Text>
            )
        )}
      </Box>
    </>
  );
};

export default OutcomeMoveAndDelete;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Flex, Text, Tag, Avatar, TagLabel } from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { monthOptions } from "../../../../constant/selectOptions";
import { getParentJournalAction } from "../../../../modules/actions";
import Loader from "../../../../common/components/Loader";
import {
  getCurrentMonthName,
  getCurrentYearValue,
} from "../../../../utils/customMoment";
import { getPreviousYearsArray } from "../../../../utils/customHooks";

const ParentJournal = ({ selectedChild }) => {
  const dispatch = useDispatch();
  const { parentJournal } = useSelector((state) => state?.user);
  const loaderState = useSelector((state) => state?.loader?.apiLoader);
  const [selectedData, setSelectedData] = useState({
    month: getCurrentMonthName(),
    year: getCurrentYearValue(),
  });
  const [journal, setJournal] = useState([]);

  const handleCurrentDatesChange = (name, value) => {
    setSelectedData({ ...selectedData, [name]: value });
  };

  const handleDateFormat = (dateString) => {
    const dateArray = String(new Date(dateString * 1000)).split(" ");
    return `${dateArray[2]} ${dateArray[1]}, ${dateArray[3]}`;
  };

  useEffect(() => {
    const createParentJournalData = () => {
      if (parentJournal === null || parentJournal?.length === 0) return;
      const updatedParentJournal = parentJournal?.map((pj) => {
        return {
          id: pj?.id,
          date: handleDateFormat(pj?.createdOn?._seconds),
          checkpointItems: {
            id: pj?.outcome?.id,
            completed: pj?.outcome?.status,
            subgoalOf: pj?.outcome?.goalName,
            taskName: pj?.outcome?.name,
          },
        };
      });
      setJournal(updatedParentJournal);
    };
    createParentJournalData();
  }, [parentJournal]);

  useEffect(() => {
    dispatch(
      getParentJournalAction(
        selectedChild?.id,
        selectedData.month,
        selectedData.year
      )
    );
  }, [selectedData, dispatch, selectedChild]);

  const selectStyles = {
    control: (styles) => ({
      ...styles,
      border: "none",
      outline: "none",
      borderRadius: "10px",
      boxShadow: "none",
      width: "full",
      // padding: '0px 12px',
      cursor: "pointer",
      height: "32px",
    }),
    dropdownIndicator: (prev, { selectProps: { menuIsOpen } }) => ({
      ...prev,
      "> svg": {
        transitionDuration: "normal",
        transform: `rotate(${menuIsOpen ? -180 : 0}deg)`,
        color: "#4299E1",
        height: "32px",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? "#E9F6FF"
          : isFocused
          ? "#F3F8FB"
          : undefined,
        color: isDisabled ? "#ccc" : isSelected ? "#4299E1" : "#141719",
        padding: "10px",
        cursor: isDisabled ? "not-allowed" : "pointer",
        fontFamily: "Inter",
        fontSize: "14px",
        lineHeight: "20px",
        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? "#E9F6FF"
              : "#E9F6FF"
            : undefined,
        },
      };
    },
    singleValue: (styles) => ({
      ...styles,
      color: "#141719",
      fontWeight: "600",
    }),
    menuList: (styles) => ({
      ...styles,
      borderRadius: "8px",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.16)",
    }),
  };

  return (
    <Box id="mainWrapper" px="16px" py="24px">
      <Text className="txnsTabText1">Parent Journal</Text>
      <Text className="txnsTabText2">
        Responses of the parent while doing exercises to achieve outcomes given.
      </Text>
      <Flex mt="16px" gap="16px">
        <Select
          useBasicStyles
          options={monthOptions}
          className="txnsTabSelect"
          value={{ label: selectedData.month, value: selectedData.month }}
          chakraStyles={selectStyles}
          name="month"
          onChange={(e) => handleCurrentDatesChange("month", e.value)}
        />
        <Select
          useBasicStyles
          chakraStyles={selectStyles}
          className="txnsTabSelect"
          value={{ label: selectedData.year, value: selectedData.year }}
          name="year"
          options={getPreviousYearsArray()}
          onChange={(e) => handleCurrentDatesChange("year", e.value)}
        />
      </Flex>
      {loaderState ? (
        <Loader text={"Loading parent journal data..."} />
      ) : (
        <Box>
          {journal?.length > 0 ? (
            journal?.map((c, index) => (
              <Box key={index}>
                <Text className="txnsFirstMapBoxText">{c?.date}</Text>
                <Flex className="txnsSecondMapFlex">
                  <Flex gap="8px" flexDir="column">
                    <Text className="parentJournalMapTaskName">
                      {c?.checkpointItems.taskName}
                    </Text>
                    <Text className="parentJournalMapSubGoal">
                      {c?.checkpointItems.subgoalOf}
                    </Text>
                  </Flex>
                  <Tag
                    className="txnsSecondMapFlexBoxTag"
                    style={{
                      backgroundColor:
                        (c?.checkpointItems?.completed === "Yes" &&
                          "#EFF7E6") ||
                        (c?.checkpointItems?.completed === "No" && "#ffdad6") ||
                        (c?.checkpointItems?.completed === "Almost" &&
                          "#F3EBFF"),
                    }}
                  >
                    <TagLabel
                      style={{
                        color:
                          (c?.checkpointItems?.completed === "Yes" &&
                            "#024742") ||
                          (c?.checkpointItems?.completed === "No" &&
                            "#410002") ||
                          (c?.checkpointItems?.completed === "Almost" &&
                            "#2F0D63"),
                      }}
                    >
                      {c?.checkpointItems?.completed}
                    </TagLabel>
                  </Tag>
                </Flex>
              </Box>
            ))
          ) : (
            <Text color="#141719" mt={10} fontSize="20px">
              You don't have any journal entries for the selected time period
            </Text>
          )}
        </Box>
      )}
    </Box>
  );
};

export default ParentJournal;

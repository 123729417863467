import { useEffect, useState } from "react";
import {
  Show,
  Box,
  Text,
  Flex,
  Grid,
  GridItem,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Image,
  Spinner,
  Button,
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import moment from "moment";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AuthenticatedContainer from "../../../container/Authenticated";
import MobileNotAllowedScreen from "../../../common/components/MobileNotAllowed";
import ChildInformation from "./Tabs/childInfo";
import Obervations from "./Tabs/Obervations";
import MedicalHistory from "./Tabs/medicalHistory";
import AcademicHistory from "./Tabs/academicHistory";
import Loader from "../../../common/components/Loader";
import { Toaster } from "../../../common/components/Toaster";
import {
  ACADEMIC_HISTORY,
  CHILD_INFO,
  MEDICAL_HISTORY,
  OBSERVATIONS,
  languageOptions,
  ROUTES_PATH,
  BACK_BTN,
  STAR_ICON,
  acrQuickTips,
} from "../../../constant";
import "./acrReport.css";
import {
  getConsultationById,
  toggleMainLoader,
  toggleAPILoader,
  getConsultationReport,
  toggleSessionLoader,
} from "../../../modules/actions";
import { getMonthDateYear1, getMomentDate } from "../../../utils/customMoment";
import { updateAcrService } from "../../../modules/services";
import { validateName } from "../../../utils/validations";

const ACRReport = () => {
  let { consultationId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const consulatationDetails = useSelector((state) => state?.consultationById);
  const consultationReport = useSelector((state) => state?.consultationReport);
  const loader = useSelector((state) => state?.loader);

  const [activeStep, setActiveStep] = useState("Child Information");
  const [defaultLanguagesUsed, setDefaultLanguagesUsed] = useState([]);
  const [defaultlanguageProficiency, setDefaultlanguageProficiency] = useState(
    []
  );
  const [defaultPrimaryConcerns, setDefaultPrimaryConcerns] = useState([]);
  const [defaultDiagnosisConcern, setDefaultDiagnosisConcern] = useState([]);

  const [childInfo, setChildInfo] = useState({
    languageUsed: [],
    languageUsedError: "",
    languageProficiency: [],
    languageProficiencyError: "",
    childPresent: "",
    childPresentError: "",
    childName: "",
    childNameError: "",
    childDob: "",
    childDobError: "",
    childGender: "",
    childGenderError: "",
    guardianPresent: "",
    guardianPresentError: "",
    primaryConcerns: [],
    primaryConcernsError: "",
  });
  const [medicalInfo, setMedicalInfo] = useState({
    prenatalMedicalIssues: [],
    prenatalMedicalIssuesError: "",
    otherMedicalIssues: "",
    otherMedicalIssuesError: "",
    babyCry: "",
    babyCryError: "",
    birthWeight: "",
    birthWeightError: "",
    gestationPeriod: "",
    gestationPeriodError: "",
    prematureWeeks: "",
    prematureWeeksError: "",
    postnatalMedicalIssues: [],
    postnatalMedicalIssuesError: "",
    otherPostnatalMedicalIssues: "",
    otherPostnatalMedicalIssuesError: "",
    developmentalMilestones: [],
    developmentalMilestonesError: "",
    pastevalutions: "",
    pastevalutionsError: "",
    pastevalutionsReason: "",
    pastevalutionsReasonError: "",
  });
  const [observations, setObservations] = useState({
    summary: "",
    summaryError: "",
    domainSpecificSkills: [],
    domainSpecificSkillsError: "",
  });
  const [academicHistory, setAcademicHistory] = useState({
    goingSchool: "",
    goingSchoolError: "",
    goingSchoolReason: "",
    goingSchoolReasonError: "",
    grade: "",
    gradeError: "",
    board: "",
    boardError: "",
    academicPerformance: "",
    academicPerformanceError: "",
    academicPerformanceReason: "",
    academicPerformanceReasonError: "",
    comprehensionOfConcept: "",
    comprehensionOfConceptError: "",
    comprehensionOfConceptReason: "",
    comprehensionOfConceptReasonError: "",
    teacherComplaints: "",
    teacherComplaintsError: "",
    teacherComplaintsReason: "",
    teacherComplaintsReasonError: "",
    diagnosis: "",
    diagnosisError: "",
    defaultDiagnosis: [],
    diagnosisConcernError: "",
    prognosis: "",
    prognosisError: "",
    recommendedPlan: [],
    recommendedPlanError: "",
    sessionsNo: "",
    sessionsNoError: "",
    sessionService: "",
    sessionServiceError: "",
    parentCouncellingNo: "",
    parentCouncellingNoError: "",
    consultationService: "",
    consultationServiceError: "",
  });

  useEffect(() => {
    if (consultationId) {
      dispatch(toggleMainLoader(true));
      dispatch(getConsultationById(consultationId));
      dispatch(toggleSessionLoader(true));
      dispatch(getConsultationReport(consultationId));
    }
  }, [consultationId]);

  useEffect(() => {
    setChildInfo({
      languageUsed: consultationReport?.languageUsed
        ? consultationReport?.languageUsed
        : consulatationDetails?.primaryLanguage
        ? [consulatationDetails?.primaryLanguage]
        : [],
      languageProficiency: consultationReport?.languageProficiency
        ? consultationReport?.languageProficiency
        : consulatationDetails?.secondaryLanguages
        ? consulatationDetails?.secondaryLanguages
        : [],

      childName: consultationReport?.childName
        ? consultationReport?.childName
        : consulatationDetails?.sessionStudentName,

      childDob: consultationReport?.childName
        ? getMomentDate(new Date(consultationReport?.childDob))
        : getMomentDate(new Date(consulatationDetails?.dateOfBirth)),

      primaryConcerns: consultationReport?.primaryConcerns
        ? consultationReport?.primaryConcerns
        : [],
      childGender: consultationReport?.childGender
        ? consultationReport?.childGender
        : "",
      childPresent: consultationReport?.childPresent
        ? consultationReport?.childPresent
        : "",
      guardianPresent: consultationReport?.guardianPresent
        ? consultationReport?.guardianPresent
        : "",
    });
    setMedicalInfo({
      prenatalMedicalIssues: consultationReport?.prenatalMedicalIssues
        ? consultationReport?.prenatalMedicalIssues
        : [],

      otherMedicalIssues: consultationReport?.otherPrenatalMedicalIssues
        ? consultationReport?.otherPrenatalMedicalIssues
        : "",

      babyCry: consultationReport?.babyCry ? consultationReport?.babyCry : "",

      birthWeight: consultationReport?.birthWeight
        ? consultationReport?.birthWeight
        : "",

      gestationPeriod: consultationReport?.gestationPeriod
        ? consultationReport?.gestationPeriod
        : "",

      prematureWeeks: consultationReport?.prematureWeeks
        ? consultationReport?.prematureWeeks
        : "",

      postnatalMedicalIssues: consultationReport?.postnatalMedicalIssues
        ? consultationReport?.postnatalMedicalIssues
        : [],

      otherPostnatalMedicalIssues:
        consultationReport?.otherPostnatalMedicalIssues
          ? consultationReport?.otherPostnatalMedicalIssues
          : "",

      developmentalMilestones: consultationReport?.developmentalMilestones
        ? consultationReport?.developmentalMilestones
        : [],

      pastevalutions: consultationReport?.pastevalutions
        ? consultationReport?.pastevalutions
        : "",

      pastevalutionsReason: consultationReport?.pastevalutionsReason
        ? consultationReport?.pastevalutionsReason
        : "",
    });
    setObservations({
      summary: consultationReport?.summary ? consultationReport?.summary : "",
      domainSpecificSkills: consultationReport?.domainSpecificSkills
        ? consultationReport?.domainSpecificSkills
        : "",
    });
    setAcademicHistory({
      goingSchool: consultationReport?.goingSchool
        ? consultationReport?.goingSchool
        : "",

      goingSchoolReason: consultationReport?.goingSchoolReason
        ? consultationReport?.goingSchoolReason
        : "",

      grade: consultationReport?.grade ? consultationReport?.grade : "",

      board: consultationReport?.board ? consultationReport?.board : "",

      academicPerformance: consultationReport?.academicPerformance
        ? consultationReport?.academicPerformance
        : "",

      academicPerformanceReason: consultationReport?.academicPerformanceReason
        ? consultationReport?.academicPerformanceReason
        : "",

      comprehensionOfConcept: consultationReport?.comprehensionOfConcept
        ? consultationReport?.comprehensionOfConcept
        : "",

      comprehensionOfConceptReason:
        consultationReport?.comprehensionOfConceptReason
          ? consultationReport?.comprehensionOfConceptReason
          : "",

      teacherComplaints: consultationReport?.teacherComplaints
        ? consultationReport?.teacherComplaints
        : "",

      teacherComplaintsReason: consultationReport?.teacherComplaints
        ? consultationReport?.teacherComplaints
        : "",

      diagnosis: consultationReport?.diagnosis
        ? consultationReport?.diagnosis
        : "",

      diagnosisConcern: consultationReport?.diagnosisConcern
        ? consultationReport?.diagnosisConcern
        : [],

      prognosis: consultationReport?.prognosis
        ? consultationReport?.prognosis
        : "",

      recommendedPlan: consultationReport?.recommendedPlan
        ? consultationReport?.recommendedPlan
        : [],

      sessionsNo: consultationReport?.sessionsNo
        ? consultationReport?.sessionsNo
        : "",

      sessionService: consultationReport?.sessionService
        ? consultationReport?.sessionService
        : "",

      parentCouncellingNo: consultationReport?.parentCouncellingNo
        ? consultationReport?.parentCouncellingNo
        : "",
      consultationService: consultationReport?.consultationService
        ? consultationReport?.consultationService
        : "",
    });
  }, [consulatationDetails, consultationReport]);

  useEffect(() => {
    setDefaultLanguagesUsed(
      childInfo?.languageUsed?.map((item) => {
        return {
          value: item,
          label: item,
        };
      })
    );
    setDefaultlanguageProficiency(
      childInfo?.languageProficiency?.map((item) => {
        return {
          value: item,
          label: item,
        };
      })
    );
    setDefaultPrimaryConcerns(
      childInfo?.primaryConcerns?.map((item) => {
        return {
          value: item,
          label: item,
        };
      })
    );
  }, [childInfo]);

  useEffect(() => {
    setDefaultDiagnosisConcern(
      academicHistory?.diagnosisConcern?.map((item) => {
        return {
          value: item,
          label: item,
        };
      })
    );
  }, [academicHistory?.diagnosisConcern]);

  const handleFormDataChange = (type, name, value, errorName) => {
    if (type === "childInfo") {
      setChildInfo({ ...childInfo, [name]: value, [errorName]: "" });
    } else if (type === "medicalInfo") {
      setMedicalInfo({ ...medicalInfo, [name]: value, [errorName]: "" });
    } else if (type === "observations") {
      setObservations({ ...observations, [name]: value, [errorName]: "" });
    } else {
      setAcademicHistory({
        ...academicHistory,
        [name]: value,
        [errorName]: "",
      });
    }
  };
  const handleMultipleSelection = (type, name, value, errorName) => {
    if (type === "medicalInfo") {
      let medicalInfoData = { ...medicalInfo };

      medicalInfoData[name].includes(value)
        ? (medicalInfoData[name] = medicalInfoData[name].filter(
            (e) => e !== value
          ))
        : medicalInfoData[name].push(value);
      medicalInfoData[errorName] = "";
      setMedicalInfo(medicalInfoData);
    } else {
      let academicHistoryData = { ...academicHistory };

      academicHistoryData[name].includes(value)
        ? (academicHistoryData[name] = academicHistoryData[name].filter(
            (e) => e !== value
          ))
        : academicHistoryData[name].push(value);
      academicHistoryData[errorName] = "";

      setAcademicHistory(academicHistoryData);
    }
  };
  const handleSaveReport = () => {
    let step;
    const validateLanguageUsed =
      childInfo?.languageUsed.length === 0
        ? "Please choose language used in consultation"
        : "";
    const validateLanguageProficiency =
      childInfo?.languageProficiency.length === 0
        ? "Please choose languages prefered"
        : "";

    setChildInfo({
      ...childInfo,
      languageProficiencyError: validateLanguageProficiency,
      languageUsedError: validateLanguageUsed,
    });
    if (activeStep === "Child Information") {
      step = 1;

      const validatechildPresent = !childInfo?.childPresent
        ? "Please choose child present or not"
        : "";
      const validateChildName = !validateName(childInfo?.childName)
        ? "Please enter valid child name"
        : "";
      const validateChildDob =
        childInfo?.childDob === "" ||
        moment(childInfo?.childDob).isAfter(moment(), "day")
          ? "Please choose valid child DOB"
          : "";
      const validatechildGender = !childInfo?.childGender
        ? "Please choose child gender"
        : "";
      const validateGuardianPresent = !childInfo?.guardianPresent
        ? "Please choose guardian present or not"
        : "";
      const validatePrimaryConcerns =
        childInfo?.primaryConcerns.length === 0
          ? "Please choose primary concerns"
          : "";
      setChildInfo({
        ...childInfo,
        childNameError: validateChildName,
        languageProficiencyError: validateLanguageProficiency,
        languageUsedError: validateLanguageUsed,
        childPresentError: validatechildPresent,
        childDobError: validateChildDob,
        childGenderError: validatechildGender,
        guardianPresentError: validateGuardianPresent,
        primaryConcernsError: validatePrimaryConcerns,
      });
      if (
        !validateChildName &&
        !validateLanguageProficiency &&
        !validateLanguageUsed &&
        !validatechildPresent &&
        !validateChildDob &&
        !validateGuardianPresent &&
        !validatechildGender &&
        !validatePrimaryConcerns
      ) {
        const reqData = {
          childName: childInfo?.childName,
          languageUsed: childInfo?.languageUsed,
          languageProficiency: childInfo?.languageProficiency,
          childGender: childInfo?.childGender,
          childPresent: childInfo?.childPresent,
          childDob: childInfo?.childDob,
          guardianPresent: childInfo?.guardianPresent,
          primaryConcerns: childInfo?.primaryConcerns,
          consultationId: consulatationDetails?.id,
          consultation: consulatationDetails,
          consulatationReportId: consultationReport?.id,
        };

        handleUpdateAcrApiCall(step, reqData);
      }
    } else if (activeStep === "Medical History") {
      step = 2;

      const validatePrenatalMedicalIssues =
        medicalInfo?.prenatalMedicalIssues.length === 0
          ? "Please choose medical issues"
          : "";
      const validateOtherMedicalIssues =
        medicalInfo?.prenatalMedicalIssues.includes("Others") &&
        !medicalInfo?.otherMedicalIssues
          ? "Please mention other issues"
          : "";
      const validateBabyCry = !medicalInfo?.babyCry
        ? "Please choose baby cried or not option"
        : "";
      const validateBirthWeight = !medicalInfo?.birthWeight
        ? "Please choose child birth weight"
        : "";
      const validateGestationPeriod = !medicalInfo?.gestationPeriod
        ? "Please enter valid gestation period"
        : "";

      const validatechildPastEvalutions = !medicalInfo?.pastevalutions
        ? "Please choose past evalutions yes or no"
        : "";
      const validatePastevalutionsReason =
        medicalInfo?.pastevalutions === "Yes" &&
        !medicalInfo?.pastevalutionsReason
          ? "Please mention past evalution reason"
          : "";

      const validateDevelopmentalMilestones =
        medicalInfo?.developmentalMilestones.length === 0
          ? "Please choose developmental milestones"
          : "";
      const validatePostnatalMedicalIssues =
        medicalInfo?.postnatalMedicalIssues.length === 0
          ? "Please choose medical issues"
          : "";
      const validateOtherPostnatalMedicalIssues =
        medicalInfo?.postnatalMedicalIssues.includes("Others") &&
        !medicalInfo?.otherPostnatalMedicalIssues
          ? "Please mention other issues"
          : "";
      setMedicalInfo({
        ...medicalInfo,
        prenatalMedicalIssuesError: validatePrenatalMedicalIssues,
        otherMedicalIssuesError: validateOtherMedicalIssues,
        babyCryError: validateBabyCry,
        birthWeightError: validateBirthWeight,
        gestationPeriodError: validateGestationPeriod,
        pastevalutionsError: validatechildPastEvalutions,
        postnatalMedicalIssuesError: validatePostnatalMedicalIssues,
        developmentalMilestonesError: validateDevelopmentalMilestones,
        otherPostnatalMedicalIssuesError: validateOtherPostnatalMedicalIssues,
        pastevalutionsReasonError: validatePastevalutionsReason,
      });
      if (
        !validatePrenatalMedicalIssues &&
        !validateOtherMedicalIssues &&
        !validateBabyCry &&
        !validateBirthWeight &&
        !validateGestationPeriod &&
        !validatePostnatalMedicalIssues &&
        !validatechildPastEvalutions &&
        !validateDevelopmentalMilestones &&
        !validateOtherPostnatalMedicalIssues &&
        !validatePastevalutionsReason &&
        !validateLanguageProficiency &&
        !validateLanguageUsed
      ) {
        const reqData = {
          prenatalMedicalIssues: medicalInfo?.prenatalMedicalIssues,
          otherPrenatalMedicalIssues: medicalInfo?.otherMedicalIssues,
          babyCry: medicalInfo?.babyCry,
          birthWeight: medicalInfo?.birthWeight,
          gestationPeriod: medicalInfo?.gestationPeriod,
          prematureWeeks: medicalInfo?.prematureWeeks,
          postnatalMedicalIssues: medicalInfo?.postnatalMedicalIssues,
          otherPostnatalMedicalIssues: medicalInfo?.otherPostnatalMedicalIssues,
          developmentalMilestones: medicalInfo?.developmentalMilestones,
          pastevalutions: medicalInfo?.pastevalutions,
          pastevalutionsReason: medicalInfo?.pastevalutionsReason,
          consulatationReportId: consultationReport?.id,
        };
        handleUpdateAcrApiCall(step, reqData);
      }
    } else if (activeStep === "Observations") {
      step = 3;

      const validateSummary = !observations?.summary
        ? "Please enter valid summary about child"
        : "";

      const validateDomainSpecificSkills =
        observations?.domainSpecificSkills?.length === 0
          ? "Please add rating for this skills"
          : "";

      let validateSkill = false;
      if (observations?.domainSpecificSkills?.length > 0) {
        observations?.domainSpecificSkills?.map((mainskills) => {
          mainskills?.data?.map((skill) => {
            if (!("rating" in skill)) {
              // Add the skillNameError property with the specified value
              skill.skillNameError = "Please add rating for this skill";
              validateSkill = true;
            } else if (skill.rating <= 3 && !("reason" in skill)) {
              skill.skillReasonError = "Please add reason for this skill";
              validateSkill = true;
            } else {
              skill.skillNameError = "";
              skill.skillReasonError = "";
              // validateSkill = false;
            }
          });
        });
      }

      setObservations({
        ...observations,
        summaryError: validateSummary,
        domainSpecificSkillsError: validateDomainSpecificSkills,
      });
      if (!validateSummary && !validateDomainSpecificSkills && !validateSkill) {
        const reqData = {
          summary: observations?.summary,
          domainSpecificSkills: observations?.domainSpecificSkills,
          consulatationReportId: consultationReport?.id,
        };
        handleUpdateAcrApiCall(step, reqData);
      }
    } else {
      step = 4;

      const validateGoingSchool = !academicHistory?.goingSchool
        ? "Please choose child going to school or not"
        : "";

      const validateGoingSchoolReason =
        academicHistory?.goingSchool === "No" &&
        !academicHistory?.goingSchoolReason
          ? "Please mention reason"
          : "";

      const validateBoard = !academicHistory?.board
        ? "Please enter valid child's board"
        : "";

      const validateChildGrade = !academicHistory?.grade
        ? "Please enter valid child's grade"
        : "";
      const validateAcademicPerformance = !academicHistory?.academicPerformance
        ? "Please choose child's academic performance"
        : "";

      const validateAcademicPerformanceReason =
        academicHistory?.academicPerformance === "Poor" &&
        !academicHistory?.academicPerformanceReason
          ? "Please mention reason"
          : "";
      const validateComprehensionOfConcept =
        !academicHistory?.comprehensionOfConcept
          ? "Please choose child's comprehension of concept"
          : "";

      const validateComprehensionOfConceptReason =
        academicHistory?.comprehensionOfConcept === "Poor" &&
        !academicHistory?.comprehensionOfConceptReason
          ? "Please mention reason"
          : "";
      const validateTeacherComplaints = !academicHistory?.teacherComplaints
        ? "Please choose teacher complaints or not"
        : "";

      const validateTeacherComplaintsReason =
        academicHistory?.teacherComplaints === "Yes" &&
        !academicHistory?.teacherComplaintsReason
          ? "Please mention reason"
          : "";
      const validateDiagnosis = !academicHistory?.diagnosis
        ? "Please choose dignosis"
        : "";
      const validateDignosisConcern =
        academicHistory?.diagnosis === "Concerns" &&
        academicHistory?.diagnosisConcern.length === 0
          ? "Please mention concern"
          : "";
      const validatePrognosis = !academicHistory?.prognosis
        ? "Please enter valid prognosis"
        : "";
      const validateRecommendedPlan =
        academicHistory?.recommendedPlan.length === 0
          ? "Please choose recommended plan"
          : "";
      const validateSessionNo =
        academicHistory?.recommendedPlan.includes("Sessions") &&
        !academicHistory?.sessionsNo
          ? "Please choose session nos"
          : "";
      const validateSessionService =
        academicHistory?.recommendedPlan.includes("Sessions") &&
        !academicHistory?.sessionService
          ? "Please choose session service"
          : "";

      const validateParentCounsellingNo =
        academicHistory?.recommendedPlan.includes("Parental Counseling") &&
        !academicHistory?.parentCouncellingNo
          ? "Please choose parent councelling nos"
          : "";
      const validateConsultationService =
        academicHistory?.recommendedPlan.includes("Consultation") &&
        !academicHistory?.consultationService
          ? "Please choose consultation service"
          : "";

      setAcademicHistory({
        ...academicHistory,
        goingSchoolError: validateGoingSchool,
        goingSchoolReasonError: validateGoingSchoolReason,
        gradeError: validateChildGrade,
        boardError: validateBoard,
        academicPerformanceError: validateAcademicPerformance,
        academicPerformanceReasonError: validateAcademicPerformanceReason,
        comprehensionOfConceptError: validateComprehensionOfConcept,
        comprehensionOfConceptReasonError: validateComprehensionOfConceptReason,
        teacherComplaintsError: validateTeacherComplaints,
        teacherComplaintsReasonError: validateTeacherComplaintsReason,
        diagnosisError: validateDiagnosis,
        diagnosisConcernError: validateDignosisConcern,
        prognosisError: validatePrognosis,
        recommendedPlanError: validateRecommendedPlan,
        sessionsNoError: validateSessionNo,
        sessionServiceError: validateSessionService,
        parentCouncellingNoError: validateParentCounsellingNo,
        consultationServiceError: validateConsultationService,
      });
      if (
        !validateGoingSchool &&
        !validateGoingSchoolReason &&
        !validateChildGrade &&
        !validateBoard &&
        !validateAcademicPerformance &&
        !validateAcademicPerformanceReason &&
        !validateComprehensionOfConcept &&
        !validateComprehensionOfConceptReason &&
        !validateTeacherComplaints &&
        !validateTeacherComplaintsReason &&
        !validateDiagnosis &&
        !validateDignosisConcern &&
        !validatePrognosis &&
        !validateRecommendedPlan &&
        !validateSessionNo &&
        !validateSessionService &&
        !validateParentCounsellingNo &&
        !validateConsultationService
      ) {
        const reqData = {
          goingSchool: academicHistory?.goingSchool,
          goingSchoolReason: academicHistory?.goingSchoolReason,
          grade: academicHistory?.grade,
          board: academicHistory?.board,
          academicPerformance: academicHistory?.academicPerformance,
          academicPerformanceReason: academicHistory?.academicPerformanceReason,
          comprehensionOfConcept: academicHistory?.comprehensionOfConcept,
          comprehensionOfConceptReason:
            academicHistory?.comprehensionOfConceptReason,
          teacherComplaints: academicHistory?.teacherComplaints,
          teacherComplaintsReason: academicHistory?.teacherComplaintsReason,
          diagnosis: academicHistory?.diagnosis,
          diagnosisConcern: academicHistory?.diagnosisConcern,
          prognosis: academicHistory?.prognosis,
          recommendedPlan: academicHistory?.recommendedPlan,
          sessionsNo: academicHistory?.sessionsNo,
          sessionService: academicHistory?.sessionService,
          parentCouncellingNo: academicHistory?.parentCouncellingNo,
          consultationService: academicHistory?.consultationService,
          consulatationReportId: consultationReport?.id,
        };
        handleUpdateAcrApiCall(step, reqData);
      }
    }
  };
  const handleUpdateAcrApiCall = (step, reqData) => {
    dispatch(toggleAPILoader(true));
    updateAcrService(step, reqData)
      .then((res) => {
        Toaster("Consultation report updated successfully", "success");
        dispatch(toggleSessionLoader(true));
        dispatch(getConsultationReport(consultationId));
        dispatch(toggleAPILoader(false));
      })
      .catch((error) => {
        console.log(error);
        dispatch(toggleAPILoader(false));
        Toaster(error.response.data.message, "error");
      });
  };

  const tabs = [
    {
      id: "Child Information",
      value: (
        <ChildInformation
          childInfo={childInfo}
          handleFormDataChange={handleFormDataChange}
          defaultPrimaryConcerns={defaultPrimaryConcerns}
        />
      ),
      icon: CHILD_INFO,
    },
    {
      id: "Medical History",
      value: (
        <MedicalHistory
          medicalInfo={medicalInfo}
          handleFormDataChange={handleFormDataChange}
          handleMultipleSelection={handleMultipleSelection}
        />
      ),
      icon: MEDICAL_HISTORY,
    },
    {
      id: "Observations",
      value: (
        <Obervations
          observations={observations}
          handleFormDataChange={handleFormDataChange}
          serviceType={consulatationDetails?.serviceType}
        />
      ),
      icon: OBSERVATIONS,
    },
    {
      id: "Academic History",
      value: (
        <AcademicHistory
          academicHistory={academicHistory}
          handleFormDataChange={handleFormDataChange}
          handleMultipleSelection={handleMultipleSelection}
          defaultDiagnosisConcern={defaultDiagnosisConcern}
        />
      ),
      icon: ACADEMIC_HISTORY,
    },
  ];

  return (
    <Box>
      <AuthenticatedContainer>
        <MobileNotAllowedScreen />
        <Show breakpoint="(min-width:767px)">
          {loader?.mainLoader && loader?.sessionLoader ? (
            <Loader text={"Loading, wait here...."} />
          ) : (
            <Box className="mainDiv">
              <Box className="exerciseBackText">
                <Image
                  src={BACK_BTN}
                  mr="8px"
                  onClick={() => navigate(ROUTES_PATH.PARENT_HOME)}
                  cursor="pointer"
                />
                <Text
                  onClick={() => navigate(ROUTES_PATH.PARENT_HOME)}
                  cursor="pointer"
                >
                  Back to level
                </Text>
              </Box>
              <Flex flexDir={{ base: "column", lg: "row" }}>
                <Flex
                  className="sidebarLeft"
                  minW={{ base: "full", lg: "288px" }}
                  flexDir={{ base: "row", lg: "column" }}
                  gap={{ base: "24px", lg: "24px" }}
                >
                  {tabs.map((item) => (
                    <Flex
                      key={item.id}
                      className={
                        item.id === activeStep
                          ? "sidebar activeTabACR"
                          : "sidebar inActiveTabACR"
                      }
                      w="full"
                      _hover={{ cursor: "pointer" }}
                      rounded={{ base: "none", md: "full" }}
                      onClick={() => setActiveStep(item.id)}
                    >
                      <Image src={item.icon} alt={item.id} mr="8px" />
                      <Text>{item.id}</Text>
                    </Flex>
                  ))}
                  {activeStep === "Observations" && (
                    <Box className="tipsBox">
                      <Flex alignItems="center">
                        <Image src={STAR_ICON} alt="" mr="8px" />
                        <Text fontWeight="600">Quick Tips</Text>
                      </Flex>
                      {acrQuickTips.map((tip, index) => (
                        <Flex mt="24px" key={index} justifyContent="flex-start">
                          <Text mr="8px">{index + 1}</Text>
                          <Text>{tip}</Text>
                        </Flex>
                      ))}
                    </Box>
                  )}
                </Flex>
                <Box
                  sx={{
                    "& .chakra-form__required-indicator": {
                      color: "black",
                    },
                  }}
                  w="full"
                  maxW="762px"
                  id="right-side"
                >
                  <Text className="formHeading">
                    {consulatationDetails?.serviceType} Consultation Report
                  </Text>
                  <Box className="specialistDiv">
                    <Flex justifyContent="space-between">
                      <Text className="specialistName">
                        {consulatationDetails?.sessionExpertName}
                      </Text>
                      <Box>
                        <Flex textAlign="left">
                          <Text className="dateHeading">Date:</Text>
                          <Text className="datevalues">
                            {consulatationDetails?.sessionDate &&
                              getMonthDateYear1(
                                consulatationDetails?.sessionDate
                              )}
                          </Text>
                        </Flex>
                        <Flex textAlign="left" mt="8px">
                          <Text className="dateHeading">Duration:</Text>
                          <Text className="datevalues">
                            {consulatationDetails?.sessionDuration} min
                          </Text>
                        </Flex>
                      </Box>
                    </Flex>
                    <Grid
                      templateColumns="repeat(12, 1fr)"
                      gap="24px"
                      mt="24px"
                    >
                      <GridItem colSpan={6}>
                        <FormControl isInvalid={childInfo.languageUsedError}>
                          <FormLabel
                            className="acrFormLabel"
                            style={{
                              color: childInfo.languageUsedError && "#BA1A1A",
                            }}
                            sx={{
                              "& .chakra-form__required-indicator": {
                                color: childInfo.languageUsedError && "#BA1A1A",
                              },
                            }}
                          >
                            Language used in consultation
                          </FormLabel>
                          <Box
                            border="1px solid #D8E5EE"
                            fontWeight="medium"
                            borderRadius={7}
                            backgroundColor="white"
                            sx={{
                              "& .css-wtf6ne": {
                                bg: "white",
                                border: "none",
                              },
                              "& .chakra-divider": {
                                opacity: 0,
                              },
                            }}
                          >
                            <Select
                              isMulti
                              onChange={(e) =>
                                handleFormDataChange(
                                  "childInfo",
                                  "languageUsed",
                                  e.map((l) => l.value, "languageUsedError")
                                )
                              }
                              name="languages"
                              closeMenuOnSelect={false}
                              value={defaultLanguagesUsed}
                              selectedOptionStyle="check"
                              placeholder="Select Language"
                              className="commonFormSelect"
                              hideSelectedOptions={false}
                              selectedOptionColorScheme="green"
                              options={languageOptions}
                            />
                          </Box>
                          {childInfo.languageUsedError && (
                            <FormErrorMessage className="personalErrorMessage">
                              {childInfo.languageUsedError}
                            </FormErrorMessage>
                          )}
                        </FormControl>
                      </GridItem>
                      <GridItem colSpan={6}>
                        <FormControl
                          isInvalid={childInfo.languageProficiencyError}
                        >
                          <FormLabel
                            className="acrFormLabel"
                            style={{
                              color:
                                childInfo.languageProficiencyError && "#BA1A1A",
                            }}
                            sx={{
                              "& .chakra-form__required-indicator": {
                                color:
                                  childInfo.languageProficiencyError &&
                                  "#BA1A1A",
                              },
                            }}
                          >
                            Child language proficiency
                          </FormLabel>
                          <Box
                            border="1px solid #D8E5EE"
                            fontWeight="medium"
                            borderRadius={7}
                            backgroundColor="white"
                            sx={{
                              "& .css-wtf6ne": {
                                bg: "white",
                                border: "none",
                              },
                              "& .chakra-divider": {
                                opacity: 0,
                              },
                            }}
                          >
                            <Select
                              isMulti
                              onChange={(e) =>
                                handleFormDataChange(
                                  "childInfo",
                                  "languageProficiency",
                                  e.map(
                                    (l) => l.value,
                                    "languageProficiencyError"
                                  )
                                )
                              }
                              name="languages"
                              closeMenuOnSelect={false}
                              value={defaultlanguageProficiency}
                              selectedOptionStyle="check"
                              placeholder="Select Language"
                              className="commonFormSelect"
                              hideSelectedOptions={false}
                              selectedOptionColorScheme="green"
                              options={languageOptions}
                            />
                          </Box>
                          {childInfo.languageProficiencyError && (
                            <FormErrorMessage className="personalErrorMessage">
                              {childInfo.languageProficiencyError}
                            </FormErrorMessage>
                          )}
                        </FormControl>
                      </GridItem>
                    </Grid>
                  </Box>
                  {tabs.map((item) => (
                    <Box key={item.id}>
                      {activeStep === item.id && item.value}
                    </Box>
                  ))}
                  <Flex
                    px="72px"
                    py="16px"
                    align="center"
                    justify="space-between"
                    pos="fixed"
                    bottom={{ base: "95px", lg: 0 }}
                    // zIndex={20}
                    bg="#FFFFFF"
                    left={0}
                    right={0}
                  >
                    <Text
                      fontWeight="600"
                      fontSize="16px"
                      lineHeight="24px"
                      letterSpacing="0.15px"
                      color="#2f0d63"
                    >
                      {childInfo?.childName?.split(" ")[0]}'s ACR Report
                    </Text>

                    <Button
                      className="saveBtn"
                      color={loader?.apiLoader ? "#91A2AE" : "white"}
                      bg={loader?.apiLoader ? "#F3F8FB" : "#4299E1"}
                      onClick={handleSaveReport}
                      // isDisabled={
                      //   // btnDisabled ||
                      //   !sessionData?.expert ||
                      //   !sessionData?.service ||
                      //   !sessionData?.noOfSessions ||
                      //   !sessionData?.startDate
                      // }
                    >
                      {loader?.apiLoader ? (
                        <Spinner />
                      ) : (
                        <Text>Save Report</Text>
                      )}
                    </Button>
                  </Flex>
                </Box>
              </Flex>
            </Box>
          )}
        </Show>
      </AuthenticatedContainer>
    </Box>
  );
};
export default ACRReport;

import React, { useState, useEffect } from "react";
import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import SingleCheckpointData from "../utils/singleCheckpointData";
import {
  getChildPathway,
  toggleMainLoader,
  getDownloadCheckpoint,
} from "../../../../modules/actions";
import Loader from "../../../../common/components/Loader";

const Pathway = ({ setWillCreatePathway, setPathwayExists, selectedChild }) => {
  const dispatch = useDispatch();
  const { mainLoader } = useSelector((state) => state?.loader);
  const pathway = useSelector((state) => state?.pathway[0]);

  const [checkpointsData, setCheckpointsData] = useState([]);
  useEffect(() => {
    dispatch(toggleMainLoader(true));
    dispatch(getChildPathway(selectedChild?.id));
  }, []);
  console.log(mainLoader, "mainLoader");
  return (
    <>
      <Box id="mainWrapper" px="16px" py="24px">
        <Text className="txnsTabText1">Pathway</Text>
        <Text className="txnsTabText2" lineHeight="24px">
          Create a personalized pathway for the child with checkpoints and
          outcomes. Each checkpoint will be a collection of multiple outcomes
          you want the child to achieve
        </Text>

        {mainLoader ? (
          <Loader text="Loading pathway details... " />
        ) : (
          <>
            <Flex mt="16px" gap="16px">
              {/* <Button className="leavesApplyLeaveBtn" _hover={{ bg: "white" }}>
                Learn More
              </Button> */}
              {pathway ? (
                <>
                  <Button
                    onClick={() => {
                      setPathwayExists(false);
                      setWillCreatePathway(true);
                    }}
                    className="leavesApplyLeaveBtn"
                    _hover={{ bg: "white" }}
                  >
                    Edit Pathway
                  </Button>
                  <Button
                    onClick={() => {
                      dispatch(toggleMainLoader(true));
                      dispatch(getDownloadCheckpoint(pathway.id));
                    }}
                    className="leavesApplyLeaveBtn"
                    _hover={{ bg: "white" }}
                  >
                    Download Pathway
                  </Button>
                </>
              ) : (
                <Button
                  onClick={() => setWillCreatePathway(true)}
                  className="createPathwayBtn"
                  _hover={{ bg: "white" }}
                >
                  Create Pathway
                </Button>
              )}
            </Flex>

            {pathway &&
              pathway?.checkpoints.map((c, index) => (
                <SingleCheckpointData
                  setCheckpointsData={setCheckpointsData}
                  checkpointsData={checkpointsData}
                  key={index}
                  c={c}
                  checkpointId={index + 1}
                  selectedChild={selectedChild}
                  pathway={pathway}
                />
              ))}
          </>
        )}
      </Box>
    </>
  );
};

export default Pathway;

import {
  Box,
  Text,
  Image,
  Flex,
  Grid,
  GridItem,
  Input,
  InputGroup,
  Textarea,
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import {
  ACADEMIC_HISTORY,
  GREEN_CHECK_MARK,
  diagnosis,
  sessionNo,
  cusultationReportServices,
} from "../../../../constant";

const YesNoOptions = ["Yes", "No"];
const boardOptions = ["State Board", "CBSE", "CAI", "IB", "ICSE", "NIOS"];
const performanceOptions = ["Good", "Poor"];
const dignosisOtpions = ["Concerns", "No Concerns"];
const recommendedPlans = ["Sessions", "Consultation", "Parental Counseling"];

const AcademicHistory = ({
  academicHistory,
  handleFormDataChange,
  handleMultipleSelection,
  defaultDiagnosisConcern,
}) => {
  return (
    <Box className="specialistDiv">
      <Flex mb="24px">
        <Image src={ACADEMIC_HISTORY} alt="Academic History" mr="8px" />
        <Text className="tabHeading">Academic History</Text>
      </Flex>
      <Grid
        templateColumns="repeat(5, 1fr)"
        gap="16px"
        alignItems="center"
        mt="24px"
      >
        <GridItem colSpan={1}>
          <Text className="acrFormLabel">Going to school</Text>
        </GridItem>
        <GridItem colSpan={3}>
          <Flex
            className="childPresentTab"
            style={{
              borderColor: academicHistory?.goingSchoolError
                ? "#ba1a1a"
                : "#D8E5EE",
            }}
          >
            {YesNoOptions?.map((value, index) => (
              <Text
                key={index}
                className={
                  academicHistory?.goingSchool === value
                    ? "childPresentTabActive"
                    : "childPresentTabInActive"
                }
                cursor="pointer"
                onClick={() =>
                  handleFormDataChange(
                    "academicHistory",
                    "goingSchool",
                    value,
                    "goingSchoolError"
                  )
                }
              >
                {value}
              </Text>
            ))}
          </Flex>
          {academicHistory?.goingSchoolError && (
            <Text className="personalErrorMessage" mt="5px" ml="15px">
              {academicHistory?.goingSchoolError}
            </Text>
          )}
          {academicHistory?.goingSchool === "No" && (
            <>
              <InputGroup mt="8px">
                <Input
                  onChange={(e) =>
                    handleFormDataChange(
                      "academicHistory",
                      e.target.name,
                      e.target.value,
                      "goingSchoolReasonError"
                    )
                  }
                  name="goingSchoolReason"
                  value={academicHistory?.goingSchoolReason}
                  placeholder="Comment your reason..."
                  maxLength={500}
                  className="acrFormInput"
                  style={{
                    borderColor: academicHistory?.goingSchoolReasonError
                      ? "#ba1a1a"
                      : "#D8E5EE",
                  }}
                />
              </InputGroup>
              {academicHistory?.goingSchoolReasonError && (
                <Text className="personalErrorMessage" mt="5px" ml="15px">
                  {academicHistory?.goingSchoolReasonError}
                </Text>
              )}
            </>
          )}
        </GridItem>
      </Grid>
      <Grid
        templateColumns="repeat(5, 1fr)"
        gap="16px"
        alignItems="center"
        mt="16px"
      >
        <GridItem colSpan={1}>
          <Text className="acrFormLabel">Grade</Text>
        </GridItem>
        <GridItem colSpan={3}>
          <InputGroup>
            <Input
              onChange={(e) =>
                handleFormDataChange(
                  "academicHistory",
                  e.target.name,
                  e.target.value,
                  "gradeError"
                )
              }
              name="grade"
              value={academicHistory?.grade}
              placeholder="Enter Child’s Grade"
              maxLength={50}
              className="acrFormInput"
              style={{
                borderColor: academicHistory?.gradeError
                  ? "#ba1a1a"
                  : "#D8E5EE",
              }}
            />
          </InputGroup>
          {academicHistory?.gradeError && (
            <Text className="personalErrorMessage" mt="5px" ml="15px">
              {academicHistory?.gradeError}
            </Text>
          )}
        </GridItem>
      </Grid>
      <Grid
        templateColumns="repeat(5, 1fr)"
        gap="16px"
        alignItems="center"
        mt="24px"
      >
        <GridItem colSpan={1}>
          <Text className="acrFormLabel">Board</Text>
        </GridItem>
        <GridItem colSpan={4}>
          <Flex
            className="childPresentTab"
            style={{
              borderColor: academicHistory?.boardError ? "#ba1a1a" : "#D8E5EE",
            }}
          >
            {boardOptions?.map((value, index) => (
              <Text
                key={index}
                className={
                  academicHistory?.board === value
                    ? "childPresentTabActive"
                    : "childPresentTabInActive"
                }
                cursor="pointer"
                onClick={() =>
                  handleFormDataChange(
                    "academicHistory",
                    "board",
                    value,
                    "boardError"
                  )
                }
              >
                {value}
              </Text>
            ))}
          </Flex>
          {academicHistory?.boardError && (
            <Text className="personalErrorMessage" mt="5px" ml="15px">
              {academicHistory?.boardError}
            </Text>
          )}
        </GridItem>
      </Grid>
      <Grid
        templateColumns="repeat(5, 1fr)"
        gap="16px"
        alignItems="center"
        mt="24px"
      >
        <GridItem colSpan={1}>
          <Text className="acrFormLabel">Academic Performance</Text>
        </GridItem>
        <GridItem colSpan={3}>
          <Flex
            className="childPresentTab"
            style={{
              borderColor: academicHistory?.academicPerformanceError
                ? "#ba1a1a"
                : "#D8E5EE",
            }}
          >
            {performanceOptions?.map((value, index) => (
              <Text
                key={index}
                className={
                  academicHistory?.academicPerformance === value
                    ? "childPresentTabActive"
                    : "childPresentTabInActive"
                }
                cursor="pointer"
                onClick={() =>
                  handleFormDataChange(
                    "academicHistory",
                    "academicPerformance",
                    value,
                    "academicPerformanceError"
                  )
                }
              >
                {value}
              </Text>
            ))}
          </Flex>
          {academicHistory?.academicPerformanceError && (
            <Text className="personalErrorMessage" mt="5px" ml="15px">
              {academicHistory?.academicPerformanceError}
            </Text>
          )}
          {academicHistory?.academicPerformance === "Poor" && (
            <>
              <InputGroup mt="8px">
                <Input
                  onChange={(e) =>
                    handleFormDataChange(
                      "academicHistory",
                      e.target.name,
                      e.target.value,
                      "academicPerformanceReasonError"
                    )
                  }
                  name="academicPerformanceReason"
                  value={academicHistory?.academicPerformanceReason}
                  placeholder="Comment your reason..."
                  maxLength={500}
                  className="acrFormInput"
                  style={{
                    borderColor: academicHistory?.academicPerformanceReasonError
                      ? "#ba1a1a"
                      : "#D8E5EE",
                  }}
                />
              </InputGroup>
              {academicHistory?.academicPerformanceReasonError && (
                <Text className="personalErrorMessage" mt="5px" ml="15px">
                  {academicHistory?.academicPerformanceReasonError}
                </Text>
              )}
            </>
          )}
        </GridItem>
      </Grid>
      <Grid
        templateColumns="repeat(5, 1fr)"
        gap="16px"
        alignItems="center"
        mt="24px"
      >
        <GridItem colSpan={1}>
          <Text className="acrFormLabel">Comprehension of concepts</Text>
        </GridItem>
        <GridItem colSpan={3}>
          <Flex
            className="childPresentTab"
            style={{
              borderColor: academicHistory?.comprehensionOfConceptError
                ? "#ba1a1a"
                : "#D8E5EE",
            }}
          >
            {performanceOptions?.map((value, index) => (
              <Text
                key={index}
                className={
                  academicHistory?.comprehensionOfConcept === value
                    ? "childPresentTabActive"
                    : "childPresentTabInActive"
                }
                cursor="pointer"
                onClick={() =>
                  handleFormDataChange(
                    "academicHistory",
                    "comprehensionOfConcept",
                    value,
                    "comprehensionOfConceptError"
                  )
                }
              >
                {value}
              </Text>
            ))}
          </Flex>
          {academicHistory?.comprehensionOfConceptError && (
            <Text className="personalErrorMessage" mt="5px" ml="15px">
              {academicHistory?.comprehensionOfConceptError}
            </Text>
          )}
          {academicHistory?.comprehensionOfConcept === "Poor" && (
            <>
              <InputGroup mt="8px">
                <Input
                  onChange={(e) =>
                    handleFormDataChange(
                      "academicHistory",
                      e.target.name,
                      e.target.value,
                      "comprehensionOfConceptReasonError"
                    )
                  }
                  name="comprehensionOfConceptReason"
                  value={academicHistory?.comprehensionOfConceptReason}
                  placeholder="Comment your reason..."
                  maxLength={500}
                  className="acrFormInput"
                  style={{
                    borderColor:
                      academicHistory?.comprehensionOfConceptReasonError
                        ? "#ba1a1a"
                        : "#D8E5EE",
                  }}
                />
              </InputGroup>
              {academicHistory?.comprehensionOfConceptReasonError && (
                <Text className="personalErrorMessage" mt="5px" ml="15px">
                  {academicHistory?.comprehensionOfConceptReasonError}
                </Text>
              )}
            </>
          )}
        </GridItem>
      </Grid>
      <Grid
        templateColumns="repeat(5, 1fr)"
        gap="16px"
        alignItems="center"
        mt="24px"
      >
        <GridItem colSpan={1}>
          <Text className="acrFormLabel">Teacher complaints</Text>
        </GridItem>
        <GridItem colSpan={3}>
          <Flex
            className="childPresentTab"
            style={{
              borderColor: academicHistory?.teacherComplaintsError
                ? "#ba1a1a"
                : "#D8E5EE",
            }}
          >
            {YesNoOptions?.map((value, index) => (
              <Text
                key={index}
                className={
                  academicHistory?.teacherComplaints === value
                    ? "childPresentTabActive"
                    : "childPresentTabInActive"
                }
                cursor="pointer"
                onClick={() =>
                  handleFormDataChange(
                    "academicHistory",
                    "teacherComplaints",
                    value,
                    "teacherComplaintsError"
                  )
                }
              >
                {value}
              </Text>
            ))}
          </Flex>
          {academicHistory?.teacherComplaintsError && (
            <Text className="personalErrorMessage" mt="5px" ml="15px">
              {academicHistory?.teacherComplaintsError}
            </Text>
          )}
          {academicHistory?.teacherComplaints === "Yes" && (
            <>
              <InputGroup mt="8px">
                <Input
                  onChange={(e) =>
                    handleFormDataChange(
                      "academicHistory",
                      e.target.name,
                      e.target.value,
                      "teacherComplaintsReasonError"
                    )
                  }
                  name="teacherComplaintsReason"
                  value={academicHistory?.teacherComplaintsReason}
                  placeholder="Comment your reason..."
                  maxLength={500}
                  className="acrFormInput"
                  style={{
                    borderColor: academicHistory?.teacherComplaintsReasonError
                      ? "#ba1a1a"
                      : "#D8E5EE",
                  }}
                />
              </InputGroup>
              {academicHistory?.teacherComplaintsReasonError && (
                <Text className="personalErrorMessage" mt="5px" ml="15px">
                  {academicHistory?.teacherComplaintsReasonError}
                </Text>
              )}
            </>
          )}
        </GridItem>
      </Grid>
      <Grid
        templateColumns="repeat(5, 1fr)"
        gap="16px"
        alignItems="flex-start"
        mt="24px"
      >
        <GridItem colSpan={1}>
          <Text className="acrFormLabel">Diagnosis</Text>
        </GridItem>
        <GridItem colSpan={3}>
          <Flex
            className="childPresentTab"
            style={{
              borderColor: academicHistory?.diagnosisError
                ? "#ba1a1a"
                : "#D8E5EE",
            }}
          >
            {dignosisOtpions?.map((value, index) => (
              <Text
                key={index}
                className={
                  academicHistory?.diagnosis === value
                    ? "childPresentTabActive"
                    : "childPresentTabInActive"
                }
                cursor="pointer"
                onClick={() =>
                  handleFormDataChange(
                    "academicHistory",
                    "diagnosis",
                    value,
                    "diagnosisError"
                  )
                }
              >
                {value}
              </Text>
            ))}
          </Flex>
          {academicHistory?.diagnosisError && (
            <Text className="personalErrorMessage" mt="5px" ml="15px">
              {academicHistory?.diagnosisError}
            </Text>
          )}
          {academicHistory?.diagnosis === "Concerns" && (
            <Box mt="16px">
              <Text className="acrFormLabel">
                The Child displays signs and symptoms of
              </Text>
              <Box
                border="1px solid #D8E5EE"
                fontWeight="medium"
                borderRadius={25}
                backgroundColor="white"
                sx={{
                  "& .css-wtf6ne": {
                    bg: "white",
                    border: "none",
                  },
                  "& .chakra-divider": {
                    opacity: 0,
                  },
                }}
                mt="8px"
              >
                <Select
                  isMulti
                  onChange={(e) =>
                    handleFormDataChange(
                      "academicHistory",
                      "diagnosisConcern",
                      e.map((l) => l.value),
                      "diagnosisConcernError"
                    )
                  }
                  name="diagnosisConcern"
                  closeMenuOnSelect={false}
                  value={defaultDiagnosisConcern}
                  selectedOptionStyle="check"
                  placeholder="Choose Diagnosis"
                  className="acrFormSelect"
                  hideSelectedOptions={false}
                  selectedOptionColorScheme="green"
                  options={diagnosis}
                  style={{
                    borderColor: academicHistory?.diagnosisConcernError
                      ? "#ba1a1a"
                      : "#D8E5EE",
                  }}
                />
              </Box>

              {academicHistory?.diagnosisConcernError && (
                <Text className="personalErrorMessage" mt="5px" ml="15px">
                  {academicHistory?.diagnosisConcernError}
                </Text>
              )}
            </Box>
          )}
        </GridItem>
      </Grid>
      <Grid
        templateColumns="repeat(5, 1fr)"
        gap="16px"
        alignItems="flex-start"
        mt="16px"
      >
        <GridItem colSpan={1}>
          <Text className="acrFormLabel">Prognosis</Text>
        </GridItem>
        <GridItem colSpan={3}>
          <Textarea
            onChange={(e) => {
              handleFormDataChange(
                "academicHistory",
                e.target.name,
                e.target.value,
                "prognosisError"
              );
              // handleNumOfWords(e);
            }}
            name="prognosis"
            value={academicHistory?.prognosis}
            // maxLength={350}
            border="1px solid #D8E5EE"
            placeholder="Enter prognosis"
            className="personalShortBio"
            // resize="none"
            // backgroundColor="white"
            style={{
              borderColor: academicHistory?.prognosisError
                ? "#ba1a1a"
                : "#D8E5EE",
            }}
          />
          {academicHistory?.prognosisError && (
            <Text className="personalErrorMessage" mt="5px" ml="15px">
              {academicHistory?.prognosisError}
            </Text>
          )}
        </GridItem>
      </Grid>
      <Grid
        templateColumns="repeat(5, 1fr)"
        gap="16px"
        alignItems="flex-start"
        mt="24px"
      >
        <GridItem colSpan={1}>
          <Text className="acrFormLabel">Recommended Plans</Text>
        </GridItem>
        <GridItem colSpan={4}>
          <Box
            className="medicalIssuesTabsFlex"
            gap="8px"
            style={{
              borderColor: academicHistory?.recommendedPlanError
                ? "#ba1a1a"
                : "#D8E5EE",
            }}
          >
            {recommendedPlans?.map((value, index) => (
              <>
                <Box
                  key={index}
                  className={
                    academicHistory?.recommendedPlan.includes(value)
                      ? "medicalIssuesTabs medicalIssuesTabsActive"
                      : "medicalIssuesTabs"
                  }
                  cursor="pointer"
                  onClick={() =>
                    handleMultipleSelection(
                      "academicHistory",
                      "recommendedPlan",
                      value,
                      "recommendedPlanError"
                    )
                  }
                  style={{
                    borderColor: academicHistory?.recommendedPlanError
                      ? "#ba1a1a"
                      : "#D8E5EE",
                  }}
                  mb="16px"
                  width="75%"
                >
                  {value}
                  {academicHistory?.recommendedPlan.includes(value) ? (
                    <Image src={GREEN_CHECK_MARK} alt="" />
                  ) : (
                    <Text> +</Text>
                  )}
                </Box>
                {academicHistory?.recommendedPlan.includes("Sessions") &&
                value === "Sessions" ? (
                  <>
                    <Flex my="16px" alignItems="center">
                      <Text className="acrFormLabel" mr="8px">
                        The Child will require
                      </Text>
                      <Box
                        border="1px solid #D8E5EE"
                        fontWeight="medium"
                        borderRadius={25}
                        backgroundColor="white"
                        sx={{
                          "& .css-wtf6ne": {
                            bg: "white",
                            border: "none",
                          },
                          "& .chakra-divider": {
                            opacity: 0,
                          },
                        }}
                        mr="8px"
                        width="102px"
                      >
                        <Select
                          // isMulti
                          onChange={(e) =>
                            handleFormDataChange(
                              "academicHistory",
                              "sessionsNo",
                              e.value,
                              "sessionsNoError"
                            )
                          }
                          name="sessionNo"
                          closeMenuOnSelect={false}
                          value={
                            academicHistory?.sessionsNo
                              ? {
                                  value: academicHistory?.sessionsNo,
                                  label: academicHistory?.sessionsNo,
                                }
                              : ""
                          }
                          selectedOptionStyle="check"
                          placeholder="Choose count"
                          className="acrFormSelect"
                          hideSelectedOptions={false}
                          selectedOptionColorScheme="green"
                          options={sessionNo}
                          style={{
                            borderColor: academicHistory?.sessionsNoError
                              ? "#ba1a1a"
                              : "#D8E5EE",
                          }}
                        />
                      </Box>
                      <Box
                        border="1px solid #D8E5EE"
                        fontWeight="medium"
                        borderRadius={25}
                        backgroundColor="white"
                        sx={{
                          "& .css-wtf6ne": {
                            bg: "white",
                            border: "none",
                          },
                          "& .chakra-divider": {
                            opacity: 0,
                          },
                        }}
                        mr="8px"
                      >
                        <Select
                          // isMulti
                          onChange={(e) =>
                            handleFormDataChange(
                              "academicHistory",
                              "sessionService",
                              e.value,
                              "sessionServiceError"
                            )
                          }
                          name="sessionService"
                          closeMenuOnSelect={false}
                          value={
                            academicHistory?.sessionService
                              ? {
                                  value: academicHistory?.sessionService,
                                  label: academicHistory?.sessionService,
                                }
                              : ""
                          }
                          selectedOptionStyle="check"
                          placeholder="Choose count"
                          className="acrFormSelect"
                          hideSelectedOptions={false}
                          selectedOptionColorScheme="green"
                          options={cusultationReportServices}
                          style={{
                            borderColor: academicHistory?.sessionServiceError
                              ? "#ba1a1a"
                              : "#D8E5EE",
                          }}
                        />
                      </Box>
                      <Text className="acrFormLabel" whiteSpace="break-spaces">
                        Sessions Per week
                      </Text>
                    </Flex>
                    {academicHistory?.sessionServiceError && (
                      <Text className="personalErrorMessage" mt="5px" ml="15px">
                        {academicHistory?.sessionServiceError}
                      </Text>
                    )}
                    {academicHistory?.sessionsNoError && (
                      <Text className="personalErrorMessage" mt="5px" ml="15px">
                        {academicHistory?.sessionsNoError}
                      </Text>
                    )}
                  </>
                ) : academicHistory?.recommendedPlan.includes(
                    "Parental Counseling"
                  ) && value === "Parental Counseling" ? (
                  <>
                    <Flex my="16px" alignItems="center">
                      <Text className="acrFormLabel" mr="8px">
                        The parent will require
                      </Text>
                      <Box
                        border="1px solid #D8E5EE"
                        fontWeight="medium"
                        borderRadius={25}
                        backgroundColor="white"
                        sx={{
                          "& .css-wtf6ne": {
                            bg: "white",
                            border: "none",
                          },
                          "& .chakra-divider": {
                            opacity: 0,
                          },
                        }}
                        mr="8px"
                      >
                        <Select
                          onChange={(e) =>
                            handleFormDataChange(
                              "academicHistory",
                              "parentCouncellingNo",
                              e.value,
                              "parentCouncellingNoError"
                            )
                          }
                          name="parentCouncellingNo"
                          closeMenuOnSelect={false}
                          value={
                            academicHistory?.parentCouncellingNo
                              ? {
                                  value: academicHistory?.parentCouncellingNo,
                                  label: academicHistory?.parentCouncellingNo,
                                }
                              : ""
                          }
                          selectedOptionStyle="check"
                          placeholder="Choose count"
                          className="acrFormSelect"
                          hideSelectedOptions={false}
                          selectedOptionColorScheme="green"
                          options={sessionNo}
                          style={{
                            borderColor:
                              academicHistory?.parentCouncellingNoError
                                ? "#ba1a1a"
                                : "#D8E5EE",
                          }}
                        />
                      </Box>

                      <Text className="acrFormLabel" whiteSpace="break-spaces">
                        Parental counseling session per week
                      </Text>
                    </Flex>
                    {academicHistory?.parentCouncellingNoError && (
                      <Text className="personalErrorMessage" mt="5px" ml="15px">
                        {academicHistory?.parentCouncellingNoError}
                      </Text>
                    )}
                  </>
                ) : academicHistory?.recommendedPlan.includes("Consultation") &&
                  value === "Consultation" ? (
                  <>
                    <Flex my="16px" alignItems="center">
                      <Text className="acrFormLabel" mr="8px">
                        The child will require
                      </Text>
                      <Box
                        border="1px solid #D8E5EE"
                        fontWeight="medium"
                        borderRadius={25}
                        backgroundColor="white"
                        sx={{
                          "& .css-wtf6ne": {
                            bg: "white",
                            border: "none",
                          },
                          "& .chakra-divider": {
                            opacity: 0,
                          },
                        }}
                        mr="8px"
                      >
                        <Select
                          // isMulti
                          onChange={(e) =>
                            handleFormDataChange(
                              "academicHistory",
                              "consultationService",
                              e.value,
                              "consultationServiceError"
                            )
                          }
                          name="sessionService"
                          closeMenuOnSelect={false}
                          value={
                            academicHistory?.consultationService
                              ? {
                                  value: academicHistory?.consultationService,
                                  label: academicHistory?.consultationService,
                                }
                              : ""
                          }
                          selectedOptionStyle="check"
                          placeholder="Choose service"
                          className="acrFormSelect"
                          hideSelectedOptions={false}
                          selectedOptionColorScheme="green"
                          options={cusultationReportServices}
                          style={{
                            borderColor:
                              academicHistory?.consultationServiceError
                                ? "#ba1a1a"
                                : "#D8E5EE",
                          }}
                        />
                      </Box>
                      <Text className="acrFormLabel" whiteSpace="break-spaces">
                        consultation
                      </Text>
                    </Flex>
                    {academicHistory?.consultationServiceError && (
                      <Text className="personalErrorMessage" mt="5px" ml="15px">
                        {academicHistory?.consultationServiceError}
                      </Text>
                    )}
                  </>
                ) : null}
              </>
            ))}
            {academicHistory?.recommendedPlanError && (
              <Text className="personalErrorMessage" mt="5px" ml="15px">
                {academicHistory?.recommendedPlanError}
              </Text>
            )}
          </Box>
        </GridItem>
      </Grid>
    </Box>
  );
};
export default AcademicHistory;

import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  ModalFooter,
  ModalCloseButton,
  Button,
  Box,
  Text,
  Image,
  Flex,
  Spinner,
} from "@chakra-ui/react";
import "./feedback.css";
import {
  DISAPPOINTED_FACE,
  DISAPPOINTED_FACE_ACTIVE,
  SLIGHTLY_SMILING_FACE,
  SLIGHTLY_SMILING_FACE_ACTIVE,
  SMILINGWITH_HEART_SHAPED_EYES,
  SMILINGWITH_HEART_SHAPED_EYES_ACTIVE,
  SMILINGWITH_OPEN_MOUTH,
  SMILINGWITH_OPEN_MOUTH_ACTIVE,
  WORRIED_FACE,
  WORRIED_FACE_ACTIVE,
  THANK_YOU_IMG,
} from "../../constant";
import { getDatewithDay } from "../../utils/customMoment";

const feedbackImgs = [
  { img: DISAPPOINTED_FACE, value: 1, activeImg: DISAPPOINTED_FACE_ACTIVE },
  { img: WORRIED_FACE, value: 2, activeImg: WORRIED_FACE_ACTIVE },
  {
    img: SLIGHTLY_SMILING_FACE,
    value: 3,
    activeImg: SLIGHTLY_SMILING_FACE_ACTIVE,
  },
  {
    img: SMILINGWITH_OPEN_MOUTH,
    value: 4,
    activeImg: SMILINGWITH_OPEN_MOUTH_ACTIVE,
  },
  {
    img: SMILINGWITH_HEART_SHAPED_EYES,
    value: 5,
    activeImg: SMILINGWITH_HEART_SHAPED_EYES_ACTIVE,
  },
];
const FeedbackPopup = ({
  isOpen,
  onClose,
  lastAttentedSession,
  handleFeedbackDataChange,
  feedback,
  thankYouOpen,
  handleFeedbackSubmit,
  apiLoader,
}) => {
  return (
    <Modal
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      autoFocus={false}
      size="sm"
    >
      <ModalOverlay />
      <ModalContent mx="10px">
        {!thankYouOpen && (
          <ModalHeader className="modalHeader">
            Rate your last session
          </ModalHeader>
        )}

        <ModalCloseButton />
        <ModalBody>
          {thankYouOpen ? (
            <Box display="flex" justifyContent="center" flexDirection="column">
              <Image src={THANK_YOU_IMG} height="185px" />
              <Text className="thankYouText">Thank You!</Text>
              <Text className="thankYouSubText">
                {" "}
                By making your voice heard you made us improve KinderPass.
              </Text>
            </Box>
          ) : (
            <>
              <Box className="feedbackExpertBlock">
                <Text>
                  {lastAttentedSession?.serviceType} with{" "}
                  {lastAttentedSession?.sessionExpertName}{" "}
                </Text>
                <Text>
                  on {getDatewithDay(lastAttentedSession?.sessionDate)} at{" "}
                  {lastAttentedSession?.startTime}
                </Text>
              </Box>
              <Flex mt="30px" justifyContent="space-between" height="50px">
                {feedbackImgs.map((item, index) => (
                  <Image
                    height={feedback.rating === item.value ? "50px" : "40px"}
                    src={
                      feedback.rating === item.value ? item.activeImg : item.img
                    }
                    key={index}
                    cursor="pointer"
                    onClick={() =>
                      handleFeedbackDataChange(
                        "rating",
                        item.value,
                        "ratingError"
                      )
                    }
                  />
                ))}
              </Flex>

              {feedback?.ratingError && (
                <Box className="personalErrorMessage">
                  {feedback?.ratingError}
                </Box>
              )}
            </>
          )}
        </ModalBody>
        {!thankYouOpen && (
          <ModalFooter mt="16px" borderTop="1px solid #a6b8c4">
            <Button
              _hover={{
                bg: "#4299E1",
                color: "#FFFFFF",
              }}
              className="modalButtons2"
              bg="#4299E1"
              color="#FFFFFF"
              onClick={() => {
                handleFeedbackSubmit();
              }}
            >
              {apiLoader ? <Spinner /> : "Submit"}
            </Button>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};
export default FeedbackPopup;

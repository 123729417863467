import * as ActionTypes from "./actionTypes";

export const loginSuccess = (data) => ({
  type: ActionTypes.LOGIN_SUCCESS,
  data,
});

export const logoutSuccess = () => ({ type: ActionTypes.LOGOUT_SUCCESS });

export const toggleMainLoader = (data) => ({
  type: ActionTypes.SHOW_OR_HIDE_MAIN_LOADER,
  data,
});

export const toggleAPILoader = (data) => ({
  type: ActionTypes.SHOW_OR_HIDE_API_LOADER,
  data,
});

export const toggleSessionLoader = (data) => ({
  type: ActionTypes.SHOW_OR_HIDE_SESSION_LOADER,
  data,
});

export const toggleFeddbackLoader = (data) => ({
  type: ActionTypes.SHOW_OR_HIDE_FEEDBACK_LOADER,
  data,
});

export const toggleModalOpen = (data) => ({
  type: ActionTypes.CHECK_MODAL_OPEN,
  data,
});

export const getUserProfile = (data) => ({
  type: ActionTypes.GET_USER_PROFILE,
  data,
});

export const saveUserProfile = (data) => ({
  type: ActionTypes.SAVE_USER_PROFILE,
  data,
});

export const saveUserProfileDetails = (data, step) => ({
  type: ActionTypes.SAVE_USER_PROFILE_DETAILS,
  data,
  step,
});

export const saveUserProfileImage = (data, step) => ({
  type: ActionTypes.SAVE_USER_PROFILE_IMAGE_DETAILS,
  data,
  step,
});

export const getExpertTransactions = (month, year) => ({
  type: ActionTypes.GET_EXPERT_TRANSACTIONS,
  month,
  year,
});

export const getExpertLeaves = (status, data) => ({
  type: ActionTypes.GET_EXPERT_LEAVES,
  status,
  data,
});

export const saveExpertData = (data) => ({
  type: ActionTypes.SAVE_EXPERT_DATA,
  data,
});

export const saveTransactionsData = (data) => ({
  type: ActionTypes.SAVE_EXPERT_TRANSACTIONS,
  data,
});

export const getParentToken = (token) => ({
  type: ActionTypes.GET_PARENT_TOKEN,
  token,
});

export const saveParentToken = (data) => ({
  type: ActionTypes.SAVE_PARENT_TOKEN,
  data,
});
export const getStudentsDataAction = (data) => ({
  type: ActionTypes.GET_ALL_STUDENTS,
  data,
});

export const saveAllStudentsDataAction = (data) => ({
  type: ActionTypes.SAVE_ALL_STUDENTS_DATA,
  data,
});

export const getDomainsGoalDataAction = (data) => ({
  type: ActionTypes.GET_DOMAINS_GOAL_DATA,
  data,
});
export const saveDomainsGoalDataAction = (data) => ({
  type: ActionTypes.SAVE_DOMAINS_GOAL_DATA,
  data,
});

export const getSuboalDomainsAction = (data) => ({
  type: ActionTypes.GET_DOMAIN_SUB_GOALS_DATA,
  data,
});

export const saveSuboalDomainsAction = (data) => ({
  type: ActionTypes.SAVE_DOMAIN_SUB_GOALS_DATA,
  data,
});

export const getParentSessionAction = (data) => ({
  type: ActionTypes.GET_PARENT_SESSION_HISTORY,
  data,
});

export const saveParentSessionAction = (data) => ({
  type: ActionTypes.SAVE_PARENT_SESSION_HISTORY,
  data,
});

export const getParentJournalAction = (id, month, year) => ({
  type: ActionTypes.GET_PARENT_JOURNAL,
  id,
  month,
  year,
});

export const saveParentJournalAction = (data) => ({
  type: ActionTypes.SAVE_PARENT_JOURNAL,
  data,
});

export const getStudentDomainAction = (data) => ({
  type: ActionTypes.GET_DOMAIN_DATA,
  data,
});

export const saveStudentDomainAction = (data) => ({
  type: ActionTypes.SAVE_DOMAIN_DATA,
  data,
});

export const getStudentsNotesAction = (data) => ({
  type: ActionTypes.GET_STUDENTS_NOTES,
  data,
});

export const saveStudentsNotesAction = (data) => ({
  type: ActionTypes.SAVE_STUDENTS_NOTES,
  data,
});

export const writeStudentsNotesAction = (data, id) => ({
  type: ActionTypes.WRITE_STUDENTS_NOTES,
  data,
  id,
});

export const deleteStudentsNotesAction = (data, id) => ({
  type: ActionTypes.DELETE_STUDENTS_NOTES,
  data,
  id,
});

export const updateStudentsNotesAction = (tokenId, data, id) => ({
  type: ActionTypes.UPDATE_STUDENTS_NOTES,
  tokenId,
  data,
  id,
});

export const getChildsDomainAction = (data) => ({
  type: ActionTypes.GET_CHILDS_DOMAIN_DATA,
  data,
});

export const saveChildsDomainAction = (data) => ({
  type: ActionTypes.SAVE_CHILDS_DOMAIN_DATA,
  data,
});
export const applyExpertLeavesAction = (data) => ({
  type: ActionTypes.APPLY_EXPERT_LEAVES,
  data,
});

export const getMyExperts = () => ({
  type: ActionTypes.GET_MY_EXPERTS,
});

export const saveMyExperts = (data) => ({
  type: ActionTypes.SAVE_MY_EXPERTS,
  data,
});

export const getUserData = (data) => ({
  type: ActionTypes.GET_USER_DATA,
  data,
});

export const saveUserData = (data) => ({
  type: ActionTypes.SAVE_USER_DATA,
  data,
});

export const getPopularArticles = () => ({
  type: ActionTypes.GET_POPULAR_ARTICLE,
});

export const savePopularArticles = (data) => ({
  type: ActionTypes.SAVE_POPULAR_ARTICLE,
  data,
});

export const getBalanceDetails = () => ({
  type: ActionTypes.GET_BALANCE_DETAILS,
});

export const saveBalanceDetails = (data) => ({
  type: ActionTypes.SAVE_BALANCE_DETAILS,
  data,
});

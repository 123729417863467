import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  ModalFooter,
  ModalCloseButton,
  Button,
  Image,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import "./paymentDetailsPopup.css";
import { ICON_GREEN, NO_SHOW, ROUTES_PATH } from "../../constant";
import { useNavigate } from "react-router-dom";

const PaymentDetailsPopup = ({ isOpen, onClose, parent, paymentDetails }) => {
  const navigate = useNavigate();
  const handleBookSession = () => {
    navigate(ROUTES_PATH.PARENT_HOME, { state: { isAfterPayment: true } });
  };
  return (
    <Modal
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      // closeOnOverlayClick={false}
      size="sm"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader className="paymentDetailsModalHeader">
          <Image
            src={
              paymentDetails?.paymentStatus === "failure" ? NO_SHOW : ICON_GREEN
            }
            mr="4px"
            height="18px"
            width="18px"
          />
          {paymentDetails?.paymentStatus === "failure"
            ? "Your Payment failed"
            : "Payment was successful!"}
        </ModalHeader>

        <ModalCloseButton />
        <ModalBody>
          {Object.keys(paymentDetails).length > 0 && (
            <Grid
              gap={1}
              templateColumns="repeat(12, 1fr)"
              className="paymentDetailsText"
            >
              {paymentDetails?.paymentStatus === "failure" && (
                <>
                  <GridItem colSpan={6}>Reasons</GridItem>
                  <GridItem colSpan={6}>
                    {paymentDetails?.failureReason}
                  </GridItem>
                </>
              )}
              <GridItem colSpan={6}>Name</GridItem>
              <GridItem colSpan={6}>{parent.fullName}</GridItem>
              <GridItem colSpan={6}>Duration</GridItem>
              <GridItem colSpan={6}>{paymentDetails?.validity} days</GridItem>
              <GridItem colSpan={6}>Rate Applied</GridItem>
              <GridItem colSpan={6}>
                {"₹ " + paymentDetails?.pricePerSession}
              </GridItem>
              <GridItem colSpan={6}>Total Sessions</GridItem>
              <GridItem colSpan={6}>{paymentDetails?.sessions}</GridItem>
              <GridItem colSpan={6} fontWeight="600">
                Amount
              </GridItem>
              <GridItem colSpan={6} fontWeight="600">
                {"₹ " + paymentDetails?.amount}
              </GridItem>
              {/* <GridItem colSpan={6}>Time and date</GridItem>
              <GridItem colSpan={4}>{paymentDetails?.createdAt}</GridItem> */}
              <GridItem colSpan={6}>Transaction ID</GridItem>
              <GridItem colSpan={6}>{paymentDetails?.paymentId}</GridItem>
              <GridItem colSpan={6}>Kinderpass Invoice Id</GridItem>
              <GridItem colSpan={6}>{paymentDetails?.id}</GridItem>
            </Grid>
          )}
        </ModalBody>

        <ModalFooter mt="16px" borderTop="1px solid #a6b8c4">
          {paymentDetails?.paymentStatus === "failure" ? (
            <>
              <Button
                bg="white"
                // color="#BA1A1A"
                mr="8px"
                border="1px solid #a6b8c4"
                onClick={() => {
                  window.open("https://wa.me/+919606719826");
                }}
                className="modalButtons2"
              >
                Contact Support
              </Button>
              <Button
                _hover={{
                  bg: "#4299E1",
                  color: "#FFFFFF",
                }}
                className="modalButtons2"
                bg="#4299E1"
                color="#FFFFFF"
                onClick={() => {
                  onClose();
                }}
              >
                Retry
              </Button>
            </>
          ) : (
            <Button
              _hover={{
                bg: "#4299E1",
                color: "#FFFFFF",
              }}
              className="modalButtons2"
              bg="#4299E1"
              color="#FFFFFF"
              onClick={() => {
                handleBookSession();
              }}
            >
              Book Session
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
export default PaymentDetailsPopup;

import React from "react";
import VideoPlayer from "../../FirstLogin/videoPlayer";
import GroupCard from "../../FirstLogin/groupCard";
import { Grid, GridItem,Text,Flex,Box } from "@chakra-ui/react";
import { videos,whatsappGroupData } from "../../../../constant";

const OnboardingStart = () => {
  return <Grid my="24px">
    <GridItem>
    
              <Flex wrap="wrap" gap="40px">
              {whatsappGroupData.map((dg, index) => (
                <Box key={index}>
                  <Text
                    fontWeight="600"
                    fontSize="16px"
                    lineHeight="24px"
                    letterSpacing="0.15px"
                    color="black"
                    mb="16px"
                  >
                    {dg.main}:
                  </Text>
                  <GroupCard key={index} categories={dg.categories} />
                </Box>
              ))}
            </Flex>
    </GridItem>
    <GridItem mt="24px">
    <Text
                fontSize="16px"
                fontWeight="600"
                lineHeight="24px"
                letterSpacing="0.15px"
              >
               Training & Onboarding Sessions:
              </Text>
             <Flex mt="16px" flexWrap="wrap" gap="16px">
                {videos.map((v, index) => (
                  <VideoPlayer v={v} index={index} key={index} />
                ))}
              </Flex>
    </GridItem>
  </Grid> ;
};

export default OnboardingStart;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, GridItem, Grid, Flex, Image, Text } from "@chakra-ui/react";
import {
  FAV,
  AUDIO_ICON,
  IMG_ICON,
  DOC_ICON,
  PDF_ICON,
  VIDEO_ICON,
  PPT_ICON,
} from "../../../../constant";
import {
  getFavouriteResources,
  toggleMainLoader,
} from "../../../../modules/actions";
import Loader from "../../../../common/components/Loader";
import { Toaster } from "../../../../common/components/Toaster";
import { addFavouriteResourceService } from "../../../../modules/services";

const Favourites = () => {
  const dispatch = useDispatch();
  const resources = useSelector((state) => state?.favouriteResources);
  const loader = useSelector((state) => state?.loader.mainLoader);

  useEffect(() => {
    dispatch(toggleMainLoader(true));
    dispatch(getFavouriteResources());
  }, []);
  const handleNavigateFirstPage = (d) => {
    // setSelectedCard(d);
    // setNavigateToGoal(true);
    window.open(d.url);
  };

  const handleFavouriteResource = (event, id) => {
    if (event.target.id === "innerDiv") {
      event.stopPropagation();
      dispatch(toggleMainLoader(true));
      window.scroll(0, 0);
      addFavouriteResourceService(id)
        .then((res) => {
          Toaster(res.data.message, "success");
          dispatch(getFavouriteResources());
        })
        .catch((error) => {
          console.log(error.response);
          dispatch(toggleMainLoader(false));
          Toaster(error.response.data.message, "error");
        });
    }
  };
  return (
    <Box mt="24px">
      <Box fontSize="28px" fontWeight="500" color="#141719" lineHeight="36px">
        Favourites
      </Box>
      <Box
        mt="8px"
        mb="16px"
        fontSize="16px"
        fontWeight="400"
        color="#3d464d"
        lineHeight="24px"
      >
        Resources used across domains and exercises for every subgoal have been
        provided here
      </Box>
      {loader ? (
        <Loader text="Loading favourite resources..." />
      ) : (
        <Grid
          my="24px"
          columnGap="37.5px"
          wrap="wrap"
          rowGap="24px"
          templateColumns={{ md: "repeat(12, 1fr)", sm: "" }}
        >
          {resources.length > 0 ? (
            resources?.map((d, index) => (
              <GridItem
                border="1px solid #a6b8c4"
                borderRadius="10px"
                cursor="pointer"
                onClick={() => handleNavigateFirstPage(d)}
                p="16px"
                colSpan={{ md: 4, sm: 12 }}
                flexDir="column"
                key={index}
                position="relative"
              >
                {/* <Flex align="top" justify="space-between"> */}
                <Image
                  h="32px"
                  w="32px"
                  src={
                    d.type === "application/pdf"
                      ? PDF_ICON
                      : d.type.includes("presentation") ||
                        d.type ===
                          "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
                        d.type === "application/vnd.google-apps.presentation"
                      ? PPT_ICON
                      : d.type === "image/png"
                      ? IMG_ICON
                      : d.type === "video/mp4"
                      ? VIDEO_ICON
                      : DOC_ICON
                  }
                />
                {/* <Image h="20px" w="20px" src={FAV} />
                </Flex> */}
                <Text
                  fontSize="14px"
                  fontWeight="600"
                  lineHeight="20px"
                  letterSpacing="0.1px"
                >
                  {d.name}
                </Text>
                <Text
                  color="#5a646b"
                  fontSize="14px"
                  fontWeight="400"
                  lineHeight="20px"
                  marginTop="8px"
                >
                  {d.goalName + "-" + d.subGoalName}
                </Text>
                <Flex
                  mt="8px"
                  align="center"
                  justify="end"
                  className="resourcesFavIcon"
                >
                  <Image
                    h="20px"
                    w="20px"
                    id="innerDiv"
                    src={FAV}
                    onClick={(e) => handleFavouriteResource(e, d.id)}
                  />
                </Flex>
              </GridItem>
            ))
          ) : (
            <GridItem
              p="16px"
              colStart={{ md: 4 }}
              colEnd={10}
              flexDir="column"
              mt="50px"
            >
              Opps! You don't have any favourite resource..
            </GridItem>
          )}
        </Grid>
      )}
    </Box>
  );
};

export default Favourites;

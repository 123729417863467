import axios from "axios";
import { retrieveToken } from "../src/utils/localStorage";
import { refreshIdToken, signout } from "./utils/firebase";
import { Toaster } from "./common/components/Toaster";

axios.defaults.headers.post["Content-Type"] = "application/json";

const axiosInstance = axios.create({
  baseURL: `${window.location.protocol}//`,
});

axiosInstance.all = axios.all;
axiosInstance.spread = axios.spread;

axiosInstance.interceptors.request.use((request) => {
  request.headers["Authorization"] = `${retrieveToken()}`;
  return request;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    const originalRequest = error.config;
    return new Promise((resolve, reject) => {
      if (
        error?.response.data.message ===
          "Firebase ID token has expired. Get a fresh ID token from your client app and try again (auth/id-token-expired). See https://firebase.google.com/docs/auth/admin/verify-id-tokens for details on how to retrieve an ID token." ||
        error?.response.data.message ===
          "Decoding Firebase ID token failed. Make sure you passed the entire string JWT which represents an ID token. See https://firebase.google.com/docs/auth/admin/verify-id-tokens for details on how to retrieve an ID token."
      ) {
        refreshIdToken();
        originalRequest._retry = true;
        return resolve(axiosInstance(originalRequest));
      } else if (
        error?.response.data.message ===
        'Firebase ID token has "kid" claim which does not correspond to a known public key. Most likely the ID token is expired, so get a fresh token from your client app and try again.'
      ) {
        Toaster(
          "Need to signout as your session expired.Please signin again",
          "info"
        );
        signout();
        // originalRequest._retry = true;
        // return resolve(axiosInstance(originalRequest));
        return reject(error);
      } else {
        return reject(error);
      }
      // if (error?.response?.status === 401) {
      //   originalRequest._retry = true;
      //   // eslint-disable-next-line
      //   const refreshToken = retrieveRefreshToken();
      //   if (!_.isEmpty(userDetails?.user)) {
      //     instance
      //       .post(getAccessTokenUrl)
      //       .then((response) => {
      //         handleResponse(response);
      //         return resolve(axiosInstance(originalRequest));
      //       })
      //       .catch((error) => {
      //         if (error?.response?.status >= 400) {
      //           handleClear();
      //         }
      //       });
      //   }
      // } else if (error?.response?.status === 403) {
      //   handleClear();
      //   return reject(error);
      // } else {
      //   return reject(error);
      // }
    });
  }
);

export default axiosInstance;

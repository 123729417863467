import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Flex, Text, Tag, Avatar, TagLabel } from "@chakra-ui/react";
import {
  ATTENDED,
  SCHEDULED,
  CANCELLED,
  RESCHEDULED,
  PARTIALLY_ATTENDED,
  NO_SHOW,
  ORANGE_WARNING,
} from "../../../../constant/images";
import { getParentSessionAction } from "../../../../modules/actions";
import Loader from "../../../../common/components/Loader";

const SessionHistory = ({ selectedChild }) => {
  const dispatch = useDispatch();
  const { sessionHistory } = useSelector((state) => state?.user);
  const loaderState = useSelector((state) => state?.loader?.apiLoader);
  const [sessions, setSessions] = useState([]);

  const handleDateFormat = (dateString) => {
    const dateArray = String(new Date(dateString * 1000)).split(" ");
    return `${dateArray[2]} ${dateArray[1]}, ${dateArray[3]}`;
  };

  useEffect(() => {
    const createSessionHistoryData = () => {
      if (sessionHistory === null || sessionHistory === []) return;
      const updatedSessionHistory = sessionHistory?.map((sh) => {
        return {
          date: handleDateFormat(sh?.sessionDate?._seconds),
          user: sh?.sessionStudentName,
          expert: sh?.sessionExpertName,
          status: sh?.status,
        };
      });
      setSessions(updatedSessionHistory);
    };
    createSessionHistoryData();
  }, [sessionHistory]);

  useEffect(() => {
    dispatch(getParentSessionAction(selectedChild?.id));
  }, [dispatch, selectedChild]);

  return (
    <>
      {loaderState ? (
        <Loader text={"Loading Session History"} />
      ) : (
        <Box id="mainWrapper" px="16px" py="24px">
          <Text className="txnsTabText1">Session History</Text>
          <Text className="txnsTabText2">
            History of sessions with status, date and other critical factors
          </Text>
          {sessions?.length > 0 ? (
            <Box mt="24px">
              {sessions?.map((session, index) => (
                <Box key={index}>
                  <Flex className="txnsSecondMapFlex">
                    <Box>
                      <Text className="sessionHistoryMapDate">
                        {session?.date}
                      </Text>
                      <Text className="sessionHistoryMapUserExpert">
                        {session?.user} / {session?.expert}
                      </Text>
                    </Box>
                    <Tag
                      className="txnsSecondMapFlexBoxTag"
                      style={{
                        backgroundColor:
                          ((session?.status === "Successfull" ||
                            session?.status === "Attended" ||
                            session?.status === "Partially Attended") &&
                            "#EFF7E6") ||
                          ((session?.status === "Cancelled" ||
                            session?.status === "noShow-Parent" ||
                            session?.status === "noShow-Expert") &&
                            "#FFDAD6") ||
                          (session?.status === "Scheduled" && "#F3EBFF") ||
                          (session?.status === "Rescheduled" && "#FEDFA0") ||
                          ((session.status === "Session Request" ||
                            session.status === "Session Request Expired" ||
                            session.status === "Rejected Session Request" ||
                            session.status === "REQUEST CANCELLED") &&
                            "#FEDFA0"),
                      }}
                    >
                      <Avatar
                        className="txnsSecondMapFlexBoxTagAvatar"
                        src={
                          ((session?.status === "Successfull" ||
                            session?.status === "Attended") &&
                            ATTENDED) ||
                          (session?.status === "Partially Attended" &&
                            PARTIALLY_ATTENDED) ||
                          (session?.status === "Cancelled" && CANCELLED) ||
                          (session?.status === "Scheduled" && SCHEDULED) ||
                          (session?.status === "Rescheduled" && RESCHEDULED) ||
                          ((session.status === "Session Request" ||
                            session.status === "Session Request Expired" ||
                            session.status === "Rejected Session Request" ||
                            session.status === "REQUEST CANCELLED") &&
                            ORANGE_WARNING) ||
                          ((session?.status === "noShow-Parent" ||
                            session?.status === "noShow-Expert") &&
                            NO_SHOW)
                        }
                      />
                      <TagLabel
                        style={{
                          color:
                            ((session?.status === "Successfull" ||
                              session?.status === "Attended" ||
                              session?.status === "Partially Attended") &&
                              "#024742") ||
                            ((session?.status === "Cancelled" ||
                              session?.status === "noShow-Parent" ||
                              session?.status === "noShow-Expert") &&
                              "#410002") ||
                            (session?.status === "Scheduled" && "#2F0D63") ||
                            (session?.status === "Rescheduled" && "#734110") ||
                            ((session.status === "Session Request" ||
                              session.status === "Session Request Expired" ||
                              session.status === "Rejected Session Request" ||
                              session.status === "REQUEST CANCELLED") &&
                              "#734110"),
                        }}
                      >
                        {session?.status}
                      </TagLabel>
                    </Tag>
                  </Flex>
                </Box>
              ))}
            </Box>
          ) : (
            <Text color="#141719" mt={10} fontSize="20px">
              You don't have any Session History
            </Text>
          )}
        </Box>
      )}
    </>
  );
};

export default SessionHistory;

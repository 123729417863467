import React, { useState } from "react";
import CreatePathwayConfirmation from "../CreatePathwayTabs/createPathwayConfirmation";
import CreatePathwayTabsContent from "../CreatePathwayTabs/createPathwayTabsContent";

const CreatePathwayHandler = ({
  setShowingTable,
  setWillCreatePathway,
  setPathwayExists,
  selectedChild,
  pathway
}) => {
  const [proceedToConfirmPathway, setProceedToConfirmPathway] = useState(false);

  return (
    <>
      {proceedToConfirmPathway ? (
        <CreatePathwayConfirmation
          setPathwayExists={setPathwayExists}
          setWillCreatePathway={setWillCreatePathway}
          setProceedToConfirmPathway={setProceedToConfirmPathway}
          setShowingTable={setShowingTable}
          selectedChild={selectedChild}
        />
      ) : (
        <CreatePathwayTabsContent
          setProceedToConfirmPathway={setProceedToConfirmPathway}
          selectedChild={selectedChild}
          setWillCreatePathway={setWillCreatePathway}
          setShowingTable={setShowingTable}
          pathway={pathway}
        />
      )}
    </>
  );
};

export default CreatePathwayHandler;

import React, { useState, useEffect } from "react";
import {
  SimpleGrid,
  FormControl,
  GridItem,
  FormLabel,
  Input,
  Box,
  Text,
  Button,
  Flex,
  Checkbox,
  FormErrorMessage,
  Spinner,
} from "@chakra-ui/react";
import { toggleAPILoader, getUserProfile } from "../../../../modules/actions";
import { saveUserProfileService } from "../../../../modules/services";
import { Toaster } from "../../../../common/components/Toaster";
import { useDispatch, useSelector } from "react-redux";

import "../onboarding.css";

const Qualifications = ({ activeStep, nextStep, prevStep }) => {
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state.user);
  const { apiLoader } = useSelector((state) => state.loader);
  const [next, setNext] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("idToken");
    dispatch(getUserProfile(token));
  }, []);

  const [qualifications, setQualifications] = useState([
    {
      id: 1,
      degree: "",
      field: "",
      certifyingOrganization: "",
      rci: false,
      rciNumber: "",
      noDegree: false,
      noField: false,
      noCertifyingOrganization: false,
      noRciNumber: false,
    },
  ]);

  useEffect(() => {
    if (profile?.qualifications !== undefined) {
      const formattedResponse =
        profile.qualifications &&
        profile.qualifications.map((item, index) => {
          return {
            id: index + 1,
            degree: item.degree,
            noDegree: item.degree ? false : true,
            field: item.fieldOfStudy,
            noField: item.fieldOfStudy ? false : true,
            certifyingOrganization: item.certifyingOrganisation,
            noCertifyingOrganization: item.certifyingOrganisation
              ? false
              : true,
            rci: item.rciCertified ? true : false,
            rciNumber: item.rciCertified ? item.rciNumber : null,
            noRciNumber: item.rciNumber ? false : true,
          };
        });
      setQualifications(formattedResponse);
    }
  }, [profile]);

  useEffect(() => {
    let flag = false;
    qualifications.map((q) => {
      if (
        !q.degree ||
        !q.field ||
        !q.certifyingOrganization ||
        (q.rci && !q.rciNumber)
      )
        return (flag = false);
      return (flag = true);
    });
    if (flag) setNext(true);
  }, [qualifications]);

  const handleFormChange = (id, name, value) => {
    setQualifications((prev) =>
      prev.map((q) => (q.id === id ? { ...q, [name]: value } : q))
    );
  };

  const handleAdd = () => {
    setQualifications((prev) => [
      ...prev,
      {
        id: qualifications.length + 1,
        degree: "",
        field: "",
        certifyingOrganization: "",
        rci: false,
        rciNumber: "",
        noDegree: false,
        noField: false,
        noCertifyingOrganization: false,
        noRciNumber: false,
      },
    ]);
  };

  const handleDelete = (id) => {
    setQualifications((prev) => prev.filter((q) => q.id !== id));
  };

  const handleNextStep = () => {
    let flag = false;
    setQualifications((prev) =>
      prev.map((item) => {
        return {
          ...item,
          noDegree: item.degree === "",
          noField: item.field === "",
          noRciNumber: item.rciNumber === "",
          noCertifyingOrganization: item.certifyingOrganization === "",
        };
      })
    );

    qualifications.map((q) => {
      if (
        !q.degree ||
        !q.field ||
        !q.certifyingOrganization ||
        (q.rci && !q.rciNumber)
      )
        return (flag = false);
      return (flag = true);
    });
    if (!flag) return;

    const formattedQualifications = qualifications.map((item) => {
      return {
        certifyingOrganisation: item.certifyingOrganization,
        degree: item.degree,
        fieldOfStudy: item.field,
        rciCertified: item.rci ? item.rci : "",
        rciNumber: item.rci ? item.rciNumber : "",
      };
    });
    // dispatch(
    //   saveUserProfileImage({ qualifications: formattedQualifications }, 3)
    // );
    // setApiLoaderStatus(true);
    dispatch(toggleAPILoader(true));
    saveUserProfileService(3, { qualifications: formattedQualifications })
      .then((res) => {
        dispatch(toggleAPILoader(false));
        nextStep();
      })
      .catch((error) => {
        console.log(error);
        dispatch(toggleAPILoader(false));
        Toaster(error.response.data.message, "error");
      });
  };

  return (
    <Box backgroundColor="white">
      {qualifications.map((item, index) => (
        <Box key={item.id} mb="24px">
          <Flex
            flexDir={{ base: "column", md: "row" }}
            justifyContent="space-between"
          >
            <Text className="qualificationsMainText">
              Qualification {index + 1}
            </Text>
            {qualifications?.length > 1 ? (
              <Button
                className="qualificationsDeleteBtn"
                _hover="white"
                onClick={() => handleDelete(item.id)}
              >
                Delete
              </Button>
            ) : (
              ""
            )}
          </Flex>
          <SimpleGrid rowGap={8} columnGap={8} mt="40px">
            <GridItem>
              <FormControl isInvalid={item.noDegree} isRequired>
                <FormLabel
                  className="qualificationsFormLabel"
                  style={{
                    color: item.noDegree && "#BA1A1A",
                  }}
                  sx={{
                    "& .chakra-form__required-indicator": {
                      color: item.noDegree && "#BA1A1A",
                    },
                  }}
                >
                  Degree/Certificate
                </FormLabel>
                <Input
                  onChange={(e) =>
                    handleFormChange(item.id, e.target.name, e.target.value)
                  }
                  name="degree"
                  placeholder="Mention degree or certificate"
                  className="qualificationsFormInput"
                  value={item.degree}
                />
                {item.noDegree && (
                  <FormErrorMessage className="qualificationsErrorMessage">
                    This field cannot be empty
                  </FormErrorMessage>
                )}
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl isInvalid={item.noField} isRequired>
                <FormLabel
                  className="qualificationsFormLabel"
                  style={{
                    color: item.noField && "#BA1A1A",
                  }}
                  sx={{
                    "& .chakra-form__required-indicator": {
                      color: item.noField && "#BA1A1A",
                    },
                  }}
                >
                  Field of Study
                </FormLabel>
                <Input
                  placeholder="Specifications"
                  name="field"
                  value={item.field}
                  className="qualificationsFormInput"
                  onChange={(e) =>
                    handleFormChange(item.id, e.target.name, e.target.value)
                  }
                />
                {item.noField && (
                  <FormErrorMessage className="qualificationsErrorMessage">
                    This field cannot be empty
                  </FormErrorMessage>
                )}
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl isInvalid={item.noCertifyingOrganization} isRequired>
                <FormLabel
                  className="qualificationsFormLabel"
                  style={{
                    color: item.noCertifyingOrganization && "#BA1A1A",
                  }}
                  sx={{
                    "& .chakra-form__required-indicator": {
                      color: item.noCertifyingOrganization && "#BA1A1A",
                    },
                  }}
                >
                  Certifying Organization
                </FormLabel>
                <Input
                  placeholder="Name of the institute"
                  name="certifyingOrganization"
                  value={item.certifyingOrganization}
                  className="qualificationsFormInput"
                  onChange={(e) =>
                    handleFormChange(item.id, e.target.name, e.target.value)
                  }
                />
                {item.noCertifyingOrganization && (
                  <FormErrorMessage className="qualificationsErrorMessage">
                    This field cannot be empty
                  </FormErrorMessage>
                )}
              </FormControl>
            </GridItem>
            {index === 0 && (
              <Box>
                <GridItem
                  display="flex"
                  fontWeight="medium"
                  justifyContent="flex-start"
                >
                  <Checkbox
                    onChange={(e) =>
                      handleFormChange(item.id, e.target.name, e.target.checked)
                    }
                    name="rci"
                    isChecked={item.rci}
                    className="qualificationsCheckBox"
                    value={item.rci}
                    colorScheme="green"
                  >
                    Are you a RCI certified therapist ?
                  </Checkbox>
                </GridItem>
                <GridItem style={{ display: item.rci ? "block" : "none" }}>
                  <FormControl isInvalid={item.noRciNumber} isRequired>
                    <FormLabel
                      className="qualificationsFormLabel"
                      style={{
                        color: item.noRciNumber && item.rci && "#BA1A1A",
                        marginTop: "24px",
                      }}
                      sx={{
                        "& .chakra-form__required-indicator": {
                          color: item.noRciNumber && item.rci && "#BA1A1A",
                        },
                      }}
                    >
                      RCI Number
                    </FormLabel>
                    <Input
                      value={item.rciNumber}
                      name="rciNumber"
                      className="qualificationsFormInput"
                      border="1px solid #A6B8C4"
                      placeholder="Mention RCI Number"
                      onChange={(e) =>
                        handleFormChange(item.id, e.target.name, e.target.value)
                      }
                    />
                    {item.noRciNumber && item.rci && (
                      <FormErrorMessage className="qualificationsErrorMessage">
                        This field cannot be empty
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </GridItem>
              </Box>
            )}
          </SimpleGrid>
        </Box>
      ))}
      <Button
        style={{
          fontSize: "14px",
          fontWeight: 600,
          fontFamily: "Inter",
          lineHeight: "24px",
        }}
        color="#4299E1"
        bg="white"
        _hover="white"
        onClick={handleAdd}
      >
        Add Qualification
      </Button>
      <Flex mt="40px" justify="flex-end" gap={4}>
        <Button
          onClick={prevStep}
          color="#4299E1"
          size="sm"
          variant="ghost"
          px="40px"
          py="20px"
        >
          Back
        </Button>
        <Box>
          <Button
            color={next && !apiLoader ? "white" : "#91A2AE"}
            size="sm"
            bg={next && !apiLoader ? "#4299E1" : "#F3F8FB"}
            px="40px"
            py="20px"
            onClick={handleNextStep}
          >
            {apiLoader ? (
              <Spinner
                bg="#f3f8fb"
                emptyColor="gray.200"
                color="blue.500"
                position="absolute"
              />
            ) : (
              "Next"
            )}
          </Button>
        </Box>
      </Flex>
    </Box>
  );
};

export default Qualifications;

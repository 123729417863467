import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const ModalContainer = ({
  isOpen,
  onClose,
  whichModal,
  handleSecondaryButtonClick,
  msg,
  id,
}) => {
  const [individualRTEData, setIndividualRTEData] = useState(msg);

  const [edit, setEdit] = useState(false);

  const handleQuillChange = (e) => {
    setIndividualRTEData(e);
    if (individualRTEData !== msg) {
      setEdit(true);
    }
  };

  const handleQuillChangeClose = () => {
    setIndividualRTEData(msg);
    onClose();
    setEdit(false);
  };

  const handleEditSave = () => {
    if (individualRTEData === msg) {
      return handleQuillChangeClose();
    }
    handleSecondaryButtonClick(id, individualRTEData);
    setEdit(false);
    onClose();
  };

  return (
    <Modal
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      autoFocus={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader className="modalHeader">
          {whichModal === "edit" ? "Edit note" : "Delete note?"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {whichModal === "edit" ? (
            <Box
              pos="relative"
              sx={{
                "& .ql-container": {
                  borderBottom: "none",
                },
              }}
            >
              <ReactQuill
                style={{ marginTop: "-8px", marginBottom: "8px" }}
                theme="snow"
                placeholder="Type Here..."
                value={individualRTEData}
                onChange={(e) => handleQuillChange(e)}
              />
              <Box pos="absolute" bottom="8px" right="8px">
                <Button
                  className="ql-toolbar-button modalButtons"
                  border="1px solid #a6b8c4"
                  bg="white"
                  color="#141719"
                  mr="8px"
                  onClick={handleQuillChangeClose}
                >
                  Cancel
                </Button>
                <Button
                  _hover={{
                    bg: "#4299e1",
                    color: "white",
                  }}
                  className="ql-toolbar-button modalButtons"
                  bg={edit ? "#4299e1" : "#f3f8fb"}
                  color={edit ? "white" : "#91a2ae"}
                  onClick={() => handleEditSave(id, individualRTEData)}
                >
                  Save
                </Button>
              </Box>
            </Box>
          ) : (
            "This note will be deleted permanently. Are you sure you want to continue?"
          )}
        </ModalBody>
        {whichModal === "delete" && (
          <ModalFooter
            mt={whichModal === "delete" && "16px"}
            borderTop={whichModal === "delete" && "1px solid #a6b8c4"}
          >
            <Button
              bg="white"
              color="#141719"
              mr="8px"
              border="1px solid #a6b8c4"
              onClick={onClose}
              className="modalButtons2"
            >
              Cancel
            </Button>
            <Button
              _hover={{
                bg: `${whichModal === "edit" ? "#4299e1" : "#ffdad6"}`,
                color: `${whichModal === "edit" ? "white" : "#410002"}`,
              }}
              className="modalButtons2"
              bg={whichModal === "edit" ? "#4299e1" : "#ffdad6"}
              color={whichModal === "edit" ? "white" : "#410002"}
              onClick={() => handleSecondaryButtonClick(id)}
            >
              {whichModal === "edit" ? "Save" : "Delete"}
            </Button>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};

export default ModalContainer;

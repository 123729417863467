import * as ActionTypes from "./actionTypes";

export const getChildPathway = (data) => ({
  type: ActionTypes.GET_CHILD_PATHWAY,
  data,
});

export const saveChildPathway = (data) => ({
  type: ActionTypes.SAVE_CHILD_PATHWAY,
  data,
});

export const getOutcomeDetails = (data) => ({
  type: ActionTypes.GET_OUTCOME_DETAILS,
  data,
});

export const saveOutcomeDetails = (data) => ({
  type: ActionTypes.SAVE_OUTCOME_DETAILS,
  data,
});

export const addParentOutcomeSttaus = (data) => ({
  type: ActionTypes.ADD_PARENT_OUTCOME_STATUS,
  data,
});

export const getDomainResources = (data) => ({
  type: ActionTypes.GET_DOMAIN_RESOURCES,
  data,
});

export const saveDomainResources = (data) => ({
  type: ActionTypes.SAVE_DOMAIN_RESOURCES,
  data,
});

export const getFavouriteResources = () => ({
  type: ActionTypes.GET_FAVOURITE_RESOURCES,
});

export const saveFavouriteResources = (data) => ({
  type: ActionTypes.SAVE_FAVOURITE_RESOURCES,
  data,
});

export const getDownloadCheckpoint = (data) => ({
  type: ActionTypes.DOWNLOAD_CHECKPOINT,
  data,
});

export const saveDownloadCheckpoint = (data) => ({
  type: ActionTypes.SAVE_DOWNLOAD_CHECKPOINT,
  data,
});

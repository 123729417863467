import {
  Box,
  Button,
  Flex,
  Grid,
  Image,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DELETE_ICON, OPEN_PATHWAY, ROUTES_PATH } from "../../../../constant";
import SingleOutcome from "./utils/singleOutcome";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { createPathwayService } from "../../../../modules/services";
import { Toaster } from "../../../../common/components/Toaster";
import { getChildPathway, toggleAPILoader } from "../../../../modules/actions";

const LOCALSTORAGE_CHECKPOINTS_DATA_KEY = "CHECKPOINT_OUTCOMES_STUDENTS_DATA";
const LOCALSTORAGE_CONFIRM_CHECKPOINTS_DATA =
  "LOCALSTORAGE_CONFIRM_CHECKPOINTS_DATA";

const CreatePathwayConfirmation = ({
  selectedChild,
  setShowingTable,
  setWillCreatePathway,
  setProceedToConfirmPathway,
  setPathwayExists,
}) => {
  const toast = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loaderState = useSelector((state) => state?.loader?.apiLoader);
  const pathway = useSelector((state) => state?.pathway[0]);

  const toastTemplate = (title, status) => {
    toast({
      position: "bottom-left",
      title: title,
      status: status,
      isClosable: true,
      duration: 5000,
    });
  };

  const [changedLocalstorage, setChangedLocalstorage] = useState(0);

  const [outcomeData, setOutcomeData] = useState([]);

  useEffect(() => {
    setOutcomeData(pathway?.checkpoints);
  }, [pathway]);

  useEffect(() => {
    dispatch(toggleAPILoader(true));
    dispatch(getChildPathway(selectedChild.id));
  }, []);

  const handleSavePathway = () => {
    const newarry = outcomeData.map((obj, index) => {
      let arr = { status: "Locked", outcomes: [] };

      obj.outcomes.map((outcome) => {
        outcome.status = outcome.status ? outcome.status : "";
        arr.outcomes.push(outcome);
        arr["status"] = outcome.checkpointStatus
          ? outcome.checkpointStatus
          : "Locked";
      });
      return arr;
    });
    const data = {
      userId: selectedChild.id,
      status: "confirmed",
      checkpoints: newarry,
    };
    dispatch(toggleAPILoader(true));
    createPathwayService(data)
      .then((res) => {
        dispatch(toggleAPILoader(false));
        setPathwayExists(true);
        setWillCreatePathway(false);
        Toaster("Pathway created successfully", "success");
        navigate(ROUTES_PATH.MY_STUDENTS);
      })
      .catch((error) => {
        console.log(error);
        dispatch(toggleAPILoader(false));
        Toaster(error.response.data.message, "error");
      });
  };

  const handleDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) return;

    const newOutcomeData = [...outcomeData];
    const sourceArrayIndex = Number(source.droppableId);
    const destinationArrayIndex = Number(destination.droppableId);
    const [removed] = newOutcomeData[sourceArrayIndex].splice(source.index, 1);
    newOutcomeData[destinationArrayIndex].splice(destination.index, 0, removed);

    setOutcomeData(newOutcomeData);
  };

  const handleRemoveCheckpoint = (index) => {
    outcomeData.splice(index, 1);
    setOutcomeData(outcomeData);
    localStorage.setItem(
      LOCALSTORAGE_CONFIRM_CHECKPOINTS_DATA,
      JSON.stringify(outcomeData)
    );
    setChangedLocalstorage((prev) => prev + 1);
  };

  return (
    <Box>
      <Grid
        maxW="100vw"
        overflowX="clip"
        position="sticky"
        zIndex="10000"
        top="0"
        row
        className="layoutBanner"
      >
        <Flex className="pathwayHandlerStudentNameBanner">
          <Text
            cursor="pointer"
            onClick={() => setShowingTable(true)}
            className="pathwayHandlerText"
            color="#141719"
          >
            My students
          </Text>
          <Image h="16px" w="16px" src={OPEN_PATHWAY} />
          <Text
            onClick={() => setWillCreatePathway(false)}
            className="pathwayHandlerText"
            cursor="pointer"
            color="#141719"
          >
            {selectedChild?.childName}
          </Text>
          <Image h="16px" w="16px" src={OPEN_PATHWAY} />
          <Text
            className="pathwayHandlerText"
            onClick={() => setProceedToConfirmPathway(false)}
            cursor="pointer"
            color="#141719"
          >
            Create Pathway
          </Text>
          <Image h="16px" w="16px" src={OPEN_PATHWAY} />
          <Text className="pathwayHandlerText" color="#5a646b">
            Pathway
          </Text>
        </Flex>
      </Grid>
      <Flex
        zIndex={1000000}
        position="sticky"
        bg="white"
        top="128px"
        justify="space-between"
        align="center"
        py="16px"
        px="72px"
        borderBottom="1px solid #d8e5ee"
      >
        <Box>
          <Text
            fontSize="22px"
            fontWeight="500"
            lineHeight="28px"
            color="#141719"
          >
            Pathway
          </Text>
          <Text
            fontSize="14px"
            fontWeight="400"
            lineHeight="20px"
            color="#5a646b"
            mt="8px"
          >
            A pathway consists of multiple outcomes and grouped into checkpoints
          </Text>
        </Box>
        <Flex gap="16px">
          <Button
            fontWeight="600"
            fontSize="14px"
            lineHeight="20px"
            borderRadius="10px"
            px="20px"
            border="1px solid #4299e1"
            bg="white"
            color="#141719"
            _hover={{ bg: "white" }}
            onClick={() => setProceedToConfirmPathway(false)}
            py="10px"
          >
            Add Outcomes
          </Button>
          <Button
            fontWeight="600"
            fontSize="14px"
            color="white"
            bg="#4299e1"
            _hover={{ bg: "#4299e1" }}
            lineHeight="20px"
            borderRadius="10px"
            px="20px"
            py="10px"
            onClick={handleSavePathway}
          >
            Confirm Pathway
          </Button>
        </Flex>
      </Flex>
      {outcomeData?.length > 0 ? (
        <Box mx={{ base: "80px", lg: "208px" }} mb="200px">
          {outcomeData?.map((ch, index) => (
            <Box mt="24px" key={index}>
              <Flex justify="space-between" align="center">
                <Text
                  px="16px"
                  py="8px"
                  border="1px solid #d8e5ee"
                  borderRadius="40px"
                  minW="max-content"
                  fontWeight="600"
                  fontSize="16px"
                  lineHeight="24px"
                  letterSpacing="0.15px"
                  color="#141719"
                >
                  Checkpoint {index + 1}
                </Text>
                <Box w="100%" borderBottom="2px dotted #a6b8c4"></Box>
                {outcomeData?.length > 1 && ch.status !== "Accomplished" && (
                  <Image
                    onClick={() => handleRemoveCheckpoint(index)}
                    cursor="pointer"
                    src={DELETE_ICON}
                  />
                )}
              </Flex>
              {ch?.outcomes.map((c, index2) => (
                <Box key={c.id}>
                  <SingleOutcome
                    totatCheckpoints={outcomeData.length}
                    index={index2}
                    mainArrayIndex={index}
                    c={c}
                    setChangedLocalstorage={setChangedLocalstorage}
                    setOutcomeData={setOutcomeData}
                    outcomeData={outcomeData}
                  />
                </Box>
              ))}
            </Box>
          ))}
        </Box>
      ) : (
        <Text color="#141719" m={10} fontSize="20px">
          You have removed all the outcomes, please select some before
          proceeding forward
        </Text>
      )}
    </Box>
  );
};

export default CreatePathwayConfirmation;

import { useState } from "react";
import {
  Box,
  Text,
  Flex,
  Avatar,
  SimpleGrid,
  GridItem,
  Button,
  Image,
} from "@chakra-ui/react";
import { DeleteIcon, ViewIcon } from "@chakra-ui/icons";
import { useSelector, useDispatch } from "react-redux";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {
  calculateAge,
  handleStringManupulation,
} from "../../../../utils/customHooks";
import { getDateMonthYear1 } from "../../../../utils/customMoment";
import { DOC_ICON, PDF_ICON, PLUS_ICON, PPT_ICON } from "../../../../constant";
import UploadDoc from "../uploadDoc";
import Loader from "../../../../common/components/Loader";
import { deleteDocumentService } from "../../../../modules/services";
import { toggleAPILoader, getUserProfile } from "../../../../modules/actions";
import { Toaster } from "../../../../common/components/Toaster";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1400 },
    items: 3.5,
    slidesToSlide: 3,
  },
  desktop: {
    breakpoint: { max: 1300, min: 1024 },
    items: 2.5,
    slidesToSlide: 2,
  },
  tablet: {
    breakpoint: { max: 1024, min: 550 },
    items: 2.5,
    slidesToSlide: 2,
  },
  mobile: {
    breakpoint: { max: 550, min: 280 },
    items: 1.5,
    slidesToSlide: 1,
  },
  foldmobile: {
    breakpoint: { max: 280, min: 0 },
    items: 0.5,
    slidesToSlide: 0.5,
  },
};

const ParentChild = ({ loader }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.profile);
  const userBalance = useSelector((state) => state.userBalance);
  const apiLoader = useSelector((state) => state?.loader?.apiLoader);
  const [overlayOpen, setOverlayOpen] = useState(false);

  const handleDeleteDocument = (docId, index) => {
    const reqData = {
      docId,
      docIndex: index,
    };
    dispatch(toggleAPILoader(true));
    deleteDocumentService(reqData)
      .then((res) => {
        Toaster("Child documents deleted successfully", "success", "top-left");
        const token = localStorage.getItem("idToken");
        dispatch(getUserProfile(token));
        dispatch(toggleAPILoader(false));
      })
      .catch((error) => {
        console.log(error);
        dispatch(toggleAPILoader(false));
        Toaster(error.response.data.message, "error", "top-left");
      });
  };

  return (
    <Box id="mainWrapper" px="16px" py="24px">
      {loader ? (
        <Loader text="Loading child details..." />
      ) : (
        <>
          {overlayOpen && <UploadDoc setOverlayOpen={setOverlayOpen} />}
          <Text className="txnsTabText1">Child</Text>
          <Text className="txnsTabText2">Information about your child</Text>
          <Flex className="parentProfileMainBodyContainer">
            <Avatar
              className="parentProfileAvatar"
              name={user?.childName}
              src={user?.image}
            />
            <Flex flexDirection="column" ml="16px">
              <Text className="profileChildName">{user?.childName}</Text>
              <Flex mt="4px">
                <Flex gap="4px" mr="8px">
                  <Text className="profileAgeGender">
                    {calculateAge(user?.childDob)}
                  </Text>
                </Flex>
                <Text style={{ marginTop: "-5px" }}>.</Text>
                <Flex gap="4px" ml="8px">
                  <Text className="profileAgeGender">{user?.childGender}</Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <SimpleGrid
            rowGap="16px"
            gridTemplateColumns={{ base: "1 1fr", sm: "160px 1fr" }}
            className="parentProfileMainBodyContainer2"
          >
            <GridItem colSpan="160px">
              <Text className="servicesMainBodyTitle">Session balance</Text>
            </GridItem>
            <GridItem colSpan={1}>
              <Text className="servicesMainBodyTitleDesc">
                {Number(userBalance?.currentBalance) > 0
                  ? Number(userBalance?.currentBalance).toFixed(2)
                  : 0}{" "}
                Sessions
              </Text>
            </GridItem>
            <GridItem colSpan="160px">
              <Text className="servicesMainBodyTitle">Concerns</Text>
            </GridItem>
            <GridItem colSpan={1}>
              <Text className="servicesMainBodyTitleDesc">
                {handleStringManupulation(user?.conditions)}
              </Text>
            </GridItem>
            <GridItem colSpan="160px">
              <Text className="servicesMainBodyTitle">Language</Text>
            </GridItem>
            <GridItem colSpan={1}>
              <Text className="servicesMainBodyTitleDesc">
                {handleStringManupulation(user?.languages)}
              </Text>
            </GridItem>

            <GridItem colSpan="160px">
              <Text className="servicesMainBodyTitle">Date of birth</Text>
            </GridItem>
            <GridItem colSpan={1}>
              <Text className="servicesMainBodyTitleDesc">
                {getDateMonthYear1(user?.childDob)}
              </Text>
            </GridItem>
          </SimpleGrid>
          <Flex my="24px" justifyContent="space-between">
            <Box>
              <Text className="txnsTabText1">Uploads</Text>
              <Text className="txnsTabText2">
                Files uploaded will appear below
              </Text>
            </Box>

            <Button
              className="uploadBtn"
              _hover={{
                bg: "blue.500",
              }}
              onClick={() => {
                setOverlayOpen(true);
              }}
            >
              <Image src={PLUS_ICON} /> Upload
            </Button>
          </Flex>
          {apiLoader ? (
            <Loader text="Loading child details..." />
          ) : (
            user?.childDocuments?.length > 0 && (
              <Carousel
                responsive={responsive}
                partialVisible={true}
                itemClass="childDocGrid"
                // centerMode={true}
                // customRightArrow={<CustomRightArrow />}
                // customLeftArrow={<CustomLeftArrow />}
              >
                {/* maxW="240px" */}
                {user?.childDocuments?.map((doc, docId) =>
                  doc?.documents?.map((item, index) => (
                    <GridItem maxW="240px" key={index}>
                      <Image
                        src={
                          item.type.includes("pdf")
                            ? PDF_ICON
                            : item.type.includes("presentation")
                            ? PPT_ICON
                            : item.type.includes("image")
                            ? item.url
                            : DOC_ICON
                        }
                        height="150px"
                        width="100%"
                        maxW="240px"
                        objectFit="contain"
                        background="#E9F6FF"
                      />
                      <Box p="20px">
                        <Box className="childDocTitleDiv">{doc.name}</Box>
                        <Flex
                          mt="16px !important"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Text className="txnsTabText2" color="#3D464D">
                            {item.type.includes("pdf")
                              ? "PDF"
                              : item.type.includes("presentation")
                              ? "PPT"
                              : item.type.includes("image")
                              ? "Image"
                              : "Document"}
                          </Text>
                          <Box>
                            <ViewIcon
                              className="myProfileIndexIcons"
                              mr="8px"
                              cursor="pointer"
                              onClick={() => window.open(item.url)}
                            />
                            <DeleteIcon
                              style={{ color: "#BA1A1A" }}
                              className="myProfileIndexIcons"
                              cursor="pointer"
                              onClick={() => handleDeleteDocument(docId, index)}
                            />
                          </Box>
                        </Flex>
                      </Box>
                    </GridItem>
                  ))
                )}
              </Carousel>
            )
          )}

          {/* <Grid
            templateColumns={{ md: "repeat(12, 1fr)", sm: "" }}
            className="childDocGrid"
            gap="24px"
          >
            {user?.childDocuments?.map((doc) =>
              doc.documents?.map((item, index) => (
                <GridItem colSpan={3} className="childDocDiv" key={index}>
                  <Image
                    src={
                      item.type.includes("pdf")
                        ? PDF_ICON
                        : item.type.includes("presentation")
                        ? PPT_ICON
                        : item.type.includes("image")
                        ? item.url
                        : DOC_ICON
                    }
                    height="150px"
                    width="240px"
                    objectFit="contain"
                    background="#E9F6FF"
                  />
                  <Box p="20px">
                    <Box className="childDocTitleDiv">{doc.name}</Box>
                    <Text
                      className="txnsTabText2"
                      color="#3D464D"
                      mt="16px !important"
                    >
                      {item.type.includes("pdf")
                        ? "PDF"
                        : item.type.includes("presentation")
                        ? "PPT"
                        : item.type.includes("image")
                        ? "Image"
                        : "Document"}
                    </Text>
                  </Box>
                </GridItem>
              ))
            )}
          </Grid> */}
        </>
      )}
    </Box>
  );
};
export default ParentChild;

import { Box, Image, Text } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import AuthenticatedContainer from "../../../container/Authenticated";
import { BACK_BTN, ROUTES_PATH } from "../../../constant";

const ActivityDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <AuthenticatedContainer>
      <Box px="70px" py="24px" mb="100px">
        <Box className="exerciseBackText" mb="10px">
          <Image
            src={BACK_BTN}
            mr="8px"
            onClick={() => navigate(ROUTES_PATH.PARENT_EXPLORE)}
            cursor="pointer"
          />
          <Text
            onClick={() => navigate(ROUTES_PATH.PARENT_EXPLORE)}
            cursor="pointer"
          >
            Go Back
          </Text>
        </Box>
        <Text className="topicHeading">
          {location.state.title + " - " + location.state.suggested_time}
        </Text>
        <Image
          src={`http://soicalprod.s3.ap-southeast-1.amazonaws.com/stg_activityimages/compressed2/${location.state.id}.${location.state.title}.jpg`}
          objectFit="cover"
          background="#E9F6FF"
          // height="600px"
          width="100%"
        />

        <Text className="topicHeading" mt="24px">
          Activity
        </Text>
        <Text className="exploreSubText">{location.state.description}</Text>

        <Text className="topicHeading" mt="24px">
          What your child will learn
        </Text>
        <Text className="exploreSubText">{location.state.learning}</Text>

        {location.state.materials_required && (
          <>
            <Text className="topicHeading" mt="24px">
              Materials Required
            </Text>
            <Text className="exploreSubText">
              {location.state.materials_required}
            </Text>
          </>
        )}
      </Box>
    </AuthenticatedContainer>
  );
};
export default ActivityDetails;

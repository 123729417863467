import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, Button, Flex, Image, Text, Show } from "@chakra-ui/react";
import AuthenticatedContainer from "../../../container/Authenticated";
import AllResources from "./Tabs/AllResources";
import Favourites from "./Tabs/Favourites";
import { PLUS, ROUTES_PATH } from "../../../constant";
import OnboardingStart from "./Tabs/OnboardingStart";
import { getDomainsGoalDataAction } from "../../../modules/actions";
import MobileNotAllowedScreen from "../../../common/components/MobileNotAllowed";
import "./resources.css";

const Resource = () => {
  let tabsData = [
    {
      id: "Training & Onboarding",
      value: <OnboardingStart />,
      route: ROUTES_PATH.EXPERT_ONBOARDING_RESOURCES,
    },
    {
      id: "Favourites",
      value: <Favourites />,
    },
  ];
  const [activeStep, setActiveStep] = useState("Training & Onboarding");
  const [tabs, setTabs] = useState(tabsData);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { domainGoals } = useSelector((state) => state?.user);
  const loader = useSelector((state) => state?.loader?.apiLoader);

  useEffect(() => {
    dispatch(getDomainsGoalDataAction());
  }, []);

  useEffect(() => {
    if (domainGoals) {
      domainGoals.map((domain) => {
        tabsData.push({
          id: domain.name,
          value: <AllResources domain={domain} type="expert" />,
        });
      });
    }
    setTabs(tabsData);
  }, [domainGoals]);

  const handleTabChang = (item) => {
    // navigate(item.route);
    setActiveStep(item?.id);
    window.scroll(0, 0);
  };
  return (
    <AuthenticatedContainer>
      <MobileNotAllowedScreen />
      <Show breakpoint="(min-width:767px)">
        <Flex
          maxW="100vw"
          overflowX="clip"
          gap={{ lg: "41px" }}
          px="72px"
          id="secondBox-DynamicSteps"
          flexDir={{ base: "column", lg: "row" }}
        >
          <Flex
            className="myStudentIndexSecondBoxMainFlexContainer"
            minW={{ base: "100vw", lg: "208px", xl: "288px" }}
            maxW={{ lg: "288px" }}
            flexDir={{ base: "row", lg: "column" }}
            gap={{ base: "12px", lg: "16px" }}
            id="left-side"
            h="full"
            zIndex={100}
            bg="white"
            borderTop="1px solid #e5e5e5"
          >
            {/* <Button
              borderRadius="40px"
              bg="#4299e1"
              color="white"
              display="flex"
              justifyContent="space-between"
              fontSize="14px"
              fontWeight="600"
              lineHeight="20px"
              px="16px"
              py="10px"
              _hover={{ bg: "#4299e1" }}
            >
              Upload
              <Image src={PLUS} />
            </Button> */}
            {tabs?.map((item) => (
              <Flex key={item?.id}>
                <Text
                  key={item?.id}
                  className={
                    item.id === activeStep
                      ? "myProfileIndexSecondBoxFirstMapText activeTab"
                      : "myProfileIndexSecondBoxFirstMapText inActiveTab"
                  }
                  w="full"
                  _hover={{ cursor: "pointer" }}
                  rounded="full"
                  // style={{
                  //   backgroundColor:
                  //     item?.id === activeStep ? "#E9F6FF" : "white",
                  //   border:
                  //     item?.id === activeStep ? "#E9F6FF" : "1px solid #A6B8C4",
                  //   color: item?.id === activeStep ? "#2b6cb0" : "#5a646b",
                  // }}
                  onClick={() => handleTabChang(item)}
                >
                  {item?.id}
                </Text>
              </Flex>
            ))}
          </Flex>
          <Box
            sx={{
              "& .chakra-form__required-indicator": {
                color: "black",
              },
            }}
            w="full"
            // zIndex={10}
            id="right-side"
            // maxHeight="90vh"
            // overflow="scroll"
          >
            {tabs.map((item) => (
              <Box key={item.id}>{activeStep === item.id && item.value}</Box>
            ))}
          </Box>
        </Flex>
      </Show>
    </AuthenticatedContainer>
  );
};

export default Resource;

import React from "react";
import {
  Flex,
  Image,
  Text,
  SimpleGrid,
  GridItem,
  FormControl,
  FormLabel,
  InputGroup,
  Input,
  Box,
} from "@chakra-ui/react";

import { INFO_ICON } from "../../../../constant/images";
import "react-calendar/dist/Calendar.css";

const LeavesApplier = ({ setCloser, setApplyLeaveData, applyLeaveData }) => {
  const handleLeaveDataChange = (name, value, errorName) => {
    setCloser(true);
    setApplyLeaveData({ ...applyLeaveData, [name]: value, [errorName]: "" });
  };

  return (
    <>
      <Flex className="leavesApplierFlex">
        <Image src={INFO_ICON} />
        <Text className="leavesApplierFlexText1">
          Applying for leave does not automatically cancel your sessions during
          your leaves time period
        </Text>
      </Flex>
      <SimpleGrid gap="24px" p="16px">
        <GridItem>
          <FormControl>
            <FormLabel className="leavesApplierFlexFormLabel">
              Reason *
            </FormLabel>
            <InputGroup>
              <Input
                className="leavesApplierFlexFormInput"
                onChange={(e) =>
                  handleLeaveDataChange(
                    e.target.name,
                    e.target.value,
                    "reasonError"
                  )
                }
                name="reason"
                value={applyLeaveData?.reason}
                placeholder="Type here"
                maxLength={100}
              />
            </InputGroup>
            {applyLeaveData?.reasonError && (
              <div className="personalErrorMessage">
                {applyLeaveData?.reasonError}
              </div>
            )}
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl>
            <FormLabel className="leavesApplierFlexFormLabel">
              Start Date & Time *
            </FormLabel>
            <Flex gap="16px">
              <Box width="100%">
                <InputGroup>
                  <Input
                    className="leavesApplierFlexFormInput"
                    name="startDate"
                    value={applyLeaveData?.startDate}
                    onChange={(e) =>
                      handleLeaveDataChange(
                        e.target.name,
                        e.target.value,
                        "startDateError"
                      )
                    }
                    placeholder="DD/MM/YYYY"
                    type="date"
                    min={new Date().toISOString().split("T")[0]}
                    isRequired
                    cursor="text"
                    // onKeyDown={(e) => e.preventDefault()}
                  />
                </InputGroup>
                {applyLeaveData?.startDateError && (
                  <div className="personalErrorMessage">
                    {applyLeaveData?.startDateError}
                  </div>
                )}
              </Box>
              <Box width="100%">
                <InputGroup
                  sx={{
                    "& div": {
                      fontWeight: 400,
                      fontSize: "14px",
                    },
                  }}
                >
                  <Input
                    className="leavesTabSelect"
                    name="startTime"
                    textColor="black !important"
                    type="time"
                    onChange={(e) =>
                      handleLeaveDataChange(
                        "startTime",
                        e.target.value,
                        "startTimeError"
                      )
                    }
                    value={applyLeaveData?.startTime}
                    border="1px solid #A6B8C4"
                    locale="sv-sv"
                    placeholder="From"
                    cursor="text"
                  />
                </InputGroup>
                {applyLeaveData?.startTimeError && (
                  <div className="personalErrorMessage">
                    {applyLeaveData?.startTimeError}
                  </div>
                )}
              </Box>
            </Flex>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl>
            <FormLabel className="leavesApplierFlexFormLabel">
              End Date & Time *
            </FormLabel>
            <Flex gap="16px">
              <Box width="100%">
                <InputGroup>
                  <Input
                    className="leavesApplierFlexFormInput"
                    name="endDate"
                    value={applyLeaveData?.endDate}
                    onChange={(e) =>
                      handleLeaveDataChange(
                        e.target.name,
                        e.target.value,
                        "endDateError"
                      )
                    }
                    placeholder="DD/MM/YYYY"
                    type="date"
                    min={
                      applyLeaveData?.startDate
                        ? new Date(applyLeaveData?.startDate)
                            .toISOString()
                            .split("T")[0]
                        : null
                    }
                    isRequired
                    cursor="text"
                    // isDisabled={!applyLeaveData.startDate}
                  />
                </InputGroup>
                {applyLeaveData?.endDateError && (
                  <div className="personalErrorMessage">
                    {applyLeaveData?.endDateError}
                  </div>
                )}
              </Box>
              <Box width="100%">
                <InputGroup
                  sx={{
                    "& div": {
                      fontWeight: 400,
                      fontSize: "14px",
                    },
                  }}
                >
                  <Input
                    className="leavesTabSelect"
                    textColor="black !important"
                    name="endTime"
                    type="time"
                    onChange={(e) =>
                      handleLeaveDataChange(
                        "endTime",
                        e.target.value,
                        "endTimeError"
                      )
                    }
                    value={applyLeaveData?.endTime}
                    border="1px solid #A6B8C4"
                    locale="sv-sv"
                    placeholder="To"
                    min={"16:00"}
                    cursor="text"
                  />
                </InputGroup>
                {applyLeaveData?.endTimeError && (
                  <div className="personalErrorMessage">
                    {applyLeaveData?.endTimeError}
                  </div>
                )}
              </Box>
            </Flex>
          </FormControl>
        </GridItem>
        <GridItem>
          <Text
            className="leavesApplierFlexReadPolicy"
            onClick={() =>
              window.open(
                "https://dux-staging.s3.ap-south-1.amazonaws.com/PolicyDocs/KinderPass+Leave+Policy+(1).pdf"
              )
            }
          >
            Read Policy
          </Text>
        </GridItem>
      </SimpleGrid>
    </>
  );
};

export default LeavesApplier;

import { Box, Image, Text } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import AuthenticatedContainer from "../../../container/Authenticated";
import { BACK_BTN, ROUTES_PATH } from "../../../constant";

const Articles = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const removeAllHtmlTagsExceptP = (str) => {
    return str
      .replace(/<(?!\/?p|\/?div|\/?h1|\/?h2|\/?h3|\/?h4|\/?h5|\/?h6).*?>/g, "")
      .replace(/\s\s+/g, " ");
  };
  return (
    <AuthenticatedContainer>
      <Box className="exerciseBackText" mb="10px" p="20px 50px">
        <Image
          src={BACK_BTN}
          mr="8px"
          onClick={() => navigate(ROUTES_PATH.PARENT_EXPLORE)}
          cursor="pointer"
        />
        <Text
          onClick={() => navigate(ROUTES_PATH.PARENT_EXPLORE)}
          cursor="pointer"
        >
          Go Back
        </Text>
      </Box>
      <Image src={location.state.cover_image_url} width="100%" />
      <Box py="20px" px={{ base: "24px", md: "72px" }} mb="100px">
        <Text className="exploreArticleCategory">
          {location.state.category}
        </Text>
        <Text className="exploreArticleTitle">{location.state.title}</Text>
        <p
          dangerouslySetInnerHTML={{
            __html: location.state.body,
          }}
          className="articleInfo"
        ></p>
      </Box>
    </AuthenticatedContainer>
  );
};
export default Articles;

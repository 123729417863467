import { Box, Image, Show, Text } from "@chakra-ui/react";
import { MOBILE_NOT_ALLOWED } from "../../../constant";
import "../Loader/loader.css";

const MobileNotAllowedScreen = () => {
  return (
    <Show breakpoint="(max-width:767px)">
      <Box className="loaderMainDiv" p="20px">
        <Image src={MOBILE_NOT_ALLOWED} alt="" />
        <Text className="mobileNotAllowedHeading">
          This function is not available on the mobile yet.
        </Text>
        <Text className="mobileNotAllowedSubText">
          We are working to introduce the KinderPass Therapist Dashboard on the
          mobile version soon. Till then, you can use it on the laptop/ desktop.
        </Text>
      </Box>
    </Show>
  );
};
export default MobileNotAllowedScreen;

import { useState, useEffect } from "react";
import {
  Box,
  Image,
  SimpleGrid,
  GridItem,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  FormErrorMessage,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { Select } from "chakra-react-select";
import moment from "moment";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { BACK_CARET, NEXT_CARET } from "../../../../constant";
import { getDate } from "../../../../utils/customMoment";

const SessionDetails = ({
  sessionData,
  handleFormDataChange,
  handleCalendarChange,
  calenderStartDate,
}) => {
  const user = useSelector((state) => state?.user?.profile);
  const expertLeaves = useSelector(
    (state) => state.user?.expertData?.leaves?.data
  );
  const [expertOptions, setExpertOptions] = useState([]);
  const [serviceTypeOptions, setServiceTypeOptions] = useState([]);

  useEffect(() => {
    const options = user?.myExperts?.map((item) => {
      return {
        value: item.educatorName,
        label: item.educatorName,
        expertDetails: item,
      };
    });
    setExpertOptions(options);
  }, [user?.myExperts]);

  useEffect(() => {
    const options = [];
    user?.myExperts?.map((item) => {
      if (item.educatorName === sessionData?.expert?.value) {
        item.service.map((service) => {
          options.push({
            value: service,
            label: service,
          });
        });
      }
    });

    setServiceTypeOptions(options);
  }, [sessionData?.expert, user?.myExperts]);

  const renderDayTileClass = ({ date }) => {
    // Check if the date should be excluded
    const hasAppliedLeave = expertLeaves?.some((leave) => {
      const currentDate = moment(date);
      const leaveStartDate = moment
        .unix(leave.leaveStartDateTime._seconds)
        .startOf("d");
      const leaveEndDate = moment
        .unix(leave.leaveEndDateTime._seconds)
        .endOf("d");

      return currentDate.isBetween(leaveStartDate, leaveEndDate, null, "[]");
    });

    // Apply custom styling for leaves
    return hasAppliedLeave ? "markOnLeave disabled" : "";
  };

  return (
    <Box>
      {/* <Text className="parentEditProfileHeading">Personal</Text> */}
      <SimpleGrid gap="16px" mt="16px">
        <GridItem>
          <FormControl isInvalid={sessionData?.expertError}>
            <FormLabel className="personalFormLabel">
              Which expert do you want to book session with?
            </FormLabel>
            <Select
              onChange={(e) => handleFormDataChange("expert", e, "expertError")}
              name="expert"
              value={sessionData?.expert}
              selectedOptionStyle="check"
              placeholder="Select option"
              className="profileFormSelect"
              hideSelectedOptions={false}
              selectedOptionColorScheme="green"
              options={expertOptions}
              isSearchable={false}
            />
            {sessionData?.expertError && (
              <FormErrorMessage className="personalErrorMessage">
                {sessionData?.expertError}
              </FormErrorMessage>
            )}
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={sessionData?.serviceError}>
            <FormLabel className="personalFormLabel">
              Which service do you want for these sessions?
            </FormLabel>
            <Select
              onChange={(e) =>
                handleFormDataChange("service", e.value, "serviceError")
              }
              name="service"
              value={{
                label: sessionData?.service,
                value: sessionData?.service,
              }}
              selectedOptionStyle="check"
              placeholder="Select option"
              className="profileFormSelect"
              hideSelectedOptions={false}
              selectedOptionColorScheme="green"
              options={serviceTypeOptions}
              isSearchable={false}
              isDisabled={serviceTypeOptions.length === 0}
            />
            {sessionData?.serviceError && (
              <FormErrorMessage className="personalErrorMessage">
                {sessionData?.serviceError}
              </FormErrorMessage>
            )}
          </FormControl>
        </GridItem>

        <GridItem>
          <FormControl isInvalid={sessionData?.noOfSessionsError}>
            <FormLabel className="personalFormLabel">
              How many sessions do you want to book?
            </FormLabel>
            <InputGroup>
              <Input
                type="number"
                onChange={(e) =>
                  handleFormDataChange(
                    e.target.name,
                    e.target.value,
                    "noOfSessionsError"
                  )
                }
                min={1}
                max={user?.cureentBalance}
                name="noOfSessions"
                value={sessionData?.noOfSessions}
                placeholder="Enter no of sessions you want to book"
                maxLength={30}
                border="1px solid #A6B8C4"
                className="myProfileFormInput"
              />
            </InputGroup>
            {sessionData?.noOfSessionsError && (
              <FormErrorMessage className="personalErrorMessage">
                {sessionData?.noOfSessionsError}
              </FormErrorMessage>
            )}
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={sessionData?.startDateError}>
            <FormLabel className="personalFormLabel">
              When do you want to start these session?
            </FormLabel>
            <Calendar
              name="startDate"
              prevLabel={<Image src={BACK_CARET} />}
              nextLabel={<Image src={NEXT_CARET} />}
              calendarType="US"
              prev2Label={null}
              next2Label={null}
              view="month"
              onChange={handleCalendarChange}
              value={calenderStartDate}
              tileClassName={renderDayTileClass}
              minDate={moment().add(1, "day").toDate()}
              maxDate={
                user?.validTill
                  ? getDate(user?.validTill)
                  : moment().add(60, "day").toDate()
              }
            />
            {sessionData?.startDateError && (
              <FormErrorMessage className="personalErrorMessage">
                {sessionData?.startDateError}
              </FormErrorMessage>
            )}
          </FormControl>
        </GridItem>
      </SimpleGrid>
    </Box>
  );
};
export default SessionDetails;

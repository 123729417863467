import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { useDispatch } from "react-redux";
import { toggleModalOpen } from "../../../../modules/actions";

const SingleCheckpointModal = ({
  isOpen,
  onClose,
  checkPointNumber,
  startCheckpoint,
  completeCheckpoint,
  checkpointFunction,
  setShowCheckpoints,
}) => {
  const dispatch = useDispatch();

  const blueBtnHandler = () => {
    if (checkpointFunction?.btn === "Start") {
      startCheckpoint(checkPointNumber);
      setShowCheckpoints(true);
    } else {
      completeCheckpoint(checkPointNumber);
    }
    dispatch(toggleModalOpen(false));
    onClose();
  };

  return (
    <Modal
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          fontSize="14px"
          fontWeight="600"
          lineHeight="24px"
          letterSpacing="0.15px"
          color="#141719"
          pt="16px"
          pl="16px"
          pb={0}
        >
          {checkpointFunction?.handler}
        </ModalHeader>
        <ModalBody pt="8px" pl="0" pr={0}>
          <Text
            fontSize="14px"
            fontWeight="500"
            lineHeight="20px"
            px="16px"
            color="#141719"
          >
            {checkpointFunction?.text1}
          </Text>
          <Text
            fontSize="14px"
            fontWeight="500"
            lineHeight="20px"
            color="#141719"
            px="16px"
            mt="8px"
          >
            {checkpointFunction?.text2}
          </Text>
          <Flex
            align="center"
            justify="flex-end"
            borderTop="1px solid #a6b8c4"
            mt="16px"
            p="12px"
          >
            {/* <Text
              fontWeight="600"
              fontSize="14px"
              lineHeight="20px"
              color="#4299e1"
              cursor="pointer"
            >
              Learn More
            </Text> */}
            <Box>
              <Button
                px="12px"
                py="6px"
                borderRadius="10px"
                border="1px solid #a6b8c4"
                bg="white"
                color="#141719"
                _hover={{
                  bg: "white",
                  color: "#141719",
                }}
                mr="8px"
                onClick={() => {
                  dispatch(toggleModalOpen(false));
                  onClose();
                }}
              >
                Cancel
              </Button>
              <Button
                _hover={{
                  bg: "#4299e1",
                  color: "white",
                }}
                px="12px"
                py="6px"
                borderRadius="10px"
                bg="#4299e1"
                color="white"
                onClick={blueBtnHandler}
              >
                {checkpointFunction?.btn}
              </Button>
            </Box>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SingleCheckpointModal;

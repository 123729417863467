import { Box, Button, Flex, Image, Spinner, Text } from "@chakra-ui/react";
import { CLOSE } from "../../../../constant/images";
import React from "react";
import { useSelector } from "react-redux";
import "../../MyStudents/myStudents.css";

const OverlayComponent = ({
  children,
  title,
  setOverlayOpen,
  closer,
  setServicesData,
  handleNextStep,
  apiLoader,
  next,
  btnDisabled,
  handleClearData,
}) => {
  const reduxState = useSelector((state) => state);

  const handleCloseTrue = () => {
    if (title === "Edit services") {
      setServicesData({
        serviceTypes: reduxState?.user?.profile?.serviceTypes || [],
        condition: reduxState?.user?.profile?.condition || [],
        languages: reduxState?.user?.profile?.languages || [],
        startingAgeRange: reduxState?.user?.profile?.startingAgeRange || 0,
        endingAgeRange: reduxState?.user?.profile?.endingAgeRange || 0,
        yearsOfExperience: reduxState?.user?.profile?.yearsOfExperience || 0,
        noServiceTypes: false,
        noStartingAgeRange: false,
        noEndingAgeRange: false,
        noYearsOfExperience: false,
        noCondition: false,
        noLanguages: false,
      });
    } else if (
      title === "Apply for leave" ||
      title === "Schedule session" ||
      title === "Edit profile"
    ) {
      handleClearData();
    }
    setOverlayOpen(false);
  };

  const handleOverlayCloser = () => {
    closer ? handleCloseTrue() : setOverlayOpen(false);
  };

  return (
    <Box>
      <Box className="overlayComponentMainBox"></Box>
      <Box className="overlayComponentContentBox">
        <Flex className="overlayComponentContentBoxFlex">
          <Text className="overlayComponentContentBoxFlexTitle">{title}</Text>
          <Image
            className="overlayComponentContentBoxFlexImage"
            onClick={handleOverlayCloser}
            src={CLOSE}
          />
        </Flex>
        <Box className="overlayComponentContentBoxChildren">{children}</Box>
        <Flex className="overlayComponentContentBoxBtnsFlex">
          <Button
            onClick={handleOverlayCloser}
            className="overlayComponentContentBoxBtn1"
          >
            Back
          </Button>
          <Button
            className="overlayComponentContentBoxBtn2"
            color={!btnDisabled && !apiLoader ? "white" : "#91A2AE"}
            bg={!btnDisabled && !apiLoader ? "#4299E1" : "#F3F8FB"}
            onClick={handleNextStep}
            disabled={apiLoader && !btnDisabled}
            cursor={!btnDisabled && !apiLoader ? "disabled" : "pointer"}
          >
            {apiLoader ? (
              <Spinner />
            ) : (
              <Text>
                {title === "Apply for leave"
                  ? "Apply"
                  : title === "Schedule session"
                  ? "Book Session"
                  : "Save"}
              </Text>
            )}
          </Button>
        </Flex>
      </Box>
    </Box>
  );
};

export default OverlayComponent;

import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getParentToken } from "../../../modules/actions";
import Loader from "../../../common/components/Loader";
import { checkAuth } from "../../../utils/firebase";

const ParentLogin = () => {
  const dispatch = useDispatch();
  const loader = useSelector((state) => state.loader);
  let { token } = useParams();

  useEffect(() => {
    if (token) {
      localStorage.setItem("kpToken", token);
      dispatch(getParentToken(token));
    } else {
      checkAuth();
    }
  }, []);

  return (
    <div> {loader.mainLoader ? <Loader text="“Please wait....”" /> : null}</div>
  );
};
export default ParentLogin;

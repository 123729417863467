import React, { useState, useEffect } from "react";
import {
  SimpleGrid,
  GridItem,
  FormControl,
  FormLabel,
  Input,
  Box,
  Button,
  Flex,
  FormErrorMessage,
  Spinner,
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import {
  areaOfConcerns,
  serviceOptions,
} from "../../../../constant/selectOptions";
import { toggleAPILoader, getUserProfile } from "../../../../modules/actions";
import { saveUserProfileService } from "../../../../modules/services";
import { useDispatch, useSelector } from "react-redux";
import { Toaster } from "../../../../common/components/Toaster";

import "../onboarding.css";

const Services = ({ activeStep, nextStep, prevStep }) => {
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state.user);
  const { apiLoader } = useSelector((state) => state.loader);
  const [formData, setFormData] = useState({
    serviceType: [],
    startingAge: "",
    endingAge: "",
    experience: "",
    condition: [],
  });

  const [next, setNext] = useState(false);

  const [defaultServiceTypes, setDefaultServiceTypes] = useState([]);
  const [defaultCondition, setDefaultCondition] = useState([]);
  const [noServiceType, setNoServiceType] = useState(false);
  const [noStartingAge, setNoStartingAge] = useState(false);
  const [noEndingAge, setNoEndingAge] = useState(false);
  const [noExperience, setNoExperience] = useState(false);
  const [noCondition, setNoCondition] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("idToken");
    dispatch(getUserProfile(token));
  }, []);

  useEffect(() => {
    setFormData({
      serviceType:
        profile?.serviceTypes?.length > 0
          ? profile?.serviceTypes?.map((item) => {
              return {
                value: item,
                label: item,
              };
            })
          : [],
      startingAge: profile?.startingAgeRange ? profile?.startingAgeRange : "",
      endingAge: profile?.endingAgeRange ? profile?.endingAgeRange : "",
      experience: profile?.yearsOfExperience ? profile?.yearsOfExperience : "",
      condition:
        profile?.condition?.length > 0
          ? profile?.condition?.map((item) => {
              return {
                value: item,
                label: item,
              };
            })
          : [],
    });
  }, [profile]);

  useEffect(() => {
    const { serviceType, startingAge, endingAge, experience, condition } =
      formData;
    if (
      !startingAge ||
      !endingAge ||
      !experience ||
      !serviceType.length > 0 ||
      !condition.length > 0 ||
      startingAge === endingAge
    ) {
      setNext(false);
    } else {
      setNext(true);
    }
  }, [formData]);

  const handleFormDataChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleNextStep = () => {
    const { serviceType, startingAge, endingAge, experience, condition } =
      formData;

    setNoServiceType(serviceType.length === 0);
    let validStartingAge =
      startingAge === "" ||
      Number(startingAge) > 16 ||
      Number(startingAge) < 0 ||
      Number(startingAge) > Number(endingAge) ||
      Number(startingAge) === Number(endingAge);
    setNoStartingAge(validStartingAge);
    let validEndingRange =
      endingAge === "" ||
      Number(endingAge) > 16 ||
      Number(startingAge) > Number(endingAge) ||
      Number(startingAge) === Number(endingAge);
    setNoEndingAge(validEndingRange);
    let validExperience =
      experience === "" || Number(experience) < 0 || Number(experience) >= 100;
    setNoExperience(validExperience);
    setNoCondition(condition.length === 0);

    if (
      condition.length > 0 &&
      serviceType.length > 0 &&
      !validExperience &&
      !validStartingAge &&
      !validEndingRange
    ) {
      const formattedFormData = {
        condition: formData.condition.map(function (obj) {
          return obj.value;
        }),
        serviceTypes: formData.serviceType.map(function (obj) {
          return obj.value;
        }),
        startingAgeRange: Number(formData.startingAge),
        endingAgeRange: Number(formData.endingAge),
        yearsOfExperience: Number(formData.experience),
      };
      // dispatch(saveUserProfileImage(formattedFormData, 2));
      // setApiLoaderStatus(true);
      dispatch(toggleAPILoader(true));
      saveUserProfileService(2, formattedFormData)
        .then((res) => {
          dispatch(toggleAPILoader(false));
          nextStep();
        })
        .catch((error) => {
          console.log(error);
          dispatch(toggleAPILoader(false));
          Toaster(error.response.data.message, "error");
        });
    }
  };

  return (
    <Box>
      <SimpleGrid className="servicesSimpleGrid">
        <GridItem colSpan={2}>
          <FormControl isInvalid={noServiceType} isRequired>
            <FormLabel
              className="servicesFormLabel"
              style={{
                color: noServiceType && "#BA1A1A",
              }}
              sx={{
                "& .chakra-form__required-indicator": {
                  color: noServiceType && "#BA1A1A",
                },
              }}
            >
              Service Type
            </FormLabel>
            <Box
              border="1px solid #A6B8C4"
              fontWeight="normal"
              borderRadius={7}
              sx={{
                "& .chakra-divider": {
                  opacity: 0,
                },
                "& .css-wtf6ne": {
                  bg: "white",
                  border: "none",
                },
              }}
              backgroundColor="white"
            >
              <Select
                isMulti
                onChange={(e) => handleFormDataChange("serviceType", e)}
                name="serviceType"
                value={formData.serviceType}
                closeMenuOnSelect={false}
                selectedOptionStyle="check"
                placeholder=""
                className="commonFormSelect"
                hideSelectedOptions={false}
                selectedOptionColorScheme="green"
                options={serviceOptions}
              />
            </Box>
            {serviceOptions && (
              <FormErrorMessage className="servicesErrorMessage">
                This field cannot be empty
              </FormErrorMessage>
            )}
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 2, md: 1 }}>
          <FormControl isInvalid={noStartingAge} isRequired>
            <FormLabel
              className="servicesFormLabel"
              style={{
                color: noStartingAge && "#BA1A1A",
              }}
              sx={{
                "& .chakra-form__required-indicator": {
                  color: noStartingAge && "#BA1A1A",
                },
              }}
            >
              Starting Age Range (youngest age) of clients
            </FormLabel>
            <Input
              type="number"
              onChange={(e) =>
                handleFormDataChange(e.target.name, e.target.value)
              }
              value={formData.startingAge}
              name="startingAge"
              className="servicesFormInput"
              style={{ border: !noStartingAge ? "1px solid #A6B8C4" : "1px" }}
              backgroundColor="white"
            />
            {noStartingAge && (
              <FormErrorMessage className="servicesErrorMessage">
                Provide valid starting age less than 16
              </FormErrorMessage>
            )}
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 2, md: 1 }}>
          <FormControl isInvalid={noEndingAge} isRequired>
            <FormLabel
              className="servicesFormLabel"
              style={{
                color: noEndingAge && "#BA1A1A",
              }}
              sx={{
                "& .chakra-form__required-indicator": {
                  color: noEndingAge && "#BA1A1A",
                },
              }}
            >
              Ending Age Range (oldest age) of clients
            </FormLabel>
            <Input
              onChange={(e) =>
                handleFormDataChange(e.target.name, e.target.value)
              }
              type="number"
              value={formData.endingAge}
              className="servicesFormInput"
              name="endingAge"
              style={{ border: !noEndingAge ? "1px solid #A6B8C4" : "1px" }}
              backgroundColor="white"
            />
            {noEndingAge && (
              <FormErrorMessage className="servicesErrorMessage">
                Provide ending age less than 16 and greater than starting age
              </FormErrorMessage>
            )}
          </FormControl>
        </GridItem>
        <GridItem colSpan={2}>
          <FormControl isInvalid={noCondition} isRequired>
            <FormLabel
              className="servicesFormLabel"
              style={{
                color: noCondition && "#BA1A1A",
              }}
              sx={{
                "& .chakra-form__required-indicator": {
                  color: noCondition && "#BA1A1A",
                },
              }}
            >
              Condition
            </FormLabel>
            <Box
              border="1px solid #A6B8C4"
              fontWeight="normal"
              borderRadius={7}
              sx={{
                "& .chakra-divider": {
                  opacity: 0,
                },
                "& .css-wtf6ne": {
                  bg: "white",
                  border: "none",
                },
              }}
              backgroundColor="white"
            >
              <Select
                isMulti
                name="condition"
                onChange={(e) => handleFormDataChange("condition", e)}
                closeMenuOnSelect={false}
                selectedOptionStyle="check"
                placeholder=""
                value={formData.condition}
                className="commonFormSelect"
                hideSelectedOptions={false}
                selectedOptionColorScheme="green"
                options={areaOfConcerns}
              />
            </Box>
            {noCondition && (
              <FormErrorMessage className="servicesErrorMessage">
                This field cannot be empty
              </FormErrorMessage>
            )}
          </FormControl>
        </GridItem>
        <GridItem colSpan={2}>
          <FormControl isInvalid={noExperience} isRequired>
            <FormLabel
              className="servicesFormLabel"
              style={{
                color: noExperience && "#BA1A1A",
              }}
              sx={{
                "& .chakra-form__required-indicator": {
                  color: noExperience && "#BA1A1A",
                },
              }}
            >
              Years of Experience
            </FormLabel>
            <Input
              onChange={(e) =>
                handleFormDataChange(e.target.name, e.target.value)
              }
              type="number"
              name="experience"
              value={formData.experience}
              className="servicesFormInput"
              border="1px solid #A6B8C4"
              backgroundColor="white"
            />
            {noExperience && (
              <FormErrorMessage className="servicesErrorMessage">
                Please mention a valid experience
              </FormErrorMessage>
            )}
          </FormControl>
        </GridItem>
      </SimpleGrid>
      <Flex mt="40px" justify="flex-end" gap="24px">
        <Button
          onClick={prevStep}
          color="#4299E1"
          size="sm"
          variant="ghost"
          px="40px"
          py="20px"
        >
          Back
        </Button>
        <Box>
          <Button
            color={next && !apiLoader ? "white" : "#91A2AE"}
            size="sm"
            bg={next && !apiLoader ? "#4299E1" : "#F3F8FB"}
            px="40px"
            py="20px"
            onClick={handleNextStep}
            disabled={next ? false : true}
          >
            {apiLoader ? (
              <Spinner
                bg="#f3f8fb"
                emptyColor="gray.200"
                color="blue.500"
                position="absolute"
              />
            ) : (
              "Next"
            )}
          </Button>
        </Box>
      </Flex>
    </Box>
  );
};

export default Services;
